const actionPrefix = "FACE_CAPTURE_";

export const PREVIEW = actionPrefix + "PREVIEW";
export const RETRY = actionPrefix + "RETRY";
export const RESET = actionPrefix + "RESET";
export const CAMERA_CHANGED = actionPrefix + "CAMERA_CHANGED";

export function preview(payload) {
  //TODO@gva: this affects navigation and should be moved away from facecapture actions
  return {
    type: PREVIEW,
    payload,
  };
}

export function retry() {
  return { type: RETRY };
}

export function reset() {
  return { type: RESET }
}