export const SWITCHED_TO_MOBILE_PHONE = "SWITCHED_TO_MOBILE_PHONE";
export const SWITCHED_TO_MOBILE_TRANSACTION = "SWITCHED_TO_MOBILE_TRANSACTION";
export const API_SET_APPLICATION_DATA = "API_SET_APPLICATION_DATA";
export const API_SET_APPLICATION_PARAMS = "API_SET_APPLICATION_PARAMS";

export const switchedToMobilePhone = (payload) => (dispatch) => {
  return dispatch({ type: SWITCHED_TO_MOBILE_PHONE, payload });
};

export const switchedToMobileTransaction = (payload) => (dispatch) => {
  return dispatch({ type: SWITCHED_TO_MOBILE_TRANSACTION, payload });
};

export const setApplicationData = (payload) => {
  return { type: API_SET_APPLICATION_DATA, payload };
};

export const USER = "USER";
export const PARTNER = "PARTNER";
export const IFRAME = "IFRAME";
export const USERMANUAL = "USERMANUAL"

export const setApplicationParams = (playload) => (dispatch) => {
  return dispatch({ type: API_SET_APPLICATION_PARAMS, playload})
}
