export function drawOutherLines(
  ctx,
  edgeOverlay,
  offsetHeight,
  offsetWidth,
  img,
  allRetriesFailed,
  isDocumentVertical
) {
  img.onload = () => {
    // console.log(ctx,
    //   edgeOverlay,
    //   offsetHeight,
    //   offsetWidth,
    //   allRetriesFailed,
    //   isDocumentVertical)
    if (isDocumentVertical) {
      let documentWidth = offsetHeight / 1.5858;
      let upperLeftX = (offsetWidth - documentWidth) / 2;

      ctx.drawImage(
        img,
        (offsetWidth - documentWidth) / 2,
        edgeOverlay,
        documentWidth,
        offsetHeight - edgeOverlay * 2
      );
      if (!allRetriesFailed) {
        ctx.beginPath();
        ctx.moveTo(
          upperLeftX - edgeOverlay + 0.4 * edgeOverlay,
          edgeOverlay * 2
        );
        ctx.lineTo(
          upperLeftX - edgeOverlay + 0.4 * edgeOverlay,
          edgeOverlay - 0.4 * edgeOverlay
        );
        ctx.lineTo(upperLeftX + edgeOverlay, edgeOverlay - 0.4 * edgeOverlay);
        ctx.moveTo(
          upperLeftX - edgeOverlay + 0.4 * edgeOverlay,
          offsetHeight - 2 * edgeOverlay
        );
        ctx.lineTo(
          upperLeftX - edgeOverlay + 0.4 * edgeOverlay,
          offsetHeight - 0.4 * edgeOverlay
        );
        ctx.lineTo(upperLeftX + edgeOverlay, offsetHeight - 0.4 * edgeOverlay);
        ctx.moveTo(
          offsetWidth - upperLeftX - edgeOverlay,
          edgeOverlay - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - upperLeftX + 0.6 * edgeOverlay,
          edgeOverlay - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - upperLeftX + 0.6 * edgeOverlay,
          edgeOverlay * 2
        );
        ctx.moveTo(
          offsetWidth - upperLeftX - edgeOverlay,
          offsetHeight - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - upperLeftX + 0.6 * edgeOverlay,
          offsetHeight - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - upperLeftX + 0.6 * edgeOverlay,
          offsetHeight - 2 * edgeOverlay
        );
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#4AB336";
        ctx.stroke();
      }
    } else {
      ctx.drawImage(
        img,
        edgeOverlay,
        edgeOverlay,
        offsetWidth - edgeOverlay * 2,
        offsetHeight - edgeOverlay * 2
      );
      if (!allRetriesFailed) {
        ctx.beginPath();
        ctx.moveTo(edgeOverlay - 0.4 * edgeOverlay, edgeOverlay * 2);
        ctx.lineTo(
          edgeOverlay - 0.4 * edgeOverlay,
          edgeOverlay - 0.4 * edgeOverlay
        );
        ctx.lineTo(edgeOverlay * 2, edgeOverlay - 0.4 * edgeOverlay);
        ctx.moveTo(
          edgeOverlay - 0.4 * edgeOverlay,
          offsetHeight - 2 * edgeOverlay
        );
        ctx.lineTo(
          edgeOverlay - 0.4 * edgeOverlay,
          offsetHeight - 0.4 * edgeOverlay
        );
        ctx.lineTo(2 * edgeOverlay, offsetHeight - 0.4 * edgeOverlay);
        ctx.moveTo(
          offsetWidth - 2 * edgeOverlay,
          edgeOverlay - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - 0.4 * edgeOverlay,
          edgeOverlay - 0.4 * edgeOverlay
        );
        ctx.lineTo(offsetWidth - 0.4 * edgeOverlay, edgeOverlay * 2);
        ctx.moveTo(
          offsetWidth - 2 * edgeOverlay,
          offsetHeight - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - 0.4 * edgeOverlay,
          offsetHeight - 0.4 * edgeOverlay
        );
        ctx.lineTo(
          offsetWidth - 0.4 * edgeOverlay,
          offsetHeight - 2 * edgeOverlay
        );
        ctx.lineWidth = 4;
        ctx.strokeStyle = "#4AB336";
        ctx.stroke();
      }
    }
  };
}
