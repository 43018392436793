export const ID_CARD = "ID_CARD";
export const PASSPORT = "PASSPORT";
export const DRIVERS_LICENCE = "DRIVERS_LICENCE";
export const OTHER_DOCUMENT = "OTHER_DOCUMENT";
export const ID_CARD_PORT = "ID_CARD_PORT";
export const DRIVERS_LICENCE_PORT = "DRIVERS_LICENCE_PORT";
export const OTHER_DOCUMENT_PORT = "OTHER_DOCUMENT_PORT";

export const COPY_ID_CARD = "COPY_ID_CARD";
export const COPY_PASSPORT = "COPY_PASSPORT";
export const MONK_ID_DOCUMENT = "MONK_ID_DOCUMENT";
export const OFFICIAL_ID_DOCUMENT = "OFFICIAL_ID_DOCUMENT";

export const SEC_OFFICIAL_ID_CARD = "SEC_OFFICIAL_ID_CARD"
export const SEC_GOVERNMENT_ID_CARD = "SEC_GOVERNMENT_ID_CARD"
export const SEC_MONK_ID_DOCUMENT = "SEC_MONK_ID_DOCUMENT"

export const selectSecondDoc = [
    {
        id: 1,
        nameth: "หลักฐานแสดงตนจากทางราชการ",
        nameen: "Official Identity document",
        tag: "SEC_OFFICIAL_ID_CARD"
    },
    {
        id: 2,
        nameth: "เอกสารสำเนาบัตรข้าราชการ หรือ สำเนาบัตรสมาชิก กบข.",
        nameen: "Government official card Identity document",
        tag: "SEC_GOVERNMENT_ID_CARD"
    },
    {
        id: 3,
        nameth: "ใบรับรองสมณศักดิ์พระ",
        nameen: "Monk Honor Certificate",
        tag: "SEC_MONK_ID_DOCUMENT"
    }
]