//TODO@gva: to be deprecated in favor of enumerateCameraDevices
export function getCameraIds() {
  return navigator.mediaDevices.enumerateDevices()
    .then((devices) => {
      return devices
        .filter((device) => {
          return (
            device.kind === "videoinput"
          );
        })
        .map((device) => {
            try{
                let facingMode = '';
                let fm = device.getCapabilities().facingMode;
                // console.log(device.getCapabilities());
                if(Array.isArray(fm)){
                    facingMode = device.getCapabilities().facingMode[0];
                }else{
                    facingMode = device.getCapabilities().facingMode;
                }
                if(facingMode !== undefined){
                    let obj = { cameraId: device.deviceId,  label: device.label === undefined ? "": device.label , facingMode: facingMode };
                    return obj;
                }else{
                    return { cameraId: device.deviceId, label: device.label === undefined ? "": device.label  };
                }
            }catch(err){
                return { cameraId: device.deviceId === undefined ? "n/a": device.deviceId , label: device.label === undefined ? "": device.label  };
            }

        });
    })
    .catch(function (err) {
      // console.log(err.name + ": => " + err.message);
    });
}

export function enumerateCameraDevices() {
  return navigator.mediaDevices
    .enumerateDevices()
    .then((devices) => devices.filter(deviceFilter))
    .catch(function (err) {
      // console.log(err.name + ": " + err.message);
    });
}

function deviceFilter(device) {
  return (
    device.kind === "videoinput" &&
    device.label &&
    !device.label.includes(" IR ")
  );
}
