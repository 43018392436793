import {
  SELECT_DOC_TYPE,
  PREVIEW,
  RETRY,
  RESET_RETRIES,
  SET_CROPPED_IMAGE,
  CREATE_INSTANCE,
  FIRST_TIME_INIT,
  RETRY_PROCESSING,
  SET_DOCUMENT_QUALITY_RETRIES,
  RETRY_DOCUMENT_QUALITY,
  RESET_ALL_RETRIES_FAILED,
  RESETING_DOCUMENT_QUALITY_RETRIES,
  SET_CROPPED_FIRST_PAGE,
  NEW_DOCUMENT_SELECTED,
  RESET_CONFIRMED,
  SET_RESPONSE_DOCUMENT_DATA,
  SET_CROPPED_SECOND_PAGE,
  DOPA_RETRY,
  CHECK_FROM_PORTRAIT_PAGE,
  RETRY_INCORRECT_IDCARD,
} from "../actions/documentCapture";

const defaultState = {
  firstTimeInitialized: false,
  documentType: undefined,
  blobFirstPage: undefined,
  blobSecondPage: undefined,
  base64CroppedFirstPage: undefined,
  base64CroppedSecondPage: undefined,
  documentQualityRetriesFront: undefined,
  documentQualityRetriesBack: undefined,
  retries: 0,
  message: undefined,
  processingRetriesCounter: 0,
  allRetriesFailed: false,
  summaryScorePresent: undefined,
  fromPortrait: false,
  countIncorrectIDCard: 3,
};

export function documentCapture(state = defaultState, action) {
  switch (action.type) {
    case CREATE_INSTANCE:
      return {
        ...state,
        docCaptureInstance: action.payload,
      };
    case FIRST_TIME_INIT:
      return {
        ...state,
        firstTimeInitialized: true,
      };
    case SELECT_DOC_TYPE:
      return {
        ...state,
        documentType: action.payload,
      };
    case NEW_DOCUMENT_SELECTED:
      return {
        ...state,
        blobFirstPage: undefined,
        blobSecondPage: undefined,
        base64CroppedFirstPage: undefined,
        base64CroppedSecondPage: undefined,
        retries: 0,
      };

    case PREVIEW:
      if (state.base64CroppedFirstPage) {
        // alert("1")
        return {
          ...state,
          blobSecondPage: action.payload.blob,
          base64CroppedSecondPage: action.payload.base64,
          message: action.payload.message,
          isRejected: action.payload.isRejected,
          captureMethod: action.payload.captureMethod,
          allRetriesFailed: action.payload.allRetriesFailed,
          summaryScorePresent: action.payload.summaryScorePresent,
        };
      }
      // alert("2")
      return {
        ...state,
        blobFirstPage: action.payload.blob,
        base64CroppedFirstPage: action.payload.base64,
        blobSecondPage: undefined,
        base64CroppedSecondPage: undefined,
        message: action.payload.message,
        isRejected: action.payload.isRejected,
        captureMethod: action.payload.captureMethod,
        allRetriesFailed: action.payload.allRetriesFailed,
        summaryScorePresent: action.payload.summaryScorePresent,
      };
    case RESET_ALL_RETRIES_FAILED: {
      return {
        ...state,
        allRetriesFailed: false,
      };
    }
    case RESET_RETRIES: {
      return {
        ...state,
        retries: 0,
      };
    }
    case SET_DOCUMENT_QUALITY_RETRIES: {
      // console.log(action.payload)
      return {
        ...state,
        documentQualityRetriesFront: action.payload,
        documentQualityRetriesBack: action.payload,
      };
    }
    case RETRY_DOCUMENT_QUALITY: {
      if (action.payload === "front") {
        return {
          ...state,
          documentQualityRetriesFront: state.documentQualityRetriesFront - 1,
        };
      } else {
        return {
          ...state,
          documentQualityRetriesBack: state.documentQualityRetriesBack - 1,
        };
      }
    }
    case RETRY:
      return {
        ...state,
        //delete only if we didn't go to second page yet
        blobFirstPage: state.blobSecondPage ? state.blobFirstPage : undefined,
        blobSecondPage: undefined,
        croppedResponseBase64: undefined,
        retries: state.retries + 1,
        base64CroppedFirstPage: state.base64CroppedSecondPage
          ? state.base64CroppedFirstPage
          : undefined,
        // base64CroppedFirstPage: undefined,
        base64CroppedSecondPage: undefined,
      };
    case DOPA_RETRY:
      return {
        ...state,
        //delete only if we didn't go to second page yet
        blobFirstPage: state.blobSecondPage ? state.blobFirstPage : undefined,
        blobSecondPage: undefined,
        croppedResponseBase64: undefined,
        retries: 0,
        base64CroppedFirstPage: state.base64CroppedSecondPage
          ? state.base64CroppedFirstPage
          : undefined,
        base64CroppedSecondPage: undefined,
      };
    case SET_CROPPED_IMAGE: {
      return {
        ...state,
        croppedResponseBase64: action.payload,
      };
    }
    case SET_CROPPED_FIRST_PAGE: {
      return {
        ...state,
        base64CroppedFirstPage: action.payload,
      };
    }
    case SET_CROPPED_SECOND_PAGE: {
      return {
        ...state,
        base64CroppedSecondPage: action.payload,
      };
    }
    case RETRY_PROCESSING: {
      return {
        ...state,
        blobFirstPage: undefined,
        blobSecondPage: undefined,
        croppedResponseBase64: undefined,
        base64CroppedFirstPage: undefined,
        base64CroppedSecondPage: undefined,
        processingRetriesCounter: state.processingRetriesCounter + 1,
      };
    }
    case RESET_CONFIRMED: {
      return {
        ...state,
        confirmed: false,
      };
    }

    case RESETING_DOCUMENT_QUALITY_RETRIES: {
      if (action.payload === "front") {
        return {
          ...state,
          documentQualityRetriesFront:
            state.documentQualityRetriesFront < 2
              ? 2
              : state.documentQualityRetriesFront,
        };
      } else {
        return {
          ...state,
          documentQualityRetriesBack:
            state.documentQualityRetriesBack < 2
              ? 2
              : state.documentQualityRetriesBack,
        };
      }
    }
    case CHECK_FROM_PORTRAIT_PAGE : {

      return {
        ...state,
        fromPortrait: action.payload
      }
    }
    case RETRY_INCORRECT_IDCARD: {
      return {
        ...state,
        countIncorrectIDCard: state.countIncorrectIDCard - 1,
      }
    }
    default:
      return state;
  }
}

const defaultDataDoc = {

}
export function documentCaptureSetData(state = defaultDataDoc, action) {
  switch (action.type) {
    case SET_RESPONSE_DOCUMENT_DATA:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state;
  }
}
