import React from "react";
import { connect } from "react-redux";
import { go } from "logic/actions/navigation";
import "../../LinkNativeCapture/style.css"

export function DebugLinks({ go, pages }) {
  const items = Object.keys(pages).map(key =>
    <li key={key}>
      <button
        type="button"
        className="button-link"
        onClick={() => go(key)}>
        &#8594; {key.replace("Page", "")}
      </button>
    </li>
  );

  return <ul style={{ position: "absolute", left: 0, zIndex: 10000 }}>{items}</ul>
}

export default connect(null, { go })(DebugLinks);