import styled from 'styled-components'

export const MessageBoxWrapper = styled.div`

     & div.message-box {
        width: 82%;
        border-radius: 3px;
        background-color: white;
        color: rgba(54, 64, 69, 1);
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        margin-bottom: 0.5vh;
        margin-left: 7%;
        padding: 5px;
    }
    
    @media (min-width: 567px) {
        & div.message-box {
           width: 83%;
           border-radius: 3px;
           background-color: white;
           color: rgba(54, 64, 69, 1);
           font-size: 2.2vh;
           line-height: 20px;
           text-align: center;
           margin-bottom: 0.5vh;
           margin-left: 6%;
           padding: 0.5em;
         }
       }
       
       @media (max-height: 1140px) {
        & div.message-box {
           width: 84%;
           border-radius: 3px;
           background-color: white;
           color: rgba(54, 64, 69, 1);
           font-size: 14px;
           line-height: 20px;
           text-align: center;
           margin-top: -5vh;
           margin-left: 6%;
           margin-right: 6%;
           padding: 5px;
         }
       }


    
`;