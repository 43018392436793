const actionPrefix = "NAVIGATION_";

export const NAVIGATION_GO = actionPrefix + "GO";
export const NAVIGATION_NEXT = actionPrefix + "NEXT";
export const NAVIGATION_NEXT_STEP = actionPrefix + "NEXT_STEP";
export const NAVIGATION_PREVIOUS = actionPrefix + "PREVIOUS";

export const go = (payload) => (dispatch) => {
  return dispatch({ type: NAVIGATION_GO, payload });
};

export const nextPage = () => (dispatch) => {
  return dispatch({ type: NAVIGATION_NEXT });
};

export const nextStep = () => (dispatch) => {
  return dispatch({ type: NAVIGATION_NEXT_STEP });
};

export const previous = () => (dispatch) => {
  return dispatch({ type: NAVIGATION_PREVIOUS });
};
