import React from "react";
import { string } from "prop-types";

import "./style.css";

Icon.propTypes = {
  name: string,
  color: string,
  small: string,
  className: string,
};

function Icon({ name, color, small, large, className }) {
  return (
    <svg
      className={`${className || ""} icon ${
        (small ? "icon-sm" : "" || large ? "icon-lg" : "")
      }  icon-${name} icon-${color}`}
    >
      <use xlinkHref={`#icon-${name}`} />
    </svg>
  );
}

export default Icon;
