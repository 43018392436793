import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import "./style.css";
import { useTranslation } from "react-i18next";

export default function SlowNetworkWarning() {
  const [showMessage, setShowMessage] = useState(false);
  let mounted = useRef(true);
  const { t } = useTranslation();
  let dispatch = useDispatch();

  function onClose() {
    setShowMessage(false);
    dispatch(toggleNetworkWarnings(false));
  }

  useEffect(() => {
    setTimeout(() => {
      mounted.current && setShowMessage(true);
    }, 15000);
    return () => {
      mounted.current = false;
    };
  }, []);

  if (showMessage)
    return (
      <div className="slow-network-warning" onClick={onClose}>
        {t('Common.slow_network')}
        <span className="x">X</span>
      </div>
    );
  return null;
}
