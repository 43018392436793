// const START = 'Please center your face so it fills the guide.'
// const AWAIT_RESULTS = 'Please wait for the result. On slow devices it can take a few seconds.'
// const TOO_CLOSE = "Too Close";
// const TOO_CLOSE_ML = 'Too\nClose'
// const TOO_FAR = "Too Far";
// const TOO_FAR_ML = 'Too\nFar'
// const NOT_CENTERED = 'Center Face'
// const NOT_CENTERED_ML = "Center\nFace";
// const HOLD = "Hold...";
// const MOVE = 'Move Device Closer Now'
// const MOVE_ML = `Move \n Closer \n Now`;
// const ANALYSING = "Analysing...";
// const NONE = "";

export function getLabelForResult(result, score) {
  if (result === "PASS") {
    return `PASSED - ${score * 100}%`;
  } else if (result === "FAIL") {
    return `FAILED - ${score * 100}%`;
  }
  return undefined;
}

export function getLabelForMessage(message, UPDATE_TYPES, t) {
  switch (message) {
    case UPDATE_TYPES.READY:
    case UPDATE_TYPES.END_CAPTURE:
      return "";
    case UPDATE_TYPES.NOT_CENTERED:
      return t("PageInstructionsFaceLiveness3D.label_not_centered");
    case UPDATE_TYPES.TOO_FAR:
      return t("PageInstructionsFaceLiveness3D.label_too_far");
    case UPDATE_TYPES.TOO_CLOSE:
      return t("PageInstructionsFaceLiveness3D.label_too_close");
    case UPDATE_TYPES.HOLD:
      return t("PageInstructionsFaceLiveness3D.label_hold");
    case UPDATE_TYPES.MOVE_CLOSER:
      return t("PageInstructionsFaceLiveness3D.label_move_closer_now");
    default:
      return undefined;
  }
}
