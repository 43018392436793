/*global Daon*/
/*eslint no-undef: "error"*/
import {
  CACHE_LIVENESS_TEST,
  writeToLocalCache,
} from "logic/localCacheAdapter";
import { store } from "../store";

const actionPrefix = "LIVENESS_TEST_";

export const CREATE_INSTANCE = actionPrefix + "CREATE_INSTANCE";
export const SET_TEST_DATA = actionPrefix + "SET_TEST_DATA";
export const SET_VIDEO_ID = actionPrefix + "SET_VIDEO_ID";
export const FAILED_ATTEMPT = actionPrefix + "FAILED_ATTEMPT";
export const RETRY = actionPrefix + "RETRY";
export const FIRST_TIME_INIT = actionPrefix + "FIRST_TIME_INIT";
export const ERROR_MESSAGE = actionPrefix + "ERROR_MESSAGE";

export function createInstance() {
  return {
    type: CREATE_INSTANCE,
    payload: new Daon.CLRCapture(true),
  };
}

export function firstTimeInit() {
  return {
    type: FIRST_TIME_INIT,
  };
}

export const setTestData = (payload) => (dispatch) => {
  dispatch({
    type: SET_TEST_DATA,
    payload,
  });
};

export function setVideoId(payload) {
  return {
    type: SET_VIDEO_ID,
    payload,
  };
}

function reduceRetries() {
  const { livenessTest } = store.getState();
  try {
    if (livenessTest && livenessTest.livenessTestData) {
      // TODO@Ljubo: Leave this for demo, later check on every place if retriesLeft is null
      let retriesLeft = 10;
      if (livenessTest.livenessTestData.items[0].maxRetries) {
        retriesLeft = livenessTest.livenessTestData.items[0].maxRetries - 1;
      }
      if (livenessTest.livenessTestData.retriesLeft !== undefined)
        retriesLeft = livenessTest.livenessTestData.retriesLeft - 1;
      writeToLocalCache(
        { ...livenessTest.livenessTestData, retriesLeft },
        CACHE_LIVENESS_TEST
      );
    }
  } catch (err) {
    // console.log("Failed to update retries");
  }
}

export function failedAttempt() {
  reduceRetries();
  return {
    type: FAILED_ATTEMPT,
  };
}

export function retryLiveness() {
  const { livenessTest } = store.getState();
  return {
    type: RETRY,
    payload:
      livenessTest &&
      livenessTest.livenessTestData &&
      livenessTest.livenessTestData.items[0].type,
  };
}

export function errorMessage(payload) {
  return {
    type: ERROR_MESSAGE,
    payload,
  };
}
