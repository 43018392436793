export default function registerDeviceUnlockEvent() {
  let timestamp = new Date().getTime();
  function checkUnlock() {
    let current = new Date().getTime();
    if (current - timestamp > 4000) {
      let event = document.createEvent("Events");
      event.initEvent("deviceunlock", true, true);
      document.dispatchEvent(event);
    }
    timestamp = current;
  }

  window.setInterval(checkUnlock, 1000);
}
