import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from "react-i18next";
import { go, nextPage } from 'logic/actions/navigation';
import './style.css'
import Webcam from "react-webcam";
import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "Components/PageContent";
import CaptureButtonPanel from "../../CaptureButtonPanel";

import { Modal } from 'react-bootstrap';
import { DOCUMENT_CAPTURE_TYPE2_SELECT } from "../../../logic/enums/pages"
import {
    setImageSecondDocument,
    setStatusImageSecond,
    rollBackImageStatus
} from 'logic/actions/documentCaptureSecondType';
import { blobToBase64 } from 'logic/blobToBase64';
import { flipCamera } from 'logic/actions/documentCaptureConfiguration';
import { store } from 'logic/store';
import { setRearCamera } from "../../../logic/reducers/camera";
// eslint-disable-next-line
import VisiblityChangeHandler from 'Components/modules/common/VisiblityChangeHandler';
import { isIOS, isMobile, isAndroid, isMac } from "logic/deviceType";
import imgwarning from "../../../assets/warning-icon.png";
import {
    changeCamera
} from "logic/actions/documentCaptureConfiguration";
import { secWidth, secHeight } from "logic/documentResolution"

const START = 0;
class PageDocumentCaptureType2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pending: true,
            image: null,
            showAndHide: false,
            formState: START,
            cameraPermission: true,
            isReady: false,
            spinner: true,
            showSwitchCameraButton: store.getState().camera.cameras.length > 1,
            deviceId: null,
            isMobile: true,
            closeCamera: true,
            facingMode: "environment",
            mirror: false,
            rotateLandscape: false,
            onModalErrorCamera: false
        }
        this.webcam = null
        this.state.deviceId = this.getDefaultCamera();
    }

    getDefaultCamera() {
        let cam = undefined;
        try {
            const rearCamera = store.getState().camera.rearCamera;
            if (rearCamera !== undefined) {
                cam = rearCamera;
                store.dispatch(setRearCamera(cam));
                // console.log("using default rearCamera : " + cam.cameraId);
            } else if (store.getState().cameras !== undefined && store.getState().camera.cameras.length > 0) {
                let index = store.getState().cameras.length - 1;
                cam = store.getState().camera.cameras[index];
                // console.log("assigning default rearCamera : " + cam.cameraId );
                store.dispatch(setRearCamera(cam));
            }
            if (cam.cameraId !== undefined) {
                return cam.cameraId;
            } else {
                return "";
            }
        } catch (err) {
            return "";
        }
    }

    componentDidMount() {
        window.scrollBy(1, 2);
        setTimeout(() => {
            window.scrollBy(-1, -2);
        }, 100);
        this.setState({
            isMobile: isMobile(navigator.userAgent),
            mirror: this.props.mirror
        });
        if (isIOS(navigator.userAgent)) this.setState({ isIOS: true });

        window.addEventListener("orientationchange", this.checkScreenOrientation);
        // call event in case user is in landscape mode when first loads
        this.checkScreenOrientation();
    }

    componentDidUpdate() {
        if (this.state.mirror !== this.props.mirror) {
            this.setState({ mirror: this.props.mirror })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("orientationchange", this.checkScreenOrientation);
        this.stop();
    }

    setRef = (webcam) => {
        this.webcam = webcam;
    }

    checkScreenOrientation = () => {
        let screenOrientation = undefined;
        try {
            if (isAndroid(navigator.userAgent) || isIOS(navigator.userAgent) || isMobile(navigator.userAgent) || isMac(navigator.userAgent)) {
                if (window.screen.orientation) {
                    screenOrientation = window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait';
                } else {
                    // iOS/safari
                    screenOrientation = Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait';
                }
            }
        }
        catch (err) { }

        this.setState({
            stsOrientation: screenOrientation,
            rotateLandscape: screenOrientation === "landscape" ? true : false
        })
    }

    stop = () => {
        try {
            let stream = this.webcam.video.srcObject;
            const tracks = stream.getTracks();

            tracks.forEach(track => track.stop());
        } catch (err) { }
        if (this.web !== undefined && this.webcam.video !== undefined) {
            this.webcam.video.srcObject = null;
        }
    };

    getImageBase64 = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const imageSrc = await this.webcam.getScreenshot();
                resolve(imageSrc);
            } catch (err) {
                reject(err.toString());
            }
        })
    }
    capture = async () => {
        this.setState({
            pending: true
        })
        await this.getImageBase64()
            .then((result) => {
                // console.log(result);
                let croppedPage;
                if (result.includes("data:image/jpeg;base64")) {
                    croppedPage = result.replace("data:image/jpeg;base64,", "");
                } else if (result.includes("data:image/png;base64")) {
                    croppedPage = result.replace("data:image/png;base64,", "");
                } else {
                    croppedPage = result
                }
                if (this.props.blobFirstPage === undefined && this.props.statusFirstPage) {
                    this.props.setImageSecondDocument({
                        blob: result,
                        base64Cropped: croppedPage
                    })
                } else if (this.props.blobSecondPage === undefined && this.props.statusSecondPage) {
                    this.props.setImageSecondDocument({
                        blob: result,
                        base64Cropped: croppedPage
                    })
                }
                setTimeout(() => {
                    this.setState({ pending: false })
                    this.props.go(DOCUMENT_CAPTURE_TYPE2_SELECT)
                }, 150)
            })
            .catch((err) => {
                if (!this.props.base64CroppedSecondDocFirstPage && this.props.statusFirstPage) {
                    this.props.rollBackImageStatus("page1");
                } else if (!this.props.base64CroppedSecondDocSecondPage && this.props.statusSecondPage) {
                    this.props.rollBackImageStatus("page2");
                }
                setTimeout(() => {
                    this.setState({ pending: false })
                    this.props.go(DOCUMENT_CAPTURE_TYPE2_SELECT)
                }, 150)
            })
    }

    changeCamera = (cameraId) => {
        this.setState({ showSwitchCameraButton: false });
        this.changeMirror();
        this.setState({
            facingMode: this.props.facingMode === "user" ? "environment" : "user",
            deviceId: cameraId
        })
        this.props.changeCamera({
            cameraId: cameraId,
            // facingMode: this.props.facingMode === "user" ? "environment" : "user",
        });
        this.videoReady({ active: true });
    };

    retryCameraInit = () => {
        this.getDefaultCamera();
        this.setState({
            cameraPermission: true
        })
    }

    // re-enable the switch camera option once stream is available
    videoReady = (res) => {
        if (res.active) {
            this.setState({
                pending: false,
                isReady: true,
                spinner: false
            })
        }
        this.setState({ showSwitchCameraButton: store.getState().camera.cameras.length > 1 });
    }

    videoError = (res) => {
        // console.log(res)
        this.setState({
            cameraPermission: false,
            pending: false,
            spinner: false
        })
    }

    changeMirror = () => {
        this.props.flipCamera({
            mirror: this.props.mirror ? false : true
        })
    }

    onVisibilityChange = (isVisible) => {
        if (!this.state.pending && isVisible) {
            if (this.props.blobFirstPage === undefined && this.props.statusFirstPage) {
                this.props.rollBackImageStatus("page1");
            } else if (this.props.blobSecondPage === undefined && this.props.statusSecondPage) {
                this.props.rollBackImageStatus("page2");
            }
            setTimeout(() => {
                this.setState({ pending: false })
                this.props.go(DOCUMENT_CAPTURE_TYPE2_SELECT)
            }, 150)
        }
    };

    render() {
        const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
        const shouldScaleEdge = isEdge ? "ui-canvas scale-edge" : "ui-canvas";
        let containerClassName;
        if (this.props.captureType === "LANDSCAPE") {
            containerClassName = this.state.isIOS
                ? "landscape-safari"
                : "others-landscape";
        } else if (isEdge) {
            containerClassName = "ui-canvas-container hiddenEdge";
        } else {
            containerClassName = "ui-canvas-container";
        }

        const shouldBeFlipped = !this.state.mirror ? "flip-x" : "";
        const videoClassName = `calculating-canvas ${shouldScaleEdge} ${shouldBeFlipped}`;
        // let w = window.innerWidth;
        // let h = window.innerHeight;
        const xWidth = parseInt(process.env.REACT_APP_USE_SECOND_DOCUMENT_RESOLUTION_WIDTH) || secWidth;
        const xHeight = parseInt(process.env.REACT_APP_USE_SECOND_DOCUMENT_RESOLUTION_HEIGHT) || secHeight;
        const videoConstraints = {
            width: xWidth,
            height: xHeight,
            deviceId: this.state.deviceId
        };
        return (
            <PageContent toggleLoading={this.state.pending} progressBar={false} type={PAGE_CONTENT_TYPE_CAPTURE} isSpinner={this.state.spinner}>
                {/* <VisiblityChangeHandler
                    onVisibilityChange={this.onVisibilityChange}
                /> */}
                {/* <S.MessageBoxWrapper>
                    <p className="message-box box-shadow">{this.state.deviceId}</p>
                </S.MessageBoxWrapper> */}
                <div className={containerClassName}>
                    {this.state.cameraPermission && <Webcam
                        className={videoClassName}
                        audio={false}
                        minScreenshotHeight={xHeight}
                        minScreenshotWidth={xWidth}
                        mirrored={false}
                        forceScreenshotSourceSize={true}
                        ref={this.setRef}
                        screenshotFormat="image/jpeg"
                        screenshotQuality={0.93}
                        videoConstraints={videoConstraints}
                        onUserMedia={this.videoReady}
                        onUserMediaError={this.videoError}
                    />}
                </div>
                <footer>
                    {this.state.isReady && this.state.cameraPermission && (
                        <div>
                            <CaptureButtonPanel
                                showMirror={true}
                                onChangeMirror={this.changeMirror}
                                onCaptureClick={this.capture}
                                onChangeCamera={this.changeCamera}
                                backDisabled={true}
                                showSwitchCameraButton={this.state.showSwitchCameraButton}
                                disabled={this.state.formState !== START}
                                text={this.props.t("Common.capture")}
                                facingMode={'environment'}
                            />
                        </div>
                    )}
                    {!this.state.cameraPermission && (
                        <div className="center">
                            <button className={"btn"} style={{ backgroundColor: '#D31145' }} onClick={this.retryCameraInit}>
                                {this.props.t(
                                    "PageDocumentCapturePortrait.confirm_camera_permission"
                                )}
                            </button>
                        </div>
                    )}
                </footer>
                <Modal
                    show={this.state.rotateLandscape}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '20%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="center">
                            <h3 className="mt-3">{this.props.t('PageDocumentCapturePortrait.display_is_landscape_header')}</h3>
                            <p className="mb-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCapturePortrait.display_is_landscape_title')}</p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ rotateLandscape: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </PageContent>
        )
    }
}
const componentWithTranslation = withTranslation()(PageDocumentCaptureType2);
export default connect(
    (state) => {
        const {
            blobSecondDocFirstPage,
            blobSecondDocSecondPage,
            base64CroppedSecondDocFirstPage,
            base64CroppedSecondDocSecondPage,
            documentQualityRetriesFront,
            documentQualityRetriesBack,
            firstPageRetries,
            secondPageRetries,
            statusFirstPage,
            statusSecondPage
        } = state.documentCaptureSecondType;
        const { width, height, cameraId, singlePaged, count, dialCountryNum, facingMode, mirror } =
            state.documentCaptureConfiguration;

        return {
            blobSecondDocFirstPage,
            blobSecondDocSecondPage,
            base64CroppedSecondDocFirstPage,
            base64CroppedSecondDocSecondPage,
            documentQualityRetriesFront,
            documentQualityRetriesBack,
            firstPageRetries,
            secondPageRetries,
            statusFirstPage,
            statusSecondPage,
            width,
            height,
            cameraId,
            singlePaged,
            count,
            dialCountryNum,
            facingMode,
            mirror
        };
    }, {
    go,
    nextPage,
    changeCamera,
    setImageSecondDocument,
    blobToBase64,
    flipCamera,
    setStatusImageSecond,
    rollBackImageStatus
}
)(componentWithTranslation)
