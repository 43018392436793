/* eslint-disable default-case */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { func, number, string } from "prop-types";
import {
  numOfFrames,
  captureInterval,
  initialCaptureDelay,
} from "logic/multiCaptureConfiguration";
import DocumentCaptureUI from "../DocumentCaptureUI";
import DeviceUnlockHandler from "../../common/DeviceUnlockHandler";
import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "../../../PageContent";
import { Modal } from "react-bootstrap";
import {
  preview,
  retryDocumentQuality,
  setCroppedFirstPage,
  selectDocumentType,
  retry,
  retryProcessing,
  checkActionFromPortrait
} from "logic/actions/documentCapture";
import {
  changeCamera,
  modalShowed,
  flipCamera
} from "logic/actions/documentCaptureConfiguration";
import { documentsSave } from "logic/actions/api/document";
import { go } from "logic/actions/navigation";

import { width, height } from "logic/documentResolution";
import { WEBRTC } from "logic/enums/captureTypes";
import { blobToBase64 } from "logic/blobToBase64";
import { store } from "logic/store";
import "./style.css";
import {
  logEvent,
  document_capture_started,
  document_capture_error,
} from "logic/eventLogger";

import CaptureButtonPanel from "../../../CaptureButtonPanel";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import VisiblityChangeHandler from "../../common/VisiblityChangeHandler";
import {
  VERIFY_IDENTITY,
  NEW_DOCUMENT_CAPTURE_INSTRUCTIONS
} from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import { setSelectedCamera } from "logic/reducers/camera";
import { isSinglePaged } from "logic/actions/documentCaptureConfiguration";
import imgwarning from "../../../../assets/warning-icon.png";
import { startEventTimer, getTimePassed } from "logic/stepTimers";
import { setExpireTimeOut } from "logic/actions/expireTimeOut";
import { isIOS, isMobile, isAndroid, isMac } from "logic/deviceType";
import { setDefaultCameras } from "logic/cameraChecker";

const START = 0;
const CAPTURING = 1;
const PASS = 2;
let captureTimeOutId = undefined;
let retryTimeoutId = undefined;
let refreshTimeoutId = undefined;
let retryInitTimeoutId = undefined;
let errorTimeoutId = undefined;
let qaConnectionTimeoutId = undefined;

export class PageDocumentCapturePortrait extends Component {
  static propTypes = {
    preview: func,
    retries: number,
    documentType: string,
    documentQualityHostname: string,
    documentQualityRetries: number
  };

  constructor(props) {
    super(props);
    this.canvasRef = new React.createRef();

    this.state = {
      pending: true,
      spinner: true,
      message: "",
      formState: START,
      captureMethod: WEBRTC,
      cameraPermission: true,
      hideNative: false,
      showSwitchCameraButton: store.getState().camera.cameras.length > 1,
      defaultShowCamera: store.getState().camera.cameras.length > 1,
      coords: {},
      modalImageFail: false,
      canChangeCamera: true,
      cameraTimeout: false,
      camera: {},
      cameraCount: 0,
      metadatahasLoaded: false,
      stsOrientation: "",
      showDebug: false,
      rotateLandscape: false
    };

    this.onDocSDKInitialized.bind(this);
    this.capture.bind(this);
    if (!getTimePassed("document_capture")) startEventTimer("document_capture");
  }

  UNSAFE_componentWillMount() {
    window.scrollBy(1, 2);
    setTimeout(() => {
      window.scrollBy(-1, -2);
    }, 100);
  }
  componentDidMount() {
    this.setState({ camera: store.getState().camera });
    if (
      !this.props.base64CroppedFirstPage &&
      this.props.documentType !== "PASSPORT"
    ) {
      this.setState({ hintFacePresent: true });
    }
    this.setState({ camera: store.getState().camera })
    window.addEventListener("orientationchange", this.checkScreenOrientation);
    // call event in case user is in landscape mode when first loads
    this.checkScreenOrientation();
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.checkScreenOrientation);
    this.setState = (state, callback) => {
      return;
    };
  }

  formatConsoleDate = (date) => {
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let milliseconds = date.getMilliseconds();

    return '[' +
      ((hour < 10) ? '0' + hour : hour) +
      ':' +
      ((minutes < 10) ? '0' + minutes : minutes) +
      ':' +
      ((seconds < 10) ? '0' + seconds : seconds) +
      '.' +
      ('00' + milliseconds).slice(-3) +
      '] ';
  }

  checkScreenOrientation = () => {
    let screenOrientation = undefined;
    try {
      if (isAndroid(navigator.userAgent) || isIOS(navigator.userAgent) || isMobile(navigator.userAgent) || isMac(navigator.userAgent)) {
        if (window.screen.orientation) {
          screenOrientation = window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait';
        } else {
          // iOS/safari
          screenOrientation = Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait';
        }
      }
    }
    catch (err) { }

    this.setState({
      stsOrientation: screenOrientation,
      rotateLandscape: screenOrientation === "landscape" ? true : false
    })
  }

  onVisibilityChange = (isVisible) => {
    if (
      this.state.captureMethod !== "NATIVE" &&
      !this.state.pending &&
      isVisible
    ) {
      this.props.checkActionFromPortrait(true);
      this.props.retryProcessing()
      this.props.retry()
      this.props.go(NEW_DOCUMENT_CAPTURE_INSTRUCTIONS);
    }
  };

  onCameraStarted = (dc, vid, onloadedCallback) => {
    try {
      this.video.removeEventListener('loadedmetadata', this.onLoadedMetadata);
    } catch (err) { }
    // this.props.setExpireTimeOut(new Date().getTime())
    this.setState({ showSwitchCameraButton: this.state.defaultShowCamera });
    this.video = dc.camera.videoEl;
    this.video.play();
    this.camera = dc.camera;
    this.setState({ metadatahasLoaded: false, cameraTimeout: false });
    if (this.video.readyState === 0) {
      this.timeoutCamera = setTimeout(() => {
        // console.log("cameraTimeout" + this.video.readyState === undefined ? "n/a": this.video.readyState );
        if (this.video.readyState === 0) {
          this.setState({
            message: this.props.t("Warning.camera_connect_issues"),
            pending: false,
            spinner: true,
            cameraTimeout: true
          });
        } else {
          if (!this.state.metadatahasLoaded) {
            this.onLoadedMetadata();
          }
        }
      }, 10000);
      this.video.addEventListener('loadedmetadata', this.onLoadedMetadata);
    } else {
      this.onLoadedMetadata();
    }
  };

  onLoadedMetadata = () => {
    clearTimeout(this.timeoutCamera);
    this.setState({ metadatahasLoaded: true, pending: false, cameraTimeout: false, spinner: false });
    if (this.camera.videoTracks.length) {
      const trackSettings = this.camera.videoTracks[0].getSettings()
      if (trackSettings !== undefined) {
        this.setState({
          streamWidth: trackSettings.width,
          streamHeight: trackSettings.height,
        });
      }
      const device = this.camera.videoTracks[0];
      const { deviceId, groupId } = device.getSettings();
      this.props.setSelectedCamera({
        label: device.label === undefined ? deviceId : device.label,
        deviceId,
        groupId,
      });
    } else {
      const width = this.camera.videoEl.videoWidth;
      const height = this.camera.videoEl.videoHeight;
      this.setState({
        streamWidth: width,
        streamHeight: height,
      });
    }
    this.video.removeEventListener('loadedmetadata', this.onLoadedMetadata);
  }

  captureFeedback = async (response, calledFrom) => {
    if (!response.result && !response.message) {
      this.setState({
        pending: false,
        blobPreview: undefined,
        networkError: true,
        message: this.props.t("Common.error_connection_issues"),
        formState: START,
      });
    } else {
      if (calledFrom !== this.state.captureMethod || this.state.formState === PASS) return;
      this.setState({
        pending: false,
        blobPreview: undefined,
        networkError: false,
      });
      logEvent("DOCUMENT_CAPTURE-[use quality server]", {
        documentType: this.props.documentType,
        response: response
      });
      if (response.result === "PASS") {
        this.state.dc.stopAutoCapture();

        this.setState({
          message: "",
          formState: PASS,
        });
        this.props.preview({
          blob: await blobToBase64(response.sentBlobImage),
          base64: response.responseBase64Image,
          isRejected: false,
          captureMethod: this.state.captureMethod,
          summaryScorePresent: false,
        });
      } else {
        if (this.props.documentQualityRetriesFront && !this.props.base64CroppedFirstPage) {
          this.props.retryDocumentQuality("front");
        } else if (this.props.documentQualityRetriesBack && this.props.base64CroppedFirstPage) {
          this.props.retryDocumentQuality();
        } else {
          this.setState({
            pending: false,
            modalImageFail: true
          })
        }

        const logData = {
          documentCaptureFeedback: response.feedback || response.message,
          documentCaptureFeedbackCode: response.code,
          documentType: this.props.documentType,
          documentCountry: this.props.dialCountryNum,
          streamWidth: this.state.streamWidth,
          streamHeight: this.state.streamHeight,
          calledFrom,
        };
        logEvent(document_capture_error, logData);

        this.setState({
          message: response.feedback || response.message,
          formState: START,
        });
      }
      // if (process.env.REACT_APP_USE_QUALITY_SERVER === "true") {
      //   logEvent("DOCUMENT_CAPTURE-[use quality server]", {
      //     documentType: this.props.documentType,
      //     response: response
      //   });
      //   if (response.result === "PASS") {
      //     this.state.dc.stopAutoCapture();

      //     this.setState({
      //       message: "",
      //       formState: PASS,
      //     });
      //     this.props.preview({
      //       blob: await blobToBase64(response.sentBlobImage),
      //       base64: response.responseBase64Image,
      //       isRejected: false,
      //       captureMethod: this.state.captureMethod,
      //       summaryScorePresent: false,
      //     });
      //   } else {
      //     if (this.props.documentQualityRetriesFront && !this.props.base64CroppedFirstPage) {
      //       this.props.retryDocumentQuality("front");
      //     } else if (this.props.documentQualityRetriesBack && this.props.base64CroppedFirstPage) {
      //       this.props.retryDocumentQuality();
      //     } else {
      //       this.setState({
      //         pending: false,
      //         modalImageFail: true
      //       })
      //     }

      //     const logData = {
      //       documentCaptureFeedback: response.feedback || response.message,
      //       documentCaptureFeedbackCode: response.code,
      //       documentType: this.props.documentType,
      //       documentCountry: this.props.dialCountryNum,
      //       streamWidth: this.state.streamWidth,
      //       streamHeight: this.state.streamHeight,
      //       calledFrom,
      //     };
      //     logEvent(document_capture_error, logData);

      //     this.setState({
      //       message: response.feedback || response.message,
      //       formState: START,
      //     });
      //   }
      // } else {
      //   logEvent("DOCUMENT_CAPTURE", {
      //     documentType: this.props.documentType,
      //     response: response,
      //   });
      //   if (response.result === "PASS") {
      //     this.state.dc.stopAutoCapture();

      //     this.setState({
      //       message: "",
      //       formState: PASS,
      //     });
      //     this.props.preview({
      //       blob: await blobToBase64(response.sentBlobImage),
      //       base64: response.responseBase64Image,
      //       isRejected: false,
      //       captureMethod: this.state.captureMethod,
      //       summaryScorePresent: false,
      //     });
      //   } else {
      //     getCaptureData(this.state.dc, this.state.lastFrameCaptured)
      //       .then(async (data) => {
      //         const { blob, base64, summaryScorePresent } = data;
      //         this.props.preview({
      //           blob: blob,
      //           base64: base64,
      //           summaryScorePresent: summaryScorePresent,
      //           captureMethod: this.state.captureMethod,
      //           // isRejected: true,
      //           // allRetriesFailed: true,
      //         });
      //       });
      //     this.state.dc.stopAutoCapture();
      //   }
      // }
    }
  }

  captureError(err, calledFrom) {
    if (
      calledFrom !== this.state.captureMethod ||
      this.state.formState === PASS
    ) {
      return;
    }
    let errorMessage = this.props.t("Common.error_connection_issues")
    if (err !== undefined) {
      if (err.message !== undefined) {
        errorMessage = err.message;
      }
    }
    if (captureTimeOutId !== undefined)
      clearTimeout(captureTimeOutId)

    this.setState({
      pending: false,
      formState: START,
      blobPreview: undefined,
      message: errorMessage,
      networkError: true,
    });
  }

  handleShowNativeInstructions = () => {
    try {
      this.state.dc.stopCamera();
    } catch (err) { }
    this.props.modalShowed();
    this.setState({ nativeInstructions: true });
    if (this.props.count >= 1) {
      this.handleCloseNativeInstructions();
    }
  };

  handleHideNative = () => {
    this.setState({ hideNative: true });
  };

  handleCloseNativeInstructions = () => {
    this.setState({ nativeInstructions: false });
    if (this.state.inputRef && this.state.inputRef.current) {
      const inputRef = { ...this.state.inputRef };
      inputRef.current.value = "";
      this.state.inputRef.current.click();
    }
  };

  saveNativeButtonRef = (inputRef) => {
    this.setState({ inputRef });
  };

  captureMultipleFramesTimeout = async () => {
    let sdkOptions = {
      queryParams: {},
      croppingTolerance: 0.06,
      hintFacePresent: this.state.hintFacePresent,
      initialCaptureDelay: parseInt(process.env.REACT_APP_INITIAL_DELAY_CAPTURE) || initialCaptureDelay,
      captureInterval: parseInt(process.env.REACT_APP_CAPTURE_INTERVAL) || captureInterval,
      numOfFrames: parseInt(process.env.REACT_APP_NUMBER_OF_SUBMISSIONS) || numOfFrames
    };
    let sdkCallPromise = this.state.dc.captureMultipleFrames(sdkOptions);
    const delay = (delayedPromise, ms) =>
      new Promise((resolve, reject) => {
        qaConnectionTimeoutId = setTimeout(() => {
          return reject(new Error(this.props.t('PageDocumentCapturePortrait.timeout_quality_check')))
        }, ms)
        delayedPromise
          .catch(() => { })
          .then(() => { })
      })
    const response = await Promise.race([sdkCallPromise, delay(sdkCallPromise, 30000)]);
    return response;
  }

  capture = async () => {
    logEvent(document_capture_started, {
      documentType: this.props.documentType,
      startTime: window.performance.now()
    });
    const startTime = performance.now();

    this.props.toggleNetworkWarnings(true);
    if (process.env.REACT_APP_USE_QUALITY_SERVER === "false") {
      const cropCanvas = window.document.createElement('canvas');
      const vWidth = this.video.videoWidth;
      const vHeight = this.video.videoHeight;
      cropCanvas.width = vWidth;
      cropCanvas.height = vHeight;
      cropCanvas.getContext('2d').drawImage(this.video, 0, 0, vWidth, vHeight);

      let docImageUrl = cropCanvas.toDataURL('image/jpeg');

      let arr = docImageUrl.split(',');
      let mime = arr[0].match(/:(.*?);/)[1];
      let byteStr = atob(arr[1]);
      let length = byteStr.length;
      let u8arr = new Uint8Array(length);

      while (length--) {
        u8arr[length] = byteStr.charCodeAt(length);
      }

      let docImageBlob = new Blob([u8arr], { type: mime });

      this.state.dc.stopAutoCapture();
      this.props.toggleNetworkWarnings(false);
      this.props.preview({
        blob: await blobToBase64(docImageBlob),
        base64: await blobToBase64(docImageBlob),
        isRejected: false,
        captureMethod: WEBRTC,
        summaryScorePresent: false,
      });
    } else {
      this.setState({
        captureMethod: WEBRTC,
        formState: CAPTURING,
        message: "",
        networkError: false,
        startTime,
      });
      await this.captureMultipleFramesTimeout()
        .then((res) => {
          if (qaConnectionTimeoutId !== undefined) {
            clearTimeout(qaConnectionTimeoutId);
          }
          if (res === undefined) {
            return this.captureError({ message: 'Error obtaining Quality Result.' }, WEBRTC);
          } else {
            this.setState({
              lastFrameCaptured: res.sentBlobImage || res.base64Image,
              pending: true,
              spinner: true
            });
            return this.captureFeedback(res, WEBRTC);
          }
        })
        .catch((error) => {
          if (qaConnectionTimeoutId !== undefined) {
            clearTimeout(qaConnectionTimeoutId);
          }
          return this.captureError(error, WEBRTC);
        })
        .finally(() => this.props.toggleNetworkWarnings(false));
    }
  }

  toggleLoading = (state = undefined) => {
    this.setState({ pending: !this.state.pending });
  };

  tapHandler = () => {
    this.capture();
    isMobile(navigator.userAgent) &&
      (!this.props.documentType.includes("PORT") ||
        this.props.documentType === "PASSPORT")
      ? this.setState({ userMessage: this.props.t("PageDocumentCapturePortrait.please_keep_still") }) : this.setState({ userMessage: "" });
    captureTimeOutId = setTimeout(() => {
      this.setState({ pending: true, userMessage: "", spinner: true });
    }, (parseInt(process.env.REACT_APP_CAPTURE_INTERVAL) || captureInterval) * (parseInt(process.env.REACT_APP_NUMBER_OF_SUBMISSIONS) || numOfFrames) + (parseInt(process.env.REACT_APP_INITIAL_DELAY_CAPTURE) || initialCaptureDelay) + 50);

    if (
      (!this.props.base64CroppedFirstPage &&
        !this.props.documentQualityRetriesFront &&
        !this.props.blobSecondPage &&
        this.props.documentQualityRetriesBack) ||
      (this.props.base64CroppedFirstPage &&
        !this.props.documentQualityRetriesBack)
    ) {
      // console.log("retries fail!")
      captureTimeOutId = setTimeout(() => {
        this.setState({ pending: true, userMessage: "", spinner: true });
        this.state.dc.stopCamera();
      }, (parseInt(process.env.REACT_APP_CAPTURE_INTERVAL) || captureInterval) * (parseInt(process.env.REACT_APP_NUMBER_OF_SUBMISSIONS) || numOfFrames) + (parseInt(process.env.REACT_APP_INITIAL_DELAY_CAPTURE) || initialCaptureDelay) + 50);
    }
  };

  onDocSDKInitialized = (dc) => {
    this.setState({ dc: dc });
  };

  changeCamera = (cameraId) => {
    this.setState({ pending: false, showSwitchCameraButton: false });
    this.changeMirror();
    this.props.changeCamera({ cameraId: cameraId });
  };

  changeMirror = () => {
    this.props.flipCamera({
      mirror: this.props.mirror ? false : true
    })
  }

  cameraPermissionDenied = () => {
    errorTimeoutId = setTimeout(() => {
      this.setState({
        cameraPermission: false,
        message: this.props.t("PageDocumentCapturePortrait.please_enable_camera"),
        isError: true,
        retryCameraInit: false,
        pending: false,
        showSwitchCameraButton: store.getState().camera.cameras.length > 1
      });
      clearTimeout(errorTimeoutId);
    }, 50);
  };

  cameraSourceUnavailable = () => {
    // console.log("cameraSourceUnavailable");
    errorTimeoutId = setTimeout(() => {
      this.setState({
        cameraPermission: false,
        message: this.props.t("Common.error_camera_source"),
        isError: true,
        retryCameraInit: false,
        pending: false,
        showSwitchCameraButton: store.getState().camera.cameras.length > 1
      });
      clearTimeout(errorTimeoutId);
    }, 50);
  };

  onChangeDocumentOrientation = () => {
    this.props.documentType === "ID_CARD"
      ? this.props.selectDocumentType("ID_CARD_PORT")
      : this.props.selectDocumentType("ID_CARD");
  };

  retryCameraInit = () => {
    retryInitTimeoutId = setTimeout(() => {
      this.setState({
        cameraPermission: true,
        retryCameraInit: true,
        message: "",
        isError: false,
        pending: true,
      });
      clearTimeout(retryInitTimeoutId);
    }, 50)
  };

  onDeviceUnlock = () => {
    if (this.state.captureMethod !== "NATIVE" && !this.state.pending)
      this.props.go(VERIFY_IDENTITY);
  };

  resetCamera = () => {
    try {
      this.video.removeEventListener('loadedmetadata', this.onLoadedMetadata);
    } catch (err) { }
    this.changeCamera(this.props.cameraId);
  }

  retryCapture = () => {
    this.props.retryProcessing()
    this.props.retry()
    this.props.checkActionFromPortrait(false)
    retryTimeoutId = setTimeout(() => {
      // this.props.go(VERIFY_IDENTITY)
      this.props.go(NEW_DOCUMENT_CAPTURE_INSTRUCTIONS);
      clearTimeout(retryTimeoutId)
    }, 100)
  }

  refreshCameras = () => {
    this.setState({ cameraCount: store.getState().camera.cameras.length });
    setDefaultCameras();
    refreshTimeoutId = setTimeout(() => {
      clearTimeout(refreshTimeoutId);
      this.setState({ camera: store.getState().camera });
      if (this.state.camera.cameras.length !== this.state.cameraCount) {
        this.setState({ message: "Cameras have changed" });
      } else {
        this.setState({ message: "No Camera changes" });
      }
    }, 50);
  }

  render() {
    var positionmsg, titlemsg;
    if (!this.props.base64CroppedFirstPage && !this.props.base64CroppedSecondPage && !this.props.singlePaged && this.props.documentType === "ID_CARD") {
      positionmsg = this.props.t('PageDocumentCapturePortrait.header_font')
    } else if (this.props.base64CroppedFirstPage && !this.props.base64CroppedSecondPage && !this.props.singlePaged && this.props.documentType === "ID_CARD") {
      positionmsg = this.props.t('PageDocumentCapturePortrait.header_back')
    }
    if (this.props.documentType === "ID_CARD") {
      titlemsg = this.props.t('PageDocumentCapturePortrait.header_title_id_card')
    } else if (this.props.documentType === "PASSPORT") {
      titlemsg = this.props.t('PageDocumentCapturePortrait.header_title_passport')
    } else if (this.props.documentType === "COPY_ID_CARD") {
      titlemsg = this.props.t('PageDocumentCaptureOtherSelect.copy_of_idcard')
    } else if (this.props.documentType === "MONK_ID_DOCUMENT") {
      titlemsg = this.props.t('PageDocumentCaptureOtherSelect.book_of_monk')
    } else if (this.props.documentType === "OFFICIAL_ID_DOCUMENT") {
      titlemsg = this.props.t('PageDocumentCaptureOtherSelect.official_id_document')
    }

    let message = "";
    if (this.state.message === "Try to straighten up the document.") {
      message = this.props.t("PageDocumentCapturePortrait.try_to_straighten")
    } else if (this.state.message === "Try to hold the ID closer to the camera.") {
      message = this.props.t("PageDocumentCapturePortrait.try_to_hold_id")
    } else if (this.state.message === "Move the document away from strong light.") {
      message = this.props.t("PageDocumentCapturePortrait.move_the_document")
    } else if (this.state.message === "Hold the document / camera steady.") {
      message = this.props.t("PageDocumentCapturePortrait.hold_the_document")
    } else if (this.state.message === "Try to capture the document on a plain background.") {
      message = this.props.t("PageDocumentCapturePortrait.try_to_capture")
    } else if (this.state.message === "Try to hold the document in a steady position.") {
      message = this.props.t("PageDocumentCapturePortrait.try_to_hold_document")
    } else if (this.state.message === "Unable to detect the document, please ensure that the document is fully visible within the view.") {
      message = this.props.t("PageDocumentCapturePortrait.unable_to_detech")
    } else {
      message = this.state.message || this.props.t("PageDocumentCapturePortrait.position_document");
    }
    const messageClass = `message-box box-shadow ${this.state.networkError ? "message-box-error" : ""}`;
    return (
      <PageContent toggleLoading={this.state.pending} type={PAGE_CONTENT_TYPE_CAPTURE} isSpinner={this.state.spinner}>
        {/* <S.MessageBoxWrapper>
          <p className={messageClass}>{this.state.stsOrientation}</p>
        </S.MessageBoxWrapper> */}
        <div className="no-scroll">
          <VisiblityChangeHandler
            onVisibilityChange={this.onVisibilityChange}
          />
          <DeviceUnlockHandler onDeviceUnlock={this.onDeviceUnlock} />
          <p className="instructions-on-portrait-header">{titlemsg}</p>
          <p className="instructions-on-portrait-title">{positionmsg}</p>
          <DocumentCaptureUI
            onInitialized={this.onDocSDKInitialized}
            width={width}
            height={height}
            documentType={this.props.documentType}
            blobPreview={this.state.blobPreview}
            url={
              this.props.documentQualityHostname +
              "/rest/v1/quality/assessments"
            }
            onCameraStarted={this.onCameraStarted}
            cameraId={this.props.cameraId}
            facingMode={this.props.facingMode}
            captureMethod={this.state.captureMethod}
            cameraPermissionDenied={this.cameraPermissionDenied}
            cameraSourceUnavailable={this.cameraSourceUnavailable}
            retryCameraInit={this.state.retryCameraInit}
            toggleLoading={this.toggleLoading}
          />
          <Modal
            show={this.state.nativeInstructions && this.props.count < 2}
            onHide={this.handleCloseNativeInstructions}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <p>
                  <strong>
                    {this.props.t("PageDocumentCapturePortrait.please_position_document")}
                  </strong>
                </p>
              </Modal.Title>
            </Modal.Header>
          </Modal>
          <p className="instructions-on-portrait">{this.state.userMessage}</p>

          <footer>
            <div className={messageClass}>{message}</div>

            {this.state.formState === START && this.state.cameraPermission && !this.state.cameraTimeout && (
              <CaptureButtonPanel
                showMirror={true}
                backDisabled={true}
                onChangeMirror={this.changeMirror}
                onCaptureClick={this.tapHandler}
                onChangeCamera={this.changeCamera}
                showSwitchCameraButton={this.state.showSwitchCameraButton}
                disabled={this.state.formState !== START}
                text={this.props.t("Common.capture")}
                facingMode={'environment'}
              />
            )}
            {this.state.formState === START && this.state.cameraPermission && this.state.cameraTimeout && (
              <CaptureButtonPanel
                showMirror={true}
                backDisabled={true}
                onChangeMirror={this.changeMirror}
                onCaptureClick={this.resetCamera}
                onChangeCamera={this.changeCamera}
                showSwitchCameraButton={this.state.showSwitchCameraButton}
                disabled={this.state.formState !== START}
                text={this.props.t("Common.retry")}
                facingMode={'environment'}
              />
            )}
            {!this.state.cameraPermission && (
              <div className="center">
                <button className={"btn"} style={{ backgroundColor: '#D31145' }} onClick={this.retryCameraInit}>
                  {this.props.t(
                    "PageDocumentCapturePortrait.confirm_camera_permission"
                  )}
                </button>
              </div>
            )}
          </footer>
        </div>
        <Modal
          show={this.state.modalImageFail}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="row row-justify-center">
              <img
                style={{ width: '30%', height: 'inherit' }}
                src={imgwarning}
                alt="warning"
              />
            </div>
            <div className="row">
              <a className="mt-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCapturePortrait.warning_retry_capture')}</a>
            </div>
            <div className="row">
              <a className="mt-1 mb-3" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCapturePortrait.warning_retry_capture_return')}</a>
            </div>
            <div className="row row-justify-center">
              <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={this.retryCapture}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.rotateLandscape}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="row row-justify-center">
              <img
                style={{ width: '20%', height: 'inherit' }}
                src={imgwarning}
                alt="warning"
              />
            </div>
            <div className="center">
              <h3 className="mt-3">{this.props.t('PageDocumentCapturePortrait.display_is_landscape_header')}</h3>
              <p className="mb-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCapturePortrait.display_is_landscape_title')}</p>
            </div>
            <div className="row row-justify-center">
              <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ rotateLandscape: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
            </div>
          </Modal.Body>
        </Modal>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageDocumentCapturePortrait);
export default connect(
  (state) => {
    const {
      retries,
      documentType,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      documentQualityRetriesFront,
      documentQualityRetriesBack,
    } = state.documentCapture;
    const { width, height, cameraId, singlePaged, count, dialCountryNum, facingMode, mirror } =
      state.documentCaptureConfiguration;
    const { documentQualityHostname } = state.configuration;
    const {
      numOfFrames,
      initialCaptureDelay,
      captureInterval,
      showNativeCamera,
    } = state.configuration.extraConfig.featureFlags;

    const tenant = state.configuration.tenant;

    return {
      mirror,
      facingMode,
      retries,
      documentType,
      base64CroppedSecondPage,
      documentQualityHostname,
      base64CroppedFirstPage,
      documentQualityRetriesFront,
      documentQualityRetriesBack,
      initialCaptureDelay,
      captureInterval,
      numOfFrames,
      cameraId,
      count,
      width,
      height,
      singlePaged,
      tenant,
      showNativeCamera,
      dialCountryNum,
      ...state.documentCaptureConfiguration
    };
  },
  {
    go,
    preview,
    retryDocumentQuality,
    changeCamera,
    modalShowed,
    setCroppedFirstPage,
    documentsSave,
    toggleNetworkWarnings,
    selectDocumentType,
    setSelectedCamera,
    isSinglePaged,
    flipCamera,
    retry,
    retryProcessing,
    setExpireTimeOut,
    checkActionFromPortrait
  }
)(componentWithTranslation);
