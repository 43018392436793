/*global Daon*/
/*eslint no-undef: "error"*/

const actionPrefix = "DOCUMENT_CAPTURE_";

export const SELECT_DOC_TYPE = actionPrefix + "SELECT_DOC_TYPE";
export const PREVIEW = actionPrefix + "PREVIEW";
export const RETRY = actionPrefix + "RETRY";
export const RESET_RETRIES = actionPrefix + "RESET_RETRIES";
export const RESET_CONFIRMED = actionPrefix + "RETRY_PROCESSING";
export const SET_CROPPED_IMAGE = actionPrefix + "SET_CROPPED_IMAGE";
export const SET_CROPPED_FIRST_PAGE = actionPrefix + "SET_CROPPED_FIRST_PAGE";
export const SET_CROPPED_SECOND_PAGE = actionPrefix + "SET_CROPPED_SECOND_PAGE";
export const CREATE_INSTANCE = actionPrefix + "CREATE_INSTANCE";
export const FIRST_TIME_INIT = actionPrefix + "FIRST_TIME_INIT";
export const RETRY_PROCESSING = actionPrefix + "RETRY_PROCESSING";
export const SET_DOCUMENT_QUALITY_RETRIES =
  actionPrefix + "SET_DOCUMENT_QUALITY_RETRIES";
export const RETRY_DOCUMENT_QUALITY = actionPrefix + "RETRY_DOCUMENT_QUALITY";
export const RESET_ALL_RETRIES_FAILED =
  actionPrefix + "RESET_ALL_RETRIES_FAILED";
export const RESETING_DOCUMENT_QUALITY_RETRIES =
  actionPrefix + "RESETING_DOCUMENT_QUALITY_RETRIES";
export const NEW_DOCUMENT_SELECTED =
  actionPrefix + "  NEW_DOCUMENT_SELECTED,  ";
export const SET_RESPONSE_DOCUMENT_DATA = "SET_RESPONSE_DOCUMENT_DATA";
export const CHECK_FROM_PORTRAIT_PAGE = actionPrefix + "CHECK_FROM_PORTRAIT_PAGE";
export const RETRY_INCORRECT_IDCARD = actionPrefix + "RETRY_INCORRECT_IDCARD";
export const DOPA_RETRY = actionPrefix + "DOPA_RETRY"


export function setResponseDocumentData(payload) {
  return {type: SET_RESPONSE_DOCUMENT_DATA, payload}
}

export function selectDocumentType(payload) {
  return { type: SELECT_DOC_TYPE, payload };
}

export function newDocumentSelected() {
  return { type: NEW_DOCUMENT_SELECTED };
}

export function preview(payload) {
  //TODO@gva: this affects navigation and should be moved away from facecapture actions
  return { type: PREVIEW, payload };
}

export function retry(payload) {
  return { type: RETRY, payload };
}

export function dopaRetry(payload){
  return { type: DOPA_RETRY, payload}
}

export function retryProcessing(payload) {
  return { type: RETRY_PROCESSING, payload };
}

export function resetRetries() {
  return { type: RESET_RETRIES };
}

export function setCroppedImage(payload) {
  return { type: SET_CROPPED_IMAGE, payload };
}

export function setDocumentQualityRetries(payload) {
  return { type: SET_DOCUMENT_QUALITY_RETRIES, payload };
}

export function setCroppedFirstPage(payload) {
  return { type: SET_CROPPED_FIRST_PAGE, payload };
}

export function setCroppedSecondPage(payload) {
  return { type: SET_CROPPED_SECOND_PAGE, payload };
}

export function retryDocumentQuality(payload) {
  return { type: RETRY_DOCUMENT_QUALITY, payload };
}

export function createInstance(config) {
  return {
    type: CREATE_INSTANCE,
    payload: new Daon.DocumentCapture(config),
  };
}

export function resetAllRetriesFailed(payload) {
  return {
    type: RESET_ALL_RETRIES_FAILED,
  };
}

export function firstTimeInit() {
  return {
    type: FIRST_TIME_INIT,
  };
}

export function resetingDocumentQualityRetries(payload) {
  return {
    type: RESETING_DOCUMENT_QUALITY_RETRIES,
    payload,
  };
}

export function checkActionFromPortrait(payload) {
  return {
    type: CHECK_FROM_PORTRAIT_PAGE,
    payload
  }
}

export function retryIncorrectIdCard() {
  return { type: RETRY_INCORRECT_IDCARD }
}
