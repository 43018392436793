import React from "react";
import { useTranslation } from "react-i18next";
import { clearCache } from "logic/localCacheAdapter";

export default function ButtonRestartApp() {
  function restartApp() {
    clearCache();
    window.location.reload();
  }

  const { t } = useTranslation();
  return (
    <div className="center">
      <button className="btn" style={{ backgroundColor: '#D31145', fontSize: '1em' }} onClick={restartApp}>
        {t("Common.btn_restart_app")}
      </button>
    </div>
  );
}
