import styled from 'styled-components'

export const BackButton = styled.button`
height: 33px;
max-width:150px;
background: #EEEFF3;
border-radius: 4px;
display: grid;
grid-template-columns: 1fr 2fr;
align-items: center;
padding: 0px 5px;
cursor:pointer;
border:none;
font-weight:400;

`
