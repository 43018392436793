const animationStepDuration = 40; // 25fps

export class canvasDrawingClass {
  constructor(canvas, ctx, imageGreen) {
    this.ctx = ctx;
    this.canvas = canvas;
    this.imageGreen = imageGreen;
  }

  startAnimation(duration) {
    const animationStartTS = performance.now();
    const animationStopTS = animationStartTS + duration;
    const animationScalePerSec = ((1.6 - 1) / duration) * 1000;
    this.stepAnimation(animationStartTS, animationStopTS, animationScalePerSec);
  }

  drawOutline(img) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // console.log(this.canvas.width, this.canvas.height, img.width, img.height)

    let scale = 0.55;
    if (this.canvas.width < this.canvas.height) {
      scale = 0.75;
    }

    let dx = (this.canvas.width - img.width * scale) / 2;
    let dy = (this.canvas.height - img.height * scale * 1.4) / 2;
    // console.log(dx, dy)

    this.ctx.globalAlpha = 0.9;
    this.ctx.drawImage(
      img,
      dx,
      dy,
      img.width * scale,
      img.height * scale * 1.4
    );
  }

  stepAnimation = (animationStartTS, animationStopTS, animationScalePerSec) => {
    const currentTS = performance.now();
    if (currentTS < animationStopTS) {
      let newScale =
        1 + (animationScalePerSec * (currentTS - animationStartTS)) / 1000;

      this.ctx.setTransform(
        newScale,
        0,
        0,
        newScale,
        (-this.canvas.width * (newScale - 1)) / 2,
        (-this.canvas.height * (newScale - 1)) / 2
      );
      this.drawOutline(this.imageGreen);
      setTimeout(
        () =>
          this.stepAnimation(
            animationStartTS,
            animationStopTS,
            animationScalePerSec
          ),
        animationStepDuration
      );
    } else {
      console.log("skip stepAnimation");
    }
  };
}
