import styled from 'styled-components'

export const MainWrapper = styled.div`
    & p {
        font-size: 14px;
        padding: 2px;
    }
`;

export const Button = styled.button`
    margin-top: 10%;
`
  