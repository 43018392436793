import {
    isSafari,
    isAndroid,
    isMac,
    isChrome,
    isIOS,
    isFirefox,
    isEdge,
    get_browser,
    isSupported,
    CHROME_VER, SAFARI_VER, FIREFOX_VER, EDGE_VER
} from "./deviceType";
import { setCameraList, setRearCamera, setFrontCamera } from "./reducers/camera";
import { getCameraIds } from "./getCameraIds";
import { store } from "./store";

export function checkCameraSupport() {
    const browser = get_browser();
    if (!isSupported(browser)) {
        console.log(browser.name + " " + browser.version + " not supported");
        return false;
    } else {
        console.log("%c"+browser.name + " " + browser.version + " supported", 'background: #222; color: #bada55');
        return true;
    }
}

export function recommendBrowser(translation) {
    const browser = get_browser();
    let message = "";
    let targetVersion = 1111;

    switch (browser.name.toLowerCase()) {
        case "chrome":
            message = translation("Warning.browser_version");
            targetVersion = CHROME_VER;
            break;
        case "safari":
            message = translation("Warning.browser_version");
            targetVersion = SAFARI_VER;
            break;
        case "edge":
            message = translation("Warning.browser_version");
            targetVersion = EDGE_VER;
            break;
        case "firefox":
            message = translation("Warning.browser_version");
            targetVersion = FIREFOX_VER;
            break;
        default:
            message = translation("Warning.browser_incompatible");
    }
    message = message.replace("{browser.name}", browser.name);
    message = message.replace("{browser.version}", browser.version);
    message = message.replace("{target.version}", targetVersion);
    return message;
}

export function checkCameraAccess() {
    const constraints = { video: { facingMode: 'user' } }; // note use facingMode: 'environment' if preference is to check for rear camera first
    return new Promise((success, failure) => {
        navigator.mediaDevices.getUserMedia(constraints).then(
            (userStream) => {
                let mediaStream = userStream;
                try {
                    mediaStream.getVideoTracks().forEach(track => {
                        track.stop();
                    });
                } catch (err) {

                }
                success();
            }, (error) => {
                // trigger view for camera instructions here
                failure(error.toString());
                // console.log("no camera to access " + error.toString());
            }).catch(err => {
                failure(err.toString());
            }
            );
    });
}


export function defaultHandleFailure(errString, userAgent, translation) {
    if (errString.indexOf("NotAllowedError:") >= 0) {
        //let baseMessage = "<div>You have either denied this site access to the camera or do not currently have a camera connected, to complete eKYC you must enable access to a camera <br>If camera is present to permit access for this device please open link below and follow instructions</div>";
        let baseMessage = translation("Warning.camera_blocked");
        // determine which instrutions to provide
        if (isSafari(userAgent)) {
            if (isIOS(userAgent)) {
                baseMessage = baseMessage + "<div><a href='/camera-safari-iOS.html' target='_blank'>Instructions</a>";
            } else {
                baseMessage = baseMessage + "<div><a href='/camera-safari-mac.html' target='_blank'>Instructions</a>";
            }
        } else if (isChrome(userAgent)) {
            if (isAndroid(userAgent)) {
                baseMessage = baseMessage + "<div><a href='/camera-chrome-android.html' target='_blank'>Instructions</a>";
            } else if (isIOS(userAgent)) {
                baseMessage = baseMessage + "<div><s href='/camera-chrome-IOS.html' target='_blank'>Instructions</s>";
            } else if (isMac(userAgent)) {
                baseMessage = baseMessage + "<div><a href='/camera-chrome-mac.html' target='_blank'>Instructions</a>";
            } else {
                baseMessage = baseMessage + "<div><a href='/camera-chrome-windows.html' target='_blank'>Instructions</a>";
            }
        }
        if (isFirefox(userAgent)) {
            if (isAndroid(userAgent)) {
                baseMessage = baseMessage + "<div><a href='/camera-firefox-android.html' target='_blank'>Instructions</a>";
            } else if (isIOS(userAgent)) {
                baseMessage = baseMessage + "<div><a href='/camera-firefox-IOS.html' target='_blank'>Instructions</a>";
            } else if (isMac(userAgent)) {
                baseMessage = baseMessage + "<div><a href='/camera-firefox-mac.html' target='_blank'>Instructions</a>";
            } else {
                baseMessage = baseMessage + "<div><a href='/camera-firefox-windows.html' target='_blank'>Instructions</a>";
            }
        } else if (isEdge(userAgent)) {
            baseMessage = baseMessage + "<div><a href='/camera-edge-windows.html' target='_blank' >Instructions</a>";
        }
        // console.log(baseMessage);
        return baseMessage;
    } else {
        return "<div>" + errString + "</div>";
    }
}

export function setDefaultCameras() {
    getCameraIds().then(
        (list) => {
            if (list.length > 0) {
                if (list.length >= 2 && !isAndroid(navigator.userAgent)){
                  list = list.slice(0,2)
                }
                let fCamera = list[0];
                let rCamera = list[list.length - 1];


                //setFrontCamera(list[0]);
                //setRearCamera(list[list.length - 1]);
                for (let i = 0; i < list.length; i++) {
                    const device = list[i];
                    if (device.facingMode !== undefined) {
                        if (device.facingMode === 'user') {
                            fCamera = device;
                        } else if (device.facingMode === 'environment') {
                            rCamera = device;
                        }
                    }
                }
                if (rCamera === fCamera && list.length > 1) {
                    rCamera = list[list.length - 2];
                }
                const frontCamera = fCamera;
                const rearCamera = rCamera;
                store.dispatch(setRearCamera(rearCamera));
                // setRearCamera(rearCamera);
                store.dispatch(setFrontCamera(frontCamera));
                // setFrontCamera(frontCamera);
                // try{
                //     console.log("setFrontCamera " + store.camera.frontCamera.cameraId);
                // } catch(err){}
                // try {
                //     console.log("setRearCamera " + store.camera.rearCamera.cameraId);
                // }catch(err){}
                const finalList = list;
                store.dispatch(setCameraList(finalList));
                // console.log("setCameraList",list);
                // console.log(store.getState());
            }
        }
    );

}
