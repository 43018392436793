// eslint-disable-next-line
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import DebugLinks from "Components/Debug/DebugLinks";
import PageDocumentCaptureConfirm from "Components/modules/DocumentCapture/PageDocumentCaptureConfirm";
import PageDocumentCapturePortrait from "Components/modules/DocumentCapture/PageDocumentCapturePortrait";
import PageFaceCapture from "Components/modules/FaceCapture/PageFaceCapture";
import PageFaceCaptureConfirm from "Components/modules/FaceCapture/PageFaceCaptureConfirm";
import PageFaceCaptureInstructions from "Components/modules/FaceCapture/PageFaceCaptureInstructions";
import PageFaceLiveness3D from "Components/modules/FaceLiveness3D/PageFaceLiveness3D";
import OldPageFaceLiveness3D from "Components/modules/OldFaceLiveness3D/OldPageFaceLiveness3D";
import OldPageInstructionsFaceLiveness3D from "Components/modules/OldFaceLiveness3D/OldPageInstructionsFaceLiveness3D";
import PageLivenessTestRetry from "Components/modules/LivenessCLR/PageLivenessTestRetry";
import PageSessionExpired from "Components/PageSessionExpired";
import PageThankYou from "Components/PageThankYou";
import PageVerifyIdentity from "Components/modules/DocumentCapture/PageVerifyIdentity";
import PageDocumentConfirmOCRData from "Components/PageDocumentConfirmOCRData";
import PageIntroConfirmToStart from "Components/myModules/PageIntroConfirmToStart";
import PageGuidance from "Components/myModules/PageGuidance";
import PageDocumentCaptureType2 from "Components/myModules/PageDocumentCaptureType2";
import PageDocumentCaptureOtherSelect from "Components/modules/DocumentCapture/PageDocumentCaptureOtherSelect";
import PageDocumentCaptureType2Select from "Components/modules/DocumentCapture/PageDocumentCaptureType2Select";
import PageLoginFromPartner from "Components/myModules/PageLoginFromPartner";
import PageShowOCRPassport from "Components/PageDocumentConfirmOCRData/TypeCommon/PageShowOCRPassport";
import PageShowOCROtherDocs from "Components/PageDocumentConfirmOCRData/TypeCommon/PageShowOCROtherDocs";
import PageShowOCRIdentityCard from "Components/PageDocumentConfirmOCRData/TypeCommon/PageShowOCRIdentityCard";
import NewPageDocumentCaptureInstructions from "Components/modules/DocumentCapture/NewPageDocumentCaptureInstructions";
import PageSignedUp from "Components/myModules/PageSignedUp";
import PageExpireLink from "Components/myModules/PageExpireLink";
import PageInstructionVideo3DFL from "Components/modules/FaceLiveness3D/PageInstructionVideo3DFL";
import PagePassiveLivenessTestRetry from "Components/PagePassiveLivenessTestRetry";

// import ErrorBoundary from "../ErrorBoundary";

// import PageApproved from "Components/PageApproved";
// import PageContinueOnDesktop from "Components/PageContinueOnDesktop";
// import PageDeclined from "Components/PageDeclined";
// import PageDocumentCountrySelector from "Components/modules/DocumentCapture/PageDocumentCountrySelector";
// import PageInstructionsCLR from "Components/modules/LivenessCLR/PageInstructionsCLR";
// import PageInstructionsFaceLiveness3D from "Components/modules/FaceLiveness3D/PageInstructionsFaceLiveness3D";
// import PageInstructionVideoDocument from "Components/modules/DocumentCapture/PageInstructionVideoDocument";
// import PageInstructionVideoSelfie from "Components/modules/FaceCapture/PageInstructionVideoSelfie";
// import PageLivenessTest from "Components/modules/LivenessCLR/PageLivenessTest";
// import PageReviewing from "Components/PageReviewing";
// import PageCheckDOPA from "Components/myModules/PageCheckDOPA";
// import PageUserManualID from "Components/myModules/PageUserManualID";

const pages = {
  PageDocumentCaptureConfirm: <PageDocumentCaptureConfirm />,
  PageDocumentCapturePortrait: <PageDocumentCapturePortrait />,
  PageDocumentConfirmOCRData: <PageDocumentConfirmOCRData />,
  PageFaceCapture: <PageFaceCapture />,
  PageFaceCaptureConfirm: <PageFaceCaptureConfirm />,
  PageFaceCaptureInstructions: <PageFaceCaptureInstructions />,
  PageFaceLiveness3D: <PageFaceLiveness3D />,
  OldPageFaceLiveness3D: <OldPageFaceLiveness3D />,
  OldPageInstructionsFaceLiveness3D: <OldPageInstructionsFaceLiveness3D />,
  PageLivenessTestRetry: <PageLivenessTestRetry />,
  PagePassiveLivenessTestRetry: <PagePassiveLivenessTestRetry />,
  PageSessionExpired: <PageSessionExpired />,
  PageThankYou: <PageThankYou />,
  PageVerifyIdentity: <PageVerifyIdentity />,
  PageIntroConfirmToStart: <PageIntroConfirmToStart />,
  PageGuidance: <PageGuidance />,
  PageDocumentCaptureType2: <PageDocumentCaptureType2 />,
  PageDocumentCaptureOtherSelect: <PageDocumentCaptureOtherSelect />,
  PageDocumentCaptureType2Select: <PageDocumentCaptureType2Select />,
  PageLoginFromPartner: <PageLoginFromPartner />,
  PageShowOCRPassport: <PageShowOCRPassport />,
  PageShowOCROtherDocs: <PageShowOCROtherDocs />,
  PageShowOCRIdentityCard: <PageShowOCRIdentityCard />,
  NewPageDocumentCaptureInstructions: <NewPageDocumentCaptureInstructions />,
  PageInstructionVideo3DFL: <PageInstructionVideo3DFL />,
  PageSignedUp: <PageSignedUp />,
  PageExpireLink: <PageExpireLink />,

  // PageUserManualID: <PageUserManualID />
  // PageApproved: <PageApproved />,
  // PageContinueOnDesktop: <PageContinueOnDesktop />,
  // PageDeclined: <PageDeclined />,
  // PageDocumentCountrySelector: <PageDocumentCountrySelector />,
  // PageInstructionsCLR: <PageInstructionsCLR />,
  // PageInstructionsFaceLiveness3D: <PageInstructionsFaceLiveness3D />,
  // PageInstructionVideo3DFL: <PageInstructionVideo3DFL />,
  // PageInstructionVideoDocument: <PageInstructionVideoDocument />,
  // PageInstructionVideoSelfie: <PageInstructionVideoSelfie />,
  // PageLivenessTest: <PageLivenessTest />,
  // PageReviewing: <PageReviewing />,
  // PageCheckDOPA: <PageCheckDOPA />,
};

export default function Navigation() {
  const page = useSelector((state) => state.navigation.page);
  const featureFlags = useSelector(
    (state) => state.configuration.extraConfig.featureFlags
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isDebugLinksActive =
    featureFlags.debugLinks === "true" ||
    featureFlags.debugLinks === true
    // || process.env.REACT_APP_DEBUG_LINKS;

  return (
    <React.Fragment>
      {isDebugLinksActive && (
        <DebugLinks
          pages={{
            NewPageDocumentCaptureInstructions: <NewPageDocumentCaptureInstructions />,
            PageFaceCaptureInstructions: <PageFaceCaptureInstructions />,
            OldPageInstructionsFaceLiveness3D: <OldPageInstructionsFaceLiveness3D />,
            PageFaceCapture: <PageFaceCapture />,
          }}
        />
      )}
      {pages[page]}
    </React.Fragment>
  );
}
