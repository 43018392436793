import styled from 'styled-components'

  export const SquaredIcon = styled.div`
  height: 28px;
  width: 28px;
  opacity: 0.7;
  border-radius: 3px;
  background-color: #364045;
  position: absolute;
  left: 80%;
  bottom: 20%;
`
export const MessageParagraph = styled.p`
margin-right: 0px;
`

export const WrapperDiv = styled.div`
    height: 45vh;
     #wrapperDiv {
        
        ${props=>{
          return props.isNative ? "transform: scaleX(-1)" : ""
          }}
    }
    & .wrapper {
        background-color: #f8f9f9;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    & .loaded-image-container { 
        pointer-events: none;
        height: 85%;
        padding-top: 7.5%;
        padding-bottom: 7.5%;
      }

    & .rotate-mobile-native {
        transform: rotate(90deg);
      }

`;