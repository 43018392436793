import React, { Component } from "react";
import { connect } from "react-redux";
import { func, number, object } from "prop-types";

import RoundIcon from "../../../RoundIcon";
import Icon from "../../../Icon";
import PageContent from "../../../PageContent";
import { retryLiveness } from "logic/actions/livenessTest";
import { go } from "logic/actions/navigation";
import { logEvent, liveness_test_retry } from "logic/eventLogger";
import { THANK_YOU } from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import { instantiateFaceLiveness3D, clear3dflState } from "logic/actions/faceLiveness3D";
import './style.css';

import face3DVideo from "assets/videos/03-3D-FaceOLD.mp4"
import face3DPoster from "assets/videos/03-3D-Face.png"

export class PageLivenessTestRetry extends Component {
  static propTypes = {
    retry: func,
    retriesLeft: number,
    livenessTestData: object,
  };

  constructor(props) {
    super(props);
    this.retry.bind(this);
  }

  componentDidMount() {
    if (this.props.retriesLeft <= 0) {
      this.props.go(THANK_YOU);
    }
  }

  retry = () => {
    const value =
      this.props.livenessTestData && this.props.livenessTestData.items[0].type;

    logEvent(liveness_test_retry, {
      livenessTestType: value,
      retryCount: this.props.retryCount,
    });
    this.props.instantiateFaceLiveness3D();
    this.props.clear3dflState();
    this.props.retryLiveness();
  };

  setErrorMessage() {
    let message;
    const errorCode = this.props.errorMessage
      ? this.props.errorMessage.trim()
      : null;
    switch (errorCode) {
      case "Try again - face matching was not successful.": {
        message = this.props.t("PageLivenessTestRetry.enrol_Image_Mismatch");
        break;
      }
      case "Try again - unable to detect a consistent face images. Ensure that your face is kept within the guiding frame.": {
        message = this.props.t(
          "PageLivenessTestRetry.insufficient_Faces_Detected"
        );
        break;
      }
      case "Try again - unable to detect movement. Ensure that your face is kept within the guiding frame as it moves.": {
        message = this.props.t("PageLivenessTestRetry.no_Movement_Detected");
        break;
      }
      case "Try again - insufficient face data captured. Ensure that your face is kept within the guiding frame as it moves.": {
        message = this.props.t("PageLivenessTestRetry.insufficient_Frames");
        break;
      }
      case "Try again - face matching was not successful. Ensure that your face is kept within the guiding frame as it moves.": {
        message = this.props.t("PageLivenessTestRetry.frame_Mismatch");
        break;
      }
      case "Try again - ensure that your face is kept within the guiding frame.": {
        message = this.props.t("PageLivenessTestRetry.passive_Fail");
        break;
      }
      default: {
        message = this.props.t("PageLivenessTestRetry.error_extra_checks");
      }
    }
    return message;
  }
  render() {
    // const buttonClass = `btn ${this.props.retriesLeft ? "green" : "gray"}`;
    let errMessage;
    if (this.props.livenessTestData.items[0].type !== "SVR3DFL") {
      errMessage = this.props.t("PageLivenessTestRetry.error_extra_checks");
    } else {
      errMessage = this.setErrorMessage();
    }

    return (
      <PageContent>
        <header />
        <h1>{this.props.t("PageLivenessTestRetry.issue")}</h1>
        <div className="panel bg-blue flex-row">
          <RoundIcon color="blue">
            <Icon name="Info" color="white" />
          </RoundIcon>
          <div className="panel-content">
            {this.props.retriesLeft ? (
              <p style={{ color: 'black', fontSize: '0.9em' }}>{errMessage}</p>
            ) : (
              <p>{this.props.t("PageLivenessTestRetry.error_extra_checks")}</p>
            )}
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "250px", marginTop: "20px" }}>
          <video
            loop
            autoPlay
            playsInline
            width="60%"
            height="60%"
            poster={face3DPoster}
          >
            <source
              src={face3DVideo}
              type="video/mp4"
            />
            {this.props.t("Common.error_video")}
          </video>
        </div>
        <footer className="footer">
          <button
            disabled={!this.props.retriesLeft}
            onClick={this.retry}
            className="btn"
            style={{ backgroundColor: "#D31145", fontSize: "1em", marginTop: '0px' }}
          >
            {this.props.t("PageLivenessTestRetry.retry_attempt", {
              count: this.props.retriesLeft,
            })}
          </button>
        </footer>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageLivenessTestRetry);
export default connect((state) => state.livenessTest,
  {
    retryLiveness,
    go,
    instantiateFaceLiveness3D,
    clear3dflState
  })(componentWithTranslation);
