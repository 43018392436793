export const FIREFOX_VER = 52;
export const EDGE_VER = 91;
export const SAFARI_VER = 11;
export const CHROME_VER = 57;
export const IPOS_VER = 0;
//export const CHROME_VER =95;
export function isAndroid(userAgent) {
  return !!userAgent.match(/(Android)/i);
}

export function isMobile(userAgent) {
  return !!userAgent.match(
    /(Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone)/i
  );
}

export function isIOS(userAgent) {
  const isSafari = !!userAgent.match(/Version\/[\d.]+.*Safari/);
  const iOS = /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
  return isSafari && iOS;
}

export function isIpad(userAgent) {
  var standalone = window.navigator.standalone,
    userAgentTemp = window.navigator.userAgent.toLowerCase(),
    safari = /safari/.test(userAgentTemp),
    ios = /iphone|ipod|ipad/.test(userAgentTemp);

  if (ios) {
    if (!standalone && safari) {
      //browser
    } else if (standalone && !safari) {
      //standalone
    } else if (!standalone && !safari) {
      //uiwebview
    };
  } else {
    //not iOS
  };
}

export function isSafari(userAgent) {
  // Detect Safari
  //let safariAgent = userAgent.indexOf("Safari") > -1;

  // Detect Chrome
  //let chromeAgent = userAgent.indexOf("Chrome") > -1;

  // Discard Safari since it also matches Chrome
  //return chromeAgent && safariAgent ? false : true;

  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}

export function isChrome(userAgent) {

  let chromeAgent = userAgent.indexOf("Chrome") > -1;
  return chromeAgent;
}

export function isFirefox(userAgent) {
  let fireFoxAgent = userAgent.toLowerCase().indexOf("firefox") > -1;
  return fireFoxAgent;
}

export function isMac(userAgent) {
  let appleMacAgaent = userAgent.indexOf("Macintosh") > -1;
  return appleMacAgaent;
}

export function isEdge(userAgent) {
  let edgeAgent = userAgent.indexOf("Edg") > -1;
  return edgeAgent;
}

export function get_browser() {
  var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident|ipos(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\bOPR|Edge\/(\d+)/)
    if (tem != null) { return { name: 'Opera', version: tem[1] }; }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
  return {
    name: M[0],
    version: M[1]
  };
}

export function isSupported(browser) {
  let majorVer = browser.version;
  let checkVer = 1111;
  switch (browser.name.toLowerCase()) {
    case "chrome":
      checkVer = CHROME_VER;
      break;
    case "safari":
      checkVer = SAFARI_VER;
      break;
    case "firefox":
      checkVer = FIREFOX_VER;
      break;
    case "edge":
      checkVer = EDGE_VER;
      break;
    case "ipos":
      return true;
    default:
      return false;
  }
  return (majorVer >= checkVer);
}

