// import uuid from "uuid";

// let _userId;

// export function getUserId() {
//   if (!_userId) {
//     _userId = uuid();
//   }
//   return _userId;
// }

export function generateUserID(componentID, referenceID) {
  return componentID + "_" + referenceID
}