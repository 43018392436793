// export function blobToBase64(blob) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     try {
//       reader.readAsDataURL(blob);
//       reader.onloadend = () => {
//         if (reader.result.includes("data:image/jpeg;base64"))
//           resolve(reader.result.replace("data:image/jpeg;base64,", ""));

//         if (reader.result.includes("data:image/png;base64"))
//           resolve(reader.result.replace("data:image/png;base64,", ""));
//       };
//     } catch (err) {
//       reject(err);
//     }
//   });
// }

export function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
      reader.onerror = reject;
      reader.onloadend = () => {
        if (reader.result.includes("data:image/jpeg;base64"))
          resolve(reader.result.replace("data:image/jpeg;base64,", ""));

        if (reader.result.includes("data:image/png;base64"))
          resolve(reader.result.replace("data:image/png;base64,", ""));
      };
      reader.readAsDataURL(blob);
  });
}

export function blobToImages(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
      reader.onerror = reject;
      reader.onloadend = () => {
        if (reader.result.includes("data:image/jpeg;base64"))
          resolve(reader.result);

        if (reader.result.includes("data:image/png;base64"))
          resolve(reader.result);
      };
      reader.readAsDataURL(blob);
  });
}

