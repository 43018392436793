import React, { Component } from "react";
import { bool, number, oneOfType, arrayOf, node } from "prop-types";

export default class TimeoutVisibility extends Component {
  static propTypes = {
    visible: bool,
    timeout: number,
    children: oneOfType([arrayOf(node), node]),
  };
  constructor(props) {
    super(props);
    this.state = {
      visibility: "hidden",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.state.visibility === "visible"
        ? this.setState({ visibility: "hidden" })
        : this.setState({ visibility: "visible" });
    }
  }

  componentDidMount() {
    if (this.props.timeout && this.props.visible === true) {
      this.timeoutId = setTimeout(() => {
        this.setState({ visibility: "visible" });
      }, this.props.timeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutId);
  }

  render() {
    return <div style={this.state}>{this.props.children}</div>;
  }
}
