import Navigation from "./Navigation";
import ConnectionSpinner from "./ConnectionSpinner";
import ApplicationConfig from "./ApplicationConfig";
import ApplicationContinue from "./ApplicationContinue";
// import ExpireSession from "./ExpireSession";
import { useSelector } from "react-redux";
import SlowNetworkWarning from "./SlowNetworkWarning";

export default function App() {
  const isInProgress = useSelector((store) => store.apiFetch.isInProgress);
  // const statusExpire = useSelector((store) => store.expireTimeOut.stsExpireTime)
  // console.log(process.env.NODE_ENV, process.env.REACT_APP_SHOW_BUILD_VERISON, process.env.REACT_APP_SHOW_ONBOARDING_RESULT)
  return (
    <ConnectionSpinner>
      {isInProgress && <SlowNetworkWarning />}
      {/* {statusExpire && <ExpireSession />} */}
      <ApplicationConfig>
        <ApplicationContinue>
          <Navigation />
        </ApplicationContinue>
      </ApplicationConfig>
    </ConnectionSpinner>
  );
}
