
/*global Daon */
/*eslint no-undef: "error"*/

import React, { Component } from "react";
import { func, number, instanceOf, bool } from "prop-types";
import { connect } from "react-redux";
import {
  clearMessages,
  clearReadyState
} from "logic/actions/faceLiveness3D";
import { canvasDrawingClass } from "./utils/canvasDrawingClass";

import { getLabelForResult, getLabelForMessage } from "./labels";
import frameOverlayGreen from "assets/frame_overlay_far_green-640.png";
import frameOverlayRed from "assets/frame_overlay_far_red-640.png";
import {
  faceLivenessMovementDuration,
  faceLivenessMovementDelay,
} from "logic/faceLivenessTimingConfiguration";
import "./style.css";
import { withTranslation } from "react-i18next";
import { store } from "logic/store";
import { go } from "logic/actions/navigation";

const UPDATE_TYPES = Daon.FaceLiveness3D.UPDATE_TYPES;
export class OldFaceLiveness3DUI extends Component {
  static propTypes = {
    width: number,
    height: number,
    onInitialized: func,
    onCameraStarted: func,
    instance: instanceOf(Object),
    retryCameraInit: bool,
    initVideo: func
  };

  constructor(props) {
    super(props);
    this.counterTimer.bind(this);
    this.videoRef = new React.createRef();
    this.state = {
      animationStart: false,
      finished: false,
      reinitCamera: false,
      counter: faceLivenessMovementDelay / 1000,
      isCounterActive: false,
      mirror: false,
      xWidth: parseInt(process.env.REACT_APP_USE_LIVENESS_RESOLUTION_WIDTH) || 640,
      xHeight: parseInt(process.env.REACT_APP_USE_LIVENESS_RESOLUTION_HEIGHT) || 480
      // xWidth: 320,
      // xHeight: 240
    };
  }

  streamCamera(e) {
    e.style["-webkit-transform"] = "";
    e.style.transform = "";
    e.attributes.autoplay || e.setAttribute("autoplay", "");
    e.attributes.playsinline || e.setAttribute("playsinline", "");
  }

  setRef = (canvas) => {
    this.canvas = canvas;
  }

  defaultCamera() {
    const camRef = store.getState().camera.frontCamera;
    this.initializeCamera(camRef.cameraId);
  }


  initializeCamera(cameraId) {
    // console.log("initializeCamera" + cameraId);
    const constraints = {
      video: {
        deviceId: cameraId,
        width: { ideal: this.state.xWidth },
        height: { ideal: this.state.xHeight }
      },
      audio: false
    }
    return navigator.mediaDevices.getUserMedia(constraints)
      .then(
        (mediaStream) => {
          try {
            if (this.videoRef.current.srcObject !== undefined) {
              this.videoRef.current.srcObject = undefined;
            }
            this.videoRef.current.srcObject = mediaStream;
            this.streamCamera(this.videoRef.current);
            this.props.onCameraStarted(this.videoRef.current, this.videoRef);

            // this.canvas = this.refs.canvas;
            this.ctx = this.canvas.getContext("2d");
            // this.text = this.refs.text;

            this.imageGreen = new Image();
            this.imageGreen.src = frameOverlayGreen;

            this.imageRed = new Image();
            this.imageRed.src = frameOverlayRed;

            this.canvasDrawingClass = new canvasDrawingClass(
              this.canvas,
              this.ctx,
              this.imageGreen
            );

            this.imageGreen.onload = () => {
              this.props.greenOverlayLoaded();
            };

            this.props.clearMessages();
          } catch (err) { }
        }, (err) => {
          // console.log(err);
        }
      );
  }



  componentDidMount() {
    this.defaultCamera();
    this.setState({
      mirror: this.props.mirror
    })
    try {
      // this.canvas = this.refs.canvas;
      this.ctx = this.canvas.getContext("2d");
      // this.text = this.refs.text;

      this.imageGreen = new Image();
      this.imageGreen.src = frameOverlayGreen;

      this.imageRed = new Image();
      this.imageRed.src = frameOverlayRed;

      this.canvasDrawingClass = new canvasDrawingClass(
        this.canvas,
        this.ctx,
        this.imageGreen
      );

      this.imageGreen.onload = () => {
        this.props.greenOverlayLoaded();
      };

      this.imageRed.onload = () => {
        this.props.redOverlayLoaded();
        this.canvasDrawingClass.drawOutline(this.imageRed);
      };

      this.props.clearMessages();
    } catch (err) { }
  }

  shouldComponentUpdate(prevProps) {
    return (
      prevProps.isReady !== this.props.isReady ||
      prevProps.message !== this.props.message ||
      // prevProps.result !== this.props.result ||
      // prevProps.retryCameraInit !== this.props.retryCameraInit ||
      prevProps.isRunning !== this.props.isRunning
      // prevProps.finished !== this.props.finished
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.message !== state.message) {
      return {
        message: props.message,
      };
    }

    if (props.isReady !== state.isReady) {
      let timeoutIsReady = setTimeout(() => {
        clearTimeout(timeoutIsReady)
        return {
          isReady: props.isReady
        };
      }, 100)
    }

    if (props.result === "PASS" || props.result === "FAIL")
      return { result: props.result };

    if (props.isRunning && props.isRunning !== state.isRunning)
      return { isRunning: props.isRunning };

    if (props.finished && props.finished !== state.finished)
      return { finished: props.finished };

    if (props.retryCameraInit !== state.retryCameraInit)
      return { retryCameraInit: props.retryCameraInit };

    return null;
  }

  componentDidUpdate() {
    switch (this.state.message) {
      case UPDATE_TYPES.READY:
        break;
      case UPDATE_TYPES.AWAIT_RESULTS:
        this.setState({ finished: true });
        if (typeof this.props.onDone === "function") {
          this.props.onDone();
        }
        break;
      case UPDATE_TYPES.HOLD:
        this.setState({ isCounterActive: true });
        this.canvasDrawingClass.drawOutline(this.imageGreen);
        if (this.counterInterval) clearInterval(this.counterInterval)
        this.counterInterval = setInterval(() => {
          if (this.state.counter === 1) {
            this.setState({ isCounterActive: false });
            clearInterval(this.counterInterval);
          } else {
            this.setState({ counter: this.state.counter - 1 });
            this.forceUpdate();
          }
        }, 1000);
        break;
      case UPDATE_TYPES.MOVE_CLOSER:
        this.canvasDrawingClass.startAnimation(faceLivenessMovementDuration);
        break;
      default:
        break;
    }

    if (this.state.retryCameraInit) this.reinitCamera();
  }

  componentWillUnmount() {
    try {
      this.props.instance.stopCamera();
      this.props.instance.terminate();
    } catch (err) { }
    try {
      if (this.videoRef.current) {
        const stream = this.videoRef.current.srcObject;
        // const tracks = stream.getTracks();
        stream.getVideoTracks().forEach(track => {
          track.stop();
        });
      }
    } catch (err) { }


    this.setState = (state, callback) => {
      return;
    };
  }

  counterTimer() {
    if (this.state.counter === 1) {
      this.setState({ isCounterActive: false });
      clearInterval(this.counterInterval);
    } else {
      this.setState({ counter: this.state.counter - 1 });
      // this.forceUpdate();
    }
  }

  reinitCamera() {
    this.setState({ reinitCamera: true });
  }

  render() {
    let guiding = document.getElementById("guiding");
    let instructionsStyle = this.state.isCounterActive
      ? "instructions-on-face-countdown"
      : "instructions-on-face";
    let instuctions;
    const shouldBeFlipped = !this.props.mirror ? "flip-x" : "";
    const containerVideo = `ui-canvas ${shouldBeFlipped}`;
    const containerCanvas = `ui-canvas-container ui-canvas`;
    if (this.props.message === UPDATE_TYPES.HOLD) {
      if (guiding) guiding.style.textShadow = "0 0 5px green";
      instructionsStyle = "instructions-on-face-countdown";
      instuctions = this.state.counter;
    } else {
      if (guiding) guiding.style.textShadow = this.props.message === UPDATE_TYPES.MOVE_CLOSER ? "0 0 5px green" : "0 0 5px red";
      instuctions =
        getLabelForResult(this.state.result, this.props.score) ||
        getLabelForMessage(this.props.message, UPDATE_TYPES, this.props.t);

    }

    const safariRenderHack = { opacity: this.state.counter % 2 ? 1 : 0.99 };

    return (
      <div>
        <p style={safariRenderHack} id="guiding" className={instructionsStyle}>{instuctions}</p>
        <div className="ui-canvas-container">
          <div className={containerVideo}>
            <video style={{ width: "100%", height: "100%", objectFit: "cover" }} ref={this.videoRef}></video>
          </div>
          {/* <video className={containerVideo} style={{ width: "100%", height: "100%" }} ref={this.videoRef}></video> */}
          {!this.state.finished ? (
            <canvas
              style={{ zIndex: 10 }}
              className={containerCanvas}
              // ref="canvas"
              ref={this.setRef}
              // width={this.state.xWidth}
              // height={this.state.xHeight}
              width={640}
              height={480}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
const componentWithTranslation = withTranslation()(OldFaceLiveness3DUI);
export default connect(
  (state) => {
    const { mirror } = state.documentCaptureConfiguration;
    return {
      ...state.faceLiveness3D,
      mirror
    };
  },
  {
    clearMessages,
    clearReadyState,
    go
  }
)(componentWithTranslation);