/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import "../../style.css";
import { Modal } from 'react-bootstrap';
import PageContent from 'Components/PageContent';
import { go, nextStep } from "logic/actions/navigation";
import NumberFormat from 'react-number-format';
import { documentsGet, documentUpdate } from "logic/actions/api/document";
import imgwarning from '../../../../assets/warning-icon.png';
import {
    DOCUMENT_CAPTURE_TYPE2_SELECT
} from "logic/enums/pages";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import { logEvent } from 'logic/eventLogger';

export class PageShowOCROtherDocs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentOCRDataLoaded: true,
            buttonsDisabled: false,
            error: null,
            message: null,
            onModalExpire: false,
            disableField: false,
            field: {},
            errors: {},
            mrzObjectData: null,
            gender: "",
            formIsValid: true,
            inputGender: '',
            firstNameValid: true,
            lastNameValid: true,
            nameEngValid: true,
            middleNameValid: true,
            pidValid: true,
            laserValid: true,
            birtDayValid: true,
            genderValid: true,
            expireValid: true,
            placeHolder: "",
            onModalBirthDayExample: false,
            setCurrentDate: ""
        }
        this.documentPut.bind(this)
    }

    componentDidMount() {
        this.placeholderOfDate()
    }
    handleDopaSuccess = () => {
        setTimeout(() => {
            this.props.go(DOCUMENT_CAPTURE_TYPE2_SELECT)
        }, 200)
    }
    splitDate = (date) => {
        let splitDate, ogBirthday;
        splitDate = date.split('/')
        if (splitDate[0] === '-' && splitDate[1] === '-') {
            let year = parseInt(splitDate[2]) - 543;
            ogBirthday = year.toString() + '0101';
        } else if (splitDate[0] === '-') {
            let year = parseInt(splitDate[2]) - 543;
            ogBirthday = year.toString() + splitDate[1] + '01';
        } else {
            let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
            let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
            let year = parseInt(splitDate[2]) - 543;
            ogBirthday = year.toString() + mounth + day;
        }
        return ogBirthday
    }

    documentPut = () => {
        startEventTimer("other_ocr_conirm_[put-data]")
        logEvent("other_ocr_conirm_[put-data]", {
            start_time: window.performance.now(),
            response: null
        })
        let splitBirthDay = this.splitDate(this.birthDay.value);
        let splitExpireDate = this.expire.value ? this.splitDate(this.expire.value) : "";

        this.setState({
            btnSubmitsts: false,
            documentOCRDataLoaded: false,
            onModal: false,
            disableField: true
        });
        const uncroppedFirstPage = this.props.blobSecondPage ? this.props.blobSecondPage : this.props.blobFirstPage;
        const uncroppedSecondPage = this.props.blobSecondPage;
        const updatedOCRFields = {
            confirmedGivenNames: this.givenname.value.trim(),
            confirmedLastname: this.surname.value.trim(),
            middleName: this.middleName.value.trim(),
            confirmedIdNumber: this.docNumber.value.trim(),
            gender: this.state.gender,
            birthOfDate: splitBirthDay,
            expireDate: splitExpireDate,
            confirmedBirthdate: splitBirthDay,
        }
        this.props.documentUpdate(
            uncroppedFirstPage,
            uncroppedSecondPage,
            this.props.documentType,
            updatedOCRFields
        )
            .then((response) => {
                logEvent("other_ocr_conirm_[put-data]", {
                    start_time: getTimePassed("other_ocr_conirm_[put-data]"),
                    response: response
                })
                this.handleDopaSuccess();
            })
            .catch((err) => {
                logEvent("other_ocr_conirm_[put-data]", {
                    start_time: getTimePassed("other_ocr_conirm_[put-data]"),
                    response: err
                })
                if (err.response && err.response.data && err.response.data.message) {
                    this.setState({
                        btnSubmitsts: true,
                        documentOCRDataLoaded: true,
                        error: err.response.data.message,
                        disableField: false
                    });
                } else {
                    this.setState({
                        btnSubmitsts: true,
                        error: err.toString(),
                        documentOCRDataLoaded: true,
                        disableField: false
                    });
                }
            });
    }
    compareDateExpire = (dateDoc) => {
        var date1 = new Date();
        var date2 = new Date(dateDoc);
        if (date1.getTime() > date2.getTime()) {
            this.setState({ onModalExpire: true })
            return true;
        }
        return false
    }

    confirmData = (e) => {
        e.preventDefault();
        let errors = {};
        // let errorTemp = this.state.errors
        if (!this.givenname.value) {
            errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null')
            this.setState({ firstNameValid: false })
        } else {
            if (typeof this.state.errors["firstname"] !== "undefined") {
                if (this.state.errors["firstname"]) {
                    errors["firstname"] = this.state.errors["firstname"]
                    this.setState({ firstNameValid: false })
                } else {
                    errors["firstname"] = ""
                    this.setState({ firstNameValid: true })
                }
            } else {
                errors["firstname"] = ""
                this.setState({ firstNameValid: true })
            }
        }
        if (!this.surname.value) {
            errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null')
            this.setState({ lastNameValid: false })
        } else {
            if (typeof this.state.errors["lastname"] !== "undefined") {
                if (this.state.errors["lastname"]) {
                    errors["lastname"] = this.state.errors["lastname"]
                    this.setState({ lastNameValid: false })
                } else {
                    errors["lastname"] = ""
                    this.setState({ lastNameValid: true })
                }
            } else {
                errors["lastname"] = ""
                this.setState({ lastNameValid: true })
            }
        }
        if (!this.docNumber.value) {
            errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.doc_number_not_null')
            this.setState({ laserValid: false })
        } else {
            if (typeof this.state.errors["docNumber"] !== "undefined") {
                if (this.state.errors["docNumber"]) {
                    errors["docNumber"] = this.state.errors["docNumber"]
                    this.setState({ laserValid: false })
                } else {
                    errors["docNumber"] = ""
                    this.setState({ laserValid: true })
                }
            } else {
                errors["docNumber"] = ""
                this.setState({ laserValid: true })
            }
        }
        if (!this.state.gender) {
            errors["gender"] = this.props.t('PageDocumentConfirmOCRData.gender_not_null')
            this.setState({ genderValid: false })
        } else {
            errors["gender"] = ""
            this.setState({ genderValid: true })
        }

        if (!this.birthDay.value) {
            errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_not_null')
            this.setState({ birtDayValid: false })
        } else {
            if (typeof this.state.errors["birthday"] !== "undefined") {
                if (this.state.errors["birthday"]) {
                    errors["birthday"] = this.state.errors["birthday"]
                    this.setState({ birtDayValid: false })
                } else {
                    errors["birthday"] = ""
                    this.setState({ birtDayValid: true })
                }
            } else {
                errors["birthday"] = ""
                this.setState({ birtDayValid: true })
            }
        }

        if (typeof this.state.errors["expireDate"] !== "undefined") {
            if (this.state.errors["expireDate"]) {
                errors["expireDate"] = this.state.errors["expireDate"]
                this.setState({ laserValid: false })
            } else {
                errors["expireDate"] = ""
                this.setState({ laserValid: true })
            }
        } else {
            errors["expireDate"] = ""
            this.setState({ laserValid: true })
        }
        this.setState({ errors: errors });
        setTimeout(() => {
            // console.log(this.state.laserValid, this.state.firstNameValid, this.state.lastNameValid, this.state.birtDayValid, this.state.pidValid, this.state.genderValid, this.state.genderValid, this.state.birtDayValid)
            if (this.state.laserValid &&
                this.state.firstNameValid &&
                this.state.lastNameValid &&
                this.state.birtDayValid &&
                this.state.pidValid &&
                this.state.genderValid &&
                this.state.birtDayValid &&
                this.state.expireValid
            ) {
                this.documentPut()
            }
        }, 200)
    }

    handleValidate = () => {
        let fields = this.state.field;
        let errors = {};
        if (this.props.documentType === "COPY_ID_CARD") {
            if (typeof fields["docNumber"] !== "undefined") {
                if (fields["docNumber"]) {
                    if (!fields["docNumber"].match(/^\d+$/g)) {
                        errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.pid_number_only');
                        this.setState({ pidValid: false })
                    } else {
                        if (fields["docNumber"].match(/^\d{14,}$/g)) {
                            errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.pid_more_13_digit');
                            this.setState({ pidValid: false })
                        } else if (fields["docNumber"].match(/^\d{1,12}$/g)) {
                            errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.pid_less_13_digit');
                            this.setState({ pidValid: false })
                        } else {
                            errors["docNumber"] = "";
                            this.setState({ pidValid: true })
                        }

                    }
                } else {
                    errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.pid_not_null');
                    this.setState({ pidValid: false })
                }
            }
        } else {
            if (typeof fields["docNumber"] !== "undefined") {
                // console.log(fields["pid"])
                if (fields["docNumber"]) {
                    if (fields["docNumber"].match(/[$-/:-?{-~!"^_`'\\]/g)) {
                        errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.laser_id_ch_and_num_only');
                        this.setState({ pidValid: false })
                    } else {
                        errors["docNumber"] = "";
                        this.setState({ pidValid: true })
                    }
                } else {
                    errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.doc_number_not_null');
                    this.setState({ pidValid: false })
                }
            }
        }

        if (this.props.documentType === "COPY_ID_CARD" && typeof fields["firstname"] !== "undefined") {
            if (fields["firstname"]) {
                if (!fields["firstname"].match(/^[ๅภถุึคูตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ $-/:.-?{-~!"^_`\\]+$/g)) {
                    errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.firstname_th_only');
                    this.setState({ firstNameValid: false })
                } else {
                    errors["firstname"] = "";
                    this.setState({ firstNameValid: true })
                }
            } else {
                errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null');
                this.setState({ firstNameValid: false })
            }
        } else {
            if (typeof fields["firstname"] !== "undefined") {
                // console.log(fields["firstname"])
                // [\u0E00-\u0E7Fa-zA-Z']
                if (fields["firstname"]) {
                    if (fields["firstname"].match(/[0123456789\\]/g)) {
                        errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.other_firstname_alphabet_only');
                        this.setState({ firstNameValid: false })
                    } else {
                        errors["firstname"] = "";
                        this.setState({ firstNameValid: true })
                    }
                } else {
                    errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null');
                    this.setState({ firstNameValid: false })
                }
            }
        }

        if (this.props.documentType === "COPY_ID_CARD" && typeof fields["lastname"] !== "undefined") {
            if (fields["lastname"]) {
                if (!fields["lastname"].match(/^[ๅภถุึคูตจขชๆไำพะัีรนยบลฃฟหกดเ้่าสวงผปแอิืทมใฝฎฑธํ๊ณฯญฐฅฤฆฏโฌ็๋ษศซฉฮฺ์ฒฬฦ $-/:.-?{-~!"^_`\\]+$/g)) {
                    errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.lastname_th_only');
                    this.setState({ lastNameValid: false })
                } else {
                    errors["lastname"] = "";
                    this.setState({ lastNameValid: true })
                }
            } else {
                errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null');
                this.setState({ lastNameValid: false })
            }
        } else {
            if (typeof fields["lastname"] !== "undefined") {
                // console.log(fields["firstname"])
                // [\u0E00-\u0E7Fa-zA-Z']
                if (fields["lastname"]) {
                    if (fields["lastname"].match(/[0123456789]/g)) {
                        errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.other_lastname_alphabet_only');
                        this.setState({ lastNameValid: false })
                    } else {
                        errors["lastname"] = "";
                        this.setState({ lastNameValid: true })
                    }
                } else {
                    errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null');
                    this.setState({ lastNameValid: false })
                }
            }
        }

        if (typeof fields["birthday"] !== "undefined") {
            if (fields["birthday"]) {
                if (!fields["birthday"].match(/^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[0-2])[-/.]\d{4}$/g)) {
                    errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                    this.setState({ birtDayValid: false })
                } else {
                    let getMinDate = fields["birthday"].split('/');
                    let getMaxDate = this.state.setCurrentDate.split('/');
                    if (getMinDate[getMinDate.length - 1].length >= 4) {
                        if (parseInt(getMinDate[getMinDate.length - 1]) >= 2420 &&  // Min year
                            parseInt(getMinDate[getMinDate.length - 1]) <= parseInt(getMaxDate[getMaxDate.length - 1]) // Max year
                        ) {
                            if (parseInt(getMinDate[getMinDate.length - 1]) === parseInt(getMaxDate[getMaxDate.length - 1]) &&
                                ((parseInt(getMinDate[0]) > parseInt(getMaxDate[0]) && parseInt(getMinDate[1]) === parseInt(getMaxDate[1])) || // Max day
                                    parseInt(getMinDate[1]) > parseInt(getMaxDate[1])) // Max month
                            ) {
                                errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                                this.setState({ birtDayValid: false })
                            } else {
                                errors["birthday"] = "";
                                this.setState({ birtDayValid: true })
                            }
                        } else {
                            errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                            this.setState({ birtDayValid: false })
                        }
                    }
                }
            } else {
                errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_not_null');
                this.setState({ birtDayValid: false })
            }
        }

        if (typeof fields["expireDate"] !== "undefined") {
            if (fields["expireDate"]) {
                if (!fields["expireDate"].match(/^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[0-2])[-/.]\d{4}$/g)) {
                    errors["expireDate"] = this.props.t('PageDocumentConfirmOCRData.expire_invalid');
                    this.setState({ birtDayValid: false })
                } else {
                    let getMinDate = fields["expireDate"].split('/');
                    let getMaxDate = this.state.setCurrentDate.split('/');
                    let getMaxYear = parseInt(getMaxDate[getMaxDate.length - 1]) + 200
                    if (getMinDate[getMinDate.length - 1].length >= 4) {
                        if (parseInt(getMinDate[getMinDate.length - 1]) >= 2420 &&  // Min year
                            parseInt(getMinDate[getMinDate.length - 1]) <= getMaxYear // Max year
                        ) {
                            errors["expireDate"] = "";
                            this.setState({ expireValid: true })
                        } else {
                            errors["expireDate"] = this.props.t('PageDocumentConfirmOCRData.expire_invalid');
                            this.setState({ expireValid: false })
                        }
                    }
                }
            }
        }
        this.setState({ errors: errors });
        // console.log(this.state.errors)
    }

    handleChange = (e, field) => {
        e.preventDefault();
        let fields = this.state.field;
        fields[field] = e.target.value;

        this.setState({ field: fields });
        this.handleValidate()
    }
    placeholderOfDate = () => {
        let date = new Date().toLocaleDateString('th-TH',
            {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric'
            })
        let splitDate = date.split('/')
        let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
        let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
        let output = day + '/' + mounth + '/' + splitDate[splitDate.length - 1]
        this.setState({
            placeHolder: 'ex. ' + output,
            setCurrentDate: output
        })
    }
    selectGender = (title) => {
        if (title === "M") {
            this.female.checked = false
            this.male.checked = true
            this.setState({ gender: "Male" })
        } else if (title === "F") {
            this.female.checked = true
            this.male.checked = false
            this.setState({ gender: "Female" })
        } else if (this.male.checked === true) {
            this.setState({ gender: "Male" })
        } else if (this.female.checked === true) {
            this.setState({ gender: "Female" })
        }
    }
    render() {
        return (
            <PageContent toggleLoading={!this.state.documentOCRDataLoaded} progressBar={true} isSpinner={true}>
                <h1>{this.props.t("PageDocumentConfirmOCRData.title")}</h1>
                <h1>{this.props.t("PageDocumentConfirmOCRData.title_x")}</h1>

                <form onSubmit={(e) => this.confirmData(e)}>
                    <div className="card-body" style={{ marginBottom: '30%' }}>
                        <div className="container-fluid mt-1">
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.other_doc.number_id")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.docNumber = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "docNumber")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["docNumber"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center mt-2">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.firstname")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.givenname = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "firstname")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.middlename")}</label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.middleName = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "middlename")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["middlename"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.lastname")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.surname = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "lastname")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.other_doc.date_of_birth")}<b style={{ color: "red" }}>*</b></label>
                                        <label style={{ color: "#22A8DA", fontSize: '0.8em', marginTop: '0px', cursor: 'pointer', textAlign: 'right' }} onClick={() => this.setState({ onModalBirthDayExample: true })}><u><i>{this.props.t("PageDocumentConfirmOCRData.id_card.laser_id_ex")}</i></u></label>
                                        <NumberFormat
                                            type="text"
                                            className="form-control"
                                            getInputRef={(input) => (this.birthDay = input)}
                                            mask="_"
                                            format="##/##/####"
                                            placeholder={this.state.placeHolder}
                                            onChange={(e) => this.handleChange(e, "birthday")}
                                            disabled={this.state.disableField}
                                        />
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["birthday"]}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-justify-center">
                                <div className="col">
                                    <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.gender")}<b style={{ color: "red" }}>*</b></label>
                                    <div className="row">
                                        <label className="radio-button">
                                            <input
                                                type='radio'
                                                ref={(input) => this.male = input}
                                                onClick={() => this.selectGender()}
                                                name='radio'
                                                disabled={this.state.disableField}
                                            />
                                            <span className="indicator"></span><span className="p3" style={{ fontSize: '1.7em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.male")}</span>
                                        </label>
                                        <label className="radio-button ml-5">
                                            <input
                                                type='radio'
                                                ref={(input) => this.female = input}
                                                onClick={() => this.selectGender()}
                                                name='radio'
                                                disabled={this.state.disableField}
                                            />
                                            <span className="indicator"></span><span className="p3" style={{ fontSize: '1.7em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.female")}</span>
                                        </label>
                                        <span className="ml-3" style={{ color: "red" }}>{this.state.errors["gender"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.other_doc.expired")}</label>
                                        <NumberFormat
                                            type="text"
                                            className="form-control"
                                            getInputRef={(input) => (this.expire = input)}
                                            mask="_"
                                            format="##/##/####"
                                            placeholder={this.state.placeHolder}
                                            onChange={(e) => this.handleChange(e, "expireDate")}
                                            disabled={this.state.disableField}
                                        />
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["expireDate"]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="message-box-error">{this.props.message}</div> */}
                    <footer className="footer d-flex align-items-center flex-column bd-highlight">
                        <a style={{ fontFamily: 'AIA Regular', color: "black", marginBottom: "0px", marginTop: "0px" }}>{this.props.t("PageDocumentConfirmOCRData.confirm_data")}</a>
                        <button
                            disabled={this.state.buttonsDisabled || !this.state.documentOCRDataLoaded}
                            type="submit"
                            value="submit"
                            className="btn"
                            style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
                        >
                            {this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}
                        </button>
                    </footer>
                </form>
                <Modal
                    show={this.state.onModalExpire}
                    // onHide={() => this.setState({ onModal: false })}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentConfirmOCRData.passport.alert_expired')}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.confirmExpire()}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <div className="col-6">
                                <button type="button" className="btn btn-outline-dark" onClick={() => this.setState({ onModal: false })} style={{ color: "black" }}>Cancel</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-success" onClick={this.documentPut}>Confirm</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
                <Modal
                    show={this.state.onModalBirthDayExample}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-start">
                            <h4>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_header")}</h4>
                            <p className="mt-3" style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_tile")}</p>
                            <p style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_ex1")}</p>
                            <p style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_ex2")}</p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ onModalBirthDayExample: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageShowOCROtherDocs);
export default connect(
    (state) => {
        const {
            allRetriesFailed,
            blobFirstPage,
            blobSecondPage,
            documentType,
            message,
            isRejected,
            processingRetriesCounter,
            retries,
            summaryScorePresent,
        } = state.documentCapture;
        return {
            ...state.changeMultiLanguage,
            ...state.configuration.extraConfig.featureFlags,
            ...state.documentCaptureConfiguration,
            ...state.documentCaptureSetData,
            ...state.dopaCheck,
            allRetriesFailed,
            blobFirstPage,
            blobSecondPage,
            documentType,
            message,
            isRejected,
            processingRetriesCounter,
            retries,
            summaryScorePresent,
        };
    },
    {
        go,
        nextStep,
        documentsGet,
        documentUpdate
    }
)(componentWithTranslation)