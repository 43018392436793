import styled from "styled-components";

const handleColor = (color) => {
  switch (color) {
    case "green":
      return "background-color:#4AB336;";
    case "yellow":
      return "background-color: #f2ca00;";
    case "blue":
      return "background-color: #00A4F5;";
    case "grey":
      return "background-color: #808080;";
    case "red":
      return "background-color: #D31145;";
    case "intro":
      return "background-color:#f3f4f4;";
    case "white":
      return "background-color:#ffffff;";
    default:
      return "";
  }
};

const handleType = (type) => {
  switch (type) {
    case "noDoc":
      return "width: 32px; height: 24px;";
    case "docType":
      return "  width: 24px; height: 24px;margin: auto;margin-top: 5%;";
    case "preview":
      return "margin:15px; margin-left:0px";
    default:
      return "margin:15px";
  }
};

const RoundIcon = styled.div`
  border-radius: 100px;
  line-height: 0em;
  padding: 8px;

  ${({ type }) => handleType(type)};
  ${({ color }) => handleColor(color)};
`;

export default RoundIcon;
