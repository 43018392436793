export const application_start = "application_start";
export const application_end = "application_end";
export const page_open = "page_open";
export const step_completed = "step_completed";
export const switching_to_mobile = "switching_to_mobile";
export const switched_to_mobile = "switched_to_mobile";
export const face_capture_started = "face_capture_started";
export const face_capture_native = "face_capture_native";
export const face_captured = "face_captured";
export const face_capture_retry = "face_capture_retry";
export const face_capture_error = "face_capture_error";
export const document_capture_started = "document_capture_started";
export const document_capture_native = "document_capture_native";
export const document_changed_camera = "document_changed_camera";
export const document_captured = "document_captured";
export const document_capture_retry = "document_capture_retry";
export const document_capture_error = "document_capture_error";
export const liveness_test_started = "liveness_test_started";
export const liveness_test_retry = "liveness_test_retry";

export const fl3d_error = "fl3d_error";
export const retry_camera_init = "retry_camera_init";

const loggingFunctions = [];

export function subscribeLoggingFunction(fn) {
  loggingFunctions.push(fn);
}

export function logEvent(name, params) {
  loggingFunctions.forEach((fn) => fn(name, params));
}
