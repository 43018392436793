/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
//import { getCameraIds } from "logic/getCameraIds";
import ButtonBack from "../common/ButtonBack";
import cameraIcon from "assets/icons/16-24/Ico-camera-flip.svg";
import mirrorIcon from "assets/mirror+icon.svg";
import "./style.css";
import { useTranslation } from "react-i18next";
import { store } from "logic/store";
import { setFrontCamera, setRearCamera } from "logic/reducers/camera";

export default function CaptureButtonPanel({
  onCaptureClick,
  disabled,
  backDisabled,
  text,
  onChangeCamera,
  showSwitchCameraButton,
  showMirror,
  onChangeMirror,
  facingMode
}) {
  const [deviceList, setDeviceList] = useState({});
  const [cameraIndex, setCameraIndex] = useState(0);
  //const [cameraRef, setCameraRef] = useState({});

  const { t } = useTranslation();

  //useEffect(() => {
  //  getCameraIds().then((deviceList) => {
  //    setDeviceList(deviceList);
  //    setCameraIndex(deviceList.length - 1);
  //  });
  //}, []);
  useEffect(() => {
    // console.log(store.getState());
    // console.log("fetching camera state");
    const thecameras = store.getState().camera.cameras;
    // console.log(thecameras);
    setDeviceList(thecameras);
    // if (deviceList === undefined || deviceList.length === 0) {
    //   console.log("No Cameras listed");
    // }
    let selectedCam;
    let camIndex = 0;
    if (facingMode === 'user') {
      const frontCamera = store.getState().camera.frontCamera;
      if (frontCamera !== undefined) {
        // console.log("defaulting camera to: " + frontCamera.cameraId);
        selectedCam = frontCamera;
      } else {
        store.dispatch(setFrontCamera(deviceList[camIndex]));
        selectedCam = deviceList[camIndex];
        //cameraRef.frontCamera = deviceList[camIndex];
        // console.log("frontCamera set from index");
      }
    } else {
      const rearCamera = store.getState().camera.rearCamera;
      if (rearCamera !== undefined) {
        // console.log("defaulting camera to: " + rearCamera.cameraId);
        selectedCam = rearCamera;
      } else {
        camIndex = deviceList.length - 1;
        store.dispatch(setRearCamera(deviceList[camIndex]));
        selectedCam = deviceList[camIndex];
        //cameraRef.rearCamera = ;
        // console.log("rearCamera set from index");
      }
    }
    for (let i = 0; i < deviceList.length; i++) {
      if (deviceList[i] === selectedCam) {
        // console.log("selectedCam: " + selectedCam.cameraId + " assigned as index " + i);
        camIndex = i;
      }
    }
    setCameraIndex(camIndex);
  }, [deviceList, facingMode]);

  const switchCamera = () => {
    let newCameraIndex = cameraIndex + 1;
    if (deviceList.length === 1) {
      this.setState({ showSwitchCameraButton: false });
    }
    if (newCameraIndex > deviceList.length - 1) {
      newCameraIndex = 0;
    }
    try {
      let selectedCamera = undefined;
      if (facingMode === 'user') {
        store.dispatch(setFrontCamera(deviceList[newCameraIndex]));
        // setFrontCamera(deviceList[newCameraIndex]);
        selectedCamera = store.getState().camera.frontCamera;
        // console.log("changing camera " + facingMode + " " + selectedCamera.cameraId);
      } else {
        store.dispatch(setRearCamera(deviceList[newCameraIndex]));
        // setRearCamera(deviceList[newCameraIndex]);
        selectedCamera = store.getState().camera.rearCamera;
        // console.log("changing camera " + facingMode + " " + selectedCamera.cameraId);
      }
      onChangeCamera(selectedCamera.cameraId);
      setCameraIndex(newCameraIndex);
    } catch {
      // console.log("Camera " + newCameraIndex + " not found");
      onChangeCamera("");
      newCameraIndex = 0;
      setCameraIndex(newCameraIndex);
    }

    // } else {
    //   onChangeCamera(undefined);
    //   setCameraIndex(0);
    // }
  };

  return (
    <div className="main-container">
      {backDisabled ? (
        <div />
      ) : (
        <div className="box-1">
          <ButtonBack />
        </div>
      )}
      {!showMirror ? (
        <div />
      ) : (
        <div className="box-1">
          <button
            className="flip-camera"
            onClick={() =>
              typeof onChangeMirror === "function" && onChangeMirror()
            }
          >
            <img alt={t("Common.change_camera")} src={mirrorIcon} />
            <a> </a>
          </button>
        </div>
      )}
      <div className="capture-button-panel">
        <button
          className={`btn ${disabled ? "gray" : "blue"} fontCheck`}
          onClick={() =>
            typeof onCaptureClick === "function" && onCaptureClick()
          }
          disabled={disabled}
        >
          {text}
        </button>
      </div>

      <div className="box-3">
        {showSwitchCameraButton ? (
            <button className="flip-camera" onClick={switchCamera}>
              <img alt={t("Common.change_camera")} src={cameraIcon} />
              <a style={{ fontFamily: 'AIA Regular', color: "white", marginTop: '0px' }}>{t("Common.change_camera")}</a>
            </button>
        ) : null}
      </div>
    </div>
  );
}
