import {
  NAVIGATION_GO,
} from "../actions/navigation";
import {
  // INTRO_PAGE,
  INTRO_CONFIRM_TO_START
} from "../enums/pages";
import determineStep from "../determineStep";

const defaultState = {
  page: INTRO_CONFIRM_TO_START,
  step: 0,
};

export function navigation(state = defaultState, action) {
  switch (action.type) {
    case NAVIGATION_GO:
      return {
        ...state,
        page: action.payload,
        step: determineStep(action.payload) || state.step,
      };
    default:
      return state;
  }
}
