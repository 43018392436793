import { store } from "../../store";
// import { refreshSession } from "logic/refreshSession";
import { axiosInstance } from "logic/api";
import { isMobile } from "logic/deviceType";

import { delayedPromise } from "logic/delayedPromise";
import { faceCaptureResponse } from "mocks/applicationPost";

/**
 * POST /{tenant}/IdentityXServices/rest/v1/users/{userId}/face/samples
 */
export const faceSave = (data) => (dispatch) => {
  const { application } = store.getState();
  const deviceMode = isMobile(navigator.userAgent) ? "MOBILE" : "DESKTOP";
  let promise = null
  var action;
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(faceCaptureResponse)
  } else {
    if (window.dtrum) {
      action = window.dtrum.enterAction('8.Verify Face');
    }        
    promise = axiosInstance.post(
      `/IdentityXServices/rest/v1/users/${application.user.id}/face/samples?deviceMode=${deviceMode}`,
      { data, format: "jpg" }
    );
  }

  return promise.then((response) => {
    if (window.dtrum) {
      window.dtrum.leaveAction(action);
    }    
    return response;
  });
};
