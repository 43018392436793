import React, { Component } from "react";
import { connect } from "react-redux";
import "./style.css"
import { withTranslation } from "react-i18next";
import PageContent from "Components/PageContent";
import imgerror from "../../../assets/error-icon.png"




export class PageSignedUp extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }
    render() {
        return (
            <PageContent toggleLoading={false} progressBar={false}>
                <div className="container">
                    <div className="card-body center">
                        <img
                            style={{width: "50%"}}
                            src={imgerror}
                            alt="thankyou"
                            className="mb-3"
                        />
                        <div className="title-text mt-4 mb-2 w-full">
                            <h2>{this.props.t("PageExpireLink.title_text")}</h2>
                        </div>
                    </div>
                </div>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageSignedUp);
export default connect(
    (state) => {
        return {
            ...state.application,
            ...state.setApplicationParams,
            language: state.changeMultiLanguage
        }
    },
)(componentWithTranslation);