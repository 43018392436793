// export const numOfFrames = 1;
// export const initialCaptureDelay = 400;
// export const captureInterval = 400;

// export const numOfFrames = 8;
// export const initialCaptureDelay = 150;
// export const captureInterval = 150;

// export const numOfFrames = 5;
// export const initialCaptureDelay = 200;
// export const captureInterval = 200;

export const numOfFrames = 3;
export const initialCaptureDelay = 300;
export const captureInterval = 300;

// export const numOfFrames = 2;
// export const initialCaptureDelay = 350;
// export const captureInterval = 350;
