import React, { useEffect, useState } from "react";
import { bool } from "prop-types";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";

ConnectionSpinner.propTypes = {
  isOnline: bool,
};

function ConnectionSpinner({ children }) {
  const [isOnline, setIsOnline] = useState(true);
  const { t } = useTranslation()
  useEffect(() => {
    function handleOffline() {
      setTimeout(() => {
        if (!window.navigator.onLine) {
          setIsOnline(false);
        }
      }, 3000);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);
    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  if (isOnline) return children;

  return (
    <LoadingOverlay
      active={!isOnline}
      spinner
      text={t("Common.connection_check_internet_connection")}
    />
  );
}

export default ConnectionSpinner;
