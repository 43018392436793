import { connect } from "react-redux";
import { func } from "prop-types";

import PageContent, { PAGE_CONTENT_TYPE_INSTRUCTIONS } from "../../../PageContent";
import { nextPage } from "logic/actions/navigation";

// import face3DVideo from "assets/videos/03-3D-Face.mp4";
import oldFace3DVideo from "assets/videos/03-3D-FaceOLD.mp4";

import face3DPoster from "assets/videos/03-3D-Face.png";
import { useTranslation } from "react-i18next";

PageInstructionVideo3DFL.propTypes = {
  go: func,
};

export function PageInstructionVideo3DFL(props) {
  function onStart() {
    props.nextPage();
  }
  const { t } = useTranslation();
  return (
    <PageContent progressBar={true} type={PAGE_CONTENT_TYPE_INSTRUCTIONS}>
      <h1 style={{ margin: "20px" }}>{t("Common.instruction_video")}</h1>
      <div style={{ display: "flex", justifyContent: "center", marginBottom: "250px" }}>
        <video
          loop
          autoPlay
          playsInline
          width="70%"
          height="70%"
          poster={face3DPoster}
        >
          <source
            src={oldFace3DVideo}
            type="video/mp4"
          />
          {t("Common.error_video")}
        </video>
      </div>
      <footer className="footer">
        <button
          className="btn"
          type="button"
          style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
          onClick={onStart}
        >
          {t("Common.continue")}
        </button>
      </footer>
    </PageContent>
  );
}

export default connect(
  (state) => {
    return { ...state.configuration.extraConfig.featureFlags };
  },
  { nextPage }
)(PageInstructionVideo3DFL);
