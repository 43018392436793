import { useEffect, useState } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useTranslation } from "react-i18next";

export default function ProcessingOverlay({ children, toggleLoading, overLay, isSpinner }) {
  LoadingOverlay.propTypes = undefined // fix the 'failed prop type' warning
  const { t } = useTranslation();
  const [textUpdated, setTextUpdated] = useState(t("Common.processing"));
  let [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!toggleLoading) setCounter(0);
    let loadingInterval = setInterval(() => {
      if (toggleLoading) setCounter((c) => c++);
    }, 1000);
    return () => {
      clearInterval(loadingInterval);
    };
  }, [toggleLoading]);

  useEffect(() => {
    if (counter > 19) {
      setTextUpdated(t("Common.still_processing"));
    } else {
      setTextUpdated(t("Common.processing"));
    }
  }, [counter, t]);

  return (
    <LoadingOverlay
      active={toggleLoading}
      classNamePrefix={overLay ? "" : "_loading_overlay_overlay"}
      spinner={isSpinner}
      styles={{
        overlay: (base) => ({
          ...base,
          background: overLay ? 'rgba(0, 0, 0)' : 'rgba(0, 0, 0, 0.8)',
        })
      }}
      text={textUpdated}
    >
      {children}
    </LoadingOverlay>
  );
}
