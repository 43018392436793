import styled from 'styled-components'
export const RestartAppWrapper = styled.div`
    width: 40%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-left: 30%;
    margin-top: 5%;
    
    & > button {
        margin: 0;
        margin-top: 10%;
        width: 90%;
    }
`

export const RetryMessage = styled.h1`
    display: flex;
    justify-content: center;
    color: rgb(54, 64, 69);
    margin: 20px;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: center;
`