import styled from "styled-components";

export const DeviceItemStyle = styled.p`
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  ${({ selected }) => {
    if (selected)
      return `
        font-weight: bold;
        color: blue;`;
  }}
`;

export const ButtonLink = styled.button`
  margin-left: 10px;
  background: none;
  border:none;
  font:inherit;
  font-size: inherit;
  text-decoration: underline;
  cursor: pointer;
`