import { store } from "../../store";
import { PASSPORT } from "../../enums/documentType";
// import { refreshSession } from "logic/refreshSession";
import { axiosInstance } from "logic/api";

// import { addXMP } from "../../addXMP";
import { delayedPromise } from "logic/delayedPromise";
import { documentResponse } from "mocks/applicationPost";
import { documentGetOCRdata } from "mocks/getDocumentOCRdata";

import { setResponseDocumentData } from "../documentCapture";

const actionPrefix = "API_";
export const SAVE_DOCUMENT = actionPrefix + "SAVE_DOCUMENT";


export const documentParameter = {
  id_card: {
    parameter: 'U605K1-P1',
    form_id: 'U605K1'
  },
  passport: {
    parameter: 'U846K1-P1',
    form_id: 'U846K1',
    secondDoc: {
      official_doc: {
        parameter_first: 'U606K1-P1',
        parameter_second: 'U606K1-P2',
        form_id: 'U606K1'
      },
    }
  },
  other: {
    copy_id_card: {
      parameter: 'U606K1-P1',
      form_id: 'U605K1'
    },
    official_doc: {
      parameter: 'U605K1-P1',
      form_id: 'U605K1'
    },
    monk_doc: {
      parameter: 'U605K1-P1',
      form_id: 'U605K1'
    },
    secondDoc: {
      official_doc: {
        parameter_first: 'U606K1-P1',
        parameter_second: 'U606K1-P2',
        form_id: 'U606K1'
      },
      gover_doc: {
        parameter: 'U693K1-P1',
        form_id: 'U693K1'
      },
      cert_monk_doc: {
        parameter: 'U606K1-P1',
        form_id: 'U606K1'
      }
    }
  }
}

// note recursive function;
export const pollForDocument = (data) => async (dispatch) => {
  const { application } = store.getState();
  let documentId = data.id;
  let linkRequest = `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentId}`;
  // console.log(linkRequest)
  let promise = await axiosInstance.get(linkRequest);
  return promise;
};

/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/documents
 */
export const documentsSave = (image1, image2, documentType) => async (dispatch) => {
  const documentStore = store.getState().documentCaptureConfiguration;
  const isDocumentSinglePaged = documentStore.singlePaged;
  let type;
  if (documentType === "ID_CARD") {
    type = "1"
  } else if (documentType === "PASSPORT") {
    type = "2"
  } else {
    type = "3"
  }
  const request = {
    processingType: "ID",
    captureType: "BBC",
    captured: new Date().toISOString(),
    captureFormat: documentType === PASSPORT ? "PASSPORT" : "ID1",
    clientCapture: {
      images: [
        {
          type: "FRONT",
          sensitiveData: {
            imageFormat: "JPG",
            value: image1,
          },
        },
      ],
      ocrData: {
        sensitiveData: {
          value: {
            onboardType: type
          }
        }
      }
    }
  };

  if (documentType !== PASSPORT && isDocumentSinglePaged !== true) {
    request.clientCapture.images.push({
      type: "BACK",
      sensitiveData: {
        imageFormat: "JPG",
        value: image2,
      },
    });
  }
  const { application } = store.getState();
  const { documentCaptureSetData } = store.getState();
  const isAsync = false;
  let promise1 = null, promise2 = null;
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise1 = delayedPromise(documentResponse)
  } else {
    let linkRequest;
    if (documentCaptureSetData.id) {
      linkRequest = `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentCaptureSetData.id}`
      promise1 = await axiosInstance.post(linkRequest, request);
      promise2 = await axiosInstance.get(linkRequest);
      return Promise.all([promise1, promise2]).then(([resultOne, resultTwo]) => {
        dispatch(setResponseDocumentData(resultTwo.data));
        return resultTwo;
      })
    } else {
      linkRequest = `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents?isAsync=${isAsync}`
      promise1 = axiosInstance.post(linkRequest, request);
      return promise1.then((response) => {
        dispatch(setResponseDocumentData(response.data));
        return response;
      });
    }
  }
};

/**
 * POST /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/documents
 */
export const secondDocumentsSave = (image1, image2, documentType) => (dispatch) => {
  // const documentStore = store.getState().documentCaptureConfiguration;
  // const isDocumentSinglePaged = documentStore.singlePaged;
  let selectDoctype;
  if (documentType === "PASSPORT") {
    selectDoctype = documentParameter.passport.secondDoc.official_doc.form_id
  } else if (documentType === "SEC_OFFICIAL_ID_CARD") {
    selectDoctype = documentParameter.other.secondDoc.official_doc.form_id
  } else if (documentType === "SEC_GOVERNMENT_ID_CARD") {
    selectDoctype = documentParameter.other.secondDoc.gover_doc.form_id
  } else if (documentType === "SEC_MONK_ID_DOCUMENT") {
    selectDoctype = documentParameter.other.secondDoc.cert_monk_doc.form_id
  }
  const request = {
    captureType: "DR",
    captured: new Date().toISOString(),
    captureFormat: "SECOND DOC",
    clientCapture: {
      images: [
        {
          type: "FRONT",
          sensitiveData: {
            imageFormat: "JPG",
            value: image1,
          },
          subtype: "UNPROCESSED"
        },
      ],
      ocrData: {
        sensitiveData: {
          value: {
            formId: selectDoctype
          }
        }
      }
    },
  };

  if (image2) {
    request.clientCapture.images.push({
      type: "BACK",
      sensitiveData: {
        imageFormat: "JPG",
        value: image2,
      },
      subtype: "UNPROCESSED"
    });
  }
  const { application } = store.getState();
  let promise = null
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(documentResponse)
  } else {
    promise = axiosInstance.post(
      `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents`,
      request
    );
  }

  return promise.then((response) => {
    dispatch(setResponseDocumentData(response.data))
    return response;
  });
};

//==================================[Document UPDATE]==================================
export const documentUpdate = (image1, image2, documentType, updatedOCRFields) => (dispatch) => {
  const documentStore = store.getState().documentCaptureConfiguration;
  const isDocumentSinglePaged = documentStore.singlePaged;
  let updateOCR;
  if (documentType === "ID_CARD") {
    updateOCR = {
      ...updatedOCRFields,
      formId: documentParameter.id_card.form_id,
      onboardType: "1"
    }
  } else if (documentType === "PASSPORT") {
    updateOCR = {
      ...updatedOCRFields,
      formId: documentParameter.passport.form_id,
      onboardType: "2"
    }
  } else if (documentType === "COPY_ID_CARD") {
    updateOCR = {
      ...updatedOCRFields,
      formId: documentParameter.other.copy_id_card.form_id,
      onboardType: "3"
    }
  } else if (documentType === "MONK_ID_DOCUMENT") {
    updateOCR = {
      ...updatedOCRFields,
      formId: documentParameter.other.monk_doc.form_id,
      onboardType: "3"
    }
  } else if (documentType === "OFFICIAL_ID_DOCUMENT") {
    updateOCR = {
      ...updatedOCRFields,
      formId: documentParameter.other.official_doc.form_id,
      onboardType: "3"
    }
  }
  const request = {
    processingType: "ID",
    captureType: "BBC",
    captured: new Date().toISOString(),
    captureFormat: documentType === PASSPORT ? "PASSPORT" : "ID1",
    clientCapture: {
      images: [
        {
          type: "FRONT",
          sensitiveData: {
            imageFormat: "JPG",
            value: image1,
          },
        },
      ],
      ocrData: {
        sensitiveData: {
          value: {
            ...updateOCR
          }
        }
      }
    },
  };

  if (documentType !== PASSPORT && isDocumentSinglePaged !== true) {
    request.clientCapture.images.push({
      type: "BACK",
      sensitiveData: {
        imageFormat: "JPG",
        value: image2,
      },
    });
  }

  const { application } = store.getState();
  const { documentCaptureSetData } = store.getState();
  let promise = null
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(documentResponse)
  } else {
    promise = axiosInstance.post(
      `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentCaptureSetData.id}`,
      request
    );
  }

  return promise.then((response) => {
    return response;
  });
};

//TODO@Lwhite: Get Document Data
/**
 * GET /{tenant}/DigitalOnBoardingServices/rest/v1/users/{userId}/idchecks/{idCheckId}/documents/{documentId}
 */
export const documentsGet = (documentId) => (dispatch) => {
  const { application, documentCaptureSetData } = store.getState();
  let promise = null
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(documentGetOCRdata)
  } else {
    promise = axiosInstance.get(
      `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentCaptureSetData.id}/serverProcessed/ocrData/sensitiveData`
    );
  }
  return promise.then(response => {
    // refreshSession();
    return response;
  });
};

// ================================[Temp]================================
// export const documentsGet = (documentId) => (dispatch) => {
//   const { application, documentCaptureSetData } = store.getState();
//   let document = axiosInstance.get(
//       `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentCaptureSetData.id}`
//   );

//   let serverProcessed = axiosInstance.get(
//       `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentCaptureSetData.id}/serverProcessed`
//   );

//   let serverProcessedSensitiveData = axiosInstance.get(
//       `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/documents/${documentCaptureSetData.id}/serverProcessed/ocrData/sensitiveData`
//   );

//   return Promise.all([document, serverProcessed, serverProcessedSensitiveData]).then(([resultOne, resultTwo, resultThree]) => {
//     const documentResponse = resultOne;
//     const serverDataResponse = resultTwo;
//     const sensitiveDataResponse = resultThree;

//     let combinedResponse = documentResponse;
//     combinedResponse.data.serverProcessed = serverDataResponse.data;
//     combinedResponse.data.serverProcessed.ocrData.sensitiveData = sensitiveDataResponse.data;
//     // console.log(combinedResponse)
//     return combinedResponse;
//   });
// };
