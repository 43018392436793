import React, { Component } from 'react'
import { connect } from 'react-redux'
import { string, instanceOf } from "prop-types";
import './style.css'
import { withTranslation } from "react-i18next";
import PageContent, { PAGE_CONTENT_TYPE_INSTRUCTIONS } from 'Components/PageContent';
import { go } from "logic/actions/navigation";
import {
    DOCUMENT_CAPTURE_PORTRAIT,
    VERIFY_IDENTITY
} from "logic/enums/pages"
import {
    createInstance
} from "logic/actions/documentCapture";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import imgsuccess from "../../../../assets/success-icon.png"
import {
    ID_CARD,
    COPY_ID_CARD,
    COPY_PASSPORT,
    MONK_ID_DOCUMENT,
    OFFICIAL_ID_DOCUMENT
} from "logic/enums/documentType";
import { isMobile } from "logic/deviceType";
import {
    isSinglePaged,
    modalShowed,
    multiCaptureConfig,
    verticalDocumentsPresent,
    changeCamera,
    flipCamera
} from "logic/actions/documentCaptureConfiguration";
import { setExpireTimeOut } from 'logic/actions/expireTimeOut';
import {
    width,
    height
} from "logic/documentResolution"

const START = 0;
// const PASS = 2;
export class NewPageDocumentCaptureInstructions extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dc: undefined,
            pending: false,
            message: "",
            formState: START,
            shouldStartNativeCamera: false,
            width: 2560,
            height: 1440,
            isMobile: isMobile(navigator.userAgent),
            facingMode: "environment",
        }
        this.submit.bind(this)
        if (this.props.documentCapture) {
            this.props.changeCamera({
                cameraId: this.props.documentCaptureConfiguration.cameraId,
                facingMode: this.state.facingMode
            })
            this.props.flipCamera({
                mirror: true
            })
            const { documentQualityHostname } = this.props.configuration;
            const url = documentQualityHostname + "/rest/v1/quality/assessments";
            const xWidth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH) <= 0 ? width : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH);
            const xHeigth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT) <= 0 ? height : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT);
            this.initDocumentCaptureSDK(
                url,
                xWidth,
                xHeigth,
                this.props.documentCapture.documentType,
                this.state.facingMode
            );
        }
        if (!getTimePassed("document_capture")) startEventTimer("document_capture");
    }

    static propTypes = {
        documentType: string,
        base64CroppedFirstPage: instanceOf(Blob),
        configuration: instanceOf(Object),
        documentCapture: instanceOf(Object),
    };

    componentDidMount() {
        // alert("is safari => " + isSafari(navigator.userAgent) + "\n is IOS => " + isIOS(navigator.userAgent))
        if (!this.props.documentCapture.base64CroppedFirstPage &&
            this.props.documentCapture.documentType !== "PASSPORT") {
            this.setState({ hintFacePresent: true });
            this.documentPagesNum(this.props.documentCapture.documentType);
        }
        if (!this.props.documentCaptureConfiguration.verticalDocumentsPresent) {
            this.changeDocumentOrientation("default");
        }
    }

    initDocumentCaptureSDK = (url, width, height, documentType, facingMode) => {
        // console.log(width, height)
        const config = {
            url,
            width,
            height,
            facingMode,
            captureType: "PORTRAIT",
            documentType: documentType === "PASSPORT" ? documentType : "ID_CARD",
        };
        this.props.createInstance(config);
    };

    documentPagesNum = (type) => {
        switch (type) {
            case ID_CARD:
                this.props.isSinglePaged(true);
                break;
            case COPY_ID_CARD:
                this.props.isSinglePaged(true);
                break;
            case COPY_PASSPORT:
                this.props.isSinglePaged(true);
                break;
            case MONK_ID_DOCUMENT:
                this.props.isSinglePaged(true);
                break;
            case OFFICIAL_ID_DOCUMENT:
                this.props.isSinglePaged(true);
                break;
            default:
                this.props.isSinglePaged(false);
        }
    };
    changeDocumentOrientation(orientation) {
        if (orientation === "vertical") {
            switch (this.props.documentCapture.documentType) {
                case "ID_CARD": {
                    this.props.selectDocumentType("ID_CARD_PORT");
                    break;
                }
                case "DRIVERS_LICENCE": {
                    this.props.selectDocumentType("DRIVERS_LICENCE_PORT");
                    break;
                }
                default: {
                    this.props.selectDocumentType("OTHER_DOCUMENT_PORT");
                }
            }
        } else if (orientation === "default") return;
        else {
            switch (this.props.documentCapture.documentType) {
                case "ID_CARD_PORT": {
                    this.props.selectDocumentType("ID_CARD");
                    break;
                }
                case "DRIVERS_LICENCE_PORT": {
                    this.props.selectDocumentType("DRIVERS_LICENCE");
                    break;
                }
                default: {
                    this.props.selectDocumentType("OTHER_DOCUMENT");
                }
            }
        }
    }
    componentWillUnmount() {
        // this.props.setExpireTimeOut(new Date().getTime())
        this.props.documentCapture.docCaptureInstance.clearCapturedFrames();
    }
    submit = () => {
        // this.props.setExpireTimeOut(new Date().getTime())
        // this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
        // this.props.go(DOCUMENT_CAPTURE_INSTRUCTIONS);
        if (!this.props.documentCapture.fromPortrait) {
            this.props.go(VERIFY_IDENTITY);
        } else {
            this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
        }

    }
    render() {
        return (
            <PageContent progressBar={true} type={PAGE_CONTENT_TYPE_INSTRUCTIONS}>
                <div className="contianer-fluid">
                    <div className="card-body">
                        <h1>{this.props.t('NewPageFaceCaptureInstructions.header')}</h1>
                        <div className="container">
                            <div className="row mt-3 no-gutters">
                                <div className="col-1">
                                    <img
                                        className="ml-3 mb-3"
                                        style={{ width: '50%', height: 'inherit' }}
                                        src={imgsuccess}
                                        alt=""
                                    />
                                </div>
                                <div className="col mr-3 ml-3">
                                    <p className="font-text w-full">{this.props.t('NewPageFaceCaptureInstructions.title1')}</p>
                                </div>
                            </div>
                            <div className="row mt-0 no-gutters">
                                <div className="col-1">
                                    <img
                                        className="ml-3 mb-3"
                                        style={{ width: '50%', height: 'inherit' }}
                                        src={imgsuccess}
                                        alt=""
                                    />
                                </div>
                                <div className="col mr-3 ml-3">
                                    <p className="font-text w-full">{this.props.t('NewPageFaceCaptureInstructions.title2')}</p>
                                </div>
                            </div>
                            <div className="row mt-0 no-gutters">
                                <div className="col-1">
                                    <img
                                        className="ml-3 mb-3"
                                        style={{ width: '50%', height: 'inherit' }}
                                        src={imgsuccess}
                                        alt=""
                                    />
                                </div>
                                <div className="col mr-3 ml-3">
                                    <p className="font-text w-full">{this.props.t('NewPageFaceCaptureInstructions.title3')}</p>
                                </div>
                            </div>
                            <div className="row mt-0 no-gutters">
                                <div className="col-1">
                                    <img
                                        className="ml-3 mb-3"
                                        style={{ width: '50%', height: 'inherit' }}
                                        src={imgsuccess}
                                        alt=""
                                    />
                                </div>
                                <div className="col mr-3 ml-3">
                                    <p className="font-text w-full">{this.props.t('NewPageFaceCaptureInstructions.title4')}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <button type="button" className="btn" onClick={() => this.submit()} style={{ backgroundColor: '#D31145', fontSize: '1em', marginTop: '0px' }}>{this.props.t('NewPageFaceCaptureInstructions.btn_submit')}</button>
                </footer>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(NewPageDocumentCaptureInstructions);
export default connect(
    (state) => {
        return {
            livenessTest: state.livenessTest,
            documentCapture: state.documentCapture,
            configuration: state.configuration,
            lockUI: state.configuration.extraConfig.featureFlags.lockUI,
            timeout: state.configuration.extraConfig.featureFlags.timeout,
            documentCaptureConfiguration: state.documentCaptureConfiguration,
        };
    },
    {
        createInstance,
        go,
        isSinglePaged,
        modalShowed,
        multiCaptureConfig,
        verticalDocumentsPresent,
        changeCamera,
        flipCamera,
        setExpireTimeOut
    }
)(componentWithTranslation)