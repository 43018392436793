export const SET_DOPA_CHECK_DATA = "SET_DOPA_CHECK_DATA";
export const SET_DOPA_RETRY = "SET_DOPA_RETRY";
export const RESET_DOPA_CHECK = 'RESET_DOPA_CHECK';
export const SET_RESPONSE_DOPA = 'SET_RESPONSE_DOPA';
export const SET_TWO_ELEMENT_CHECK = 'SET_TWO_ELEMENT_CHECK';

export function setResponseDopa(payload){
    return {type: SET_RESPONSE_DOPA, payload}
}

export function setDataDOPACheck(payload) {
    return { type: SET_DOPA_CHECK_DATA, payload }
}

export function countDopaRetries(payload){
    return {type: SET_DOPA_RETRY, payload}
}

export function resetDopaCheck(payload){
    return {type: RESET_DOPA_CHECK, payload}
}

export function setTwoElementCheck(payload) {
    return {type: SET_TWO_ELEMENT_CHECK, payload}
}