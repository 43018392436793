/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import './style.css';
// eslint-disable-next-line
import PageContent from "Components/PageContent";
import { go, nextPage } from "logic/actions/navigation";
import { withTranslation } from "react-i18next";
import { store } from "logic/store";
import QRCode from 'react-qr-code';
import { isMobile, isIOS } from "logic/deviceType";
import { challengeCreate } from "logic/actions/api/liveness";
import { Modal } from "react-bootstrap";
import { startEventTimer, getTimePassed, getTimeStart } from "logic/stepTimers";
import { logEvent } from "logic/eventLogger";
import { checkCameraAccess, checkCameraSupport, defaultHandleFailure, setDefaultCameras, recommendBrowser } from "logic/cameraChecker";
import imgwarning from '../../../assets/warning-icon.png';
import { encryptUserId } from "logic/encryptData";

export class PageGuidance extends Component {

    constructor(props) {
        super(props)
        this.state = {
            genQRcode: "NA",
            isMobile: true,
            pending: false,
            smsCode: 123456,
            modalNationality: false,
            thisIsign: false,
            showBarcode: false,
            thisIpos: false,
            onModal: false,
            onModalErrorCamera: false,
            error: null,
            cameraCount: 0,
            errorHtml: "",
            hasPermittedCamera: false,
            userIdEncrypt: ""
        }
        this.submit.bind();
        this.goBack.bind();
        this.callBackSuccess.bind(this)
        if (!getTimePassed("landing_page")) startEventTimer("landing_page");
    }

    UNSAFE_componentWillMount() {
        // console.log(window.dtrum)
        if (this.props.pathname === "login") {
            let idAgent = this.props.user.userId.split("_")[1]
            let time = this.props.user.userId.split("_")[2]
            let t_time = time.substring(1, time.length)
            let userEncrypt = encryptUserId(this.props.user.userId)
            this.setState({
                userIdEncrypt: idAgent + t_time + userEncrypt
            })
        } else {
            let userEncrypt = encryptUserId(this.props.user.userId)
            // let userId = this.props.user.userId.split(/[_T]+/g)
            let userId = this.props.user.userId.split("_")
            // console.log(userId[userId.length - 1].substring(userId[userId.length - 1].length - 9, userId[userId.length - 1].length) + userEncrypt)
            this.setState({
                userIdEncrypt: userId[userId.length - 1].substring(userId[userId.length - 1].length - 9, userId[userId.length - 1].length) + userEncrypt
            })
        }
        this.setState({
            cameraCount: store.getState().camera.cameras.length,
        });
        this.setState({ isMobile: isMobile(navigator.userAgent), genQRcode: this.props.urlStart });
        if (isIOS(navigator.userAgent)) this.setState({ isIOS: true });
        if (this.props.pathname === "iframe") {
            this.setState({ thisIsign: true });
            if (this.props.componentID === "90" || this.props.componentID === "91") {
                if (this.props.secondDevice !== "yes")
                    this.setState({ showBarcode: true })
            }
        }
        // else if (this.props.pathname === "login" || this.props.pathname === "manual") {
        //     this.setState({ showBarcode: true })
        // }
    }

    componentDidMount() {
        this.allowCamera()
    }

    componentWillUnmount() {
        this.setState({ pending: false });
    }
    allowCamera() {
        this.setState({ pending: true });
        if (!checkCameraSupport()) {
            this.setState({ error: recommendBrowser(this.props.t) });
        }
        checkCameraAccess().then(() => {
            setDefaultCameras();
            this.setState({
                hasPermittedCamera: true,
                pending: false,
                onModal: true,
                onModalErrorCamera: false
            })
        }, (err) => {
            this.setState({
                errorHtml: defaultHandleFailure(err.toString(), navigator.userAgent, this.props.t),
                pending: false,
                onModal: false,
                onModalErrorCamera: true

            });
        });
    }
    selectChangeLanguage(lang) {
        window.scrollBy(1, 2);
        setTimeout(() => {
            window.scrollBy(-1, -2);
        }, 100);
        store.dispatch({
            type: 'CHANGE_LANGUAGE',
            payload: {
                setupLanguage: lang
            }
        })
    }

    handleSuccessAplication = () => {
        startEventTimer("landing_page")
        logEvent("landing_page", {
            start_time: window.performance.now(),
            response: null
        })
        // this.props.setExpireTimeOut(new Date().getTime())
        this.props
            .challengeCreate()
            .then((res) => {
                logEvent("landing_page", {
                    end_time: window.performance.now() - getTimeStart("landing_page"),
                    reponse: res
                })
                // this.props.setExpireTimeOut(new Date().getTime())
                this.props.nextPage();
            })
            .catch((err) => {
                logEvent("landing_page", {
                    end_time: window.performance.now() - getTimeStart("landing_page"),
                    reponse: err
                })
                // this.props.setExpireTimeOut(new Date().getTime())
                this.setState({
                    error: err.toString(),
                    pending: false,
                });
            });
    };

    submit = (e) => {
        e.preventDefault()
        this.setState({ pending: true });
        this.handleSuccessAplication()
    }
    goBack = () => {
        let url = this.props.callbackURL + '?eKycProcessStatus=201&transactionID=' + this.props.id
        window.location = url
        // setTimeout(() => {
        //     window.location.reload();
        // }, 200)
    }
    callBackSuccess = () => {
        let url = this.props.callbackURL + '?eKycProcessStatus=200&transactionID=' + this.props.id
        window.location = url
        // window.close();
        // console.log("here!")
    }
    pageScroll() {
        window.scrollBy(0, document.body.scrollHeight);
        // setTimeout(this.pageScroll(),10);
    }
    isignSubmit = () => {
        let url = this.props.callbackURL + '?eKCYProcessStatus=200&transactionID=' + this.props.id
        window.location = url
    }
    render() {
        let marginScreen = this.state.thisIsign ? '40%' : '20%';
        return (
            <PageContent toggleLoading={this.state.pending} progressBar={false} isSpinner={true}>
                <div className="container-fluid" style={{ marginBottom: marginScreen }}>
                    <div className="card-body text-center">
                        <h1><span>{this.props.t("PageIntroComfirmToStart.header")}</span></h1>
                        <div className="row" style={{ justifyContent: "center" }}>
                            <div className="btn-group col-10 mb-1" role="group" aria-label="Language Group">
                                <button
                                    type="button"
                                    className="btn button-hov"
                                    onClick={() => this.selectChangeLanguage('th')}
                                    style={{ color: this.props.language.setupLanguage === "th" ? "#fff" : "black", fontSize: "1em", backgroundColor: this.props.language.setupLanguage === "th" ? "#D31145" : "" }}>
                                    <a className="mt-1">{this.props.t("PageIntroComfirmToStart.th_button")}</a>
                                </button>
                                <button
                                    type="button"
                                    className="btn button-hov"
                                    onClick={() => this.selectChangeLanguage('en')}
                                    style={{ color: this.props.language.setupLanguage === "en" ? "#fff" : "black", fontSize: "1em", backgroundColor: this.props.language.setupLanguage === "en" ? "#D31145" : "" }}>
                                    <a className="mt-1">{this.props.t("PageIntroComfirmToStart.en_button")}</a>
                                </button>
                            </div>
                        </div>
                        <div className="card-body container mt-4 mb-4 w-full">
                            <div className="row-justify-center">
                                <a style={{ fontFamily: 'AIA Regular' }}>
                                    {this.props.t("PageIntroComfirmToStart.intro1_scope_1")}
                                    {this.props.t("PageIntroComfirmToStart.intro1_scope_2")}
                                    {this.props.t("PageIntroComfirmToStart.intro1_scope_3")}
                                    {this.props.t("PageIntroComfirmToStart.intro1_scope_4")}
                                </a>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body" style={{ backgroundColor: 'white', width: "100%" }}>
                                <div className="font-text w-full scroll-bar-intro">
                                    <h4><b>{this.props.t("PageIntroComfirmToStart.intro2_scope_1")}</b></h4>
                                    <a style={{ fontFamily: 'AIA Regular' }}>{this.props.t("PageIntroComfirmToStart.intro2_scope_2")}</a><p />
                                    <h6 style={{ fontFamily: 'AIA Regular', textAlign: "left" }}><b>{this.props.t("PageIntroComfirmToStart.intro2_scope_3")}</b></h6>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>{this.props.t("PageIntroComfirmToStart.intro2_scope_4")}</p><br />
                                    <h6 style={{ fontFamily: 'AIA Regular', textAlign: "left" }}><b>{this.props.t("PageIntroComfirmToStart.intro2_scope_5")}</b></h6>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>{this.props.t("PageIntroComfirmToStart.intro2_scope_6")}</p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>{this.props.t("PageIntroComfirmToStart.intro2_scope_7")}</p>

                                    <hr /> <p />
                                    <h4 style={{ fontFamily: 'AIA Regular' }}><b>{this.props.t("PageGuidance.header")}</b></h4>
                                    <h6 style={{ fontFamily: 'AIA Regular', textAlign: "left", marginTop: '5%' }}><b>{this.props.t("PageGuidance.title1")}</b></h6>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>{this.props.t("PageGuidance.title1_text1")}{this.props.t("PageGuidance.title1_text2")}</p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>{this.props.t("PageGuidance.title1_text3")}{this.props.t("PageGuidance.title1_text4")}</p>
                                    <h6 style={{ fontFamily: 'AIA Regular', textAlign: "left" }}><b>{this.props.t("PageGuidance.title2")}</b></h6>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>{this.props.t("PageGuidance.title2_text1")}{this.props.t("PageGuidance.title2_text2")}</p>

                                    <hr /> <p />
                                    <h4 style={{ fontFamily: 'AIA Regular' }}><b>{this.props.t('NewPageFaceCaptureInstructions.header')}</b></h4>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left", marginTop: '5%' }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('NewPageFaceCaptureInstructions.title1')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('NewPageFaceCaptureInstructions.title2')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('NewPageFaceCaptureInstructions.title3')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('NewPageFaceCaptureInstructions.title4')}
                                    </p>

                                    <hr /> <p />
                                    <h4 style={{ fontFamily: 'AIA Regular' }}><b>{this.props.t("PageFaceCaptureInstructions.take_selfie")}</b></h4>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left", marginTop: '5%' }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('PageFaceCaptureInstructions.remove_hats')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('PageFaceCaptureInstructions.well_lit_area')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('PageFaceCaptureInstructions.plain_background')}
                                    </p>

                                    {/* <hr />
                                    <h4 style={{ fontFamily: 'AIA Regular' }}><b>{this.props.t("Common.extra_checks")}</b></h4>
                                    <p className="center">
                                        {this.props.t("OldPageInstructionsFaceLiveness3D.extra_checks_description")}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left", marginTop: '5%' }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('OldPageInstructionsFaceLiveness3D.face_oval')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('OldPageInstructionsFaceLiveness3D.wait_countdown')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('OldPageInstructionsFaceLiveness3D.keep_face_oval')}
                                    </p>
                                    <p style={{ fontFamily: 'AIA Regular', textAlign: "left" }}>
                                        <i style={{ color: "#9CCC65" }} className="bi bi-check-circle"></i>
                                        &nbsp; {this.props.t('OldPageInstructionsFaceLiveness3D.wait_end')}
                                    </p> */}
                                </div>
                            </div>
                        </div>
                        {this.state.showBarcode &&
                            <div className="row-justify-center">
                                <QRCode
                                    className="mb-1 mt-3"
                                    value={this.state.genQRcode}
                                    level="M"
                                    fgColor="#000000"
                                    size={128}
                                />
                                <p className="container" style={{ color: 'black', marginBottom: "0px" }}>{this.props.t("PageIntroComfirmToStart.title_QRcode")}</p>
                                <a className="container" style={{ fontSize: '0.9em', color: 'black' }}>{this.props.t("PageIntroComfirmToStart.text_ask_callback_success")}</a>
                                <button
                                    type="button"
                                    className="btn btn-lg button-callback-hover"
                                    onClick={() => this.callBackSuccess()}
                                    style={{ fontSize: '1em', marginTop: '0px' }}
                                >
                                    {this.props.t("PageIntroComfirmToStart.btn_ask_callback_success")}
                                </button>
                            </div>}
                    </div>
                    <input type="hidden" id="user" name="user" value={this.state.userIdEncrypt} />
                </div>
                <footer className="footer d-flex align-items-center flex-column bd-highlight" style={{ justifyContent: 'center' }}>
                    {this.props.pathname === "iframe" && this.state.hasPermittedCamera === true && this.props.secondDevice !== "yes" ?
                        (<button
                            type="button"
                            className="btn btn-link bi-chevron-compact-left mb-2"
                            onClick={() => this.goBack()}
                            style={{ color: "#333D47", fontSize: '1em', marginTop: '0px' }}
                        >{this.props.t("PageIntroComfirmToStart.button_back")}</button>
                        ) : null}
                    {this.state.hasPermittedCamera === true &&
                        <button
                            type="button"
                            className="btn btn-lg mb-2"
                            onClick={(e) => this.submit(e)}
                            style={{ backgroundColor: "#D31145", color: "white", fontSize: '1em', marginTop: '0px' }}
                        >{this.props.t("PageIntroComfirmToStart.button_start")}</button>}
                </footer>

                <Modal
                    show={this.state.onModal}
                    onHide={() => this.setState({ onModal: false })}
                    // size="lg"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="center">
                            <p style={{ color: 'black', fontSize: '0.8em' }}>
                                {this.props.t('PageIntroComfirmToStart.dialog_instruction')}
                            </p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ onModal: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModalErrorCamera}
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="center mt-2">
                            <p style={{ color: 'black', fontSize: '0.8em' }}>
                                {this.props.t('PageIntroComfirmToStart.dialog_allow_camera_permise')}
                            </p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button"
                                className="btn btn-lg"
                                style={{ backgroundColor: "#D31145", fontSize: '1em' }}
                                onClick={() => window.location.reload()}
                            >{this.props.t("PageIntroComfirmToStart.btn_refresh_camera_permise")}</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </PageContent>
        )
    }
}
const componentWithTranslation = withTranslation()(PageGuidance);
export default connect(
    (state) => {
        return {
            ...state.application,
            ...state.setApplicationParams,
            language: state.changeMultiLanguage
        }
    },
    {
        go,
        nextPage,
        challengeCreate
    }
)(componentWithTranslation);