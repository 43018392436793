import ReactDOM from "react-dom";
import { Provider as StateProvider } from "react-redux";

import "promise-polyfill/src/polyfill";
import "logic/canvasToBlobPolyfill";
import "assets/styles/main.css";
import "assets/styles/text.css";
import "assets/styles/panel.css";
import "assets/styles/button.css";
import "assets/styles/desktop.css";
import "assets/styles/new-design.css";

import App from "Components/App";
// import WebCLRLoader from "Components/App/WebCLRLoader";
import IconDefinitions from "Components/App/IconDefinitions";
import * as serviceWorker from "serviceWorker";
import { store } from "logic/store";
// import { defaultState } from "logic/reducers/configuration";
// import { setBaseTag } from "logic/tenant";
import "i18n";
import { setup } from "./logic/integartions/consoleLog";

// if (process.env.REACT_APP_MOCK_API === "true") {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }
if (process.env.REACT_APP_DEBUG_PAGES === "true") {
  setup();
}

if(process.env.REACT_APP_DYNATRACE_SCRIPT === "PRD"){
  let script = document.createElement('script');
  script.src = "https://js-cdn.dynatrace.com/jstag/16c6b3f0987/bf24184cpa/15066147a531477d_complete.js";
  script.crossOrigin = "anonymous";
  document.body.appendChild(script);
}else if(process.env.REACT_APP_DYNATRACE_SCRIPT === "UAT"){
  let script = document.createElement('script');
  script.src = "https://js-cdn.dynatrace.com/jstag/16c6b3f0987/bf24184cpa/b48e5dc5c75356ba_complete.js";
  script.crossOrigin = "anonymous";
  document.body.appendChild(script);
}
// setBaseTag(defaultState.tenant);

ReactDOM.render(
  <StateProvider store={store}>
      <IconDefinitions />
      {/* <WebCLRLoader /> */}
      <App />
  </StateProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
