export const applicationPostMock = {
    "id": "bba7f997-9cdb-4265-9fde-2b828a8e05df",
    "user": {
      "id": "QTAzGe_QkO58yLhu4wd0ypVDxQ",
      "userId": "bba7f997-9cdb-4265-9fde-2b828a8e05df",
      "primaryPhone": "+381665126482",
      "href": "https://engdobs-idx45-qc.identityx-cloud.com/onboardingdemo/IdentityXServices/rest/v1/users/QTAzGe_QkO58yLhu4wd0ypVDxQ",
      "relHref": "/onboardingdemo/IdentityXServices/rest/v1/users/QTAzGe_QkO58yLhu4wd0ypVDxQ"
    }
  }

  export const partnerResponse = {
    status: true, 
    user: "60_1212312121"
  }

export const livenessSetupResponse = {
  "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/idchecks/NHREG16yPi44y4RUHuyZaA/challenges",
  "items": [{
    "additionalConfig": {
      "challengeAlias": "f6d40d79-3bfa-4909-aa91-5b4183502191"
    },
      "created": "2021-07-27T09:45:50.144+00:00",
      "detectionThreshold": 0.75,
      "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/idchecks/NHREG16yPi44y4RUHuyZaA/challenges/AGYK69J8b-uM31Ds4K4jrA",
      "id": "AGYK69J8b-uM31Ds4K4jrA",
      "index": 0,
      "maxRetries": 2,
      "policy": {
        "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/admin/policies/bVTfdXpFW9PVBmdY7GImyw"
      },
      "type": "SVR3DFL"
  }],
  "metadata": {
    "limit": 0,
    "page": 1,
    "pageCount": 1,
    "totalCount": 1,
    "paging": {}
  }
}

export const documentResponse = {
  "allowedSensitiveDataPermissions": "Get,Create,Update,Delete,Block,Unblock",
  "captureFormat": "ID1",
  "captureType": "BBC",
  "captured": "2021-07-27T09:59:02.518+00:00",
  "classification": "Identity Card",
  "clientCapture":{
    "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/idchecks/NHREG16yPi44y4RUHuyZaA/documents/u9ObV1GP2snGxHXb1yLrtQ/clientCapture"
  },
  "completed": "2021-07-27T09:59:09.140+00:00",
  "created": "2021-07-27T09:59:05.095+00:00",
  "elapsedTime": 3876,
  "expiry": "2102-04-20T00:00:00.000+00:00",
  "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/idchecks/NHREG16yPi44y4RUHuyZaA/documents/u9ObV1GP2snGxHXb1yLrtQ",
  "id": "u9ObV1GP2snGxHXb1yLrtQ",
  "issued": "2013-07-26T00:00:00.000+00:00",
  "issuer": "Thailand",
  "processingStatus": "PROCESSED",
  "processingType": "ID",
  "processorName": "REGULA(apac-regula.identityx-cloud.com:8443)",
  "resubmissionCount": 0,
  "retryCount": 0,
  "serverProcessed":{
    "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/idchecks/NHREG16yPi44y4RUHuyZaA/documents/u9ObV1GP2snGxHXb1yLrtQ/serverProcessed"
   },
  "submitted": "2021-07-27T09:59:05.264+00:00",
  "timeToProcess": 3546,
  "type": "Thailand - Id Card #2"
}

export const faceCaptureResponse = {
  "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/IdentityXServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/face/samples?deviceMode=DESKTOP",
  "items": [{
    "created": "2021-07-27T10:58:37.891+00:00",
    "enrollments":{
      "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/IdentityXServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/samples/oqpXvYC52nFhP7G7JRFI3g/enrollments",
      "items": [{
        "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/IdentityXServices/rest/v1/enrollments/GuAk5OuqDWkBXSHHaP34zA"
      }],
      "metadata":{
        "limit": 1,
        "page": 1,
        "totalCount": 1
      },
      "paging":{
        "first": null,
        "last": null,
        "next": null,
        "previous": null
      }
    },
    "evaluationResult": "OK (0)",
    "evaluations": [{
      "evaluationDate": "2021-07-27T10:58:37.877+00:00",
      "qualityAssessmentResult":{
        "metaData":{
          "Exposure": "58",
          // "ExposurePass": "true",
          "EyesFoundConfidence": "0.908265",
          // "EyesFoundPass": "true",
          "EyesOpenConfidence": "0.888889",
          // "EyesOpenPass": "true",
          "FaceBoxLowerRightCornerX": "488",
          "FaceBoxLowerRightCornerY": "523",
          "FaceBoxUpperLeftCornerX": "188",
          "FaceBoxUpperLeftCornerY": "222",
          "FaceCntrPositionX": "338",
          "FaceCntrPositionY": "373",
          "FaceFoundConfidence": "1.0",
          // "FaceFoundPass": "true",
          "FrontalPoseConfidence": "0.9",
          // "FrontalPosePass": "true",
          // "GlobalFaceQualityPass": "true",
          "GlobalFaceQualityScore": "0.896482",
          "GrayscaleDensity": "204",
          // "GrayscaleDensityPass": "true",
          "InterEyesDistance": "126",
          // "InterEyesDistancePass": "true",
          "LeftEyePositionX": "281",
          "LeftEyePositionY": "320",
          "OnlyOneFaceConfidence": "1.0",
          // "OnlyOneFacePass": "true",
          "PoseAngleRoll": "3",
          // "PoseAngleRollPass": "true",
          "ProcessingTimePerFrame": "50.0",
          // "ProcessingTimePerFramePass": "true",
          "RightEyePositionX": "407",
          "RightEyePositionY": "326",
          "Sharpness": "66",
          // "SharpnessPass": "true",
          'UniformLightingConfidence': "0.75",
          // 'UniformLightingPass': "true",
          "policyName": "DaonFace v3.0 Template Generation",
        },
        "passed": true,
        "vendor": "daonfacev3"
      },
      "resultCode": 0,
      "resultMessage": "OK"
    }],
    "format": "jpg",
    "fraudulent": false,
    "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/IdentityXServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw/samples/oqpXvYC52nFhP7G7JRFI3g",
    "id": "oqpXvYC52nFhP7G7JRFI3g",
    "lastEvaluated": "2021-07-27T10:58:37.877+00:00",
    "tenant":{
      "href": "https://apac-sampleapp-1019.identityx-cloud.com/IdentityXServices/rest/v1/tenants/FuN7p4oKEEWM4r-NDV7YHw"
    },
    "type": "Face",
    "usable": true,
    "user":{
      "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/IdentityXServices/rest/v1/users/QTAzuDXOKq_oqaVji2UYpvT1Kw"
    }
  }],
  "metadata":{
    "limit": 1,
    "page": 1,
    "totalCount": 1
  },
  "paging":{
    "first": null,
    "last": null,
    "next": null,
    "previous": null
  }
}

export const livenessResponse = {
  "captured": "2021-07-27T15:57:23.939+00:00",
  "challenges": [{
    "challenge":{
      "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzUMRkW5_ZiiishJVKSuJznQ/idchecks/Zg3ElA1GjlhRqoXpiciJdQ/challenges/YYgi8VYyF-Nk7d-54n1exA",
      "type": "SVR3DFL"
    },
    "completed": 99999,
    "start": 0
  }],
  "clientMetrics":{
    "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzUMRkW5_ZiiishJVKSuJznQ/idchecks/Zg3ElA1GjlhRqoXpiciJdQ/videos/q8uiYLlFRdfuN5Vb0EwuzQ/clientMetrics"
  },
  "completed": "2021-07-27T15:57:26.861+00:00",
  "created": "2021-07-27T15:57:25.567+00:00",
  "digest": "95pk46FERtbE+nIf7Rzzkz0V8nYhNG3cqzKnwwkIytY=",
  "digestAlgorithm": "SHA256",
  "elapsedTime": 1164,
  "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzUMRkW5_ZiiishJVKSuJznQ/idchecks/Zg3ElA1GjlhRqoXpiciJdQ/videos/q8uiYLlFRdfuN5Vb0EwuzQ",
  "id": "q8uiYLlFRdfuN5Vb0EwuzQ",
  "processingStatus": "PROCESSED",
  "retryCount": 0,
  "sensitiveData":{
    "href": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzUMRkW5_ZiiishJVKSuJznQ/idchecks/Zg3ElA1GjlhRqoXpiciJdQ/videos/q8uiYLlFRdfuN5Vb0EwuzQ/sensitiveData",
    "mediaHref": "https://apac-sampleapp-1019.identityx-cloud.com/asphere/DigitalOnBoardingServices/rest/v1/users/QTAzUMRkW5_ZiiishJVKSuJznQ/idchecks/Zg3ElA1GjlhRqoXpiciJdQ/videos/q8uiYLlFRdfuN5Vb0EwuzQ/sensitiveData/value"
  },
  "sensitiveDataPresent": true,
  "submitted": "2021-07-27T15:57:25.697+00:00",
  "subtype": "SVR3DFL_CHALLENGE",
  "timeToProcess": 1100,
  "videoFormat": "SVR3DFL"
}