import React from "react";
import { string, func } from "prop-types";
import LinkNativeCapture from "../LinkNativeCapture";
import LinkSelectCamera from "./LinkSelectCamera";
import "./style.css";
import { useTranslation } from "react-i18next";

NativeCaptureBox.propTypes = {
  facingMode: string,
  onUploaded: func,
  handleHideNative: func,
};

export default function NativeCaptureBox(props) {
  const { t } = useTranslation();
  return (
    <div className="native-capture-box box-shadow">
      {t("Common.having_problems")}
      <span className="x" onClick={props.handleHideNative}>
        X
      </span>
      <div>
        <div style={{ display: "inline" }}>
          <LinkSelectCamera {...props} />
        </div>

        <LinkNativeCapture {...props} />
      </div>
    </div>
  );
}
