import styled from "styled-components";

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;

  @media only screen and (min-width: 600px) {
    width: 700px;
  }
`;
