import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { func } from "prop-types";
import "./style.css"
import PageContent, { PAGE_CONTENT_TYPE_INSTRUCTIONS } from "../../../PageContent";
import { go, nextPage } from "logic/actions/navigation";
import { useTranslation } from "react-i18next";
import { instantiateFaceLiveness3D, clear3dflState } from "logic/actions/faceLiveness3D";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import imgsuccess from "../../../../assets/success-icon.png"

OldPageInstructionsFaceLiveness3D.propTypes = {
  go: func,
};

export function OldPageInstructionsFaceLiveness3D(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (props.faceLiveness3D && props.faceLiveness3D.instance && props.faceLiveness3D.instance.videoEl) {
      try {
        if (props.faceLiveness3D.instance.videoEl) {
          const stream = props.faceLiveness3D.instance.videoEl.srcObject;
          stream.getVideoTracks().forEach(track => {
            track.stop();
          });
          instantiateFaceLiveness3D()(dispatch)
          props.clear3dflState()
        }
      } catch (err) { }
    }
    if (!getTimePassed("liveness_test")) startEventTimer("liveness_test");
  }, [props.faceLiveness3D, dispatch, props]);

  return (
    <PageContent progressBar={true} type={PAGE_CONTENT_TYPE_INSTRUCTIONS}>
      <div className="container-fluid" style={{ marginBottom: '20%' }}>
        <div className="card-body">
          <h1>{t("Common.extra_checks")}</h1>
          <p className="center">
            {t("OldPageInstructionsFaceLiveness3D.extra_checks_description")}
          </p>
          <div className="row no-gutters">
            <div className="col-1">
              <img
                className="ml-3 mb-3"
                style={{ width: '50%', height: 'inherit' }}
                src={imgsuccess}
                alt=""
              />
            </div>
            <div className="col mr-3 ml-3">
              <p className="font-text w-full">{t("OldPageInstructionsFaceLiveness3D.face_oval")}</p>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-1">
              <img
                className="ml-3 mb-3"
                style={{ width: '50%', height: 'inherit' }}
                src={imgsuccess}
                alt=""
              />
            </div>
            <div className="col mr-3 ml-3">
              <p className="font-text w-full">{t("OldPageInstructionsFaceLiveness3D.wait_countdown")}</p>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-1">
              <img
                className="ml-3 mb-3"
                style={{ width: '50%', height: 'inherit' }}
                src={imgsuccess}
                alt=""
              />
            </div>
            <div className="col mr-3 ml-3">
              <p className="font-text w-full">{t("OldPageInstructionsFaceLiveness3D.keep_face_oval")}</p>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-1">
              <img
                className="ml-3 mb-3"
                style={{ width: '50%', height: 'inherit' }}
                src={imgsuccess}
                alt=""
              />
            </div>
            <div className="col mr-3 ml-3">
              <p className="font-text w-full">{t("OldPageInstructionsFaceLiveness3D.wait_end")}</p>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer">
        <button
          className="btn"
          type="button"
          style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
          onClick={() => props.nextPage()}
        >
          {t("Common.continue")}
        </button>
      </footer>
    </PageContent>
  );
}

export default connect(
  (state) => {
    return {
      faceLiveness3D: state.faceLiveness3D
    }
  }
  , {
    go,
    nextPage,
    clear3dflState
  })(OldPageInstructionsFaceLiveness3D);
