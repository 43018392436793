let startTimes = {};

export function startEventTimer(stepName) {
  startTimes[stepName] = window.performance.now();
}

export function getTimePassed(stepName) {
  if (startTimes[stepName]) {
    return window.performance.now() - startTimes[stepName];
  }
  return undefined;
}

export function getTimeStart(stepName) {
  if(startTimes[stepName]){
    return startTimes[stepName];
  }
  return undefined
}

// export function getTimeStart(stepName) {
//   if(startTimes[stepName]){
//     return startTimes[stepName];
//   }
//   return undefined
// }