import React, { Component } from "react";
import { connect } from "react-redux";
import { func, instanceOf } from "prop-types";
import { blobToBase64 } from "logic/blobToBase64";
import PageContent from "Components/PageContent";
import { retry } from "logic/actions/faceCapture";
import { nextStep, go } from "logic/actions/navigation";
import { faceSave } from "logic/actions/api/face";
import { failedAttempt } from "logic/actions/livenessTest";
import * as S from "./PageFaceCaptureConfirm.styles";
import {
  logEvent,
  face_capture_retry,
  step_completed,
} from "logic/eventLogger";
import { withTranslation } from "react-i18next";
import { IDRND_FACE } from "logic/enums/livenessTestType";
import { THANK_YOU } from "logic/enums/pages";
import { getTimePassed, getTimeStart, startEventTimer } from "logic/stepTimers";
import { Image } from "load-image-react";
import { changeCamera } from "logic/actions/documentCaptureConfiguration";
import './style.css'

export class PageFaceCaptureConfirm extends Component {
  static propTypes = {
    retry: func,
    faceSave: func,
    go: func,
    blob: instanceOf(Blob),
  };

  constructor(props) {
    super(props);
    this.state = { retry: true, blobUrl: '' };
    this.getBack.bind(this);
    this.submit.bind(this);
    if (!getTimePassed("face_confirm")) getTimeStart("face_confirm")
  }

  getBack = () => {
    logEvent(face_capture_retry, { retryCount: this.props.retries + 1 });
    this.props.retry();
  };

  UNSAFE_componentWillMount() {
    try {
      this.setState({ blobUrl: URL.createObjectURL(this.props.blob) })
    } catch {
      this.setState({ blobUrl: "data:image/jpeg;base64," + this.props.blob })
    }
  }
  componentDidMount() {
    const wrapperDiv = document.getElementById("wrapperDiv");
    this.setState({
      width: wrapperDiv.offsetWidth,
      height: wrapperDiv.offsetHeight,
    });
  }

  submit = () => {
    startEventTimer("face_confirm")
    logEvent("face_confirm", {
      start_time: window.performance.now(),
      response: null
    })
    this.setState({ pending: true, error: null });
    blobToBase64(this.props.blob)
      .then((data) => {
        this.props
          .faceSave(data)
          .then((response) => {
            // console.log(response)
            logEvent("face_confirm_save_success", {
              start_time: getTimePassed("face_confirm"),
              response: response
            })
            let errorMessage = "";
            if (response.data && response.data.items) {
              response.data.items.forEach((item) => {
                if (item.evaluations) {
                  item.evaluations.forEach((evaluation) => {
                    if (
                      evaluation.qualityAssessmentResult &&
                      !evaluation.qualityAssessmentResult.passed
                    ) {
                      errorMessage +=
                        evaluation.qualityAssessmentResult.error.message;
                    }
                  });
                }
              });
            }
            if (errorMessage) {
              this.setError(errorMessage);
            } else {
              const stepName = "face_capture";
              const timePassed = getTimePassed(stepName);
              logEvent(step_completed, {
                stepNameCompleted: stepName,
                timePassed,
                totalRetryCount: this.props.retries || 0,
              });
              this.props.nextStep();
            }
          })
          .catch((error) => {
            logEvent("face_confirm_save_fail", {
              start_time: getTimePassed("face_confirm"),
              response: error
            })
            let errorMessage = "";
            if (
              error.response &&
              error.response.data &&
              error.response.data.feedback
            ) {
              error.response.data.feedback.forEach(
                (singleError) => (errorMessage += singleError.message)
              );
            } else {
              errorMessage = this.props.t("Common.error_connection_issues");
            }
            this.setError(errorMessage);
          });
      })
      .catch(() => {
        this.setState({ error: "Failed to convert image", pending: false });
      });
  };

  setError(errorMessage) {
    if (
      this.props.livenessTestData &&
      this.props.livenessTestData.items[0].type === IDRND_FACE
    ) {
      if (this.state.retry) {
        this.setState({ retry: false });
        this.props.failedAttempt();
      }
      if (this.props.retriesLeft <= 0) {
        this.props.go(THANK_YOU);
      }
      errorMessage +=
        "<br><br>" +
        this.props.t("PageFaceCaptureConfirm.error_liveness", {
          retriesLeft: this.props.retriesLeft,
        });
    }
    this.setState({
      error: errorMessage,
      pending: false,
    });
  }

  render() {
    const isActive = this.state.pending && !this.state.error;
    const classNameBtn = this.state.pending ? "#FF7A85" : "#D31145";
    const text = this.props.isRejected
      ? this.props.t("PageFaceCaptureConfirm.retake_selfie")
      : this.props.t("PageFaceCaptureConfirm.happy_with_image");
    const capture = this.state.pending ? "page-standard" : "";
    // const containerClass = `container ${capture}`
    return (
      <PageContent toggleLoading={isActive} progressBar={true} isSpinner={true}>
        <h1>{this.props.t("PageFaceCaptureConfirm.title")}</h1>
        <p className="center">{text}</p>

        <S.WrapperDiv
          id="wrapperDiv"
          className={"panel border-gray bg-row"}
          style={{ marginBottom: '10%' }}
          isNative={this.props.captureMethod === "NATIVE"}
        >
          {/* <BlobPreview
            width={this.state.width}
            height={this.state.height}
            flipX={true}
            blob={this.props.blob}
          /> */}
          <div className="wrapper">
            <Image
              src={this.state.blobUrl}
              loadOptions={{
                orientation: true,
                maxWidth: this.state.width,
                maxHeight: this.state.height,
              }}
            />
          </div>
        </S.WrapperDiv>

        {/* <p
          className="error"
          dangerouslySetInnerHTML={{
            __html: this.state.error,
          }}
        ></p> */}

        <footer className="footer d-flex align-items-center flex-column bd-highlight">
          {this.props.isRejected ? (
            <div className="message-box-error">
              {this.props.message}
              <button
                type="button"
                disabled={this.state.pending}
                className="btn btn-link bi-chevron-compact-left"
                style={{ color: "#333D47", fontSize: '1em', marginTop: '0px' }}
                onClick={this.getBack}
              >
                {this.props.t("PageFaceCaptureConfirm.retake_photo")}
              </button>
            </div>
          ) : (
            <>
              <button
                type="button"
                disabled={this.state.pending}
                className="btn btn-link bi-chevron-compact-left"
                style={{ color: "#333D47", fontSize: '1em', marginTop: '0px' }}
                onClick={this.getBack}
              >
                {this.props.t("PageFaceCaptureConfirm.retake_photo")}
                {/* {this.props.t("PageFaceCaptureConfirm.retake2_photo", {count: 2 - this.props.retries})} */}
              </button>
              <button
                type="button"
                disabled={this.state.pending}
                className="btn mt-2"
                style={{ backgroundColor: classNameBtn, fontSize: '1em', marginTop: '0px' }}
                onClick={this.submit}
              >
                {this.props.t("PageFaceCaptureConfirm.btn_confirm_photo")}
              </button>
            </>
          )}
        </footer>
        <div className={capture}>
        </div>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageFaceCaptureConfirm);
export default connect(
  (state) => {
    return {
      ...state.faceCapture,
      ...state.livenessTest,
      documentCaptureConfiguration: state.documentCaptureConfiguration
    };
  },
  {
    faceSave,
    retry,
    nextStep,
    go,
    failedAttempt,
    changeCamera,
  }
)(componentWithTranslation);
