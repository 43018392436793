/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import * as Api from "logic/api";
import PanelError from "../../PanelError";
import ButtonRestartApp from "../../common/ButtonRestartApp";
import PageContent from "../../PageContent";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setConfiguration } from "logic/actions/configuration";
import { application_start, logEvent } from "logic/eventLogger";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import { checkLanguage } from "logic/reducers/changeLanguage"
import { isSafari } from "logic/deviceType";
import { alertUser } from "logic/eventBeforeunload";

function ApplicationConfig({ children }) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const language = useSelector(checkLanguage);

  useEffect(() => {
    if (window.location.href.includes("newSession=true")) {
      window.history.pushState({}, document.title, "");
      if (isSafari(navigator.userAgent)) window.location = ""; //hack for safari :)
    }
    // alert(navigator.userAgent)
    logEvent(application_start);
    if (!getTimePassed("application")) startEventTimer("application");
  }, []);

  useEffect(() => {
    Api.getConfiguration()
      .then((response) => {
        if (
          process.env.NODE_ENV === "development" &&
          response.data.documentQualityHostname.includes("sampleapp")
        ) {
          const externalDocumentQualityURL = new URL(
            response.data.documentQualityHostname
          );
          const externalFaceQualityURL = new URL(
            response.data.faceQualityHostname
          );

          externalDocumentQualityURL.hostname = `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;
          externalFaceQualityURL.hostname = `${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}`;

          response.data.faceQualityHostname = externalFaceQualityURL.href;
          response.data.documentQualityHostname =
            externalDocumentQualityURL.href;
        }
        dispatch(setConfiguration(response.data));
        setLoaded(true);
      })
      .catch(() => {
        setError(t("ApplicationConfig.error_connection"));
      });
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language)
    }
  }, [language, i18n]);

  if (loaded) return children;

  if (error)
    return (
      <PageContent>
        <PanelError text={error} />
        <ButtonRestartApp />
      </PageContent>
    );

  return (
    <PageContent>
      <header />
      <h1 data-testid="loading-configuration">{t("PageConfig.loading")}</h1>
    </PageContent>
  );
}

export default ApplicationConfig;
