import { store } from "../../store";
// import { refreshSession } from "logic/refreshSession";
import { axiosInstance } from "logic/api";

export const applicationSubmitForDopa = () => (dispatch) => {
    // eslint-disable-next-line
    const { configuration } = store.getState();

    // return axiosInstance.post(`/rest/v1/accountapplications/submit?evaluationPolicyName=${configuration.dopaCheckPolicyName}`, application);
    return axiosInstance.post(`/rest/v1/accountapplications/submitDopa?evaluationPolicyName=${configuration.dopaCheckPolicyName}`);
};

export const applicationSubmitTwoElementForDopa = () => (dispatch) => {
  return axiosInstance.post(`/rest/v1/accountapplications/submitDopa?evaluationPolicyName=two-element-check`);
};

export const applicationCheckDopaResult = () => (dispatch) => {
    const { application } = store.getState();

    return axiosInstance.get(
        `/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/evaluation/results`
    );
}
