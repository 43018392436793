import {
  INSTANTIATED,
  UPDATE_MESSAGE,
  UPDATE_READY,
  UPDATE_ERROR,
  UPDATE_FACE_BOX,
  CLEAR_MESSAGE,
  CLEAR_3DFL_STATE,
  CLEAR_READY,
} from "../actions/faceLiveness3D";

const defaultState = {
  instance: undefined,
  error: undefined,
  message: undefined,
  isReady: false,
  faceBox: undefined,
};

export function faceLiveness3D(state = defaultState, action) {
  switch (action.type) {
    case INSTANTIATED:
      return {
        ...state,
        instance: action.payload,
      };
    case CLEAR_3DFL_STATE:
      return {
        ...state,
        error: defaultState.error,
        message: defaultState.message,
        faceBox: defaultState.faceBox,
      };
    case UPDATE_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    case CLEAR_MESSAGE:
      return {
        ...state,
        message: "",
      };
    case UPDATE_READY:
      if (state.isReady) {
        return {
          ...state,
          isReady: false
        }
      }
      return {
        ...state,
        isReady: true,
      };
    case UPDATE_ERROR:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_FACE_BOX:
      return {
        ...state,
        faceBox: action.payload,
      };
    case CLEAR_READY:
      return {
        ...state,
        isReady: false
      };
    default:
      return state;
  }
}
