import React from "react";
import RoundIcon from "../RoundIcon";
import Icon from "../Icon";

export default function PanelError({ text }) {
  return (
    <div className="panel bg-red flex-row"  style={{margin: '20px'}}>
      <RoundIcon color="red" className="mt-1">
        <Icon name="Rejected" color="white" />
      </RoundIcon>
      <div className="panel-content ml-3 mt-2">
        <p data-testid="panel-error" style={{fontSize: '1em', color: 'black'}}><b>{text}</b></p>
      </div>
    </div>
  );
}
