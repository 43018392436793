import React, { Component } from "react";
import { connect } from "react-redux";
import { number, string, func } from "prop-types";

import FaceCaptureUI from "../../common/FaceCaptureUI";
import * as S from "./PageFaceCapture.styles";
import "./style.css"
import { Modal } from "react-bootstrap";
import NativeCaptureBox from "../../../NativeCaptureBox";
import TimeoutVisibility from "Components/modules/common/TimeoutVisibility";
import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "Components/PageContent";
import { preview } from "logic/actions/faceCapture";
import frameOverlayGreen from "assets/frame_overlay_far_green.png";
import {
  logEvent,
  face_capture_started,
  face_captured,
  face_capture_native,
  retry_camera_init,
  face_capture_error,
} from "logic/eventLogger";
import { faceSave } from "logic/actions/api/face";
import { withTranslation } from "react-i18next";
import CaptureButtonPanel from "Components/CaptureButtonPanel";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import { setSelectedCamera } from "logic/reducers/camera";
import {
  changeCamera,
  flipCamera
} from "logic/actions/documentCaptureConfiguration";
import { startEventTimer, getTimePassed, getTimeStart } from "logic/stepTimers";
import { store } from "logic/store";
import { setDefaultCameras } from "logic/cameraChecker";
import { isIOS, isMobile, isAndroid, isMac } from "logic/deviceType";
import imgwarning from "../../../../assets/warning-icon.png";
import { FACE_CAPTURE_INSTRUCTIONS } from "logic/enums/pages";
import { go, nextStep } from "logic/actions/navigation";
import { blobToBase64 } from "logic/blobToBase64";

const START = 0;
const CAPTURING = 1;
const PASS = 2;

const CAPTURE_METHOD = {
  WEBRTC: "WEBRTC",
  NATIVE: "NATIVE",
};

export class PageFaceCapture extends Component {
  static propTypes = {
    faceSave: func,
    retries: number,
    faceQualityHostname: string,
  };
  constructor(props) {
    super(props);
    this.canvasRef = new React.createRef();

    this.state = {
      message: "",
      formState: START,
      captureMethod: CAPTURE_METHOD.WEBRTC,
      cameraPermission: true,
      retryCameraInit: false,
      shouldStartNativeCamera: false,
      wasmIsLoading: true,
      hideNative: false,
      showSwitchCameraButton: store.getState().camera.cameras.length > 1,
      camera: store.getState().camera,
      showDebug: false,
      rotateLandscape: false,
      spinner: true
    };
    this.onCameraStarted.bind(this);
    if (!getTimePassed("face_capture")) startEventTimer("face_capture");
    document.addEventListener("visibilitychange", this.listenVisibilityChange);
  }

  UNSAFE_componentWillMount() {
    window.scrollBy(1, 2);
    setTimeout(() => {
      window.scrollBy(-1, -2);
    }, 100);
  }

  componentDidMount() {
    if (window.navigator.userAgent.indexOf("Edge") > -1) {
      this.canvas = this.refs.canvas;
      this.ctx = this.canvas.getContext("2d");

      this.imageGreen = new Image();
      this.imageGreen.src = frameOverlayGreen;
      this.imageGreen.onload = () => {
        this.drawOutline(this.imageGreen);
      };
    }
    window.addEventListener("orientationchange", this.checkScreenOrientation);
    // call event in case user is in landscape mode when first loads
    this.checkScreenOrientation();
    // this.retryCameraInit();
  }

  componentWillUnmount() {
    if (
      this.state.fc &&
      typeof this.state.fc.destroy === "function" &&
      this.state.cameraPermission
    ) {
      try {
        this.state.fc.destroy();
      } catch (err) { }
    }

    if (this.video) {
      this.video.onloadedmetadata = null;
      this.video = null;
    }
    window.removeEventListener("orientationchange", this.checkScreenOrientation);
    document.removeEventListener(
      "visibilitychange",
      this.listenVisibilityChange
    );
  }

  checkScreenOrientation = () => {
    let screenOrientation = undefined;
    try {
      if (isAndroid(navigator.userAgent) || isIOS(navigator.userAgent) || isMobile(navigator.userAgent) || isMac(navigator.userAgent)) {
        if (window.screen.orientation) {
          screenOrientation = window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait';
        } else {
          // iOS/safari
          screenOrientation = Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait';
        }
      }
    }
    catch (err) { }

    this.setState({
      stsOrientation: screenOrientation,
      rotateLandscape: screenOrientation === "landscape" ? true : false
    })
  }
  listenVisibilityChange = () => {
    this.props.go(FACE_CAPTURE_INSTRUCTIONS);
  };
  onVisibilityChange = (isVisible) => {
    if (isVisible) {
      if (this.state.unfocusedPage) this.state.fc.startCamera();
      this.setState({ unfocusedPage: false });
    } else {
      //this.state.fc.destroy();
      try {
        this.state.fc.stopCamera();
      } catch (err) { }
      this.setState({ unfocusedPage: true });
    }
  };

  drawOutline(img) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let scale = 1.2;

    let dx = (this.canvas.width - img.width * scale) / 2;
    let dy = (this.canvas.height - img.height * scale * 1.4) / 2;

    this.ctx.globalAlpha = 0.7;
    this.ctx.drawImage(
      img,
      dx,
      dy,
      img.width * scale,
      img.height * scale * 1.4
    );
  }

  onCameraStarted = (fc, video) => {
    this.setState({ showSwitchCameraButton: store.getState().camera.cameras.length > 1 });
    this.video = video;
    this.video.onloadedmetadata = () => {
      // console.log(video)
      if (fc.camera.videoTracks.length) {
        const trackSettings = fc.camera.videoTracks[0].getSettings();
        this.setState({
          streamWidth: trackSettings.width,
          streamHeight: trackSettings.height,
        });
        // const device = fc.camera.videoTracks[0];
      }
      this.setState({ faceFound: true, wasmIsLoading: false });
    };
  };
  faceCaptureSave(blob) {
    this.setState({
      pending: true
    })
    blobToBase64(blob)
      .then((data) => {
        this.props
          .faceSave(data)
          .then((response) => {
            // console.log(response)
            logEvent("face_confirm_save_success", {
              start_time: getTimePassed("face_confirm"),
              response: response
            })
            let errorMessage = "";
            if (response.data && response.data.items) {
              response.data.items.forEach((item) => {
                if (item.evaluations) {
                  item.evaluations.forEach((evaluation) => {
                    if (
                      evaluation.qualityAssessmentResult &&
                      !evaluation.qualityAssessmentResult.passed
                    ) {
                      errorMessage +=
                        evaluation.qualityAssessmentResult.error.message;
                    }
                  });
                }
              });
            }
            if (errorMessage) {
              this.setState({
                error: errorMessage,
                pending: false,
              });
            } else {
              this.props.nextStep();
            }
          })
          .catch((error) => {
            logEvent("face_confirm_save_fail", {
              start_time: getTimePassed("face_confirm"),
              response: error
            })
            let errorMessage = "";
            if (
              error.response &&
              error.response.data &&
              error.response.data.feedback
            ) {
              error.response.data.feedback.forEach(
                (singleError) => (errorMessage += singleError.message)
              );
            } else {
              errorMessage = this.props.t("Common.error_connection_issues");
            }
            this.setState({
              error: errorMessage,
              message: errorMessage,
              pending: false,
            });
          });
      })
      .catch(() => {
        this.setState({ error: "Failed to convert image", pending: false });
      });
  }

  captureFeedback(response, calledFrom) {
    if (
      calledFrom !== this.state.captureMethod ||
      this.state.formState === PASS
    )
      return;
    this.setState({
      pending: false,
      isError: false,
    });
    if (response.result === "PASS") {
      logEvent(face_captured, {
        end_time: getTimePassed("face_capture"),
        response: response
      });
      this.state.fc.stopAutoCapture();

      this.setState({
        message: "",
        formState: PASS,
      });
      this.props.toggleNetworkWarnings(false);
      this.props.preview({
        blob: response.sentBlobImage,
        isRejected: false,
        captureMethod: this.state.captureMethod,
      });
      // console.log(response)
      this.faceCaptureSave(response.sentBlobImage);
    } else {
      let message = response.feedback || response.message;
      logEvent(face_capture_error, {
        faceCaptureFeedback: response.feedback || response.message,
        faceCaptureFeedbackCode: response.code,
        streamWidth: this.state.streamWidth,
        streamHeight: this.state.streamHeight,
        end_time: getTimePassed("face_capture"),
        calledFrom,
      });
      if (calledFrom === CAPTURE_METHOD.NATIVE) {
        this.setState({
          pending: false,
          formState: START,
          message,
        });

        this.props.preview({
          blob: response.sentBlobImage,
          isRejected: true,
          message,
          captureMethod: this.state.captureMethod,
        });
        this.faceCaptureSave(response.sentBlobImage);
      } else {
        this.setState({ message: message });
      }
    }
  }

  captureError(err, calledFrom) {
    if (
      calledFrom !== this.state.captureMethod ||
      this.state.formState === PASS
    )
      return;
    logEvent(face_capture_error, {
      end_time: getTimePassed("face_capture"),
      response: err,
    });
    this.setState({
      pending: false,
      formState: START,
      message: this.props.t("Common.error_connection_issues"),
      isError: true,
    });
    //TODO@gva: When error occurs on native , camera is not active and we cannot start webrtc capture

    this.props.toggleNetworkWarnings(false);
  }

  startAutoCapture() {
    getTimeStart("face_capture")
    logEvent(face_capture_started, {
      start_time: window.performance.now(),
      response: null
    });
    this.setState({
      captureMethod: CAPTURE_METHOD.WEBRTC,
      formState: CAPTURING,
      message: this.props.t("PageFaceCapture.keep_face_still"),
      isError: false,
    });
    this.props.toggleNetworkWarnings(true);
    this.state.fc.startAutoCapture(
      (response) => this.captureFeedback(response, CAPTURE_METHOD.WEBRTC),
      (error) => this.captureError(error, CAPTURE_METHOD.WEBRTC),
      { languageTag: this.props.i18n.language ? this.props.i18n.language : "" }
    );
  }

  handleShowNativeInstructions = () => {
    this.state.fc.stopCamera();
    this.setState({ nativeInstructions: true });
  };

  handleHideNative = () => {
    this.setState({ hideNative: true });
  };

  handleCloseNativeInstructions = () => {
    this.setState({ nativeInstructions: false });
    if (this.state.inputRef && this.state.inputRef.current) {
      const inputRef = { ...this.state.inputRef };
      inputRef.current.value = "";
      this.state.inputRef.current.click();
    }
  };

  saveNativeButtonRef = (inputRef) => {
    this.setState({ inputRef });
  };

  switchToNative() {
    this.state.fc.stopAutoCapture();
    this.setState({ captureMethod: undefined });
  }

  changeCamera = (cameraId) => {
    this.setState({ showSwitchCameraButton: false });
    this.changeMirror();
    this.props.changeCamera({
      cameraId: cameraId,
      facingMode: this.props.documentCaptureConfiguration.facingMode === "user" ? "environment" : "user"
    });
  };

  changeMirror = () => {
    this.props.flipCamera({
      mirror: this.props.documentCaptureConfiguration.mirror ? false : true
    })
  }
  nativeCapture(blob) {
    logEvent(face_capture_native);
    this.state.fc.stopAutoCapture();
    this.setState({
      captureMethod: CAPTURE_METHOD.NATIVE,
      pending: true,
      formState: CAPTURING,
    });
    this.props.toggleNetworkWarnings(true);
    this.state.fc
      .assessQuality({ blob })
      .then((response) => this.captureFeedback(response, CAPTURE_METHOD.NATIVE))
      .catch((error) => this.captureError(error, CAPTURE_METHOD.NATIVE))
      .finally(() => this.props.toggleNetworkWarnings(false));
  }

  cameraPermissionDenied = () => {

    this.setState({
      cameraPermission: false,
      message: this.props.t("Common.error_camera"),
      isError: true,
      retryCameraInit: false,
      wasmIsLoading: false,
      showSwitchCameraButton: store.getState().camera.cameras.length > 1,
    });
  };

  cameraSourceUnavailable = () => {
    this.setState({
      cameraPermission: false,
      message: this.props.t("Common.error_camera_source"),
      isError: true,
      retryCameraInit: false,
      wasmIsLoading: false,
      showSwitchCameraButton: store.getState().camera.cameras.length > 1,
    })
  }

  refreshCameras = () => {
    setDefaultCameras();
    setTimeout(() => {
      this.setState({ camera: store.getState().camera });
    }, 50);
  }

  retryCameraInit = () => {
    logEvent(retry_camera_init);
    if (!this.state.cameraPermission)
      this.setState({
        cameraPermission: true,
        retryCameraInit: true,
        message: "",
        isError: false,
      });
  };


  render() {
    let message = "";
    if (this.state.message === "No face could be found in the image.") {
      message = this.props.t("PageFaceCapture.error_no_face_could");
    } else if (this.state.message === "Multiple faces found in the image.") {
      message = this.props.t("PageFaceCapture.error_multi_face_found");
    } else if (this.state.message === "Could not find the eyes in the image.") {
      message = this.props.t("PageFaceCapture.error_not_find_eyes");
    } else if (this.state.message === "Face too small. Please move closer to camera.") {
      message = this.props.t("PageFaceCapture.error_face_to_small");
    } else if (this.state.message === "Image too dark. Please improve lighting.") {
      message = this.props.t("PageFaceCapture.error_image_to_dark");
    } else if (this.state.message === "Image blur detected. Reduce motion or improve lighting.") {
      message = this.props.t("PageFaceCapture.error_image_blur_detected");
    } else if (this.state.message === "Face lighting not uniform.") {
      message = this.props.t("PageFaceCapture.error_face_lighting_not_uniform");
    } else if (this.state.message === "Face is tilted. Please look directly at the camera.") {
      message = this.props.t("PageFaceCapture.error_face_is_tilted");
    } else if (this.state.message === "Face image too soft, lacking details. Please improve lighting.") {
      message = this.props.t("PageFaceCapture.error_face_image_too_soft");
    } else if (this.state.message === "Face not frontal. Please look directly at the camera.") {
      message = this.props.t("PageFaceCapture.error_face_not_frontal");
    } else if (this.state.message === "The eyes in the image appear to be closed.") {
      message = this.props.t("PageFaceCapture.error_eyes_appear_closed");
    } else if (this.state.message === "Face image not of sufficient quality.") {
      message = this.props.t("PageFaceCapture.error_face_not_suffic_quality");
    } else {
      message = this.props.t("PageFaceCapture.error_face_inside");
    }
    const messageClass = `message-box box-shadow ${this.state.isError ? "message-box-error" : ""}`;
    return (
      <PageContent
        toggleLoading={this.state.pending || this.state.wasmIsLoading}
        type={PAGE_CONTENT_TYPE_CAPTURE}
        isSpinner={true}
      >
        <p className="instructions-on-portrait">
          {this.props.t("PageFaceCapture.header_instruction")}
        </p>
        {this.props.showNativeCamera === "true" ||
          this.props.showNativeCamera === true ? (
          <TimeoutVisibility
            timeout={20000}
            visible={!this.state.hideNative}
            style={{}}
          >
            <NativeCaptureBox
              onClick={() => this.switchToNative()}
              onUploaded={(blob) => this.nativeCapture(blob)}
              facingMode="user"
              imageType="selfie"
              handleHideNative={this.handleHideNative}
              onButtonClicked={this.handleShowNativeInstructions}
              onNativeButtonReady={this.saveNativeButtonRef}
            />
          </TimeoutVisibility>
        ) : null}

        <FaceCaptureUI
          onInitialized={(fc) => this.setState({ fc })}
          onCameraStarted={this.onCameraStarted}
          height={parseInt(process.env.REACT_APP_USE_FACECAPTURE_RESOLUTION_HEIGHT) || 720}
          width={parseInt(process.env.REACT_APP_USE_FACECAPTURE_RESOLUTION_WIDTH) || 1280}
          url={this.props.faceQualityHostname + "/rest/v1/quality/assessments"}
          className={"overlay overlay-oval edge"}
          cameraPermissionDenied={this.cameraPermissionDenied}
          cameraSourceUnavailable={this.cameraSourceUnavailable}
          retryCameraInit={this.state.retryCameraInit}
        />

        {/* {window.navigator.userAgent.indexOf("Edge") > -1 ? (
          <canvas
            className="ui-canvas-container ui-canvas"
            ref="canvas"
            width={480}
            height={640}
          />
        ) : null} */}
        <Modal
          show={this.state.nativeInstructions}
          onHide={this.handleCloseNativeInstructions}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <p>
                <strong>{this.props.t("PageFaceCapture.face_position")}</strong>
              </p>
            </Modal.Title>
          </Modal.Header>
        </Modal>

        <footer>
          <S.MessageBoxWrapper>
            <div className={messageClass}>{message}</div>
          </S.MessageBoxWrapper>

          {this.state.formState === START && this.state.cameraPermission && (
            <CaptureButtonPanel
              onChangeCamera={this.changeCamera}
              onChangeMirror={this.changeMirror}
              showSwitchCameraButton={this.state.showSwitchCameraButton}
              showMirror={true}
              backDisabled={true}
              onCaptureClick={() => this.startAutoCapture()}
              disabled={this.state.formState === PASS}
              text={this.props.t("PageFaceCapture.start")}
              facingMode={'user'}
            />
          )}
          {!this.state.cameraPermission && (
            <div className="center">
              <button type="button" className={"btn"} style={{ backgroundColor: '#D31145' }} onClick={this.retryCameraInit}>
                {this.props.t(
                  "Common.confirm_camera"
                )}
              </button>
            </div>
          )}
        </footer>

        <Modal
          show={this.state.rotateLandscape}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="row row-justify-center">
              <img
                style={{ width: '20%', height: 'inherit' }}
                src={imgwarning}
                alt="warning"
              />
            </div>
            <div className="center">
              <h3 className="mt-3">{this.props.t('PageDocumentCapturePortrait.display_is_landscape_header')}</h3>
              <p className="mb-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCapturePortrait.display_is_landscape_title')}</p>
            </div>
            <div className="row row-justify-center">
              <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ rotateLandscape: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
            </div>
          </Modal.Body>
        </Modal>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageFaceCapture);
export default connect(
  (state) => {
    const { showNativeCamera } = state.configuration.extraConfig.featureFlags;
    return {
      retries: state.faceCapture.retries,
      faceQualityHostname: state.configuration.faceQualityHostname,
      showNativeCamera,
      documentCaptureConfiguration: state.documentCaptureConfiguration
    };
  },
  {
    preview,
    toggleNetworkWarnings,
    setSelectedCamera,
    changeCamera,
    flipCamera,
    go,
    nextStep,
    faceSave
  }
)(componentWithTranslation);
