import React, { Component } from "react";
import { connect } from "react-redux";
import { func } from "prop-types";
import { go } from "logic/actions/navigation";
import PageContent, { PAGE_CONTENT_TYPE_INSTRUCTIONS } from "Components/PageContent";
import { challengeCreate } from "logic/actions/api/liveness";
import { FACE_CAPTURE } from "logic/enums/pages";
import * as S from "./PageFaceCaptureInstructions.styles";
import { withTranslation } from "react-i18next";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import imgsuccess from '../../../../assets/success-icon.png'
import './style.css'

export class PageFaceCaptureInstructions extends Component {
  static propTypes = {
    go: func,
    challengeCreate: func,
  };
  constructor(props) {
    super(props);
    this.state = {};
    if (!getTimePassed("face_capture")) startEventTimer("face_capture");
  }

  componentDidMount() {
    window.scrollBy(1, 2);
    setTimeout(() => {
      window.scrollBy(-1, -2);
    }, 100);
  }
  nextPage() {
    if (this.props.livenessTestData) this.props.go(FACE_CAPTURE);
  }

  render() {
    const buttonClassName = !this.props.livenessTestData
      ? "btn"
      : "btn";

    return (
      <PageContent toggleLoading={this.state.pending} progressBar={true} type={PAGE_CONTENT_TYPE_INSTRUCTIONS} isSpinner={true}>
        <div className="container-fluid" style={{ marginBottom: '20%' }}>
          <div className="card-body">
            <h1>{this.props.t("PageFaceCaptureInstructions.take_selfie")}</h1>
            <div className="row no-gutters">
              <div className="col-1">
                <img
                  className="ml-3 mb-3"
                  style={{ width: '50%', height: 'inherit' }}
                  src={imgsuccess}
                  alt=""
                />
              </div>
              <div className="col mr-3 ml-3">
                <p className="font-text w-full">
                  {this.props.t("PageFaceCaptureInstructions.remove_hats")}
                </p>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-1">
                <img
                  className="ml-3 mb-3"
                  style={{ width: '50%', height: 'inherit' }}
                  src={imgsuccess}
                  alt=""
                />
              </div>
              <div className="col mr-3 ml-3">
                <p className="font-text w-full">
                  {this.props.t("PageFaceCaptureInstructions.well_lit_area")}
                </p>
              </div>
            </div>
            {/* <hr /> */}
            <div className="row no-gutters">
              <div className="col-1">
                <img
                  className="ml-3 mb-3"
                  style={{ width: '50%', height: 'inherit' }}
                  src={imgsuccess}
                  alt=""
                />
              </div>
              <div className="col mr-3 ml-3">
                <p className="font-text w-full">
                  {this.props.t("PageFaceCaptureInstructions.plain_background")}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <p className="error">{this.state.error}</p> */}
        <footer className="footer">
          <span className="buttonSpan">
            <S.Button
              className={buttonClassName}
              style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
              onClick={() => this.props.go(FACE_CAPTURE)}
            >
              {this.props.t("Common.start_selfie")}
            </S.Button>
          </span>
        </footer>
      </PageContent>
    );
  }
}
const componentWithTranslation = withTranslation()(PageFaceCaptureInstructions);
export default connect(
  (store) => {
    return {
      livenessTestData: store.livenessTest.livenessTestData,
    };
  },
  { go, challengeCreate }
)(componentWithTranslation);
