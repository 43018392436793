import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, instanceOf } from "prop-types";
import RoundIcon from "../../../RoundIcon";
import Icon from "../../../Icon";
import "./style.css";
import PageContent from "../../../PageContent";
import { selectDocumentType } from "logic/actions/documentCapture";
import {
  go,
  nextPage,
} from "logic/actions/navigation";
import {
  DOCUMENT_COUNTRY_SELECTOR,
  DOCUMENT_CAPTURE_OTHER_SELECTOR,
  // eslint-disable-next-line
  NEW_DOCUMENT_CAPTURE_INSTRUCTIONS
} from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import {
  documentSidesSelector,
  verticalDocumentsPresent,
  changeCamera,
  flipCamera,
  isSinglePaged,
} from "logic/actions/documentCaptureConfiguration";
import {
  ID_CARD,
  PASSPORT,
  COPY_ID_CARD,
  COPY_PASSPORT,
  MONK_ID_DOCUMENT,
  OFFICIAL_ID_DOCUMENT
} from "logic/enums/documentType";
import {
  setDocumentQualityRetries,
  newDocumentSelected,
} from "logic/actions/documentCapture";
import { setExpireTimeOut } from "logic/actions/expireTimeOut";
import {
  width,
  height
} from "logic/documentResolution";
import {
  createInstance,
  checkActionFromPortrait
} from "logic/actions/documentCapture";

const START = 0;
//TODO@gva: mark for refactor to hooks (componentWillMount is deprecated)
export class PageVerifyIdentity extends Component {
  static propTypes = {
    selectDocumentType: func,
    go: func,
    documentType: string,
    base64CroppedFirstPage: instanceOf(Blob),
    configuration: instanceOf(Object),
    documentCapture: instanceOf(Object),
  };

  constructor(props) {
    super(props);
    this.state = {
      dc: undefined,
      pending: false,
      message: "",
      formState: START,
      shouldStartNativeCamera: false,
      // width: 2560,
      // height: 1440,
      facingMode: "environment",
    }
    this.go.bind(this);
    this.goToPassport.bind(this);
    this.goToIdCard.bind(this);
    if (this.props.documentCapture) {
      this.props.changeCamera({
        cameraId: this.props.cameraId,
        facingMode: this.state.facingMode
      })
      this.props.flipCamera({ mirror: true })
    }
  }

  componentDidMount() {
    if (!this.props.documentCapture.base64CroppedFirstPage &&
      this.props.documentCapture.documentType !== "PASSPORT") {
      this.setState({ hintFacePresent: true });
      this.documentPagesNum(this.props.documentCapture.documentType);
    }
    if (!this.props.verticalDocumentsPresent) {
      this.changeDocumentOrientation("default");
    }

    if (this.props.documentCapture.documentQualityRetries) {
      this.props.setDocumentQualityRetries(parseInt(this.props.documentCapture.documentQualityRetries));
    } else {
      this.props.setDocumentQualityRetries(2);
    }
    
    if(this.skipPageVerify()) {
      this.goToIdCard()
    }
  }

  initDocumentCaptureSDK = (url, width, height, documentType, facingMode) => {
    // console.log(width, height)
    const config = {
      url,
      width,
      height,
      facingMode,
      captureType: "PORTRAIT",
      documentType: documentType === "PASSPORT" ? documentType : "ID_CARD",
    };
    this.props.createInstance(config);
  };
  documentPagesNum = (type) => {
    switch (type) {
      case ID_CARD:
        this.props.isSinglePaged(true);
        break;
      case COPY_ID_CARD:
        this.props.isSinglePaged(true);
        break;
      case COPY_PASSPORT:
        this.props.isSinglePaged(true);
        break;
      case MONK_ID_DOCUMENT:
        this.props.isSinglePaged(true);
        break;
      case OFFICIAL_ID_DOCUMENT:
        this.props.isSinglePaged(true);
        break;
      default:
        this.props.isSinglePaged(false);
    }
  };
  changeDocumentOrientation(orientation) {
    if (orientation === "vertical") {
      switch (this.props.documentCapture.documentType) {
        case "ID_CARD": {
          this.props.selectDocumentType("ID_CARD_PORT");
          break;
        }
        case "DRIVERS_LICENCE": {
          this.props.selectDocumentType("DRIVERS_LICENCE_PORT");
          break;
        }
        default: {
          this.props.selectDocumentType("OTHER_DOCUMENT_PORT");
        }
      }
    } else if (orientation === "default") return;
    else {
      switch (this.props.documentCapture.documentType) {
        case "ID_CARD_PORT": {
          this.props.selectDocumentType("ID_CARD");
          break;
        }
        case "DRIVERS_LICENCE_PORT": {
          this.props.selectDocumentType("DRIVERS_LICENCE");
          break;
        }
        default: {
          this.props.selectDocumentType("OTHER_DOCUMENT");
        }
      }
    }
  }
  skipPageVerify = () => {
    return (
      this.props.nationality.toLowerCase() === 'th' &&
      !['undefined', 'null', 'blank'].includes(this.props.insureAge.toLowerCase()) &&
      this.props.insureAge <= 75 &&
      !['undefined', 'null', 'blank'].includes(this.props.occupation.toLowerCase()) &&
      !this.MonkOrNun()
    )
  }
  MonkOrNun = () => {
    return /(ศ101211)|(ศ101212)|(ศ101213)/g.test(this.props.occupation)
  }
  UNSAFE_componentWillMount() {
    switch (this.props.dialCountryNum) {
      case "us":
        this.props.documentSidesSelector({ id: 0, dl: 2 });
        this.setState({
          message: this.props.t("Countries.us"),
        });
        this.props.verticalDocumentsPresent(true);
        break;
      case "gb":
        this.props.documentSidesSelector({ id: 0, dl: 1 });
        this.setState({ message: this.props.t("Countries.gb") });
        this.props.verticalDocumentsPresent(false);
        break;
      case "ie":
        this.props.documentSidesSelector({ id: 0, dl: 1 });
        this.setState({ message: this.props.t("Countries.ie") });
        this.props.verticalDocumentsPresent(false);
        break;
      case "au":
        this.props.documentSidesSelector({ id: 0, dl: 1 });
        this.setState({ message: this.props.t("Countries.au") });
        this.props.verticalDocumentsPresent(false);
        break;
      case "es":
        this.props.documentSidesSelector({ id: 2, dl: 1 });
        this.setState({ message: this.props.t("Countries.es") });
        this.props.verticalDocumentsPresent(false);
        break;
      case "nz":
        this.props.documentSidesSelector({ id: 0, dl: 2 });
        this.setState({ message: this.props.t("Countries.nz") });

        break;
      default:
        this.props.documentSidesSelector({ id: undefined, dl: undefined });
        this.setState({ message: this.props.t("Countries.other") });
        this.props.verticalDocumentsPresent(true);
    }
  }
  goToCountryPage = () => {
    this.props.go(DOCUMENT_COUNTRY_SELECTOR);
  };

  goToPassport = () => this.go(PASSPORT);

  goToIdCard = () => this.go(ID_CARD);

  goToOtherDocument = () => this.props.go(DOCUMENT_CAPTURE_OTHER_SELECTOR);
  // goToDriversLicence = () => this.go(DRIVERS_LICENCE);

  go = (type) => {
    const { documentQualityHostname } = this.props.configuration;
    const url = documentQualityHostname + "/rest/v1/quality/assessments";
    const xWidth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH) <= 0 ? width : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH);
    const xHeigth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT) <= 0 ? height : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT);
    // console.log(xHeigth, xWidth)
    this.initDocumentCaptureSDK(
      url,
      xWidth,
      xHeigth,
      type,
      this.state.facingMode
    );
    this.props.selectDocumentType(type);
    this.props.newDocumentSelected();
    this.documentPagesNum(type);
    this.props.checkActionFromPortrait(false);
    this.changeDocumentOrientation("default");
    // this.props.go(NEW_DOCUMENT_CAPTURE_INSTRUCTIONS);
    this.props.nextPage();
  };

  componentWillUnmount() {
    // this.props.setExpireTimeOut(new Date().getTime())
    if (this.props.documentCapture.docCaptureInstance) {
      this.props.documentCapture.docCaptureInstance.clearCapturedFrames();
    }
  }

  render() {
    return (
      <PageContent progressBar={false}>
        <h1>{this.props.t("PageVerifyIdentity.title")}</h1>
        {(this.props.nationality.toLowerCase() === "th" && this.props.showValid === "undefined") ||
          (this.props.showValid.indexOf('ndid') >= 0 && this.props.showValid !== "undefined") ||
          (['undefined', 'null', 'blank'].includes(this.props.nationality) && this.props.showValid === "undefined") ? (
          <div className="panel-doc-type mx-2" onClick={this.goToIdCard}>
            <RoundIcon color="red">
              <Icon name="ID-card" color="white" />
            </RoundIcon>
            <div className="w-full">
              <div>
                <h3 style={{ fontFamily: 'AIA Regular' }} className="mt-3">{this.props.t("PageVerifyIdentity.id")}</h3>
                <p style={{ fontFamily: 'AIA Regular', lineHeight: "22px" }}>{this.props.t("PageVerifyIdentity.desc_id_card")}</p>
              </div>
            </div>
          </div>
        ) : null}

        {(this.props.nationality.toLowerCase() !== "th" && this.props.showValid === "undefined") ||
          (this.props.showValid.indexOf('passport') >= 0 && this.props.showValid !== "undefined") ||
          (this.props.nationality === "undefined" && this.props.showValid === "undefined") ?
          (<div className="panel-doc-type mx-2"
            data-type={PASSPORT}
            onClick={this.goToPassport}
          >
            <RoundIcon color="red">
              <Icon name="Passport" color="white" />
            </RoundIcon>
            <div className="w-full">
              <div>
                <h3 style={{ fontFamily: 'AIA Regular' }} className="mt-3">{this.props.t("PageVerifyIdentity.passport")}</h3>
                <p style={{ fontFamily: 'AIA Regular', lineHeight: "22px" }}>{this.props.t("PageVerifyIdentity.desc_passport")}</p>
              </div>
            </div>
          </div>) : null}

        {(this.props.showValid.indexOf('other') >= 0 && this.props.showValid !== "undefined") ||
          (this.props.nationality !== "undefined" && this.props.showValid === "undefined") ||
          (this.props.nationality === "undefined" && this.props.showValid === "undefined") ?
          (<div className="panel-doc-type mx-2" onClick={this.goToOtherDocument}>
            <RoundIcon color="red">
              <Icon name="ID-card" color="white" />
            </RoundIcon>
            <div className="w-full">
              <div>
                <h3 style={{ fontFamily: 'AIA Regular' }} className="mt-3">{this.props.t("PageVerifyIdentity.other")}</h3>
                <p style={{ fontFamily: 'AIA Regular', lineHeight: "22px" }}>{this.props.t("PageVerifyIdentity.desc_other")}</p>
              </div>
            </div>
          </div>) : null}
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageVerifyIdentity);
export default connect(
  (state) => {
    return {
      ...state.setApplicationParams,
      ...state.configuration.extraConfig.featureFlags,
      documentCapture: state.documentCapture,
      configuration: state.configuration,
      ...state.documentCaptureConfiguration,
    };
  },
  {
    selectDocumentType,
    go,
    nextPage,
    setDocumentQualityRetries,
    documentSidesSelector,
    verticalDocumentsPresent,
    newDocumentSelected,
    setExpireTimeOut,
    changeCamera,
    flipCamera,
    createInstance,
    isSinglePaged,
    checkActionFromPortrait
  }
)(componentWithTranslation);
