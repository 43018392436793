/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Modal } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { go, nextStep } from "logic/actions/navigation";
import { documentsGet, documentUpdate } from "logic/actions/api/document";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import {
    logEvent
} from "logic/eventLogger";
import {
    DOCUMENT_CAPTURE_INSTRUCTIONS,
    // eslint-disable-next-line
    FACE_CAPTURE_INSTRUCTIONS,
    DOCUMENT_CAPTURE_TYPE2_SELECT,
    // CHECK_DOPA,
    VERIFY_IDENTITY
} from "logic/enums/pages";
import PageContent from 'Components/PageContent';
import { setDataDOPACheck } from 'logic/actions/dopaCheck';
import imgwarning from '../../../../assets/warning-icon.png';
import imgerror from '../../../../assets/error-icon.png';

export class PageShowOCRPassport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            documentId: null,
            buttonsDisabled: false,
            error: null,
            message: null,
            documentOCRDataLoaded: false,
            documentOCRData: null,
            onModal: false,
            visualZoneObjectData: null,
            mrzObjectData: null,
            gender: "",
            dateOfBirthValue: null,
            expireDateValue: null,
            formIsValid: true,
            inputGender: '',
            firstNameValid: true,
            lastNameValid: true,
            nameEngValid: true,
            middleNameValid: true,
            docnumValid: true,
            birtDayValid: true,
            genderValid: true,
            expireValid: true,
            field: {},
            errors: {},
            disableField: false,
            onModalExpire: false,
            countGetApi: 0,
            modalReprocess: false,
            modalServerError: false,
            statusReprocess: false,
            disableBtnReprocess: true,
            placeHolder: "",
            onModalBirthDayExample: false,
            setCurrentDate: ""
        };
        this.countReprocess = 5;
        this.getDocumentOCRData.bind(this);
        this.confirmData.bind(this)
        this.documentPut.bind(this)

        if (!getTimePassed("document_ocr_confirm")) startEventTimer("document_ocr_confirm");
    }

    componentDidMount() {
        this.placeholderOfDate()
        this.getDocumentOCRData()
    }
    placeholderOfDate = () => {
        let date = new Date().toLocaleDateString('en-GB');
        this.setState({
            placeHolder: 'ex. ' + date,
            setCurrentDate: date
        })
    }

    countDownReprocess = () => {
        let countdown = setInterval(() => {
            this.countReprocess = this.countReprocess - 1
            if (this.countReprocess <= 0) {
                clearInterval(countdown)
                this.setState({ disableBtnReprocess: false })
            } else {
                this.setState({ disableBtnReprocess: true })
            }
        }, 1000)
    }
    getDocumentOCRData = () => {
        startEventTimer("passport_ocr_conirm_[get-data]")
        logEvent("passport_ocr_conirm_[get-data]", {
            start_time: window.performance.now(),
            response: null
        })
        this.setState({
            documentOCRDataLoaded: false,
            modalReprocess: false
        });
        this.props.documentsGet()
            .then((response) => {
                // let visualZoneObject = this.state.documentOCRData.visualZone
                // let visualZoneObject = response.data.serverProcessed.ocrData.sensitiveData.visualZone
                // let mrzObject = response.data.serverProcessed.ocrData.sensitiveData.mrz
                // let visualZoneObject = response.data.visualZone
                let mrzObject = response.data.mrz
                // this.setState({ visualZoneObjectData: visualZoneObject, mrzObjectData: mrzObject })

                let keysM = Object.keys(mrzObject);
                let valueArray = [];
                keysM.forEach(function (key) {
                    valueArray.push(mrzObject[key]);
                });
                valueArray.forEach((val) => {
                    if (val.name === 'Document Number') {
                        this.docNumber.value = val.value ? val.value : ''
                    } else if (val.name === 'Given Names') {
                        this.givenname.value = val.value ? val.value : ''
                    } else if (val.name === 'Surname') {
                        this.surname.value = val.value ? val.value : ''
                    } else if (val.name === 'Date Of Birth') {
                        if (!val.validationChecks) {
                            let date = new Date(val.value).toLocaleDateString('en-GB')
                            let value = date.split('/').join('')
                            this.setState({ dateOfBirthValue: value })
                        } else {
                            this.setState({ dateOfBirthValue: "" })
                        }
                    } else if (val.name === 'Date Of Expiry') {
                        if (!val.validationChecks) {
                            let date = new Date(val.value).toLocaleDateString('en-GB')
                            let value = date.split('/').join('')
                            this.setState({ expireDateValue: value })
                        } else {
                            this.setState({ expireDateValue: "" })
                        }
                    } else if (val.name === 'Sex') {
                        this.selectGender(val.value)
                    }
                });
                logEvent("passport_ocr_conirm_[get-data]", {
                    start_time: getTimePassed("passport_ocr_conirm_[get-data]"),
                    response: response
                })
                clearTimeout(this.timeInterval)
                this.setState({
                    documentOCRDataLoaded: true,
                    documentOCRData: response.data
                });
            })
            .catch((err) => {
                logEvent("passport_ocr_conirm_[get-data]", {
                    start_time: getTimePassed("passport_ocr_conirm_[get-data]"),
                    response: err
                })
                if (this.state.countGetApi < 4) {
                    this.timeInterval = setTimeout(() => {
                        this.getDocumentOCRData()
                        this.setState({ countGetApi: this.state.countGetApi + 1 })
                    }, process.env.REACT_APP_SET_TIME_INTERVAL || 5000)
                } else {
                    clearTimeout(this.timeInterval)
                    if (this.state.statusReprocess) {
                        if (err.response && err.response.data && err.response.data.message) {
                            this.setState({
                                documentOCRDataLoaded: true,
                                modalServerError: true,
                                error: err.response.data.message,
                            });
                        } else {
                            this.setState({
                                error: err.toString(),
                                documentOCRDataLoaded: true,
                                modalServerError: true
                            });
                        }
                    } else {
                        this.setState({
                            modalReprocess: true,
                            statusReprocess: true,
                            documentOCRDataLoaded: true
                        })
                        this.countDownReprocess()
                    }
                }

            });
    }
    handleDopaSuccess = () => {
        setTimeout(() => {
            this.props.go(DOCUMENT_CAPTURE_TYPE2_SELECT)
        }, 200)
    }
    splitDate = (date) => {
        let splitDate, ogBirthday;
        splitDate = date.split('/')
        if (splitDate[0] === '-' && splitDate[1] === '-') {
            let year = parseInt(splitDate[2]);
            ogBirthday = year.toString() + '0101';
        } else if (splitDate[0] === '-') {
            let year = parseInt(splitDate[2]);
            ogBirthday = year.toString() + splitDate[1] + '01';
        } else {
            let mounth = splitDate[1].length === 1 ? '0' + splitDate[1] : splitDate[1]
            let day = splitDate[0].length === 1 ? '0' + splitDate[0] : splitDate[0]
            let year = parseInt(splitDate[2]);
            ogBirthday = year.toString() + mounth + day;
        }
        return ogBirthday
    }
    documentPut = () => {
        // e.preventDefault();
        startEventTimer("passport_ocr_conirm_[put-data]")
        logEvent("passport_ocr_conirm_[put-data]", {
            start_time: window.performance.now(),
            response: null
        })
        this.setState({
            btnSubmitsts: false,
            documentOCRDataLoaded: false,
            onModal: false,
            disableField: true
        });
        let splitBirthDay = this.splitDate(this.birthDay.value);
        let splitExpireDate = this.splitDate(this.expire.value);

        const uncroppedFirstPage = this.props.base64CroppedSecondPage ? this.props.base64CroppedSecondPage : this.props.base64CroppedFirstPage;
        const uncroppedSecondPage = this.props.base64CroppedSecondPage;
        const updatedOCRFields = {
            confirmedGivenNames: this.givenname.value.trim(),
            confirmedLastname: this.surname.value.trim(),
            middleName: this.middleName.value.trim(),
            confirmedIdNumber: this.docNumber.value.trim(),
            gender: this.state.gender,
            birthOfDate: splitBirthDay,
            expireDate: splitExpireDate,
            confirmedBirthdate: splitBirthDay
        }
        this.props.setDataDOPACheck(updatedOCRFields)
        this.props.documentUpdate(
            uncroppedFirstPage,
            uncroppedSecondPage,
            this.props.documentType,
            updatedOCRFields
        )
            .then((response) => {
                logEvent("passport_ocr_conirm_[put-data]", {
                    start_time: getTimePassed("passport_ocr_conirm_[put-data]"),
                    response: response
                })
                this.handleDopaSuccess();
            })
            .catch((err) => {
                logEvent("passport_ocr_conirm_[put-data]", {
                    start_time: getTimePassed("passport_ocr_conirm_[put-data]"),
                    response: err
                })
                if (err.response && err.response.data && err.response.data.message) {
                    this.setState({
                        btnSubmitsts: true,
                        documentOCRDataLoaded: true,
                        error: err.response.data.message,
                        disableField: false
                    });
                } else {
                    this.setState({
                        btnSubmitsts: true,
                        error: err.toString(),
                        documentOCRDataLoaded: true,
                        disableField: false
                    });
                }
            });
    }

    confirmData = (e) => {
        e.preventDefault();
        let errors = {};
        if (!this.givenname.value) {
            errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null')
            this.setState({ firstNameValid: false })
        } else {
            if (typeof this.state.errors["firstname"] !== "undefined") {
                if (this.state.errors["firstname"]) {
                    errors["firstname"] = this.state.errors["firstname"]
                    this.setState({ firstNameValid: false })
                } else {
                    errors["firstname"] = ""
                    this.setState({ firstNameValid: true })
                }
            } else {
                errors["firstname"] = ""
                this.setState({ firstNameValid: true })
            }
        }
        if (!this.surname.value) {
            errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null')
            this.setState({ lastNameValid: false })
        } else {
            if (typeof this.state.errors["lastname"] !== "undefined") {
                if (this.state.errors["lastname"]) {
                    errors["lastname"] = this.state.errors["lastname"]
                    this.setState({ lastNameValid: false })
                } else {
                    errors["lastname"] = ""
                    this.setState({ lastNameValid: true })
                }
            } else {
                errors["lastname"] = ""
                this.setState({ lastNameValid: true })
            }
        }
        if (!this.docNumber.value) {
            errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.doc_number_not_null')
            this.setState({ docnumValid: false })
        } else {
            if (typeof this.state.errors["docNumber"] !== "undefined") {
                if (this.state.errors["docNumber"]) {
                    errors["docNumber"] = this.state.errors["docNumber"]
                    this.setState({ docnumValid: false })
                } else {
                    errors["docNumber"] = ""
                    this.setState({ docnumValid: true })
                }
            } else {
                errors["docNumber"] = ""
                this.setState({ docnumValid: true })
            }
        }

        if (!this.birthDay.value) {
            errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_not_null')
            this.setState({ birtDayValid: false })
        } else {
            if (typeof this.state.errors["birthday"] !== "undefined") {
                if (this.state.errors["birthday"]) {
                    errors["birthday"] = this.state.errors["birthday"]
                    this.setState({ birtDayValid: false })
                } else {
                    errors["birthday"] = ""
                    this.setState({ birtDayValid: true })
                }
            } else {
                errors["birthday"] = ""
                this.setState({ birtDayValid: true })
            }
        }

        if (!this.expire.value) {
            errors["expire"] = this.props.t('PageDocumentConfirmOCRData.expire_not_null')
            this.setState({ expireValid: false })
        } else {
            if (typeof this.state.errors["expire"] !== "undefined") {
                if (this.state.errors["expire"]) {
                    errors["expire"] = this.state.errors["expire"]
                    this.setState({ expireValid: false })
                } else {
                    errors["expire"] = ""
                    this.setState({ expireValid: true })
                }
            } else {
                errors["expire"] = ""
                this.setState({ expireValid: true })
            }
        }

        if (!this.state.gender) {
            errors["gender"] = this.props.t('PageDocumentConfirmOCRData.gender_not_null')
            this.setState({ genderValid: false })
        } else {
            errors["gender"] = ""
            this.setState({ genderValid: true })
        }
        this.setState({ errors: errors });
        setTimeout(() => {
            if (this.state.expireValid &&
                this.state.firstNameValid &&
                this.state.lastNameValid &&
                this.state.birtDayValid &&
                this.state.docnumValid &&
                this.state.genderValid
            ) {
                this.documentPut()
            }
        }, 200)
    }
    retryCapturing = () => {
        this.props.go(DOCUMENT_CAPTURE_INSTRUCTIONS);
        this.props.retry();

        this.props.base64CroppedSecondPage
            ? this.props.resetingDocumentQualityRetries("back")
            : this.props.resetingDocumentQualityRetries("front");
    };
    handleValidate = () => {
        let fields = this.state.field;
        let errors = {};

        if (typeof fields["docNumber"] !== "undefined") {
            if (fields["docNumber"]) {
                if (fields["docNumber"].match(/[$-/:-?{-~!"^_`';\\]/g)) {
                    errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.docnum_ch_en_and_num_only');
                    this.setState({ pidValid: false })
                } else if (!fields["docNumber"].match(/^[A-Za-z0123456789]+$/g)) {
                    errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.docnum_ch_en_and_num_only');
                    this.setState({ pidValid: false })
                } else {
                    errors["docNumber"] = "";
                    this.setState({ pidValid: true })
                }
            } else {
                errors["docNumber"] = this.props.t('PageDocumentConfirmOCRData.doc_number_not_null');
                this.setState({ pidValid: false })
            }
        }

        if (typeof fields["firstname"] !== "undefined") {
            // [\u0E00-\u0E7Fa-zA-Z']
            if (fields["firstname"]) {
                if (!fields["firstname"].match(/^[A-Za-z $-/:.-?{-~!"^_`\\]+$/g)) {
                    errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.firstname_en_only');
                    this.setState({ firstNameValid: false })
                } else {
                    errors["firstname"] = "";
                    this.setState({ firstNameValid: true })
                }
            } else {
                errors["firstname"] = this.props.t('PageDocumentConfirmOCRData.first_name_not_null')
                this.setState({ firstNameValid: false })
            }
        }
        if (typeof fields["lastname"] !== "undefined") {
            if (fields["lastname"]) {
                if (!fields["lastname"].match(/^[A-Za-z $-/:.-?{-~!"^_`\\]+$/g)) {
                    errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.lastname_en_only');
                    this.setState({ lastNameValid: false })
                } else {
                    errors["lastname"] = "";
                    this.setState({ lastNameValid: true })
                }
            } else {
                errors["lastname"] = this.props.t('PageDocumentConfirmOCRData.last_name_not_null')
                this.setState({ lastNameValid: false })
            }
        }

        if (typeof fields["birthday"] !== "undefined") {
            if (fields["birthday"]) {
                if (!fields["birthday"].match(/^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[0-2])[-/.]\d{4}$/g)) {
                    errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                    this.setState({ birtDayValid: false })
                } else {
                    let getMinDate = fields["birthday"].split('/');
                    let getMaxDate = this.state.setCurrentDate.split('/');
                    if (getMinDate[getMinDate.length - 1].length >= 4) {
                        if (parseInt(getMinDate[getMinDate.length - 1]) >= 1877 &&  // Min year
                            parseInt(getMinDate[getMinDate.length - 1]) <= parseInt(getMaxDate[getMaxDate.length - 1]) // Max year
                        ) {
                            if (parseInt(getMinDate[getMinDate.length - 1]) === parseInt(getMaxDate[getMaxDate.length - 1]) &&
                                ((parseInt(getMinDate[0]) > parseInt(getMaxDate[0]) && parseInt(getMinDate[1]) === parseInt(getMaxDate[1])) || // Max day
                                    parseInt(getMinDate[1]) > parseInt(getMaxDate[1])) // Max month
                            ) {
                                errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                                this.setState({ birtDayValid: false })
                            } else {
                                errors["birthday"] = "";
                                this.setState({ birtDayValid: true })
                            }
                        } else {
                            errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_invalid');
                            this.setState({ birtDayValid: false })
                        }
                    }
                }
            } else {
                errors["birthday"] = this.props.t('PageDocumentConfirmOCRData.dob_not_null');
                this.setState({ birtDayValid: false })
            }
        }

        if (typeof fields["expire"] !== "undefined") {
            if (fields["expire"]) {
                if (!fields["expire"].match(/^(0[1-9]|[12][0-9]|3[01])[-/.](0[1-9]|1[0-2])[-/.]\d{4}$/g)) {
                    errors["expire"] = this.props.t('PageDocumentConfirmOCRData.expire_invalid');
                    this.setState({ birtDayValid: false })
                } else {
                    let getMinDate = fields["expire"].split('/');
                    let getMaxDate = this.state.setCurrentDate.split('/');
                    let getMaxYear = parseInt(getMaxDate[getMaxDate.length - 1]) + 200
                    if (getMinDate[getMinDate.length - 1].length >= 4) {
                        if (parseInt(getMinDate[getMinDate.length - 1]) >= 1877 &&  // Min year
                            parseInt(getMinDate[getMinDate.length - 1]) <= getMaxYear // Max year
                        ) {
                            errors["expire"] = "";
                            this.setState({ expireValid: true })
                        } else {
                            errors["expire"] = this.props.t('PageDocumentConfirmOCRData.expire_invalid');
                            this.setState({ expireValid: false })
                        }
                    }
                }
            } else {
                errors["expire"] = this.props.t('PageDocumentConfirmOCRData.expire_not_null');
                this.setState({ expireValid: false })
            }
        }

        this.setState({ errors: errors });
        // console.log(this.state.errors)
    }
    handleChange = (e, field) => {
        e.preventDefault();
        let fields = this.state.field
        fields[field] = e.target.value

        this.setState({ field: fields })
        this.handleValidate()
    }
    selectTitleName = (titleEn, titleTh) => {
        this.setState({ titleNameTh: titleTh, titleNameEn: titleEn })
        this.selectGender(titleEn)
    }
    cancelDopa = () => {
        setTimeout(() => {
            this.props.go(VERIFY_IDENTITY)
        }, 200)
    }
    selectGender = (title) => {
        if (title === "M") {
            this.female.checked = false
            this.male.checked = true
            this.setState({ gender: "Male" })
        } else if (title === "F") {
            this.female.checked = true
            this.male.checked = false
            this.setState({ gender: "Female" })
        } else if (this.male.checked === true) {
            this.setState({ gender: "Male" })
        } else if (this.female.checked === true) {
            this.setState({ gender: "Female" })
        }
    }
    confirmExpire = () => {
        setTimeout(() => {
            this.props.go(VERIFY_IDENTITY)
        }, 200)
    }
    render() {
        return (
            <PageContent toggleLoading={!this.state.documentOCRDataLoaded} progressBar={true} isSpinner={true}>
                <h1>{this.props.t("PageDocumentConfirmOCRData.title")}</h1>
                <h1>{this.props.t("PageDocumentConfirmOCRData.title_x")}</h1>

                <form onSubmit={(e) => this.confirmData(e)}>
                    <div className="card-body" style={{ marginBottom: '30%' }}>
                        <div className="container-fluid mt-1">
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.passport.number_id")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.docNumber = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "docNumber")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["docNumber"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center mt-2">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.firstname")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.givenname = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "firstname")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["firstname"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.middlename")}</label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.middleName = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "middlename")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["middlename"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.lastname")}<b style={{ color: "red" }}>*</b></label>
                                        <input type="text" className="form-control"
                                            ref={(input) => this.surname = input}
                                            placeholder=""
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "lastname")}
                                        ></input>
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["lastname"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.passport.date_of_birth")}<b style={{ color: "red" }}>*</b></label>
                                        <label style={{ color: "#22A8DA", fontSize: '0.8em', marginTop: '0px', cursor: 'pointer', textAlign: 'right' }} onClick={() => this.setState({ onModalBirthDayExample: true })}><u><i>{this.props.t("PageDocumentConfirmOCRData.id_card.laser_id_ex")}</i></u></label>
                                        <NumberFormat
                                            type="text"
                                            className="form-control"
                                            getInputRef={(input) => (this.birthDay = input)}
                                            value={this.state.dateOfBirthValue}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                this.setState({ dateOfBirthValue: value });
                                            }}
                                            format="##/##/####"
                                            mask="_"
                                            placeholder={this.state.placeHolder}
                                            isNumericString={true}
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "birthday")}
                                        />
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["birthday"]}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-justify-center">
                                <div className="col">
                                    <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.gender")}<b style={{ color: "red" }}>*</b></label>
                                    <div className="row">
                                        <label className="radio-button">
                                            <input
                                                type='radio'
                                                ref={(input) => this.male = input}
                                                onClick={() => this.selectGender()}
                                                name='radio'
                                                disabled={this.state.disableField}
                                            />
                                            <span className="indicator"></span><span className="p3 bt3" style={{ fontSize: '1.7em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.male")}</span>
                                        </label>
                                        <label className="radio-button ml-5">
                                            <input
                                                type='radio'
                                                ref={(input) => this.female = input}
                                                onClick={() => this.selectGender()}
                                                name='radio'
                                                disabled={this.state.disableField}
                                            />
                                            <span className="indicator"></span><span className="p3 bt3" style={{ fontSize: '1.7em' }}>{this.props.t("PageDocumentConfirmOCRData.id_card.female")}</span>
                                        </label>
                                        <span className="ml-3" style={{ color: "red" }}>{this.state.errors["gender"]}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row row-justify-center">
                                <div className="col">
                                    <div className="form-group">
                                        <label style={{ color: "black", fontSize: '0.8em' }}>{this.props.t("PageDocumentConfirmOCRData.passport.expired")}<b style={{ color: "red" }}>*</b></label>
                                        <NumberFormat
                                            type="text"
                                            className="form-control"
                                            value={this.state.expireDateValue}
                                            getInputRef={(input) => (this.expire = input)}
                                            onValueChange={(values) => {
                                                const { value } = values;
                                                this.setState({ expireDateValue: value });
                                            }}
                                            mask="_"
                                            format="##/##/####"
                                            placeholder={this.state.placeHolder}
                                            disabled={this.state.disableField}
                                            onChange={(e) => this.handleChange(e, "expire")}
                                        />
                                        <span className="mt-1" style={{ color: "red" }}>{this.state.errors["expire"]}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <footer className="footer d-flex align-items-center flex-column bd-highlight">
                        <a style={{ fontFamily: 'AIA Regular', color: "black", marginBottom: "0px", marginTop: "0px" }}>{this.props.t("PageDocumentConfirmOCRData.confirm_data")}</a>
                        <button
                            disabled={this.state.buttonsDisabled || !this.state.documentOCRDataLoaded}
                            type="submit"
                            value="submit"
                            className="btn"
                            style={{ backgroundColor: "#D31145", fontSize: '1em', marginTop: '0px' }}
                        >
                            {this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}
                        </button>
                    </footer>
                </form>
                <Modal
                    show={this.state.onModalExpire}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentConfirmOCRData.passport.alert_expired')}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.confirmExpire()}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.modalReprocess}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgwarning}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentConfirmOCRData.id_card.reprocess_get_ocr_data')}</a>
                            <a className="mt-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.countReprocess}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button
                                type="button"
                                className="btn btn-lg"
                                disabled={this.state.disableBtnReprocess}
                                style={{ backgroundColor: "#D31145", fontSize: '1em' }}
                                onClick={() => this.getDocumentOCRData()}
                            >{this.props.t("PageDocumentConfirmOCRData.btn_retry_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.modalServerError}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <img
                                style={{ width: '30%', height: 'inherit' }}
                                src={imgerror}
                                alt="warning"
                            />
                        </div>
                        <div className="row">
                            <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentConfirmOCRData.id_card.server_error_get_ocr_data')}</a>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.cancelDopa()}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModal}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-center">
                            <div className="col-6">
                                <button type="button" className="btn btn-outline-dark" onClick={() => this.setState({ onModal: false })} style={{ color: "black" }}>Cancel</button>
                            </div>
                            <div className="col-6">
                                <button type="button" className="btn btn-success" onClick={this.documentPut}>Confirm</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal
                    show={this.state.onModalBirthDayExample}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Body>
                        <div className="row row-justify-start">
                            <h4>{this.props.t("PageDocumentConfirmOCRData.id_card.example_dob_header")}</h4>
                            <p className="mt-3" style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.passport.example_dob_tile")}</p>
                            <p style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.passport.example_dob_ex1")}</p>
                            <p style={{ fontFamily: 'AIA Regular', color: 'black' }}>{this.props.t("PageDocumentConfirmOCRData.passport.example_dob_ex2")}</p>
                        </div>
                        <div className="row row-justify-center">
                            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ onModalBirthDayExample: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageShowOCRPassport);
export default connect(
    (state) => {
        const {
            allRetriesFailed,
            base64CroppedFirstPage,
            base64CroppedSecondPage,
            blobFirstPage,
            blobSecondPage,
            documentType,
            message,
            isRejected,
            processingRetriesCounter,
            retries,
            summaryScorePresent,
        } = state.documentCapture;
        return {
            ...state.changeMultiLanguage,
            ...state.configuration.extraConfig.featureFlags,
            ...state.documentCaptureConfiguration,
            ...state.documentCaptureSetData,
            ...state.dopaCheck,
            allRetriesFailed,
            base64CroppedFirstPage,
            base64CroppedSecondPage,
            blobFirstPage,
            blobSecondPage,
            documentType,
            message,
            isRejected,
            processingRetriesCounter,
            retries,
            summaryScorePresent,
        };
    },
    {
        go,
        nextStep,
        documentsGet,
        documentUpdate,
        setDataDOPACheck
    }
)(componentWithTranslation)