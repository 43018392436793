import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { go, nextPage } from 'logic/actions/navigation'
import { checkPartner } from 'logic/actions/api/application'
import { INTRO_CONFIRM_TO_START } from 'logic/enums/pages'
import { PARTNER } from 'logic/actions/application'
import { API_SET_APPLICATION_PARAMS } from "logic/actions/application";
import { applicationCreate, applicationContinue, applicationCheckResultCreate } from "logic/actions/api/application";
import { store } from 'logic/store'
import { logEvent } from 'logic/eventLogger'
// import { encryptUserId } from 'logic/encryptData'
// import { checkCameraAccess, checkCameraSupport, defaultHandleFailure, setDefaultCameras, recommendBrowser }  from "logic/cameraChecker"

import "./style.css";
// eslint-disable-next-line
import PageContent from 'Components/PageContent'
export class PageLoginFromPartner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pending: false,
            error: "",
            field: {},
            errors: {},
            usernameValid: false,
            cameraCount: 0
        }
        this.submit.bind(this);
        this.keyUpHandler.bind(this)
        this.handleChange.bind(this)
    }

    componentWillUnmount() {
        this.setState({ pending: false })
    }
    handleCreateSuccess = (result) => {
        let timestamp = Math.floor(new Date().getTime() / 1000.0)
        let userid = result.userId + '_' + timestamp
        // let tempUserId = encryptUserId(userid)
        let userInfo = {
            userId: userid
        }
        //   console.log(userInfo)
        this.props.applicationCreate(userInfo)
            .then((response) => {
                logEvent("Application_config-check-result[partner-login]", {
                    user: result,
                    pathname: "login",
                    response: response
                })

                this.props.applicationContinue({ applicationId: userInfo.userId })
                    .then((res) => {
                        let userInfo = {
                            pathname: "login",
                            userOfUse: PARTNER,
                            userId: userid,
                            nationality: "undefined",
                            showValid: "undefined",
                            insureAge : 'undefined',
                            risk: 'medium',
                            salesChannel: 'non-f2f',
                            occupation: 'undefined',
                            requiredField: {
                                idCard: 'undefined',
                                firstName: 'undefined',
                                lastName: 'undefined',
                                dateOfBirth: 'undefined',
                                gender: 'undefined',
                            }
                        }
                        store.dispatch({
                            type: API_SET_APPLICATION_PARAMS,
                            playload: userInfo
                        })
                        logEvent("Application_config-Continue[partner-login]", {
                            pathname: "login",
                            response: res
                        })
                        this.props.go(INTRO_CONFIRM_TO_START)
                    })
                    .catch((err) => {
                        logEvent("Application_config-Continue[partner-login]", {
                            pathname: "login",
                            response: err
                        })
                        this.setState({ error: err.toString(), pending: false })
                    })
            })
            .catch((err) => {
                logEvent("Application_config-check-result[partner-login]", {
                    user: result,
                    pathname: "login",
                    response: err
                })
                this.setState({ error: err.toString(), pending: false });
            })
    }
    submit = (e) => {
        e.preventDefault();
        if (!this.userid.value) {
            let errors = {};
            errors["username"] = this.props.t('PagePartnerLogin.field_not_null');
            this.setState({ usernameValid: false, errors: errors })
            return;
        } else if (!this.state.usernameValid) {
            return;
        }
        // console.log(this.userid.value)
        // let userInfo;
        this.setState({ pending: true, error: "" })
        this.props.checkPartner(this.userid.value)
            .then((response) => {
                logEvent("Check_user_name_partner[partner-login]", {
                    user: this.userid.value,
                    pathname: "login",
                    response: response
                })
                if (response.data.items.length > 0) {
                    this.handleCreateSuccess(response.data.items[0])
                } else {
                    this.setState({ pending: false, error: this.props.t('PagePartnerLogin.partner_invalid') })
                }
            })
            .catch((err) => {
                // logEvent("Check_user_name_partner[partner-login]", {
                //     user: this.userid.value,
                //     pathname: "login",
                //     response: err
                // })
                if (err.response && err.response.data && err.response.data.message) {
                    // console.log(err.response.data.message);
                    this.setState({ error: err.response.data.message, pending: false })
                } else {
                    this.setState({ error: err.toString(), pending: false })
                }
            })
    }
    handleValidate = () => {
        let fields = this.state.field;
        let errors = {};

        if (typeof fields["username"] !== "undefined") {
            if (fields["username"]) {
                if (!fields["username"].match(/^[0-9]{2}[_]\d{6}$/g)) {
                    errors["username"] = this.props.t('PagePartnerLogin.field_invalid');
                    this.setState({ usernameValid: false })
                } else {
                    errors["username"] = "";
                    this.setState({ usernameValid: true })
                }
            } else {
                errors["username"] = this.props.t('PagePartnerLogin.field_not_null');
                this.setState({ usernameValid: false })
            }
        }

        this.setState({ errors: errors, error: "" });
    }
    handleChange = (e, field) => {
        e.preventDefault();
        let fields = this.state.field
        fields[field] = e.target.value
        this.setState({ field: fields })
        this.handleValidate()
    }

    keyUpHandler(e) {
        e.preventDefault();
        if (e.key === "Enter") {
            this.submit(e)
        }
    }
    render() {
        const capture = this.state.pending ? "page-standard" : "";
        const containerClass = `container ${capture}`
        return (
            <PageContent toggleLoading={this.state.pending} progressBar={false} isSpinner={true}>
                <div className={containerClass}>
                    <h1>{this.props.t('PageIntroComfirmToStart.header')}</h1>
                    <form onSubmit={(e) => this.submit(e)}>
                        <div className="card-body" style={{ backgroundColor: "white" }}>
                            <div className="row row-justify-center mb-3">
                                <div className="col-md-10 mt-4">
                                    <label className="ml-2" style={{ fontSize: '0.7em' }}>{this.props.t('PagePartnerLogin.user_id')}</label>
                                    <input type="text"
                                        className="form-control"
                                        onKeyUp={(e) => this.keyUpHandler(e)}
                                        onChange={(e) => this.handleChange(e, "username")}
                                        disabled={this.state.pending}
                                        id="usernamePartner"
                                        ref={(input) => this.userid = input}
                                        placeholder=""
                                    // required
                                    ></input>
                                    <span className="mt-3 ml-1" style={{ color: "red" }}>{this.state.errors["username"]}</span>
                                    <span className="mt-3 ml-1" style={{ color: "red" }}>{this.state.error}</span>
                                </div>
                            </div>
                        </div>

                        <footer className="footer">
                            <button
                                disabled={this.state.pending}
                                type="submit"
                                value="submit"
                                className="btn btn-lg"
                                style={{ backgroundColor: "#D31145", color: "white", fontSize: "1em", marginTop: '0px' }}
                            >{this.props.t('PagePartnerLogin.btn_submit')}</button>
                            {/* {this.state.cameraCount > 0 &&
                             <label>Camera's detected: { this.state.cameraCount }</label>
                            } */}
                        </footer>
                    </form>
                </div>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageLoginFromPartner);
export default connect(
    (state) => {
        return {
            ...state.setApplicationParams
        }
    },
    {
        go,
        nextPage,
        checkPartner,
        applicationCreate,
        applicationContinue,
        applicationCheckResultCreate
    }
)(componentWithTranslation);
