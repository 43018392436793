/*global Daon */
/*eslint no-undef: "error"*/

import React, { Component } from "react";
import { func, number, instanceOf, bool } from "prop-types";
import { connect } from "react-redux";
import { clearMessages } from "logic/actions/faceLiveness3D";
import { canvasDrawingClass } from "./utils/canvasDrawingClass";

import { getLabelForResult, getLabelForMessage } from "./labels";
import frameOverlayGreen from "assets/frame_overlay_far_green.png";
import frameOverlayRed from "assets/frame_overlay_far_red.png";
import "./style.css";
import { withTranslation } from "react-i18next";
import { store } from "logic/store";


const UPDATE_TYPES = Daon.FaceLiveness3D.UPDATE_TYPES;

export class FaceLiveness3DUI extends Component {
  static propTypes = {
    width: number,
    height: number,
    onInitialized: func,
    onCameraStarted: func,
    instance: instanceOf(Object),
    retryCameraInit: bool,
  };

  state = {
    animationStart: false,
    finished: false,
    reinitCamera: false,
    isCounterActive: false,
  };

  constructor(props) {
    super(props);
    // this.counterTimer = this.counterTimer.bind(this);
    this.videoRef = new React.createRef();
    this.defaultCamera();
  }

  componentDidMount() {
      this.defaultCamera();
  }

  defaultCamera(){
    const camRef = store.getState().camera.frontCamera;
    // console.log("3DFL default frontCamera : " + camRef.cameraId);
    this.initializeCamera(camRef.cameraId).then(
        ( mediaStream ) => {
          this.videoRef.current.srcObject = mediaStream;
          this.props.instance.startCamera(this.videoRef.current).then(() => {
            this.props.onCameraStarted(this.videoRef.current);
          });

          this.canvas = this.refs.canvas;
          this.ctx = this.canvas.getContext("2d");
          this.text = this.refs.text;

          this.imageGreen = new Image();
          this.imageGreen.src = frameOverlayGreen;

          this.imageRed = new Image();
          this.imageRed.src = frameOverlayRed;

          this.canvasDrawingClass = new canvasDrawingClass(
              this.canvas,
              this.ctx,
              this.imageGreen
          );

          this.imageGreen.onload = () => {
            this.props.greenOverlayLoaded();
          };

          this.props.clearMessages();
        }, (err) => {

        }
    );
  }


  initializeCamera(cameraId){
    // console.log("initialiseCamera : " + cameraId );
    const constraints = {
      video: {deviceId: cameraId}
    }
    return navigator.mediaDevices.getUserMedia(constraints);
  }


  shouldComponentUpdate(prevProps) {
    return (
      prevProps.isReady !== this.props.isReady ||
      prevProps.message !== this.props.message ||
      prevProps.result !== this.props.result ||
      prevProps.retryCameraInit !== this.props.retryCameraInit ||
      prevProps.isRunning !== this.props.isRunning ||
      prevProps.finished !== this.props.finished ||
      prevProps.result !== this.props.result
    );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.message !== state.message) {
      return {
        message: props.message,
      };
    }

    if (props.isReady !== state.isReady) {
      return {
        isReady: props.isReady,
      };
    }

    if (props.result === "PASS" || props.result === "FAIL")
      return { result: props.result };

    if (props.isRunning && props.isRunning !== state.isRunning)
      return { isRunning: props.isRunning };

    if (props.finished && props.finished !== state.finished)
      return { finished: props.finished };

    if (props.retryCameraInit !== state.retryCameraInit)
      return { retryCameraInit: props.retryCameraInit };

    return null;
  }

  componentDidUpdate() {
    switch (this.state.message) {
      case UPDATE_TYPES.READY:
        break;
      case UPDATE_TYPES.AWAIT_RESULTS:
        this.setState({ finished: true });
        if (typeof this.props.onDone === "function") {
          this.props.onDone();
        }
        break;
      case UPDATE_TYPES.TOO_CLOSE:
        this.canvasDrawingClass.drawOutlineMin(this.imageGreen);
        break;
      case UPDATE_TYPES.TOO_FAR:
        this.canvasDrawingClass.drawOutlineMax(this.imageGreen);
        break;
      default:
        break;
    }

    if (this.state.retryCameraInit) this.reinitCamera();
  }

  render() {
    let instructionsStyle = this.state.isCounterActive
      ? "instructions-on-face-countdown"
      : "instructions-on-face";
    let instuctions;
    if (this.props.message === UPDATE_TYPES.HOLD) {
      instructionsStyle = "instructions-on-face-countdown";
      instuctions = this.state.counter;
    } else {
      instuctions =
        getLabelForResult(this.state.result, this.props.score) ||
        getLabelForMessage(this.props.message, UPDATE_TYPES, this.props.t);
    }

    return (
      <>
        <p className={instructionsStyle}>{instuctions}</p>
        <div className="ui-canvas-container">
          <video className="ui-canvas" ref={this.videoRef}></video>
          {!this.state.finished ? (
            <canvas
              style={{ zIndex: 10 }}
              className="ui-canvas-container ui-canvas"
              ref="canvas"
              width={parseInt(process.env.REACT_APP_USE_LIVENESS_RESOLUTION_HEIGHT) || 480}
              height={parseInt(process.env.REACT_APP_USE_LIVENESS_RESOLUTION_WIDTH) || 640}
            />
          ) : null}
        </div>
      </>
    );
  }
}
const componentWithTranslation = withTranslation()(FaceLiveness3DUI);
export default connect(
  (state) => {
    return { ...state.faceLiveness3D };
  },
  {
    clearMessages,
  }
)(componentWithTranslation);
