import React, { Component } from "react";
import { func } from "prop-types";
import registerDeviceUnlockEvent from "logic/events/registerDeviceUnlockEvent";

export default class DeviceUnlockHandler extends Component {
  static propTypes = {
    onDeviceUnlock: func.isRequired,
  };

  handleDeviceUnlocking = () => {
    if (typeof this.props.onDeviceUnlock === "function") {
      this.props.onDeviceUnlock();
    }
  };

  componentDidMount() {
    registerDeviceUnlockEvent();
    document.addEventListener("deviceunlock", this.handleDeviceUnlocking);
  }

  componentWillUnmount() {
    document.removeEventListener("deviceunlock", this.handleDeviceUnlocking);
  }

  render() {
    return <>{this.props.children}</>;
  }
}
