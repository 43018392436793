import {
    SET_STATUS_IMAGE_SECOND_TYPE,
    SET_IMAGE,
    RETRY_DOCUMENT_SECOND_TYPE_CAPTURE,
    SET_TYPE_SELECT_SECOND_DOCUMENT,
    RESET_SECOND_DOCUMENT,
    ROLL_BACK_IMAGE_STATUS,
} from "logic/actions/documentCaptureSecondType"


const defaultState = {
    blobSecondDocFirstPage: undefined,
    blobSecondDocSecondPage: undefined,
    base64CroppedSecondDocFirstPage: undefined,
    base64CroppedSecondDocSecondPage: undefined,
    tempBlobSecondDocFirstPage: undefined,
    tempBlobSecondDocSecondPage: undefined,
    tempBase64CroppedSecondDocFirstPage: undefined,
    tempBase64CroppedSecondDocSecondPage: undefined,
    documentQualityRetriesFront: undefined,
    documentQualityRetriesBack: undefined,
    firstPageRetries: 0,
    secondPageRetries: 0,
    statusFirstPage: false,
    statusSecondPage: false,
    secondDocumentType: undefined,
    idDocument: null
}

export function documentCaptureSecondType(state = defaultState, action) {
    switch (action.type) {
        case SET_IMAGE: {
            if (state.blobSecondDocFirstPage === undefined) {
                return {
                    ...state,
                    blobSecondDocFirstPage: action.payload.blob,
                    base64CroppedSecondDocFirstPage: action.payload.base64Cropped,
                    firstPageRetries: state.firstPageRetries + 1
                }
            }
            return {
                ...state,
                blobSecondDocSecondPage: action.payload.blob,
                base64CroppedSecondDocSecondPage: action.payload.base64Cropped,
                secondPageRetries: state.secondPageRetries + 1
            }
        }
        case SET_STATUS_IMAGE_SECOND_TYPE: {
            if (action.payload.position === "first") {
                return {
                    ...state,
                    statusFirstPage: action.payload.status
                }
            } else {
                return {
                    ...state,
                    statusSecondPage: action.payload.status
                }
            }
        }
        case RETRY_DOCUMENT_SECOND_TYPE_CAPTURE: {
            if (action.payload === "first") {
                return {
                    ...state,
                    tempBlobSecondDocFirstPage: state.blobSecondDocFirstPage,
                    tempBase64CroppedSecondDocFirstPage: state.base64CroppedSecondDocFirstPage,
                    blobSecondDocFirstPage: undefined,
                    base64CroppedSecondDocFirstPage: undefined,
                    statusFirstPage: false
                }
            }
            return {
                ...state,
                tempBlobSecondDocSecondPage: state.blobSecondDocSecondPage,
                tempBase64CroppedSecondDocSecondPage: state.base64CroppedSecondDocSecondPage,
                blobSecondDocSecondPage: undefined,
                base64CroppedSecondDocSecondPage: undefined,
                statusSecondPage: false
            }
        }
        case SET_TYPE_SELECT_SECOND_DOCUMENT: {
            return {
                ...state,
                secondDocumentType: action.payload.secondDocumentType,
                idDocument: action.payload.idDocument
            }
        }
        case RESET_SECOND_DOCUMENT: {
            return {
                ...state,
                blobSecondDocFirstPage: undefined,
                blobSecondDocSecondPage: undefined,
                base64CroppedSecondDocFirstPage: undefined,
                base64CroppedSecondDocSecondPage: undefined,
                tempBlobSecondDocFirstPage: undefined,
                tempBlobSecondDocSecondPage: undefined,
                tempBase64CroppedSecondDocFirstPage: undefined,
                tempBase64CroppedSecondDocSecondPage: undefined,
                documentQualityRetriesFront: undefined,
                documentQualityRetriesBack: undefined,
                firstPageRetries: 0,
                secondPageRetries: 0,
                statusFirstPage: false,
                statusSecondPage: false,
            }

        }
        case ROLL_BACK_IMAGE_STATUS: {
            if (action.payload === "page1") {
                return {
                    ...state,
                    statusFirstPage: false,
                    blobSecondDocFirstPage: state.tempBlobSecondDocFirstPage,
                    base64CroppedSecondDocFirstPage: state.tempBase64CroppedSecondDocFirstPage,
                    // firstPageRetries: state.firstPageRetries - 1
                }
            }
            return {
                ...state,
                statusSecondPage: false,
                blobSecondDocSecondPage: state.tempBlobSecondDocSecondPage,
                base64CroppedSecondDocSecondPage: state.tempBase64CroppedSecondDocSecondPage,
                // secondPageRetries: state.secondPageRetries - 1
            }
        }
        default:
            return state
    }
}