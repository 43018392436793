// import { setup as analyticsSetup } from "../integartions/firebaseAnalytics";
// import { setup as sentrySetup } from "../integartions/sentry";
// import { setup as datadogSetup } from "../integartions/datadog";
import { axiosInstance } from "./axiosInstance";
// import { steps } from "logic/steps";
// import PageGDPR from "Components/PageGDPR";

/**
 * GET https://{host}/{tenant}/config
 * @returns {Promise}
 * @example
 * request: N/A
 * response:
 * {
 *      "faceQualityHostname":"https://engdobs-facequality-qc.identityx-cloud.com",
 *      "documentQualityHostname":"https://engdobs-docquality-qc.identityx-cloud.com",
 *      "livenessPolicyName":"SampleWebLiveness",
 *      "evaluationPolicyName":"WebEvaluationPolicy",
 *      "gdprServiceHostname":"https://dobs-gdpr.identityx-cloud.com",
 *      "extraConfig":{...}
 * }
 */
export function getConfiguration() {
  return axiosInstance.get(`/config`).then((response) => {
    return response;
  });
}
