/*global Daon */
/*eslint no-undef: "error"*/
const actionPrefix = "FL3D_";

export const INSTANTIATED = actionPrefix + "INSTANTIATED";
export const UPDATE_MESSAGE = actionPrefix + "UPDATE_MESSAGE";
export const UPDATE_READY = actionPrefix + "UPDATE_READY";
export const UPDATE_ERROR = actionPrefix + "UPDATE_ERROR";
export const UPDATE_FACE_BOX = actionPrefix + "UPDATE_FACE_BOX";
export const CLEAR_MESSAGE = actionPrefix + "CLEAR_MESSAGE";
export const CLEAR_3DFL_STATE = actionPrefix + "CLEAR_3DFL_STATE";
export const CLEAR_READY = actionPrefix + "CLEAR_READY";

const DFQ_WASM = window.location.origin + "/DaonFaceQualityLite.wasm";
const TYPES = Daon.FaceLiveness3D.UPDATE_TYPES;


export const instantiateFaceLiveness3D = (isNewVersion) => (dispatch) => {
  const fl3dInstance = new Daon.FaceLiveness3D(DFQ_WASM);
  // TYPES = Daon.FaceLiveness3D.UPDATE_TYPES;
  return dispatch({
    type: INSTANTIATED,
    payload: fl3dInstance,
  });
};

export const onUpdate = (updateType, additional_data) => (dispatch) => {
  // console.log("1> On action ---> ", updateType)
  switch (updateType) {
    case TYPES.ERROR:
      if (additional_data) {
        console.log("3DFL returned an error: " + additional_data);
      } else {
        console.log("3DFL returned an error.");
      }
      return dispatch({
        type: UPDATE_ERROR,
        payload: additional_data,
      });
    case TYPES.READY:
      return dispatch({
        type: UPDATE_READY,
      });
    case TYPES.AWAIT_RESULTS:
    case TYPES.END_CAPTURE:
    case TYPES.NOT_CENTERED:
    case TYPES.TOO_FAR:
    case TYPES.TOO_CLOSE:
    case TYPES.HOLD:
    case TYPES.MOVE_CLOSER:
      return dispatch({
        type: UPDATE_MESSAGE,
        payload: updateType,
      });
    case TYPES.FACE_BOX:
      return dispatch({
        type: UPDATE_FACE_BOX,
        payload: updateType,
      });
    default:
      return;
  }
};

export function clearMessages() {
  return {
    type: CLEAR_MESSAGE,
  };
}

export function clear3dflState() {
  return {
    type: CLEAR_3DFL_STATE,
  };
}

export function clearReadyState() {
  return {
    type: CLEAR_READY
  }
}