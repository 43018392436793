/*eslint no-undef: "error"*/
import {
  NAVIGATION_GO,
} from "logic/actions/navigation";
import { steps } from "../steps";
const actionPrefix = "PASSIVE_LIVENESS_TEST_";
export const SET_LIVENESS_TEST_DATA = actionPrefix + "SET_LIVENESS_TEST_DATA";
export const FAILED_ATTEMPT = actionPrefix + "FAILED_ATTEMPT";
export const ERROR_MESSAGE = actionPrefix + "ERROR_MESSAGE";
export const failedAttempt = () => {
  return ({ type: FAILED_ATTEMPT })
}
export const retryLiveness = () => {
  return {
    type: NAVIGATION_GO,
    payload: steps[steps.length-2][0],
  }
}
export function errorMessage(payload) {
  return {
    type: ERROR_MESSAGE,
    payload,
  };
}