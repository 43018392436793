import { store } from "../../store";
// eslint-disable-next-line
import { setApplicationData } from "../application";
import { axiosInstance } from "logic/api";

import { delayedPromise } from "logic/delayedPromise";
import { applicationPostMock } from "../../../mocks/applicationPost"
import { responseSencode } from "mocks/applicationPut";
import { applicationSubmitPost } from "mocks/applicationSubmitPost"
// import { setExpireTimeOut, getExpireTimeOut, setStampTimeOut } from "../expireTimeOut";
import { encryptUserId } from "../../encryptData";

const flag = '' // if wanna use aes-gcm-256, please note flag = '?flag=aes_gcm_256'

/**
 * POST /{tenant}/rest/v1/accountapplications
 */
export const applicationCreate = (userInfo) => async (dispatch) => {
  let encryptedUserId = encryptUserId(userInfo.userId);
  const request = {
    user: { userId: encryptedUserId },
  };
  let promise = null
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(applicationPostMock)
  } else {
    // eslint-disable-next-line
    let result = await clearSession(encryptedUserId);
    promise = axiosInstance.post(`/rest/v1/accountapplications${flag}`, request);
  }
  return promise.then((response) => {
    dispatch(setApplicationData(response.data));
    return response;
  });
};

/**
 * POST /{tenant}/rest/v1/accountapplications
 */
export const applicationContinue = (userInfo) => (dispatch) => {
  var d = new Date();
  var n = d.toLocaleString("en-GB", {
    timeZone: "Asia/Bangkok",
    hour12: false,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }).replace(/\//g, '-').replace(" ", "T");
  let encryptedUserId = encryptUserId(userInfo.applicationId);
  let request = {
    id: encryptedUserId,
    user: {
      userId: encryptedUserId
    },
    referenceId: userInfo.applicationId + '@' + n
  };

  let promise = null
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(responseSencode)
  } else {
    promise = axiosInstance.post(`/rest/v1/accountapplications${flag}`, request);
  }
  return promise.then((response) => {
    dispatch(setApplicationData(response.data));
    return response;
  })
};

/**
 * PUT /{tenant}/rest/v1/accountapplications//submit?evaluationPolicyName={evaluationPolicyName}
 */
export const applicationSubmit = () => (dispatch) => {
  // eslint-disable-next-line
  const { application, configuration, documentCapture, dopaCheck, setApplicationParams } = store.getState();
  let policy;
  if (documentCapture.documentType === "ID_CARD") {
    policy = configuration.evaluationPolicyName
    if(dopaCheck.dopaTwoElementCheck) { policy = 'identity-two-element-check' }
  } else if (documentCapture.documentType === "PASSPORT") {
    policy = configuration.evaluationPassportPolicyName
  } else {
    policy = configuration.evaluationOtherPolicyName
  }
  if (process.env.REACT_APP_MOCK_API === "true") {
    return delayedPromise(applicationSubmitPost)
  } else {
    // var action;
    // if (window.dtrum) {
    //   action = window.dtrum.enterAction('10.Evaluation');
    // }
    let result = axiosInstance.post(`/DigitalOnBoardingServices/rest/v1/users/${application.user.id}/idchecks/${application.user.idCheck.id}/evaluation?evaluationPolicyName=${policy}`);    
    // if (window.dtrum) {
    //   window.dtrum.leaveAction(action);
    // }
    return result;
  }
};

export const applicationCheckResult = () => async (dispatch) => {
  const { application } = store.getState();
  let encryptedUserId = encryptUserId(application.id);
  const sessionRequest = {
    user: { userId: encryptedUserId }
  };
  // // eslint-disable-next-line
  let sessionPromise = axiosInstance.post(`/rest/v1/accountapplications${flag}`, sessionRequest);
  return Promise.all([sessionPromise]).then(([sessionResponse]) => {
    let promise = axiosInstance.post(`/rest/v1/accountapplications/checkResult?referenceId=${application.user.idCheck.referenceId}`, application);
    // let promise = axiosInstance.post(`/rest/v1/accountapplications/checkResult?referenceId=${application.user.idCheck.referenceId}`);
    return promise.finally((response) => {
      return response;
    });
  });
}

/*
* POST LOGIN USER FROM PARTNER
*/
export const checkPartner = (partnerId) => async (dispatch) => {
  let encryptedUserId = encryptUserId(partnerId);
  const sessionRequest = {
    user: { userId: encryptedUserId }
  };
  // eslint-disable-next-line
  let result = await clearSession(encryptedUserId);
  let sessionPromise = axiosInstance.post(`/rest/v1/accountapplications${flag}`, sessionRequest);

  return Promise.all([sessionPromise]).then(([sessionResponse]) => {
    let promise = axiosInstance.get(
      `/IdentityXServices/rest/v1/users?userId=${partnerId}&status=ACTIVE&limit=1`
    );
    return promise.finally((response) => {
      return response;
    });
  });
};

export const applicationCheckResultCreate = (userInfo) => async (dispatch) => {
  const { application } = store.getState();
  let encryptedUserId = encryptUserId(userInfo.userId);
  const sessionRequest = {
    user: { userId: encryptedUserId }
  };
  // eslint-disable-next-line
  let result = await clearSession(encryptedUserId);
  let sessionPromise = axiosInstance.post(`/rest/v1/accountapplications${flag}`, sessionRequest);
  return Promise.all([sessionPromise]).then(([sessionResponse]) => {
    // console.log("session response: " + JSON.stringify(sessionResponse))
    let promise = axiosInstance.post(`/rest/v1/accountapplications/checkResult`, application);
    return promise.finally((response) => {
      return response;
    });
  });
}

export const clearSession = (encryptedUserId) => {
  const sessionRequest = {
    user: { userId: encryptedUserId }
  };
  axiosInstance.defaults.headers.common['X-CSRF-TOKEN'] = '';
  let sessionPromise = axiosInstance.post(`/rest/v1/accountapplications${flag}`, sessionRequest);
  return sessionPromise.finally((res) => {
    return res
  })
}

/**
 * PUT /{tenant}/rest/v1/accountapplications/{applicationId}?smsCode={smsCode}
 */
export const applicationSendCode = (smsCode) => (dispatch) => {
  const { application } = store.getState();
  let promise = null
  if (process.env.REACT_APP_MOCK_API === "true") {
    promise = delayedPromise(responseSencode)
  } else {
    promise = axiosInstance.put(
      `/rest/v1/accountapplications/${application.id}?smsCode=${smsCode}`,
      application
    );
  }
  return promise.then((response) => {
    return response.data;
  });
};

export const applicationStatus = () => (dispatch) => {
  const { application } = store.getState();
  return axiosInstance.post(
    `/rest/v1/accountapplications/${application.id}/status`,
    application
  );
};

export const startSession = () => {
  return axiosInstance.get(`/startSession${window.location.search}`);
};
