import React, { useEffect, useState } from "react";
import PanelError from "../../PanelError";
import ButtonRestartApp from "../../common/ButtonRestartApp";
import PageContent from "../../PageContent";
import { useDispatch, connect } from "react-redux";
import { applicationContinue, applicationCheckResultCreate } from "logic/actions/api/application";
import { setApplicationParams } from "logic/actions/application";
import { go } from "logic/actions/navigation";
import { logEvent } from "logic/eventLogger";
import {
  LOGIN_FROM_PARTNER,
  SIGNED_UP,
  EXPIRE_LINK,
  // USER_MANUAL_INSERT_ID
} from "logic/enums/pages";
import {
  USER,
  IFRAME
} from 'logic/actions/application';

import { useTranslation } from "react-i18next";
import { generateUserID } from 'logic/userId';
import { sessionExpire } from 'logic/encryptData';
import { typeDecryptParams } from "logic/typeDecryptParams";


function ApplicationContinue(props) {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState("");
  const [textState, setTextState] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const parameters = new URLSearchParams(window.location.search);

    const pathname = window.location.pathname.split('/')[1]
    const start = parameters.get("start");
    const flag = parameters.get('flag')?.toLocaleUpperCase()
    if (pathname === "web" && start) {
      let result = typeDecryptParams(flag, start)
      let userInfo;
      if (result.componentID && result.referenceID) {
        userInfo = {
          userId: generateUserID(result.componentID, result.referenceID)
        }
        let expireStatus = false;
        if (result.createDate) {
          if (sessionExpire(result.createDate, result.componentID)) {
            go(EXPIRE_LINK)(dispatch)
            expireStatus = true;
            setLoaded(true);
          } else {
            expireStatus = false
          }
        }
        if (!expireStatus) {
          setTextState(t("PageContinue.check_result"))
          applicationCheckResultCreate(userInfo)(dispatch)
            .then((res) => {
              logEvent("Application_config-Check-Result[web]", {
                pathname: pathname,
                response: res
              })
              if (res.data.code === "0" && (res.data.ruleResult === "0" || res.data.ruleResult === "1")) {
                go(SIGNED_UP)(dispatch)
                setLoaded(true);
              } else {
                setTextState(t("PageContinue.app_continue"))
                applicationContinue({ applicationId: userInfo.userId })(dispatch)
                  .then((res) => {
                    let isParams = {
                      userOfUse: USER,
                      pathname: pathname,
                      componentID: result.componentID,
                      referenceID: result.referenceID,
                      nationality: result.nationality?.trim().toLowerCase() || "undefined",
                      createDate: result.createDate,
                      urlStart: window.location.href,
                      showValid: result.show ? result.show.toLowerCase().split(',') : "undefined",
                      insureAge : result.insureAge?.trim() || 'undefined',
                      risk: result.risk?.trim().toLowerCase() || 'medium',
                      salesChannel: result.salesChannel?.toLowerCase() || 'non-f2f',
                      occupation: result.occupation?.trim() || 'undefined',
                      requiredField: {
                        idCard: result.idCard?.trim() || 'undefined',
                        firstName: result.firstName?.trim() || 'undefined',
                        lastName: result.lastName?.trim() || 'undefined',
                        dateOfBirth: result.dateOfBirth?.trim() || 'undefined',
                        gender: result.gender?.trim() || 'undefined',
                      }
                    }
                    setApplicationParams(isParams)(dispatch)
                    logEvent("Application_config-Continue[web]", {
                      pathname: pathname,
                      response: res,
                      params: isParams
                    })
                    setLoaded(true);
                  })
                  .catch((err) => {
                    logEvent("Application_config-Continue[web]", {
                      pathname: pathname,
                      response: err
                    })
                    setError(err.toString())
                  })
              }
            })
            .catch((err) => {
              logEvent("Application_config-Check-Result[web]", {
                pathname: pathname,
                response: err
              })
              setTextState(t("PageContinue.app_continue"))
              applicationContinue({ applicationId: userInfo.userId })(dispatch)
                .then((res) => {
                  let isParams = {
                    userOfUse: USER,
                    pathname: pathname,
                    componentID: result.componentID,
                    referenceID: result.referenceID,
                    nationality: result.nationality?.trim().toLowerCase() || "undefined",
                    createDate: result.createDate,
                    urlStart: window.location.href,
                    showValid: result.show ? result.show.toLowerCase().split(',') : "undefined",
                    insureAge : result.insureAge?.trim() || 'undefined',
                    risk: result.risk?.trim().toLowerCase() || 'medium',
                    salesChannel: result.salesChannel?.toLowerCase() || 'non-f2f',
                    occupation: result.occupation?.trim() || 'undefined',
                    requiredField: {
                      idCard: result.idCard?.trim() || 'undefined',
                      firstName: result.firstName?.trim() || 'undefined',
                      lastName: result.lastName?.trim() || 'undefined',
                      dateOfBirth: result.dateOfBirth?.trim() || 'undefined',
                      gender: result.gender?.trim() || 'undefined',
                    }
                  }
                  setApplicationParams(isParams)(dispatch)
                  logEvent("Application_config-Continue[web]", {
                    pathname: pathname,
                    response: res,
                    params: isParams
                  })
                  setLoaded(true);
                })
                .catch((err) => {
                  logEvent("Application_config-Continue[web]", {
                    pathname: pathname,
                    response: err
                  })
                  setError(err.toString())
                })
            })
        }

      } else {
        setError("This URL is invalid.")
      }
    } else if (pathname === "iframe" && start) {
      let result = typeDecryptParams(flag, start)
      let userInfo;
      if (result.componentID && result.referenceID && result.callbackURL) {
        userInfo = {
          userId: generateUserID(result.componentID, result.referenceID)
        }
        let expireStatus = false;
        if (result.createDate) {
          if (sessionExpire(result.createDate, result.componentID)) {
            go(EXPIRE_LINK)(dispatch)
            expireStatus = true;
            setLoaded(true);
          } else {
            expireStatus = false
          }
        }
        if (!expireStatus) {
          setTextState(t("PageContinue.check_result"))
          applicationCheckResultCreate(userInfo)(dispatch)
            .then((res) => {
              logEvent("Application_config-Check-Result[iframe]", {
                pathname: pathname,
                response: res
              })
              if (res.data.code === "0" && (res.data.ruleResult === "0" || res.data.ruleResult === "1")) {
                go(SIGNED_UP)(dispatch)
                setLoaded(true);
              } else {
                setTextState(t("PageContinue.app_continue"))
                applicationContinue({ applicationId: userInfo.userId })(dispatch)
                  .then((res) => {
                    let tempUrl = window.location.href.split('=')[0];
                    let isParams = {
                      userOfUse: IFRAME,
                      pathname: pathname,
                      componentID: result.componentID,
                      referenceID: result.referenceID,
                      nationality: result.nationality?.trim().toLowerCase() || "undefined",
                      callbackURL: result.callbackURL,
                      createDate: result.createDate,
                      urlStart: tempUrl + "=" + result.newEncryptURL,
                      secondDevice: result.second ? result.second : "undefined",
                      showValid: result.show ? result.show.toLowerCase().split(',') : "undefined",
                      insureAge : result.insureAge?.trim() || 'undefined',
                      risk: result.risk?.trim().toLowerCase() || 'medium',
                      salesChannel: result.salesChannel?.toLowerCase() || 'non-f2f',
                      occupation: result.occupation?.trim() || 'undefined',
                      requiredField: {
                        idCard: result.idCard?.trim() || 'undefined',
                        firstName: result.firstName?.trim() || 'undefined',
                        lastName: result.lastName?.trim() || 'undefined',
                        dateOfBirth: result.dateOfBirth?.trim() || 'undefined',
                        gender: result.gender?.trim() || 'undefined',
                      }
                    }
                    setApplicationParams(isParams)(dispatch)
                    logEvent("Application_config-Continue[iframe]", {
                      pathname: pathname,
                      response: res,
                      params: isParams
                    })
                    setLoaded(true);
                  })
                  .catch((err) => {
                    logEvent("Application_config-Continue[iframe]", {
                      pathname: pathname,
                      response: err
                    })
                    setError(err.toString())
                  })
              }
            })
            .catch((err) => {
              logEvent("Application_config-Check-Result[iframe]", {
                pathname: pathname,
                response: err
              })
              setTextState(t("PageContinue.app_continue"))
              let tempUrl = window.location.href.split('=')[0];
              applicationContinue({ applicationId: userInfo.userId })(dispatch)
                .then((res) => {
                  let isParams = {
                    userOfUse: IFRAME,
                    pathname: pathname,
                    componentID: result.componentID,
                    referenceID: result.referenceID,
                    nationality: result.nationality?.trim().toLowerCase() || "undefined",
                    callbackURL: result.callbackURL,
                    createDate: result.createDate,
                    urlStart: tempUrl + "=" + result.newEncryptURL,
                    secondDevice: result.second ? result.second : "undefined",
                    showValid: result.show ? result.show.toLowerCase().split(',') : "undefined",
                    insureAge : result.insureAge?.trim() || 'undefined',
                    risk: result.risk?.trim().toLowerCase() || 'medium',
                    salesChannel: result.salesChannel?.toLowerCase() || 'non-f2f',
                    occupation: result.occupation?.trim() || 'undefined',
                    requiredField: {
                      idCard: result.idCard?.trim() || 'undefined',
                      firstName: result.firstName?.trim() || 'undefined',
                      lastName: result.lastName?.trim() || 'undefined',
                      dateOfBirth: result.dateOfBirth?.trim() || 'undefined',
                      gender: result.gender?.trim() || 'undefined',
                    }
                  }
                  setApplicationParams(isParams)(dispatch)
                  logEvent("Application_config-Continue[iframe]", {
                    pathname: pathname,
                    response: res,
                    params: isParams
                  })
                  setLoaded(true);
                })
                .catch((err) => {
                  logEvent("Application_config-Continue[iframe]", {
                    pathname: pathname,
                    response: err
                  })
                  setError(err.toString())
                })
            })
        }
      } else {
        setError("This URL is invalid.")
      }
    } else if (pathname === "webview" && start) {
      let result = typeDecryptParams(flag, start)

      let userInfo;
      if (result.componentID && result.referenceID) {
        userInfo = {
          userId: generateUserID(result.componentID, result.referenceID)
        }
        let expireStatus = false;
        if (result.createDate) {
          if (sessionExpire(result.createDate, result.componentID)) {
            go(EXPIRE_LINK)(dispatch)
            expireStatus = true;
            setLoaded(true);
          } else {
            expireStatus = false
          }
        }
        if (!expireStatus) {
          setTextState(t("PageContinue.check_result"))
          applicationCheckResultCreate(userInfo)(dispatch)
            .then((res) => {
              logEvent("Application_config-Check-Result[webview]", {
                pathname: pathname,
                response: res
              })
              if (res.data.code === "0" && (res.data.ruleResult === "0" || res.data.ruleResult === "1")) {
                go(SIGNED_UP)(dispatch)
                setLoaded(true);
              } else {
                setTextState(t("PageContinue.app_continue"))
                applicationContinue({ applicationId: userInfo.userId })(dispatch)
                  .then((res) => {
                    let isParams = {
                      userOfUse: USER,
                      pathname: pathname,
                      componentID: result.componentID,
                      referenceID: result.referenceID,
                      nationality: result.nationality?.trim().toLowerCase() || "undefined",
                      createDate: result.createDate,
                      urlStart: window.location.href,
                      showValid: result.show ? result.show.toLowerCase().split(',') : "undefined",
                      insureAge : result.insureAge?.trim() || 'undefined',
                      risk: result.risk?.trim().toLowerCase() || 'medium',
                      salesChannel: result.salesChannel?.toLowerCase() || 'non-f2f',
                      occupation: result.occupation?.trim() || 'undefined',
                      requiredField: {
                        idCard: result.idCard?.trim() || 'undefined',
                        firstName: result.firstName?.trim() || 'undefined',
                        lastName: result.lastName?.trim() || 'undefined',
                        dateOfBirth: result.dateOfBirth?.trim() || 'undefined',
                        gender: result.gender?.trim() || 'undefined',
                      }
                    }
                    setApplicationParams(isParams)(dispatch)
                    logEvent("Application_config-Continue[webview]", {
                      pathname: pathname,
                      response: res,
                      params: isParams
                    })
                    setLoaded(true);
                  })
                  .catch((err) => {
                    logEvent("Application_config-Continue[webview]", {
                      pathname: pathname,
                      response: err
                    })
                    setError(err.toString())
                  })
              }
            })
            .catch((err) => {
              logEvent("Application_config-Check-Result[webview]", {
                pathname: pathname,
                response: err
              })
              setTextState(t("PageContinue.app_continue"))
              applicationContinue({ applicationId: userInfo.userId })(dispatch)
                .then((res) => {
                  let isParams = {
                    userOfUse: USER,
                    pathname: pathname,
                    componentID: result.componentID,
                    referenceID: result.referenceID,
                    nationality: result.nationality?.trim().toLowerCase() || "undefined",
                    createDate: result.createDate,
                    urlStart: window.location.href,
                    showValid: result.show ? result.show.toLowerCase().split(',') : "undefined",
                    insureAge : result.insureAge?.trim() || 'undefined',
                    risk: result.risk?.trim().toLowerCase() || 'medium',
                    salesChannel: result.salesChannel?.toLowerCase() || 'non-f2f',
                    occupation: result.occupation?.trim() || 'undefined',
                    requiredField: {
                      idCard: result.idCard?.trim() || 'undefined',
                      firstName: result.firstName?.trim() || 'undefined',
                      lastName: result.lastName?.trim() || 'undefined',
                      dateOfBirth: result.dateOfBirth?.trim() || 'undefined',
                      gender: result.gender?.trim() || 'undefined',
                    }
                  }
                  setApplicationParams(isParams)(dispatch)
                  logEvent("Application_config-Continue[webview]", {
                    pathname: pathname,
                    response: res,
                    params: isParams
                  })
                  setLoaded(true);
                })
                .catch((err) => {
                  logEvent("Application_config-Continue[webview]", {
                    pathname: pathname,
                    response: err
                  })
                  setError(err.toString())
                })
            })
        }
      } else {
        setError("This URL is invalid.")
      }
    } else if (pathname === "login") {
      let isParams = {
        urlStart: window.location.href
      }
      setApplicationParams(isParams)(dispatch)
      go(LOGIN_FROM_PARTNER)(dispatch)
      setLoaded(true);
    } else {
      setError("This URL is invalid.")
    }
    // eslint-disable-next-line
  }, []);

  if (loaded) return props.children;

  if (error)
    return (
      <PageContent>
        <PanelError text={error} />
        {!props.runsWithIdp ? <ButtonRestartApp /> : null}
      </PageContent>
    );

  return (
    <PageContent>
      <header />
      <h1>{textState}</h1>
    </PageContent>
  );
}

export default connect((state) => state.configuration)(ApplicationContinue);
