/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, instanceOf } from "prop-types";
import { Lightbox } from "react-modal-image";
import PageContent from "../../../PageContent";
import BlobPreview from "../../common/BlobPreview";
import {
  retry,
  retryProcessing,
  resetAllRetriesFailed,
  resetingDocumentQualityRetries,
  createInstance,
  setCroppedFirstPage,
  setCroppedSecondPage,
  setDocumentQualityRetries
} from "logic/actions/documentCapture";
import {
  isSinglePaged,
  modalShowed,
  multiCaptureConfig,
  verticalDocumentsPresent,
  changeCamera,
} from "logic/actions/documentCaptureConfiguration";
import { go, nextStep } from "logic/actions/navigation";
import { documentsSave, pollForDocument } from "logic/actions/api/document";
import {
  PASSPORT,
  ID_CARD,
  COPY_ID_CARD,
  COPY_PASSPORT,
  MONK_ID_DOCUMENT,
  OFFICIAL_ID_DOCUMENT
} from "logic/enums/documentType";
import {
  DOCUMENT_CAPTURE_PORTRAIT,
  // eslint-disable-next-line
  VERIFY_IDENTITY,
  DOCUMENT_CAPTURE_OTHER_SELECTOR,
} from "logic/enums/pages";
import {
  ID_CARD as ID_CARD_ASPEC,
  A4_PORT as A4_PORT_ASPEC,
  // eslint-disable-next-line
  A4_LAND as A4_LAND_ASPEC
} from "logic/enums/documentAspectRatios";
import RoundIcon from "../../../RoundIcon";
import Icon from "../../../Icon";
// eslint-disable-next-line
import { drawOutherLines } from "logic/drawOutherLines";
import { isIOS, isMobile, isAndroid, isMac } from "logic/deviceType";
// import { blobToBase64 } from "logic/blobToBase64";
import "./style.css";
import { withTranslation } from "react-i18next";
// eslint-disable-next-line
import ZoomIcon from "assets/icons/16-24/Zoom.svg";
import * as S from "./PageDocumentCaptureConfirm.styles";
import { preview, selectDocumentType } from "logic/actions/documentCapture";
import { Modal } from "react-bootstrap";
import imgwarning from "../../../../assets/warning-icon.png";
import { startEventTimer, getTimePassed, getTimeStart } from "logic/stepTimers";
import { logEvent } from "logic/eventLogger";
import {
  width,
  height
} from "logic/documentResolution"

export class PageDocumentCaptureConfirm extends Component {
  static propTypes = {
    retry: func,
    base64CroppedFirstPage: string,
    base64CroppedSecondPage: string,
    blobFirstPage: string,
    blobSecondPage: string,
    documentType: string,
    documentsSave: func,
    configuration: instanceOf(Object),
    documentCapture: instanceOf(Object),
  };

  constructor(props) {
    super(props);
    this.state = {
      serverProcessingFailed: false,
      imageLoaded: false,
      locationAllowed: undefined,
      zoomDocument: false,
      buttonsDisabled: false,
      error: null,
      message: null,
      blobNotReject: "",
      width: 2560,
      height: 1440,
      modalErrType: false,
      summittedUncrop: false
    };
    if (!getTimePassed("document_confirm")) startEventTimer("document_confirm");

    this.canvasRef = new React.createRef();
    this.getBack.bind(this);
    this.submit.bind(this);


    if (this.props.documentCapture) {
      const { documentQualityHostname } = this.props.configuration;
      const url = documentQualityHostname + "/rest/v1/quality/assessments";
      const xWidth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH) <= 0 ? width : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH);
      const xHeigth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT) <= 0 ? height : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT);
      this.initDocumentCaptureSDK(
        url,
        xWidth,
        xHeigth,
        this.props.documentCapture.documentType,
        this.state.facingMode
      );
    }
  }

  formatConsoleDate = (date) => {
    let hour = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();
    let milliseconds = date.getMilliseconds();

    return '[' +
      ((hour < 10) ? '0' + hour : hour) +
      ':' +
      ((minutes < 10) ? '0' + minutes : minutes) +
      ':' +
      ((seconds < 10) ? '0' + seconds : seconds) +
      '.' +
      ('00' + milliseconds).slice(-3) +
      '] ';
  }

  componentDidMount() {
    if (this.props.documentQualityRetries) {
      this.props.setDocumentQualityRetries(
        parseInt(this.props.documentQualityRetries)
      );
    } else {
      this.props.setDocumentQualityRetries(2);
    }
    if (
      !this.props.base64CroppedFirstPage &&
      this.props.documentType !== "PASSPORT"
    ) {
      this.setState({ hintFacePresent: true });
      this.documentPagesNum(this.props.documentType);
    }
    if (this.props.documentProcessorRetries) {
      this.setState({
        documentProcessorRetries: parseInt(this.props.documentProcessorRetries),
      });
    } else {
      this.setState({ documentProcessorRetries: 3 });
    }

    if (!this.props.documentCaptureConfiguration.verticalDocumentsPresent) {
      this.changeDocumentOrientation("default");
    }
    this.drawOnCanvas();
    window.addEventListener("orientationchange", this.drawOnCanvas);
  }

  componentDidUpdate(_, prevState) {
    if (
      this.state.zoomDocument !== prevState.zoomDocument &&
      this.state.zoomDocument === false
    )
      setTimeout(() => {
        this.setState({ buttonsDisabled: false });
      }, 100);
  }

  componentWillUnmount() {
    window.removeEventListener("orientationchange", this.drawOnCanvas);
    this.props.documentCapture.docCaptureInstance.clearCapturedFrames();
    this.props.resetAllRetriesFailed();
  }

  static getDerivedStateFromProps({
    base64CroppedFirstPage,
    base64CroppedSecondPage,
    blobFirstPage,
    blobSecondPage,
    documentType
  }) {
    let tempBase64;
    if (documentType !== PASSPORT && documentType !== ID_CARD) {
      tempBase64 = blobSecondPage ? blobSecondPage : blobFirstPage
    } else {
      tempBase64 = base64CroppedSecondPage ? base64CroppedSecondPage : base64CroppedFirstPage;
    }
    // console.log(tempBase64)
    return {
      base64: tempBase64
    };
  }

  //=======================================================================================
  initDocumentCaptureSDK = (url, width, height, documentType, facingMode) => {
    if (true) {
      const config = {
        url,
        width,
        height,
        facingMode,
        captureType: "PORTRAIT",
        documentType: documentType === "PASSPORT" ? documentType : "ID_CARD",
      };
      this.props.createInstance(config);
    }
  };
  documentPagesNum = (type) => {
    switch (type) {
      case ID_CARD:
        this.props.isSinglePaged(true);
        break;
      case COPY_ID_CARD:
        this.props.isSinglePaged(true);
        break;
      case COPY_PASSPORT:
        this.props.isSinglePaged(true);
        break;
      case MONK_ID_DOCUMENT:
        this.props.isSinglePaged(true);
        break;
      case OFFICIAL_ID_DOCUMENT:
        this.props.isSinglePaged(true);
        break;
      default:
        this.props.isSinglePaged(false);
    }
  };
  changeDocumentOrientation(orientation) {
    if (orientation === "vertical") {
      switch (this.props.documentCapture.documentType) {
        case "ID_CARD": {
          this.props.selectDocumentType("ID_CARD_PORT");
          break;
        }
        case "DRIVERS_LICENCE": {
          this.props.selectDocumentType("DRIVERS_LICENCE_PORT");
          break;
        }
        default: {
          this.props.selectDocumentType("OTHER_DOCUMENT_PORT");
        }
      }
    } else if (orientation === "default") return;
    else {
      switch (this.props.documentCapture.documentType) {
        case "ID_CARD_PORT": {
          this.props.selectDocumentType("ID_CARD");
          break;
        }
        case "DRIVERS_LICENCE_PORT": {
          this.props.selectDocumentType("DRIVERS_LICENCE");
          break;
        }
        default: {
          this.props.selectDocumentType("OTHER_DOCUMENT");
        }
      }
    }
  }

  //=======================================================================================
  checkScreenOrientation = () => {
    let screenOrientation = undefined;
    try {
      if (isAndroid(navigator.userAgent) || isIOS(navigator.userAgent) || isMobile(navigator.userAgent) || isMac(navigator.userAgent)) {
        if (window.screen.orientation) {
          screenOrientation = window.screen.orientation.type.includes('landscape') ? true : false;
        } else {
          // iOS/safari
          screenOrientation = Math.abs(+window.orientation) === 90 ? true : false;
        }
      }
    }
    catch (err) { }

    return screenOrientation;
  }
  drawOnCanvas = () => {
    if (this.ctx) {
      this.ctx.clearRect(0, 0, this.ctx.canvas.width, this.ctx.canvas.height);
    }
    let isDocumentVertical = false
    try {
      if (isAndroid(navigator.userAgent) || isIOS(navigator.userAgent) || isMobile(navigator.userAgent) || isMac(navigator.userAgent)) {
        isDocumentVertical = true
      }
    }
    catch (err) { }
    setTimeout(() => {
      const wrapperDiv = document.getElementById("wrapperDiv");
      if (wrapperDiv) {
        let offsetHeight, offsetWidth;
        if (this.props.isRejected && isMobile(navigator.userAgent)) {
          offsetHeight = wrapperDiv.offsetWidth;
          offsetWidth = offsetHeight / (1920 / 1080);
        } else if (this.props.documentType === "PASSPORT") {
          offsetWidth = wrapperDiv.offsetWidth;
          offsetHeight = offsetWidth / (process.env.REACT_APP_USE_QUALITY_SERVER !== "true"
            && (window.innerWidth < window.innerHeight || this.checkScreenOrientation()) ? A4_PORT_ASPEC.aspectRatio : 1.4205);
          isDocumentVertical = process.env.REACT_APP_USE_QUALITY_SERVER !== "true"
            && (window.innerWidth < window.innerHeight || this.checkScreenOrientation()) ? true : false;
        } else if (this.props.documentType === "ID_CARD") {
          offsetWidth = wrapperDiv.offsetWidth;
          offsetHeight = offsetWidth / (process.env.REACT_APP_USE_QUALITY_SERVER !== "true"
            && (window.innerWidth < window.innerHeight || this.checkScreenOrientation()) ? A4_PORT_ASPEC.aspectRatio : ID_CARD_ASPEC.aspectRatio);
          isDocumentVertical = process.env.REACT_APP_USE_QUALITY_SERVER !== "true"
            && (window.innerWidth < window.innerHeight || this.checkScreenOrientation()) ? true : false;
        } else {
          if (!isDocumentVertical) {
            offsetWidth = wrapperDiv.offsetWidth;
            offsetHeight = offsetWidth / ID_CARD_ASPEC.aspectRatio;
          } else {
            offsetWidth = wrapperDiv.offsetWidth;
            offsetHeight = offsetWidth / A4_PORT_ASPEC.aspectRatio;
          }
        }
        // console.log(offsetWidth, offsetHeight)
        this.setState({ offsetWidth, offsetHeight });
        // let txt = 'width: ' + offsetWidth + ', height: ' + offsetHeight
        // this.setState({
        //   error: txt.toString()
        // })
        this.img = new Image();
        this.img.src = "data:image/jpeg;base64," + this.state.base64;
        // console.log(wrapperDiv.offsetWidth, wrapperDiv.offsetHeight)
        this.setState({ blobNotReject: this.state.base64 })
        if (this.refs.canvas) {
          const edgeOverlay = 0.05 * offsetWidth;
          this.canvas = this.refs.canvas;
          this.canvas.width = offsetWidth;
          this.canvas.height = offsetHeight;
          this.ctx = this.canvas.getContext("2d");
          // this.props.documentType !== "ID_CARD" &&
          // this.props.documentType !== "PASSPORT";
          drawOutherLines(
            this.ctx,
            edgeOverlay,
            offsetHeight,
            offsetWidth,
            this.img,
            this.props.allRetriesFailed,
            isDocumentVertical
          );
        }
      }
    }, 200);
  };

  showZoomedImage = () => {
    this.state.zoomDocument
      ? this.setState({ zoomDocument: false })
      : this.setState({ zoomDocument: true, buttonsDisabled: true });
  };

  submitUncroppedImages = async () => {
    // console.log(this.formatConsoleDate(new Date()), 'TIMING: - start submit uncropped image process');
    startEventTimer("document_uncrop_confirm")
    logEvent("document_uncrop_confirm", {
      response: null,
      start_time: window.performance.now()
    });
    await this.props
      .documentsSave(
        this.props.blobFirstPage,
        this.props.blobSecondPage,
        this.props.documentType
      )
      .then((response) => {
        // console.log(this.formatConsoleDate(new Date()), 'TIMING: - finish submit uncropped image process');
        logEvent("document_uncrop_confirm", {
          response: response,
          start_time: getTimeStart("document_uncrop_confirm"),
          end_time: window.performance.now() - getTimeStart("document_uncrop_confirm")
        });
        // if (
        //   response.data.processingStatus === "FAILED" &&
        //   this.props.processingRetriesCounter <
        //   this.state.documentProcessorRetries
        // ) {
        //   const retriesLeft =
        //     this.state.documentProcessorRetries -
        //     this.props.processingRetriesCounter;
        //   this.setState({
        //     serverProcessingFailed: true,
        //     pending: false,
        //     error: this.props.t(
        //       "PageDocumentCaptureConfirm.error_reading_doc",
        //       {
        //         retriesLeft,
        //       }
        //     ),
        //   });
        // } else 
        if (this.props.documentType === PASSPORT || this.props.documentType === ID_CARD) {
          this.setState({
            summittedUncrop: true
          })
          if (response.data.processingStatus === "QUEUED"
            && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
          ) {
            this.props.pollForDocument(response.data)
              .then((res) => {
                const id = res.data.id.toString();
                this.processResponse(res.data, id);
              })
              .catch(() => {
                this.setState({
                  serverProcessingFailed: true,
                  modalErrType: true,
                  pending: false
                });
              })
          } else if (response.data.classification !== "Identity Card"
            && this.props.documentCapture.documentType === "ID_CARD"
            && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
          ) {
            this.setState({
              serverProcessingFailed: true,
              modalErrType: true,
              pending: false
            });
          } else if ((response.data.classification && !(response.data.classification.indexOf("Passport") >= 0))
            && this.props.documentCapture.documentType === "PASSPORT"
            && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
          ) {
            this.setState({
              serverProcessingFailed: true,
              modalErrType: true,
              pending: false,
            });
          } else {
            this.props.nextStep();
          }
        } else {
          this.props.nextStep();
        }
      })
      .catch((err) => {
        // console.log(this.formatConsoleDate(new Date()), 'TIMING: - error in submit uncropped image process');
        logEvent("document_uncrop_confirm", {
          response: err,
          start_time: getTimeStart("document_uncrop_confirm"),
          end_time: window.performance.now() - getTimeStart("document_uncrop_confirm")
        });
        if (err.response && err.response.data && err.response.data.message) {
          this.setState({
            serverProcessingFailed: true,
            pending: false,
            error: err.response.data.message,
          });
        } else {
          this.setState({
            error: err.toString(),
            pending: false,
          });
        }
      });
  };

  processResponse = (response, id) => {
    let tmpData;
    if (response.data !== undefined) {
      tmpData = response.data;
    } else {
      tmpData = response;
    }
    logEvent("document_confirm_queue_success", {
      response: response,
      start_time: window.performance.now() - getTimeStart("document_confirm")
    })
    const data = tmpData;
    if (data.id === undefined) {
      data.id = id;
    }
    if (data === undefined) {
      this.setState({
        serverProcessingFailed: true,
        modalErrType: true,
        pending: false
      });
    } else if (data.processingStatus === "FAILED" && this.props.processingRetriesCounter < this.state.documentProcessorRetries) {
      if (this.props.isRejected) {
        const retriesLeft =
          this.state.documentProcessorRetries -
          this.props.processingRetriesCounter;
        this.setState({
          serverProcessingFailed: true,
          pending: false,
          error: this.props.t("PageDocumentCaptureConfirm.error_reading_doc", { retriesLeft })
        });
      } else if (!this.state.summittedUncrop) {
        this.submitUncroppedImages();
      } else {
        this.setState({
          serverProcessingFailed: true,
          modalErrType: true,
          pending: false,
        });
      }
    } else if (data.processingStatus === "QUEUED"
      && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
    ) {
      this.props.pollForDocument(data)
        .then((response) => {
          const id = response.data.id.toString();
          this.processResponse(response.data, id);
        })
        .catch(() => {
          this.setState({
            serverProcessingFailed: true,
            modalErrType: true,
            pending: false
          });
        })
    } else if (data.classification !== "Identity Card"
      && this.props.documentCapture.documentType === "ID_CARD"
      && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
    ) {
      this.setState({
        serverProcessingFailed: true,
        modalErrType: true,
        pending: false
      });
    } else if ((data.classification && !(data.classification.indexOf("Passport") >= 0))
      && this.props.documentCapture.documentType === "PASSPORT"
      && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
    ) {
      this.setState({
        serverProcessingFailed: true,
        modalErrType: true,
        pending: false,
      });
    } else {
      this.props.nextStep();
    }
  }

  submit = async () => {
    // console.log(this.formatConsoleDate(new Date()), 'TIMING: - start submit image process');
    this.setState({ pending: true });
    if (this.props.documentType !== PASSPORT && this.props.documentType !== ID_CARD) {
      this.submitUncroppedImages();
    } else if (
      this.props.documentType === PASSPORT ||
      this.props.base64CroppedSecondPage ||
      this.props.singlePaged === true
    ) {
      startEventTimer("document_confirm")
      logEvent("document_confirm", {
        response: null,
        start_time: window.performance.now()
      })
      await this.props
        .documentsSave(
          this.props.base64CroppedFirstPage,
          this.props.base64CroppedSecondPage,
          this.props.documentType
        )
        .then((response) => {
          // console.log(this.formatConsoleDate(new Date()), 'TIMING: - finish submit image process');
          logEvent("document_confirm_save_success", {
            response: response,
            start_time: window.performance.now() - getTimeStart("document_confirm")
          })
          if (response.data.processingStatus === "FAILED" && this.props.processingRetriesCounter < this.state.documentProcessorRetries) {
            if (this.props.isRejected) {
              const retriesLeft = this.state.documentProcessorRetries - this.props.processingRetriesCounter;
              this.setState({
                serverProcessingFailed: true,
                pending: false,
                error: this.props.t("PageDocumentCaptureConfirm.error_reading_doc", { retriesLeft }),
              });
            } else this.submitUncroppedImages();
          } else if (response.data.processingStatus === "QUEUED"
            && (this.props.documentCapture.documentType === "ID_CARD" || this.props.documentCapture.documentType === "PASSPORT")
            && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
          ) {
            this.processResponse(response, response.data.id)
          } else {
            if (response.data.classification !== "Identity Card"
              && this.props.documentCapture.documentType === "ID_CARD"
              && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
            ) {
              this.setState({
                serverProcessingFailed: true,
                modalErrType: true,
                pending: false,
              });
            } else if ((response.data.classification && !(response.data.classification.indexOf("Passport") >= 0))
              && this.props.documentCapture.documentType === "PASSPORT"
              && process.env.REACT_APP_USE_DOCUMENT_TYPE_CHECK === "true"
            ) {
              this.setState({
                serverProcessingFailed: true,
                modalErrType: true,
                pending: false,
              });
            } else {
              this.props.nextStep();
            }
          }
        })
        .catch((err) => {
          // console.log(this.formatConsoleDate(new Date()), 'TIMING: - error submit image process');
          logEvent("document_confirm_save_fail", {
            response: err,
            start_time: window.performance.now() - getTimeStart("document_confirm")
          })
          if (
            err.response &&
            err.response.data.httpStatus === "400" &&
            err.response.data.message
          ) {
            this.submitUncroppedImages();
          } else {
            this.setState({
              error: err.toString(),
              pending: false,
            });
            this.addNewConditionForIdCardPopup()
          }
        });
    } else {
      this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
    }
  };

  retryCapturing = () => {
    this.props.retryProcessing();
    this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
  };

  retryNewCapturing = () => {
    this.props.retryProcessing();
    if(this.props.processingRetriesCounter < 2) {
      this.setState({ modalErrType: false })
    } else {
      this.props.go(DOCUMENT_CAPTURE_OTHER_SELECTOR);
    }
  };

  addNewConditionForIdCardPopup = () => {
    if(
      this.props.documentCapture.documentType === "ID_CARD" &&
      this.props.processingRetriesCounter < 3 &&
      this.props.retries < 3
    ) {
      this.setState({ modalErrType: true })
    }
  }

  getBack = () => {
    this.props.retry();
    this.props.base64CroppedSecondPage
      ? this.props.resetingDocumentQualityRetries("back")
      : this.props.resetingDocumentQualityRetries("front");
    this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
  };

  render() {
    let text;
    if (this.props.isRejected && !this.props.allRetriesFailed) {
      text = this.props.t('PageDocumentCaptureConfirm.error_please_retake')
    } else if (!this.props.allRetriesFailed) {
      text = this.props.t('PageDocumentCaptureConfirm.error_ensure_visible')
    } else {
      text = this.props.t('PageDocumentCaptureConfirm.error_unable_check_quality')
    }
    const styleBtn = this.state.pending ? "#D3CAC3" : "#D31145";

    return (
      <PageContent toggleLoading={this.state.pending} progressBar={true} isSpinner={true}>
        <h1>{this.props.t("PageDocumentCaptureConfirm.title")}</h1>

        <div
          id="wrapperDiv"
          className="panel border-gray bg-row"
          style={{ position: "relative" }}
        >
          {!this.props.isRejected ? (
            <div>
              <canvas
                style={{
                  visibility: this.state.buttonsDisabled ? "hidden" : "visible",
                }}
                ref="canvas"
                width={this.state.offsetWidth}
                height={this.state.offsetHeight}
              ></canvas>
              <S.SquaredIcon onClick={this.showZoomedImage}>
                <img
                  src={ZoomIcon}
                  alt=""
                  style={{ marginLeft: "6px", marginTop: "0px" }}
                />
              </S.SquaredIcon>
            </div>
          ) : (
            <BlobPreview
              showZoomedImage={this.showZoomedImage}
              disabled={this.state.buttonsDisabled}
              blob={
                this.props.base64CroppedSecondPage ||
                this.props.base64CroppedFirstPage
              }
              width={this.state.offsetWidth}
              height={this.state.offsetHeight}
            />
          )}

          {this.state.zoomDocument && (
            <Lightbox
              medium={this.img.src}
              onClose={this.showZoomedImage}
              hideZoom={true}
              hideDownload={true}
            ></Lightbox>
          )}
        </div>
        {(this.props.allRetriesFailed || this.props.isRejected) &&
          this.props.summaryScorePresent ? (
          <div className="panel  custom-panel flex-row" style={{ marginBottom: '40%' }}>
            <RoundIcon color="yellow" type="preview">
              <Icon name="Info" color="white" />
            </RoundIcon>
            <div className="panel-content">
              <S.MessageParagraph>{text}</S.MessageParagraph>
            </div>
          </div>
        ) : this.props.allRetriesFailed || this.props.isRejected ? (
          <div className="panel custom-panel flex-row" style={{ marginBottom: '40%' }}>
            <RoundIcon color="red" type="preview">
              <Icon name="Rejected" color="white" />
            </RoundIcon>
            <div className="panel-content">
              <S.MessageParagraph>{text}</S.MessageParagraph>
            </div>
          </div>
        ) : (
          <div className="panel  custom-panel flex-row" style={{ marginBottom: '40%' }}>
            <RoundIcon color="blue" type="preview">
              <Icon name="Info" color="white" />
            </RoundIcon>
            <div className="panel-content">
              <S.MessageParagraph>{text}</S.MessageParagraph>
            </div>
          </div>
        )}
        {/* <div className="row row-justify-center">
          <div className="message-box-error">{this.props.message}</div>
        </div> */}


        <footer className="footer">
          {/* <p className="error">{this.state.error}</p> */}
          {(!this.props.isRejected || this.props.allRetriesFailed) &&
            !this.state.serverProcessingFailed ? (
            <div>
              <button
                disabled={this.state.buttonsDisabled || this.state.pending}
                className="btn btn-link bi-chevron-compact-left"
                style={{ color: "#333D47", fontSize: "1em", marginTop: '0px' }}
                onClick={this.getBack}
              >
                {this.props.t("PageDocumentCaptureConfirm.btn_take_new_picture")}
              </button>
              <br />
              <button
                disabled={this.state.buttonsDisabled || this.state.pending}
                className="btn"
                style={{ backgroundColor: styleBtn, fontSize: "1em", marginTop: '0px', marginBottom: this.props.param.pathname === "webview" ? "5%" : "0px" }}
                // style={{ backgroundColor: styleBtn, fontSize: "1em", marginTop: '0px' }}
                onClick={this.submit}
              >
                {this.props.t("PageDocumentCaptureConfirm.btn_doc_is_readable")}
              </button>
            </div>
          ) : this.state.serverProcessingFailed ? (
            <button
              disabled={this.state.buttonsDisabled || this.state.pending}
              className="btn btn-link bi-chevron-compact-left"
              style={{ color: "#333D47", fontSize: "1em", marginTop: '0px' }}
              onClick={this.getBack}
            >
              {this.props.t("PageDocumentCaptureConfirm.btn_retry_capturing")}
            </button>
          ) : (
            <button
              disabled={this.state.buttonsDisabled || this.state.pending}
              className="btn btn-link bi-chevron-compact-left"
              style={{ color: "#333D47", fontSize: "1em", marginTop: '0px' }}
              onClick={this.getBack}
            >
              {this.props.t("PageDocumentCaptureConfirm.btn_take_new_picture")}
            </button>
          )}
        </footer>
        <Modal
          show={this.state.modalErrType}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="row row-justify-center">
              <img
                style={{ width: '30%', height: 'inherit' }}
                src={imgwarning}
                alt="warning"
              />
            </div>
            <div className="row">
              <a className="mt-5" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCaptureConfirm.error_type_document')}</a>
            </div>
            <div className="row row-justify-center">
              <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={this.retryNewCapturing}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
            </div>
          </Modal.Body>
        </Modal>
      </PageContent>
    );
  }
}
const componentWithTranslation = withTranslation()(PageDocumentCaptureConfirm);
export default connect(
  (state) => {
    const {
      allRetriesFailed,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      blobFirstPage,
      blobSecondPage,
      documentType,
      message,
      isRejected,
      processingRetriesCounter,
      retries,
      summaryScorePresent,
    } = state.documentCapture;

    const { featureFlags } = state.configuration.extraConfig;

    return {
      param: state.setApplicationParams,
      allRetriesFailed,
      base64CroppedFirstPage,
      base64CroppedSecondPage,
      blobFirstPage,
      blobSecondPage,
      documentType,
      message,
      isRejected,
      processingRetriesCounter,
      retries,
      summaryScorePresent,
      documentCapture: state.documentCapture,
      configuration: state.configuration,
      singlePaged: state.documentCaptureConfiguration.singlePaged,
      docCountryOptions: state.documentCaptureConfiguration.docCountryOptions,
      documentCaptureConfiguration: state.documentCaptureConfiguration,
      documentProcessorRetries: featureFlags
        ? featureFlags.documentProcessorRetries
        : undefined,
    };
  },
  {
    retry,
    documentsSave,
    nextStep,
    go,
    retryProcessing,
    resetAllRetriesFailed,
    resetingDocumentQualityRetries,
    isSinglePaged,

    createInstance,
    setCroppedFirstPage,
    setCroppedSecondPage,
    preview,
    selectDocumentType,
    modalShowed,
    multiCaptureConfig,
    verticalDocumentsPresent,
    changeCamera,
    pollForDocument,
    setDocumentQualityRetries
  }
)(componentWithTranslation);
