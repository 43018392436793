import React from "react";
import { connect } from "react-redux";
import { func } from "prop-types";

import { previous } from "logic/actions/navigation";
import "./ButtonBack.styles.jsx";
import LeftArrow from "../../../assets/icons/16-24/Vector.svg";
import { useTranslation } from "react-i18next";
import * as S from "./ButtonBack.styles";

ButtonBack.propTypes = {
  previous: func,
  onClick: func,
};

export function ButtonBack({ onClick, previous }) {
  //fallback to onclick action
  const { t } = useTranslation();
  return (
    <S.BackButton onClick={onClick || previous}>
      <img src={LeftArrow} alt="" />
      <span style={{fontFamily: "AIA Bold"}} className="mr-0">{t("Common.back")}</span>
    </S.BackButton>
  );
}

export default connect(null, { previous })(ButtonBack);
