import { decryptParams, decryptParamsByGCM, decryptParamsByGCM256 } from 'logic/encryptData'

export const typeDecryptParams = (flag, data) => {
  if (flag === 'GCM') {
    return decryptParamsByGCM(data)
  }
  else if (flag === 'AES_GCM_256') {
    return decryptParamsByGCM256(data)
  }
  return decryptParams(data)
}