export const APPROVED = "PageApproved";
export const DECLINED = "PageDeclined";
export const CONTINUE_ON_DESKTOP = "PageContinueOnDesktop";
export const DOCUMENT_CAPTURE_PORTRAIT = "PageDocumentCapturePortrait";
export const DOCUMENT_CAPTURE_LANDSCAPE = "PageDocumentCaptureLandscape";
export const DOCUMENT_CAPTURE_LOCK = "PageDocumentCaptureLock";
export const DOCUMENT_CAPTURE_CONFIRM = "PageDocumentCaptureConfirm";
export const DOCUMENT_CONFIRM_OCR = "PageDocumentConfirmOCRData";
export const DOCUMENT_CAPTURE_INSTRUCTIONS = "PageDocumentCaptureInstructions";
export const FACE_CAPTURE = "PageFaceCapture";
export const FACE_CAPTURE_CONFIRM = "PageFaceCaptureConfirm";
export const FACE_CAPTURE_INSTRUCTIONS = "PageFaceCaptureInstructions";
export const LIVENESS_TEST = "PageLivenessTest";
export const LIVENESS_TEST_RETRY = "PageLivenessTestRetry";
export const PASSIVE_LIVENESS_TEST_RETRY = "PagePassiveLivenessTestRetry";
export const REVIEWING = "PageReviewing";
export const THANK_YOU = "PageThankYou";
export const VALIDATE_PHONE = "PageValidatePhone";
export const VERIFICATION_CODE = "PageVerificationCode";
export const VERIFY_IDENTITY = "PageVerifyIdentity";
export const SENT_TO_MOBILE = "PageSentToMobile";
export const FACE_LIVENESS_3D = "PageFaceLiveness3D";
export const OLD_FACE_LIVENESS_3D = "OldPageFaceLiveness3D";
export const INSTRUCTIONS_FACE_LIVENESS_3D = "PageInstructionsFaceLiveness3D";
export const OLD_INSTRUCTIONS_FACE_LIVENESS_3D = "OldPageInstructionsFaceLiveness3D";
export const INSTRUCTION_VIDEO_FACE_LIVENESS_3D = "PageInstructionVideo3DFL";
export const INSTRUCTION_VIDEO_DOCUMENT = "PageInstructionVideoDocument";
export const INSTRUCTION_VIDEO_SELFIE = "PageInstructionVideoSelfie";
export const DOCUMENT_COUNTRY_SELECTOR = "PageDocumentCountrySelector";
export const INTRO_PAGE = "PageIntroScreen";
export const PAGE_SESSION_EXPIRED = "PageSessionExpired";

export const INSTRUCTIONS_CLR = "PageInstructionsCLR";

export const GDPR = "PageGDPR";

// =================[NEW CUSTOMIZATION]=================

export const INTRO_CONFIRM_TO_START = "PageIntroConfirmToStart";
export const GUIDANCE = "PageGuidance";
export const CHECK_DOPA = "PageCheckDOPA";
export const DOCUMENT_CAPTURE_SECOND_TYPE = "PageDocumentCaptureType2";
export const DOCUMENT_CAPTURE_OTHER_SELECTOR = "PageDocumentCaptureOtherSelect";
export const DOCUMENT_CAPTURE_TYPE2_SELECT = "PageDocumentCaptureType2Select";
export const LOGIN_FROM_PARTNER = "PageLoginFromPartner";
export const SHOW_OCR_IDENTITY_CARD = "PageShowOCRIdentityCard";
export const SHOW_OCR_OTHER_DOCS = "PageShowOCROtherDocs";
export const SHOW_OCR_PASSPORT = "PageShowOCRPassport";
export const USER_MANUAL_INSERT_ID = "PageUserManualID";
export const NEW_DOCUMENT_CAPTURE_INSTRUCTIONS = "NewPageDocumentCaptureInstructions";
export const SIGNED_UP = "PageSignedUp";
export const EXPIRE_LINK = "PageExpireLink";