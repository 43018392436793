import { steps } from "logic/steps";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./style.css";

function ApplicationProgressBar(props) {
  const { t } = useTranslation();
  const numberOfSteps = steps.length - 2;
  const progress = [];
  for (let i = 1; i <= numberOfSteps; i++) {
    let label;
    if (i === 1) {
      label = t("Common.progress_bar_step_label", {
        step:
          props.step < 1
            ? 1
            : props.step > numberOfSteps
            ? numberOfSteps
            : props.step,
        total: numberOfSteps,
      });
    }
    let bsPrefix =
      props.step === i || (props.step < 1 && i=== 1)
        ? "light"
        : props.step < i
        ? "empty"
        : props.step > numberOfSteps
        ? "light"
        : null;
    progress.push(
      <ProgressBar
        variant={props.step > i ? "#22A8DA" : null}
        bsPrefix={bsPrefix}
        label={label}
        now={100 / numberOfSteps}
        key={i - 1}
      />
    );
  }
  return <ProgressBar className="mainBar">{progress}</ProgressBar>;
}

export default connect((state) => {
  return {
    step: state.navigation.step,
  };
})(ApplicationProgressBar);
