/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
// import './style.css'
// eslint-disable-next-line
import PageContent, { PAGE_CONTENT_TYPE_INSTRUCTIONS } from "Components/PageContent";
// eslint-disable-next-line
import { INTRO_CONFIRM_TO_START } from '../../../logic/enums/pages'
import { go, nextPage } from "logic/actions/navigation";
import { withTranslation } from "react-i18next";
import { challengeCreate } from "logic/actions/api/liveness";
import {
    applicationSendCode,
    applicationContinue,
} from "logic/actions/api/application";
// eslint-disable-next-line
import { setExpireTimeOut } from "logic/actions/expireTimeOut";

export class PageGuidance extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pending: false
        };
        this.submit.bind(this);
    }
    submit = (e) => {
        // this.props.setExpireTimeOut(new Date().getTime())
        this.props.nextPage();
    }
    render() {
        // const isActive = false
        return (
            <PageContent toggleLoading={this.state.pending} progressBar={false}>
                <div className="container-fluid" style={{marginBottom: '20%'}}>
                    <h1>{this.props.t("PageGuidance.header")}</h1>
                    {/* {this.props.t("Common.start")} */}
                    <div className="card-body">
                        <div className="row-justify-start">
                            <div className="col mb-3">
                                <h6 style={{ fontFamily: 'AIA Regular' }}>{this.props.t("PageGuidance.title")}</h6>
                            </div>
                        </div>
                        <div className="row-justify-start">
                            <div className="col">
                                <h5 style={{ fontFamily: 'AIA Regular' }}><b>{this.props.t("PageGuidance.title1")}</b></h5>
                                <a style={{ fontFamily: 'AIA Regular' }}>{this.props.t("PageGuidance.title1_text1")}
                                    {this.props.t("PageGuidance.title1_text2")}</a>
                            </div>
                        </div>
                        <div className="row-justify-start">
                            <div className="col mt-3 mb-2 title-text">
                                <a style={{ fontFamily: 'AIA Regular' }}>{this.props.t("PageGuidance.title1_text3")}
                                    {this.props.t("PageGuidance.title1_text4")}</a>
                            </div>
                        </div>
                        <hr />
                        <div className="row-justify-start">
                            <div className="col mt-3 mb-2">
                                <h5 style={{ fontFamily: 'AIA Regular' }}><b>{this.props.t("PageGuidance.title2")}</b></h5>
                                <a style={{ fontFamily: 'AIA Regular' }}>
                                    {this.props.t("PageGuidance.title2_text1")}
                                    {this.props.t("PageGuidance.title2_text2")}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <button type="button" className="btn btn-lg" onClick={this.submit} style={{ backgroundColor: "#D31145", color: "white", fontSize: '1em', marginTop: '0px' }}>{this.props.t("PageGuidance.button_start")}</button>
                </footer>
            </PageContent>
        )
    }
}
const componentWithTranslation = withTranslation()(PageGuidance);
export default connect(
    null,
    {
        go,
        nextPage,
        challengeCreate,
        applicationSendCode,
        applicationContinue,
        setExpireTimeOut
    }
)(componentWithTranslation);