import {
  SWITCHED_TO_MOBILE_PHONE,
  SWITCHED_TO_MOBILE_TRANSACTION,
  API_SET_APPLICATION_DATA,
  API_SET_APPLICATION_PARAMS
} from "../actions/application";
const defaultState = {};

export function application(state = defaultState, action) {
  if (action.type === API_SET_APPLICATION_DATA) {
    return action.payload;
  } else if (action.type === SWITCHED_TO_MOBILE_PHONE) {
    return {
      ...state,
      isSwitchedToMobile: true,
      phoneFromDesktopSession: action.payload,
    };
  } else if (action.type === SWITCHED_TO_MOBILE_TRANSACTION) {
    return {
      ...state,
      isSwitchedToMobile: true,
      transactionFromDesktopSession: action.payload,
    };
  } else {
    return state;
  }
}

export function setApplicationParams(state = defaultState, action) {
  // console.log(action)
  switch (action.type) {
    case API_SET_APPLICATION_PARAMS:
      return {
        ...state,
        ...action.playload
      };
    default:
      return state;
  }
}
