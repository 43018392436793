import {
  MULTI_CAPTURE_CONFIG,
  CAMERA_RESOLUTION,
  COUNTRY_SELECTOR,
  IS_SINGLE_PAGED,
  CAMERA_CHANGED,
  MODAL_SHOWED,
  COUNTRY_NUM,
  VERTICAL_DOCUMENTS_PRESENT,
  FLIP_CAMERA
} from "../actions/documentCaptureConfiguration";

const defaultState = {
  numOfFrames: undefined,
  initialCaptureDelay: undefined,
  captureInterval: undefined,
  width: undefined,
  height: undefined,
  dialCountryNum: "other",
  docCountryOptions: {
    id: undefined,
    dl: undefined,
    singlePaged: false,
  },
  cameraId: "",
  count: 0,
  mirror: false,
  verticalDocumentsPresent: false,
  facingMode: ""
};

export function documentCaptureConfiguration(state = defaultState, action) {
  switch (action.type) {
    case MODAL_SHOWED: {
      return {
        ...state,
        count: state.count + 1,
      };
    }
    case CAMERA_CHANGED: {
      return {
        ...state,
        cameraId: action.payload.cameraId,
        facingMode: action.payload.facingMode
      };
    }
    case IS_SINGLE_PAGED: {
      return {
        ...state,
        singlePaged: action.payload,
      };
    }
    case VERTICAL_DOCUMENTS_PRESENT: {
      return {
        ...state,
        verticalDocumentsPresent: action.payload,
      };
    }
    case COUNTRY_SELECTOR: {
      return {
        ...state,
        docCountryOptions: action.payload,
      };
    }
    case COUNTRY_NUM: {
      return {
        ...state,
        dialCountryNum: action.payload,
      };
    }
    case CAMERA_RESOLUTION: {
      return {
        ...state,
        width: action.payload.width,
        height: action.payload.height,
      };
    }

    case MULTI_CAPTURE_CONFIG: {
      return {
        ...state,
        numOfFrames: action.payload.numOfFrames,
        captureInterval: action.payload.captureInterval,
        initialCaptureDelay: action.payload.initialCaptureDelay,
      };
    }
    case FLIP_CAMERA: {
      return {
        ...state,
        mirror: action.payload.mirror
      }
    }
    default:
      return state;
  }
}
