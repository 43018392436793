import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { enumerateCameraDevices } from "logic/getCameraIds";
import { useTranslation } from "react-i18next";
import { setCameraList, setSelectedCamera } from "logic/reducers/camera";
import { useDispatch, useSelector } from "react-redux";
import * as S from "./LinkSelectCamera.style";
import { document_changed_camera, logEvent } from "logic/eventLogger";

export default function LinkSelectCamera({ changeCamera }) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState(undefined);
  const cameras = useSelector((state) => state.camera.cameras);
  const dispatch = useDispatch();

  function onSelect(device) {
    if (typeof changeCamera === "function") changeCamera(device.deviceId);
    setSelectedDevice(device);
    dispatch(setSelectedCamera(device));
    setShow(false);
    logEvent(document_changed_camera);
  }

  useEffect(() => {
    if (!cameras.length) {
      enumerateCameraDevices().then((enumeratedCameras) => {
        dispatch(setCameraList(enumeratedCameras));
      });
    }
  }, [cameras, dispatch]);

  return (
    <span>
      <Modal show={show}>
        <Modal.Header>
          <Modal.Title>{t("Common.select_camera")}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {cameras.length &&
            cameras.map((device, i) => (
              <S.DeviceItemStyle
                key={device.deviceId}
                onClick={() => {
                  onSelect(device);
                }}
                selected={device === selectedDevice}
              >
                {t("Common.select_camera_list_item", {
                  id: i + 1,
                  label: device.label === undefined ? "": device.label,
                })}
              </S.DeviceItemStyle>
            ))}
        </Modal.Body>

        <Modal.Footer>
          <button
            className="btn blue"
            onClick={() => setShow(false)}
            variant="secondary"
          >
            {t("Common.close")}
          </button>
        </Modal.Footer>
      </Modal>
      <S.ButtonLink onClick={() => setShow(true)}>
        {t("Common.change_camera")}
      </S.ButtonLink>
      &nbsp; {t("Common.or")}
    </span>
  );
}
