// import AES from 'crypto-js/aes'
// import ENC from 'crypto-js/enc-utf8'
import CryptoJS from 'crypto-js'
import forge from 'node-forge';


export function decryptParams(param) {
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
    let arrayParam = []
    try {
        let setData = param.toString().replace(/xMl3Jk/g, '+').replace(/Por21Ld/g, '/').replace(/Ml32/g, '=');
        var key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
        var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
        var decrypted = CryptoJS.AES.decrypt(setData, key, { iv: iv }).toString(CryptoJS.enc.Utf8);
        let temp = decrypted.split('&')

        temp.forEach(element => {
            const data = element.split('=')
            let o = {}
            o[data[0]] = data[1]
            arrayParam = {
                ...o,
                ...arrayParam
            }
        });

        if (arrayParam.componentID === "90" || arrayParam.componentID === "91") {
            let temp = decrypted + "&second=yes";
            arrayParam = {
                newEncryptURL: encryptURL(temp),
                ...arrayParam
            }
        }
        // console.log(arrayParam)
        return arrayParam;
    }
    catch {
        arrayParam = []
        return arrayParam
    }
}

export function decryptParamsByGCM(param) {
  try {
    let decrypted = decryptedData(param, forge.util.hexToBytes(process.env.REACT_APP_SECRET_KEY))
    let temp = decrypted.split('&')

    let paramsObj = temp.reduce((p, c) => {
      let data = c.split('=')
      p[data[0]] = data[1]
      if (p.componentID === "90" || p.componentID === "91") { 
        let newurl = decrypted + "&second=yes";
        p['newEncryptURL'] = encryptURLByGCM(newurl)
      }
      return p
    }, {})
    return paramsObj

  } catch {
    return []
  }
}

export function decryptParamsByGCM256(param) {
  try {
    let decrypted = decryptedData(param, process.env.REACT_APP_SECRET_KEY, true)
    let temp = decrypted.split('&')

    let paramsObj = temp.reduce((p, c) => {
      let data = c.split('=')
      p[data[0]] = data[1]
      if (p.componentID === "90" || p.componentID === "91") { 
        let newurl = decrypted + "&second=yes";
        p['newEncryptURL'] = encryptURLByGCM256(newurl)
      }
      return p
    }, {})
    return paramsObj

  } catch {
    return []
  }
}

export const encryptURL = (text) => {
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
    var key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
    var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]);
    // console.log(iv)
    var encrypted = CryptoJS.AES.encrypt(text, key, {
        iv: iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    // console.log(encrypted)

    let data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
    let result = data.toString().replace(/[+_]/g, 'xMl3Jk').replace(/[/_]/g, 'Por21Ld').replace(/[=_]/g, 'Ml32');

    return result;
}

export const encryptURLByGCM = (data) => {
  return encryptedData(data, forge.util.hexToBytes(process.env.REACT_APP_SECRET_KEY)).replace(/[+_]/g, 'xMl3Jk').replace(/[/_]/g, 'Por21Ld').replace(/[=_]/g, 'Ml32');
}

export const encryptURLByGCM256 = (data) => {
  return encryptedData(data).replace(/[+]/g, '-').replace(/[/]/g, '_').replace(/=+$/, '');
}

export function sessionExpire(date, component) {
    date = date.replace(/[ :/._,;~`'"?=+]/g, "");
    let tempDate = date.substring(0, 4) //yyyy
        + '-' + date.substring(4, 6) //MM
        + '-' + date.substring(6, 8) //dd
    if (date.substring(8, 10) && date.substring(10, 12)) {
        tempDate = tempDate + 'T' + date.substring(8, 10) //hh
            + ':' + date.substring(10, 12) + ':59'; //mm:ss
    } else {
        tempDate = tempDate + 'T23:59:59'
    }
    let date1 = new Date(tempDate);
    // console.log(tempDate)
    // console.log(date1)
    let date2 = new Date();
    // alert(date1 + "+++++" + date2)
    if (component === "61") {
        date1.setDate(date1.getDate() + 7)
    } else if (component === "80" || component === "81") {
        date1.setDate(date1.getDate() + 2)
    } else {
        date1.setDate(date1.getDate() + 1)
    }
    if (date1 < date2) {
        return true
    }
    return false
}

export const encryptUserId = (data) => {
	// return encryptedData(data).replace(/[+]/g, '-').replace(/[/]/g, '_').replace(/[=]/g, '') // if wanna use aes-gcm-256 to encryptUserId.
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY
  var key = CryptoJS.enc.Utf8.parse(SECRET_KEY);
  var iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00, 0x00]);
  var encrypted = CryptoJS.AES.encrypt(data, key, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
  });
  var result = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return result
}

export const encryptedData = (datamsg, key = process.env.REACT_APP_SECRET_KEY) => {
  const iv = forge.random.getBytesSync(16);
	let cipher = forge.cipher.createCipher('AES-GCM', key)
	cipher.start({
		iv: iv,
	});
	cipher.update(forge.util.createBuffer(forge.util.encodeUtf8(datamsg)));
	cipher.finish()
	let encrypted = cipher.output;
	const tag = cipher.mode.tag;
	let result = btoa(iv + encrypted.data + tag.data)
	return result
}

export const decryptedData = (datamsg, key = process.env.REACT_APP_SECRET_KEY, gcm256) => {
	datamsg = gcm256 ? urlsafeB64Decode(datamsg.toString()) :
            datamsg.toString().replace(/xMl3Jk/g, '+').replace(/Por21Ld/g, '/').replace(/Ml32/g, '=')
	datamsg = atob(datamsg)
	const iv = datamsg.slice(0, 16)
	const tag = datamsg.slice(-16)
	const data = datamsg.slice(16, datamsg.length - 16)
	var decipher = forge.cipher.createDecipher('AES-GCM', key)
	decipher.start({
		iv: iv,
		tag: tag
	})
	decipher.update(forge.util.createBuffer(data))
	const pass = decipher.finish()
	if (pass) {
		return decipher.output.toString()
	}
}

export const urlsafeB64Decode = (decodeData) => {
  decodeData = decodeData.replace(/[-]/g, '+').replace(/[_]/g, '/');
  while (decodeData.length % 4 !== 0) {
    decodeData += '=';
  }
  return decodeData;
}
