import React, { Component } from "react";
import { connect } from "react-redux";
import { go, nextStep } from "logic/actions/navigation";
import "./style.css";
import { withTranslation } from "react-i18next";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import { PASSPORT, ID_CARD } from "logic/enums/documentType";

import PageShowOCRIdentityCard from "./TypeCommon/PageShowOCRIdentityCard";
import PageShowOCRPassport from "./TypeCommon/PageShowOCRPassport";
import PageShowOCROtherDocs from "./TypeCommon/PageShowOCROtherDocs";
export class PageDocumentConfirmOCRDData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documentId: null
    };

    if (!getTimePassed("document_ocr_confirm")) startEventTimer("document_ocr_confirm");
  }
  UNSAFE_componentWillMount() {
    // console.log(this.props.documentType)
  }
  render() {
    return (
      <React.Fragment>
        {this.props.documentType === ID_CARD ? (
          <PageShowOCRIdentityCard />
        ) : (
          this.props.documentType === PASSPORT ? (
            <PageShowOCRPassport />) : (
            <PageShowOCROtherDocs />
          ))}
      </React.Fragment>
    );
  }
}
const componentWithTranslation = withTranslation()(PageDocumentConfirmOCRDData);
export default connect(
  (state) => {
    return {
      ...state.documentCapture,
      ...state.configuration.extraConfig.featureFlags
    };
  },
  {
    go,
    nextStep,
  }
)(componentWithTranslation);
