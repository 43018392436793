import { CREATE_INSTANCE, SET_TEST_DATA, SET_VIDEO_ID, FIRST_TIME_INIT, ERROR_MESSAGE, FAILED_ATTEMPT } from "../actions/livenessTest";

const defaultState = {
  livenessTestData: undefined,
  livenessVideoId: undefined,
  retriesLeft: 0,
  retryCount: 0,
  videoId: undefined,
  firstTimeInitialized: false,
  errorMessage: ""
}

export function livenessTest(state = defaultState, action) {
  switch (action.type) {
    case CREATE_INSTANCE:
      return {
        ...state,
        CLRCaptureInstance: action.payload
      }
    case SET_TEST_DATA:
      return {
        ...state,
        livenessTestData: action.payload,
        retriesLeft:
          action.payload.retriesLeft !== undefined
            ? action.payload.retriesLeft
            : action.payload.items[0].maxRetries,
        videoId: action.payload.videoId,
      };
    case SET_VIDEO_ID:
      return {
        ...state,
        livenessTestData: {
          ...state.livenessTestData,
          videoId: action.payload
        },
        videoId: action.payload
      }
    case FAILED_ATTEMPT:
      return {
        ...state,
        retriesLeft: state.retriesLeft !== undefined ? state.retriesLeft - 1 : 10,
        retryCount: state.retryCount + 1
      }
    case FIRST_TIME_INIT:
      return {
        ...state,
        firstTimeInitialized: true
      }
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    default:
      return state;
  }
}

// case SET_TEST_DATA:
//       return {
//         ...state,
//         livenessTestData: action.payload,
//         retriesLeft:
//           action.payload.retriesLeft !== undefined
//             ? action.payload.retriesLeft
//             : action.payload.items[0].maxRetries,
//         videoId: action.payload.videoId,
//       };

