const actionPrefix = "DOCUMENT_CAPTURE_CONFIGURATION";

export const MULTI_CAPTURE_CONFIG = actionPrefix + "MULTI_CAPTURE_CONFIG";
export const CAMERA_RESOLUTION = actionPrefix + "CAMERA_RESOLUTION";
export const COUNTRY_SELECTOR = actionPrefix + "COUNTRY_SELECTOR";
export const IS_SINGLE_PAGED = actionPrefix + "IS_SINGLE_PAGED";
export const CAMERA_CHANGED = actionPrefix + "CAMERA_CHANGED";
export const MODAL_SHOWED = actionPrefix + "MODAL_SHOWED";
export const COUNTRY_NUM = actionPrefix + "COUNTRY_NUM";
export const VERTICAL_DOCUMENTS_PRESENT = actionPrefix + "VERTICAL_DOCUMENTS_PRESENT";
export const FLIP_CAMERA = actionPrefix + "FLIP_CAMERA"

export function multiCaptureConfig(payload) {
  return {
    type: MULTI_CAPTURE_CONFIG,
    payload: {
      numOfFrames: payload.numOfFrames,
      initialCaptureDelay: payload.initialCaptureDelay,
      captureInterval: payload.captureInterval,
    },
  };
}
export function cameraResolution(payload) {
  return {
    type: CAMERA_RESOLUTION,
    payload: {
      width: payload.width,
      height: payload.height,
    },
  };
}

export function documentSidesSelector(payload) {
  return {
    type: COUNTRY_SELECTOR,
    payload,
  };
}
export function verticalDocumentsPresent(payload) {
  return {
    type: VERTICAL_DOCUMENTS_PRESENT,
    payload,
  };
}
export function isSinglePaged(payload) {
  return { type: IS_SINGLE_PAGED, payload };
}
export function changeCamera(payload) {
  return {
    type: CAMERA_CHANGED,
    payload,
  };
}
export function flipCamera(payload) {
  return {
    type: FLIP_CAMERA,
    payload,
  };
}
export function modalShowed() {
  return {
    type: MODAL_SHOWED,
  };
}
export const countrySelected = (payload) => (dispatch) => {
  dispatch({
    type: COUNTRY_NUM,
    payload,
  });
};