import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import PageContent from "../PageContent";
import PanelError from "Components/PanelError";
import ButtonRestartApp from "../common/ButtonRestartApp";

function PageSessionExpired({ runsWithIdp }) {
  const { t } = useTranslation();
  const text = runsWithIdp
    ? t("ApiMessaging.session_expired_oidc")
    : t("ApiMessaging.session_expired");
  return (
    <PageContent>
      <PanelError text={text} />
      {!runsWithIdp ? <ButtonRestartApp /> : null}
    </PageContent>
  );
}

export default connect((state) => {
  return {
    ...state.configuration,
  };
})(PageSessionExpired);
