import React, { Component } from "react";
import { string } from "prop-types";
// eslint-disable-next-line
import { Image } from "load-image-react";
import { LazyLoadImage } from "react-lazy-load-image-component"
import "./style.css";
import { withTranslation } from "react-i18next";

class BlobPreview extends Component {
  static propTypes = {
    className: string,
  };

  state = {};

  static getDerivedStateFromProps(nextProps, prevProps) {
    if (nextProps.width !== prevProps.width) {
      return { width: nextProps.width, height: nextProps.height };
    }
    try {
      if (nextProps.blob !== prevProps.blob) {
        return { blobUrl: URL.createObjectURL(nextProps.blob) };
      }
    } catch {
      return { blobUrl: "data:image/jpeg;base64," + nextProps.blob };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log(this.state.blobUrl)
    if (prevState.blobUrl !== this.state.blobUrl) {
      URL.revokeObjectURL(prevState.blobUrl);
    }
  }

  render() {
    const wrapperClassName = this.props.flipX ? "wrapper flip-x" : "wrapper";
    if (this.props.captureMethod === "WEBRTC") {
      return (
        <img
          ref={this.imageRef}
          src={this.state.blobUrl}
          alt={this.props.t("Common.blob_preview_img_alt")}
          className={this.props.className}
        />
      );
    } else {
      return (
        <div onClick={this.props.showZoomedImage} className={wrapperClassName}>
          {this.state.height && this.state.width ? (
            // <Image
            //   src={this.state.blobUrl}
            //   loadOptions={{
            //     orientation: true,
            //     maxWidth: this.state.width,
            //     maxHeight: this.state.height,
            //   }}
            // />
            <LazyLoadImage
              src={this.state.blobUrl}
              style={{
                maxWidth: this.state.width,
                maxHeight: this.state.height
              }}
            />
          ) : null}
        </div>
      );
    }
  }

  componentWillUnmount() {
    if (this.state.blobUrl) URL.revokeObjectURL(this.state.blobUrl);
  }
}

export default withTranslation()(BlobPreview);
