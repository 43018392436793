import {
  NAVIGATION_GO,
  NAVIGATION_NEXT,
  NAVIGATION_NEXT_STEP,
  NAVIGATION_PREVIOUS,
} from "logic/actions/navigation";
import {
  previous as navigationPrevious,
  nextStep,
  nextPage,
  // eslint-disable-next-line
  populateLiveness,
  populateDocument,
  steps,
} from "../steps";
import {
  RETRY as LIVENESS_RETRY,
  // eslint-disable-next-line
  SET_TEST_DATA,
  FAILED_ATTEMPT,
} from "../actions/livenessTest";
import {
  DOCUMENT_CAPTURE_CONFIRM,
  // eslint-disable-next-line
  FACE_CAPTURE_CONFIRM,
  FACE_CAPTURE,
  // eslint-disable-next-line
  DOCUMENT_CAPTURE_INSTRUCTIONS,
  LIVENESS_TEST_RETRY,
  NEW_DOCUMENT_CAPTURE_INSTRUCTIONS,
  // eslint-disable-next-line
  OLD_INSTRUCTIONS_FACE_LIVENESS_3D
} from "../enums/pages";
import {
  RETRY as DOCUMENT_CAPTURE_RETRY,
  PREVIEW as DOCUMENT_CAPTURE_PREVIEW,
  SELECT_DOC_TYPE
} from "../actions/documentCapture";
import {
  RETRY as FACE_CAPTURE_RETRY,
  // eslint-disable-next-line
  PREVIEW as FACE_CAPTURE_PREVIEW,
} from "../actions/faceCapture";
import { IDRND_FACE } from "logic/enums/livenessTestType";

export const navigationMiddleware = (store) => (nextMiddleware) => (action) => {
  let state;
  // console.log("middleware here => ", action.type)
  switch (action.type) {
    // case SET_TEST_DATA:
    //   if (
    //     action.payload &&
    //     action.payload.items &&
    //     action.payload.items[0].type
    //   ) {
    //     state = store.getState();
    //     const new3dfl =
    //       (state.configuration.extraConfig &&
    //         state.configuration.extraConfig.featureFlags.new3DFL === "true") ||
    //         state.configuration.extraConfig.featureFlags.new3DFL
    //         ? true
    //         : false;
    //     const selfie3dfl =
    //       (state.configuration.extraConfig &&
    //         state.configuration.extraConfig.featureFlags.selfie3dfl === "true") ||
    //         state.configuration.extraConfig.featureFlags.selfie3dfl
    //         ? true
    //         : false;
    //     populateLiveness(action.payload.items[0].type, new3dfl, selfie3dfl);
    //   }
    //   break;
    case SELECT_DOC_TYPE:
      populateDocument(action.payload)
      break;
    case NAVIGATION_NEXT:
      state = store.getState();
      store.dispatch({
        type: NAVIGATION_GO,
        payload: nextPage(state.navigation.page),
      });
      return;
    case NAVIGATION_NEXT_STEP:
      state = store.getState();
      store.dispatch({
        type: NAVIGATION_GO,
        payload: nextStep(state.navigation.page),
      });
      return;
    case NAVIGATION_PREVIOUS:
      state = store.getState();
      store.dispatch({
        type: NAVIGATION_GO,
        payload: navigationPrevious(state.navigation.page),
      });
      return;
    case DOCUMENT_CAPTURE_PREVIEW:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: DOCUMENT_CAPTURE_CONFIRM,
      });
      break;
    case DOCUMENT_CAPTURE_RETRY:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: NEW_DOCUMENT_CAPTURE_INSTRUCTIONS,
      });
      break;
    // case FACE_CAPTURE_PREVIEW:
    //   nextMiddleware(action);
    //   store.dispatch({
    //     type: NAVIGATION_GO,
    //     payload: OLD_INSTRUCTIONS_FACE_LIVENESS_3D,
    //   });
    //   return;
    case FACE_CAPTURE_RETRY:
      store.dispatch({
        type: NAVIGATION_GO,
        payload: FACE_CAPTURE,
      });
      break;
    case LIVENESS_RETRY:
      store.dispatch({
        type: NAVIGATION_GO,
        //TODO@Ljubo: Make this more flexible
        payload: steps[steps.length-2][0],
      });
      break;
    case FAILED_ATTEMPT:
      nextMiddleware(action);
      state = store.getState();
      if (
        state.livenessTest &&
        state.livenessTest.livenessTestData &&
        state.livenessTest.livenessTestData.items[0].type !== IDRND_FACE
      ) {
        store.dispatch({
          type: NAVIGATION_GO,
          payload: LIVENESS_TEST_RETRY,
        });
      }
      return;
    default:
  }
  nextMiddleware(action);
};
