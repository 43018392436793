import { SET_LIVENESS_TEST_DATA, FAILED_ATTEMPT, ERROR_MESSAGE } from '../actions/passiveLivenessTest'
const defaultState = {
  livenessTestData: undefined,
  retriesLeft: 0,
  retryCount: 0,
  errorMessage: "",
}

export function passiveLivenessTest(state = defaultState, action) {
  switch (action.type) {
    case SET_LIVENESS_TEST_DATA:
      return {
        ...state,
        livenessTestData: action.payload,
        retriesLeft: action.payload.items[0].maxRetries || 5,
      };
    case FAILED_ATTEMPT:
      return {
        ...state,
        retriesLeft: state.retriesLeft - 1,
        retryCount: state.retryCount + 1
      }
    case ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload
      }
    default:
      return state;
  }
}