export class canvasDrawingClass {
  constructor(canvas, ctx, imageGreen) {
    this.ctx = ctx;
    this.canvas = canvas;
    this.imageGreen = imageGreen;
  }

  drawOutlineMin(img) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let scale = 0.5;
    if (this.canvas.width < this.canvas.height) {
      scale = 0.75;
    }

    let dx = (this.canvas.width - img.width * scale) / 2;
    let dy = (this.canvas.height - img.height * scale * 1.4) / 2;

    this.ctx.globalAlpha = 0.7;
    this.ctx.drawImage(
      img,
      dx,
      dy,
      img.width * scale,
      img.height * scale * 1.4
    );
  }

  drawOutlineMax(img) {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    let scale = 0.85;
    if (this.canvas.width < this.canvas.height) {
      scale = 1.1;
    }

    let dx = (this.canvas.width - img.width * scale) / 2;
    let dy = (this.canvas.height - img.height * scale * 1.4) / 2;

    this.ctx.globalAlpha = 0.7;
    this.ctx.drawImage(
      img,
      dx,
      dy,
      img.width * scale,
      img.height * scale * 1.4
    );
  }
}
