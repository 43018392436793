import React, { Component } from "react";
import { connect } from "react-redux";
import { func, string, bool, object, instanceOf } from "prop-types";

import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "../../../PageContent";
import FaceLiveness3DUI from "../FaceLiveness3DUI";
import DeviceUnlockHandler from "../../common/DeviceUnlockHandler";
import "./style.css";
import {
  challengeTemplateUpload,
  challengeCreate,
} from "logic/actions/api/liveness";
import { arrayBufferToBase64 } from "logic/arrayBufferToBase64";
import faceLivenessResolution from "logic/faceLivenessResolution";
import {
  instantiateFaceLiveness3D,
  onUpdate,
  clear3dflState,
} from "logic/actions/faceLiveness3D";
import { go } from "logic/actions/navigation";
import {
  logEvent,
  liveness_test_started,
  step_completed,
  fl3d_error,
} from "logic/eventLogger";
import { SVR3DFL } from "logic/enums/livenessTestType";
import { THANK_YOU, INSTRUCTIONS_FACE_LIVENESS_3D } from "logic/enums/pages";
import { withTranslation } from "react-i18next";
import { failedAttempt } from "logic/actions/livenessTest";
import { getTimePassed } from "logic/stepTimers";
import { isIOS } from "../../../../logic/deviceType";
//import CaptureButtonPanel from "Components/CaptureButtonPanel";


//TODO@gva: mark for refactor to hooks (componentWillMount is deprecated)
export class PageFaceLiveness3D extends Component {
  static propTypes = {
    instantiateFaceLiveness3D: func,
    onUpdate: func.isRequired,
    go: func.isRequired,
    failedAttempt: func.isRequired,
    instance: instanceOf(Object),
    message: string,
    error: string,
    isReady: bool,
    faceBox: object,
    video: object
  };

  constructor(props) {
    super(props);
    document.addEventListener("visibilitychange", this.listenVisibilityChange);

    this.state = {
      isRunning: false,
      isMetadataLoaded: false,
      cameraPermission: true,
      toggleLoading: true,
      video: undefined
    };

    this.onCameraStarted.bind(this);
    this.start.bind(this);
  }

  componentWillMount() {
    if (!this.props.instance) {
      this.props.instantiateFaceLiveness3D(true);
    }
  }

  changeCamera(cameraId){
    this.props.instance.terminate();
    this.props.instantiateFaceLiveness3D(true);
    this.initFL3D();
  }

  onCameraStarted = (video) => {
    this.video = video;
    this.video.addEventListener("loadedmetadata", this.loadVideoMetadata);
  };

  loadVideoMetadata = () => {
    this.setState({ isMetadataLoaded: true });
    if (!this.props.isReady) {
      this.initFL3D();
    } else {
      //this.props.instance.startSession();
    }
  };

  start = () => {
    logEvent(liveness_test_started, {
      livenessTestType: SVR3DFL,
    });
    this.setState({ isRunning: true });
    this.props.instance.startSession();
  };

  initFL3D() {
    if(isIOS(navigator.userAgent)) {
      this.props.instance.setOptionalConfig({
        allowMP4: false,
        useJpegTpl: true
      });
    } else {
      this.props.instance.setOptionalConfig({
        allowMP4: true,
      });
    }
    this.props.instance.initialize({
      video: this.video,
      onUpdate: this.props.onUpdate,
      onTemplateCreated: this.onTemplateCreated,
    });
    this.props.instance.startProcessing();
    this.start();
  }

  templateSuccess() {
    const stepName = "liveness_test";
    const timePassed = getTimePassed(stepName);
    logEvent(step_completed, {
      stepNameCompleted: stepName,
      timePassed,
      livenessTestType: SVR3DFL,
      totalRetryCount: this.props.retryCount || 0,
    });
    this.props.instance.terminate();
    this.props.go(THANK_YOU);
  }

  onTemplateCreated = (tpl) => {
    this.setState({ pending: true });
    const base64template = arrayBufferToBase64(tpl);
    this.props
      .challengeTemplateUpload(base64template)
      .then((response) => {
        let message = "";
        if (response.data.processingStatus === "PROCESSED") {
          this.templateSuccess();
        } else if (response.data && response.data.items) {
          response.data.items.forEach((item) => {
            if (item.evaluations) {
              item.evaluations.forEach((evaluation) => {
                if (
                  evaluation.qualityAssessmentResult &&
                  !evaluation.qualityAssessmentResult.passed
                ) {
                  message += evaluation.qualityAssessmentResult.error.message;
                }
              });
            }
          });
          if (!message) {
            this.templateSuccess();
          } else {
            logEvent(fl3d_error, {
              livenessTestType: SVR3DFL,
              livenessError: message,
            });
            this.props.failedAttempt();
          }
        } else {
          this.setState({ pending: false });
          if (
            response.data.processingErrors &&
            response.data.processingErrors.items.length
          ) {
            logEvent(fl3d_error, {
              livenessTestType: SVR3DFL,
              livenessError: response.data.processingErrors.items[0].message,
              livenessErrorCode: response.data.processingErrors.items[0].code,
            });
          }
          this.props.failedAttempt();
        }
      })
      .catch((err) => {
        if (this.videoRef && this.videoRef.current) {
          this.videoRef.current.srcObject = undefined;
        }
        // console.log(err);
        this.onError();
      });
  };

  cameraPermissionDenied = () => {
    this.setState({
      cameraPermission: false,
      message: this.props.t("Common.error_camera"),
      isError: true,
      retryCameraInit: false,
    });
  };

  retryCameraInit = () => {
    // console.log("retryCameraInit");
    if (!this.state.cameraPermission)
      this.setState({
        cameraPermission: true,
        retryCameraInit: true,
        message: "",
        isError: false,
      });
  };

  onError = () => {
    this.setState({
      networkError: true,
      message: this.props.t("Common.error_connection_issues"),
    });

    setTimeout(() => {
      this.setState({
        pending: false,
      });
      this.props.failedAttempt();
    }, 3000);
  };

  setSpinnerState(spinnerState) {
    this.setState({ toggleLoading: spinnerState });
  }

  componentWillUnmount() {
    // console.log("componentWillUnmount");
    this.props.clear3dflState();
    document.removeEventListener(
      "visibilitychange",
      this.listenVisibilityChange
    );
    this.video.removeEventListener("loadedmetadata", this.loadVideoMetadata);
    this.video = null;
  }

  listenVisibilityChange = () => {
    this.props.go(INSTRUCTIONS_FACE_LIVENESS_3D);
  };

  onDeviceUnlock = () => {
    if (!this.state.isRunning) {
      this.props.go(INSTRUCTIONS_FACE_LIVENESS_3D);
    }
  };

  redOverlayLoaded = () => {
    this.setState({ isRedOverlayLoaded: true });
  };

  greenOverlayLoaded = () => {
    this.setState({ isGreenOverlayLoaded: true });
  };

  onDone = () => {
    this.setState({ pending: true });
  };

  render() {
    const messageClass = `message-box box-shadow ${
      this.state.networkError ? "message-box-error" : ""
    }`;
    return (
      <PageContent
        toggleLoading={!this.props.isReady || this.state.pending}
        type={PAGE_CONTENT_TYPE_CAPTURE}
      >
        <DeviceUnlockHandler onDeviceUnlock={this.onDeviceUnlock} />
        <div>
          <div
            className="curtain"
            style={{
              display: this.state.isRunning ? "block" : "none",
              backgroundColor: this.state.colorCode,
            }}
          />
          {this.props.instance && (
            <FaceLiveness3DUI
              onCameraStarted={this.onCameraStarted}
              width={faceLivenessResolution.width}
              height={faceLivenessResolution.height}
              isRunning={this.state.isRunning}
              isReady={this.props.isReady}
              result={this.state.result}
              score={this.state.score}
              retryCameraInit={this.state.retryCameraInit}
              cameraPermissionDenied={this.cameraPermissionDenied}
              redOverlayLoaded={this.redOverlayLoaded}
              greenOverlayLoaded={this.greenOverlayLoaded}
              onError={this.onError}
              onDone={this.onDone}
            />
          )}
        </div>
        <footer>

          <div className={this.state.message ? messageClass : null}>
            {this.state.message}
          </div>
          {!this.state.cameraPermission && (
            <button className={"btn blue"} onClick={this.retryCameraInit}>
              {this.props.t("Common.confirm_camera")}
            </button>
          )}
        </footer>
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(PageFaceLiveness3D);
export default connect(
  (state) => {
    return {
      ...state.faceLiveness3D,
      retryCount: state.livenessTest.retryCount,
    };
  },
  {
    instantiateFaceLiveness3D,
    onUpdate,
    challengeTemplateUpload,
    challengeCreate,
    go,
    failedAttempt,
    clear3dflState,
  }
)(componentWithTranslation);
