import styled from 'styled-components'

export const WrapperDiv = styled.div`
    height: 45vh;
     #wrapperDiv {
        
        ${props=>{
          return props.isNative ? "transform: scaleX(-1)" : ""
          }}
    }
    & .wrapper {
        background-color: #f8f9f9;
        object-fit: cover;
        width: 100%;
        height: 100%;
    }

    & .loaded-image-container { 
        pointer-events: none;
        height: 85%;
        padding-top: 7.5%;
        padding-bottom: 7.5%;
      }

    & .rotate-mobile-native {
        transform: rotate(90deg);
      }

`;

export const Button = styled.button`
      margin-left: 0;
`

