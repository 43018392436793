/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, connect, useSelector } from "react-redux";
import './style.css';
import { Modal } from "react-bootstrap";

import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "../PageContent";
// eslint-disable-next-line
import { applicationSubmit, applicationCheckResult } from "../../logic/actions/api/application";
import { documentUpdate } from "logic/actions/api/document";
import { clearCache } from "../../logic/localCacheAdapter";
import { useTranslation } from "react-i18next";
import PanelError from "Components/PanelError";
import ButtonRestartApp from "../common/ButtonRestartApp";
import * as S from "./PageThankYou.styles";
import imgsuccess from "../../assets/success-icon.png";
import { logEvent } from "logic/eventLogger";
import { getTimePassed, startEventTimer } from "logic/stepTimers";
import imgwarning from "../../assets/error-icon.png";
import { alertUser } from "logic/eventBeforeunload";
import { PASSIVE_LIVENESS_TEST_RETRY } from 'logic/enums/pages'
import { go } from "logic/actions/navigation";
import { failedAttempt } from "logic/actions/passiveLivenessTest";

function PageThankYou(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [error, setError] = useState("");
  const [pending, setPending] = useState(true);
  const [onModal, setOnModal] = useState(false)
  // eslint-disable-next-line
  const [stsBar, setStsBar] = useState(false)
  const [evaluationRetries, setEvaluationRetries] = useState(3);
  // eslint-disable-next-line
  const [mounted, setMounted] = useState(true);
  const [responsed, setResponsed] = useState(null);
  const [isign, setIsign] = useState(false);
  const [isSecondDevice, setIsSecondDevice] = useState(false);
  const [applicationPass, setApplicationPass] = useState(true);
  const [ekycPass, setEkycPass] = useState(true);
  const [processEnd, setProcessEnd] = useState(false)
  // eslint-disable-next-line
  const showEvalResults = useSelector((store) => {
    const { showApplicationResults } =
      store.configuration.extraConfig.featureFlags;
    return showApplicationResults === "true" || showApplicationResults === true;
  });
  // function onModalData() {

  // }
  const submitSendtoIsign = () => {
    let status;
    if (responsed.code === "0" && (responsed.ruleResult === "0" || responsed.ruleResult === "1")) {
      status = "200"
      setEkycPass(true)
      setProcessEnd(true);
    } else if (responsed.code === "1") {
      status = "400"
      setEkycPass(false)
      setProcessEnd(false);
    }
    let url = props.callbackURL + "?eKycProcessStatus=" + status + "&transactionID=" + props.id
    // window.open(url, "_blank")
    // setTimeout(() => {
    //   window.location.reload();
    // }, 200)
    window.location = url
    setPending(false);
  }
  const closeWindow = () => {
    window.close();
  }
  const passedLivenessCheck = (response) => response.results.items.filter(item => item.type === 'LA')[0].result === 'PASS'
  const goLivenessRetry = () => {
    props.failedAttempt()
    props.go(PASSIVE_LIVENESS_TEST_RETRY)
  }
  const livenessRetry = (response) => {
    return (
      !passedLivenessCheck(response.data) &&
      props.passiveLivenessTest.retriesLeft > 1
    )
  }
  const triggerEvaluation = () => {
    if (!evaluationRetries) {
      setError(t("PageThankYou.set_error"));
    } else {
      setPending(true);
      setApplicationPass(true);
      startEventTimer("thank_you")
      logEvent("thank_you", {
        start_time: window.performance.now(),
        response: null
      })
      applicationSubmit()(dispatch)
        .then((res) => {
          let submitTry = 1;
          if (res.status !== 201) {
            // console.log('Error submitting application, retry up to 2 more times')
            while (submitTry <= 3 && res.status !== 201) {
              applicationSubmit()(dispatch)
                // eslint-disable-next-line no-loop-func
                .then((newresponse) => {
                  if (newresponse.status === 201) {
                    res = newresponse;
                  }
                })
                .catch((err) => {
                  logEvent("thank_you_submission_failed", {
                    end_time: getTimePassed("thank_you"),
                    response: err
                  })
                })
              submitTry++;
            }
          }
          // only continue to check result if submission was successful
          if (res.status === 201) {
            if (livenessRetry(res)) {
              goLivenessRetry()
              return
            }
            logEvent("thank_you_submit", {
              end_time: getTimePassed("thank_you"),
              response: res
            })
            if (props.documentCapture.documentType === "ID_CARD") {
              setPending(true);
              const uncroppedSecondPage = "R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
              let confirmData = {
                confirmedGivenNames: props.dopaCheck.customerData.confirmedGivenNames,
                confirmedLastname: props.dopaCheck.customerData.confirmedLastname,
                middleName: props.dopaCheck.customerData.middleName,
                confirmedLaser: props.dopaCheck.customerData.confirmedLaser,
                confirmedIdNumber: props.dopaCheck.customerData.confirmedIdNumber,
                confirmedBirthdate: props.dopaCheck.customerData.birthOfDate,
                gender: props.dopaCheck.customerData.gender,
                birthOfDate: props.dopaCheck.customerData.birthOfDate
              }
              documentUpdate(
                props.documentCapture.base64CroppedFirstPage,
                uncroppedSecondPage,
                props.documentCapture.documentType,
                confirmData
              )(dispatch)
                .then(() => {
                  logEvent("thank_you_del_laser-id_success", {
                    end_time: getTimePassed("thank_you"),
                    response: res
                  })
                  setPending(true);
                  applicationCheckResult()(dispatch)
                    .then((response) => {
                      // console.log(response)
                      logEvent("thank_you_check_result_success", {
                        end_time: getTimePassed("thank_you"),
                        response: response
                      })
                      setPending(false);
                      setResponsed(response.data)
                      if (props.pathname === "webview") {
                        let status;
                        if (response.data.code === "0" && response.data.ruleResult === "0") {
                          status = "200"
                          setEkycPass(true)
                          setProcessEnd(true);
                        } else if (response.data.code === "0" && response.data.ruleResult === "1") {
                          status = "200"
                          setEkycPass(false)
                          setProcessEnd(true);
                        } else if (response.data.code === "1") {
                          setOnModal(true)
                          setProcessEnd(false);
                        }

                        try {
                          var data = {
                            eKycProcessStatus: status,
                            transactionID: props.id
                          }
                          window.webkit.messageHandlers.ekycResult.postMessage(JSON.stringify(data));
                          setPending(false);
                        } catch {
                          setPending(false);
                        }

                      } else if (props.pathname === "iframe") {
                        if (response.data.code === "0" && response.data.ruleResult === "0") {
                          setEkycPass(true)
                          setProcessEnd(true);
                        } else if (response.data.code === "0" && response.data.ruleResult === "1") {
                          setEkycPass(false)
                          setProcessEnd(true);
                        } else if (response.data.code === "1") {
                          setOnModal(true)
                          setProcessEnd(false);
                        }

                        if (props.secondDevice === "yes") {
                          setIsSecondDevice(true);
                        } else {
                          setIsign(true);
                        }

                      } else {
                        if (response.data.code === "0" && response.data.ruleResult === "0") {
                          setEkycPass(true)
                          setProcessEnd(true);
                        } else if (response.data.code === "0" && response.data.ruleResult === "1") {
                          setEkycPass(false)
                          setProcessEnd(true);
                        } else if (response.data.code === "1") {
                          setOnModal(true)
                          setProcessEnd(false);
                        }
                      }
                      if (process.env.REACT_APP_SHOW_ONBOARDING_RESULT === "true") {
                        alert("code = " + response.data.code + ",\nruleResult = " + response.data.ruleResult + ",\nUUID = " + response.data.userId + ",\nReferenceID = " + response.data.referenceId)
                      }

                    })
                    .catch((err) => {
                      logEvent("thank_you_check_result_failed", {
                        end_time: getTimePassed("thank_you"),
                        response: err
                      })
                      setError(err.toString())
                      setPending(false);
                    })
                })
                .catch((err) => {
                  logEvent("thank_you_del_laser-id_fail", {
                    end_time: getTimePassed("thank_you"),
                    response: err
                  })
                  setError(err.toString())
                  setPending(false);
                })
            } else {
              setPending(true);
              applicationCheckResult()(dispatch)
                .then((response) => {
                  setPending(false);
                  // console.log(response)
                  logEvent("thank_you_check_result_success", {
                    end_time: getTimePassed("thank_you"),
                    response: response
                  })
                  setResponsed(response.data)
                  if (props.pathname === "webview") {
                    if (response.data.code === "0" && response.data.ruleResult === "0") {
                      setEkycPass(true)
                      setProcessEnd(true);
                    } else if (response.data.code === "0" && response.data.ruleResult === "1") {
                      setEkycPass(false)
                      setProcessEnd(true);
                    } else if (response.data.code === "1") {
                      setOnModal(true)
                      setProcessEnd(false);
                    }

                  } else if (props.pathname === "iframe") {
                    if (response.data.code === "0" && response.data.ruleResult === "0") {
                      setEkycPass(true)
                      setProcessEnd(true);
                    } else if (response.data.code === "0" && response.data.ruleResult === "1") {
                      setEkycPass(false)
                      setProcessEnd(true);
                    } else if (response.data.code === "1") {
                      setOnModal(true)
                      setProcessEnd(false);
                    }

                    if (props.secondDevice === "yes") {
                      setIsSecondDevice(true);
                    } else {
                      setIsign(true);
                    }

                  } else {
                    if (response.data.code === "0" && response.data.ruleResult === "0") {
                      setEkycPass(true)
                      setProcessEnd(true);
                    } else if (response.data.code === "0" && response.data.ruleResult === "1") {
                      setEkycPass(false)
                      setProcessEnd(true);
                    } else if (response.data.code === "1") {
                      setOnModal(true)
                      setProcessEnd(false);
                    }
                  }
                  // setProcessEnd(true);
                  if (process.env.REACT_APP_SHOW_ONBOARDING_RESULT === "true") {
                    alert("code = " + response.data.code + ",\nruleResult = " + response.data.ruleResult + ",\nUUID = " + response.data.userId + ",\nReferenceID = " + response.data.referenceId)
                  }

                })
                .catch((err) => {
                  setError(err.toString())
                  setPending(false);
                })
            }
          } else {
            // Evaluation could not be submitted update to handle proper display for user.
            setError("Sorry, we could not finalise your evaluation. Please try again later.")
            setPending(false);
            setApplicationPass(false)
            if (evaluationRetries) {
              setEvaluationRetries(evaluationRetries - 1);
            }
          }

        })
        .catch((err) => {
          logEvent("thank_you_submit_failed", {
            end_time: getTimePassed("thank_you"),
            response: err
          })
          setError(err.toString())
          setPending(false);
          setApplicationPass(false)
          if (evaluationRetries) {
            setEvaluationRetries(evaluationRetries - 1);
          }
        })
        .finally(() => {
          clearCache();
        });
    }
  };

  useEffect(() => {
    window.removeEventListener("beforeunload", alertUser);
    if (props.faceLiveness3D && props.faceLiveness3D.instance) {
      props.faceLiveness3D.instance.stopCamera();
      props.faceLiveness3D.instance.terminate();
      props.faceLiveness3D.instance = void 0;
    }
    triggerEvaluation();
    return () => {
      setMounted(false);
    };
    // eslint-disable-next-line
  }, [dispatch]);

  if (error) {
    return (
      <PageContent>
        <PanelError text={error} />
        <ButtonRestartApp />
      </PageContent>
    );
  }

  return (
    <PageContent toggleLoading={pending} progressBar={stsBar} type={processEnd ? "" : PAGE_CONTENT_TYPE_CAPTURE} isSpinner={true}>
      <header />
      <p className="error">{error}</p>
      {evaluationRetries === 3 ? (
        processEnd ? (
          <>
            <div className="container-fluid">
              <div className="card-body center">
                <img
                  src={imgsuccess}
                  alt="thankyou"
                  className="mb-3"
                />
                <div className="title-text mt-4 mb-2 w-full">
                  <h1>{t("PageThankYou.success")}</h1>
                </div>
              </div>
            </div>
          </>
        ) : null
      ) : (
        !applicationPass ? (
          <div>
            <S.RetryMessage>{t("PageThankYou.retry_message")}</S.RetryMessage>
            <footer className="footer">
              <button className="btn" style={{ backgroundColor: "#D31145", fontSize: "1em", marginTop: '0px' }} onClick={triggerEvaluation}>
                {" "}
                {t("PageThankYou.resend_button")} ({evaluationRetries + 1})
              </button>
            </footer>
          </div>
        ) : null
      )}
      {applicationPass && !ekycPass ? (<div className="container-fluid center">
        <div className="card-body">
          <div className="title-text mt-2 mb-4 w-full">
            <p className="w-full" style={{ fontFamily: 'AIA Regular' }}>{t("PageThankYou.description")}</p>
          </div>
        </div>
      </div>
      ) : null}
      {applicationPass && processEnd && isign ? (
        <footer className="footer">
          <button className="btn mt-0 mb-2" style={{ backgroundColor: "#D31145", fontSize: "1em", marginTop: '0px' }} onClick={submitSendtoIsign}>
            {t("PageThankYou.btn_submit")}
          </button>
        </footer>
      ) : (applicationPass && processEnd && isSecondDevice ? (
        <footer className="footer">
          <button className="btn mt-0 mb-2" style={{ backgroundColor: "#D31145", fontSize: "1em", marginTop: '0px' }} onClick={closeWindow}>
            {t("PageThankYou.btn_close_windows")}
          </button>
        </footer>
      ) : null)}
      <Modal
        show={onModal}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="row row-justify-center">
            <img
              style={{ width: '30%', height: 'inherit' }}
              src={imgwarning}
              alt="warning"
            />
          </div>
          <div className="row center">
            <a className="mt-1 w-full" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{t('PageThankYou.error_rule_result')}</a>
          </div>
          <div className="row row-justify-center">
            <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => window.location.reload()}>{t("PageThankYou.btn_confirm")}</button>
          </div>
        </Modal.Body>
      </Modal>
    </PageContent>
  );
}

export default connect((state) => {
  return {
    ...state.application,
    ...state.setApplicationParams,
    dopaCheck: state.dopaCheck,
    documentCapture: state.documentCapture,
    faceLiveness3D: state.faceLiveness3D,
    configuration: state.configuration,
    passiveLivenessTest: state.passiveLivenessTest
  };
},
{
  go,
  failedAttempt,
})(PageThankYou);
