import {
    SET_START_EXPIRE_TIME,
    GET_START_EXPIRE_TIME,
    SET_STAMP_TIME
} from '../actions/expireTimeOut'

const defaultState = {
    stampTime: 0,
    expireTimeOut: 0,
    stsExpireTime: false
}

export function expireTimeOut(state = defaultState, action) {
    switch (action.type) {
        case SET_STAMP_TIME : 
            return {
                ...state,
                stampTime: action.payload,
            }
        case SET_START_EXPIRE_TIME:
            if(action.payload - state.stampTime > 30000){
                return {
                    ...state,
                    expireTimeOut: action.payload,
                    stsExpireTime: true
                }
            }else{
                return {
                    ...state,
                    expireTimeOut: action.payload,
                    stsExpireTime: false
                }
            }
        case GET_START_EXPIRE_TIME:
            return state
        default:
            return state
    }
}
export const getTimeStamp = (state) => {
    return state.expireTimeOut.stampTime
}

export const checkExpireTimeout = (state) => {
    return state.expireTimeOut.expireTimeOut
}

export const getResultExpire = (state) => {
    
}