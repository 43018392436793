import {
    SET_DOPA_CHECK_DATA,
    SET_DOPA_RETRY,
    RESET_DOPA_CHECK,
    SET_RESPONSE_DOPA,
    SET_TWO_ELEMENT_CHECK
} from '../actions/dopaCheck'

const defaultState = {
    customerData: null,
    dopaResponse: null,
    dopacheckRetries: 0
}

export function dopaCheck(state = defaultState, action) {
    switch (action.type) {
        case SET_RESPONSE_DOPA:
            return {
                ...state,
                dopaResponse: action.payload
            }
        case SET_DOPA_CHECK_DATA:
            return {
                ...state,
                customerData: action.payload,
            }
        case SET_DOPA_RETRY:
            return {
                ...state,
                dopacheckRetries: state.dopacheckRetries + 1
            }
        case RESET_DOPA_CHECK:
            return {
                // ...state,
                dopacheckRetries: 0,
                customerData: null
            }
        case SET_TWO_ELEMENT_CHECK:
            return {
              ...state,
              dopaTwoElementCheck: true
            }
        default:
            return state
    }
}