/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import PageContent from "Components/PageContent";
import { withTranslation } from "react-i18next";
import { go, nextStep } from "logic/actions/navigation";
import {
    DOCUMENT_CAPTURE_SECOND_TYPE,
    // FACE_CAPTURE_INSTRUCTIONS
} from "logic/enums/pages";
import { secondDocumentsSave } from "logic/actions/api/document";
import {
    setStatusImageSecond,
    retryDocumentCapture,
    setTypeSelectSecondDocument,
    resetSecondDocument
} from "logic/actions/documentCaptureSecondType";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";
import { selectSecondDoc } from "logic/enums/documentType";
import { store } from "logic/store";

import "./style.css";
import cameraCap from "../../../../assets/camera_icon.png";

class PageDocumentCaptureType2Select extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            pending: false,
            setCount: 3,
            countFrist: 0,
            countSecond: 0,
            showSwitchCameraButton: store.getState().camera.cameras.length > 1,
            disableBtn1Retry: false,
            disableBtn2Retry: false,
            nameDocument: undefined,
            setIdDocument: null
        }
        this.submit.bind(this)
        this.retryTakePhoto.bind(this)
        this.zoomImage.bind(this)
        this.goSecondDocCapture.bind(this)
        this.selectDocument.bind(this)
    }

    componentDidMount() {
        // if (this.state.setCount - this.props.firstPageRetries <= 0) {
        //     this.setState({ disableBtn1Retry: true })
        // }
        // if (this.state.setCount - this.props.secondPageRetries <= 0) {
        //     this.setState({ disableBtn2Retry: true })
        // }
        this.modal = document.getElementById("madalImageSecond");
        this.img = document.getElementById("imageModal");
        this.modalImg = document.getElementById("img01");

        this.selectDocument(this.props.idDocument)
    }
    retryTakePhoto(pos) {
        this.props.retryDocumentCapture(pos);
        this.goSecondDocCapture(pos);
    }
    zoomImage = (image) => {
        this.modal.style.display = "block";
        this.modalImg.src = image;

    }
    goSecondDocCapture = (pos) => {
        // console.log("click camera capture")
        // this.props.go(DOCUMENT_CAPTURE_SECOND_TYPE)
        this.props.setStatusImageSecond({
            status: true,
            position: pos
        })
        this.props.go(DOCUMENT_CAPTURE_SECOND_TYPE)
    }
    selectDocument = (id) => {
        // console.log(id)
        if (this.props.idDocument !== id) {
            this.setState({ setIdDocument: id })
            for (let val of selectSecondDoc) {
                if (val.id === id) {
                    this.setState({ nameDocument: this.props.setupLanguage === "th" ? val.nameth : val.nameen })
                    this.props.resetSecondDocument()
                    this.props.setTypeSelectSecondDocument({
                        secondDocumentType: val.tag,
                        idDocument: val.id
                    })
                }
            }
        } else {
            for (let val of selectSecondDoc) {
                if (val.id === id) {
                    this.setState({ nameDocument: this.props.setupLanguage === "th" ? val.nameth : val.nameen })
                    this.props.setTypeSelectSecondDocument({
                        secondDocumentType: val.tag,
                        idDocument: val.id
                    })
                }
            }
        }
    }
    submit = () => {
        this.setState({ pending: true })
        let docType = this.props.documentCapture.documentType === "PASSPORT" ? this.props.documentCapture.documentType : this.props.secondDocumentType
        // console.log(docType)
        this.props.secondDocumentsSave(
            this.props.base64CroppedSecondDocFirstPage,
            this.props.base64CroppedSecondDocSecondPage,
            docType
        )
            .then((res) => {
                // console.log(res)
                this.setState({ pending: false })
                // this.props.go(FACE_CAPTURE_INSTRUCTIONS)
                this.props.nextStep();
            })
            .catch((err) => {
                // console.log(err)
                this.setState({
                    error: err,
                    pending: false
                })
            })
        // this.props.nextStep()
    }

    render() {
        let disableFirstImage = ""
        if (!this.state.nameDocument && this.props.documentCapture.documentType !== "PASSPORT") {
            disableFirstImage = "card mb-3 size-box disable-second-image"
        } else {
            disableFirstImage = "card mb-3 size-box"
        }

        let disableSecondImage = ""
        if (!this.props.base64CroppedSecondDocFirstPage) {
            disableSecondImage = "card mb-2 mt-2 size-box disable-second-image"
        } else {
            disableSecondImage = "card mb-2 mt-2 size-box"
        }
        const items = selectSecondDoc.map(key =>
            <Dropdown.Item key={key.id} onClick={() => this.selectDocument(key.id)}>{this.props.setupLanguage === "th" ? key.nameth : key.nameen}</Dropdown.Item>
        )
        return (
            <PageContent toggleLoading={this.state.pending} progressBar={true} isSpinner={true}>
                <h1>{this.props.t("PageDocumentCaptureType2Select.header")}</h1>
                <div className="container" style={{ marginBottom: '40%' }}>
                    {this.props.documentCapture.documentType !== "PASSPORT" ? (<div className="center mb-4 mt-2">
                        <Dropdown as={ButtonGroup} style={{ width: '90%' }}>
                            <Button
                                className="margin-n2"
                                style={{ backgroundColor: "#ffffff", width: '80%', border: "2px solid #D31145" }}
                            >
                                <p className="font-text w-full mt-1" style={{ color: "#000000" }}>{this.state.nameDocument}</p>
                            </Button>
                            <Dropdown.Toggle
                                split
                                className="margin-n2"
                                style={{ backgroundColor: "#ffffff", width: '10%', color: "#000000", border: "2px solid #D31145" }}
                                id="dropdown-split-basic"
                            />
                            <Dropdown.Menu align={{ xl: 'start' }} style={{ width: "100%" }}>
                                {items}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>) : null}
                    <div className="row row-cols-1 row-justify-center">
                        {!this.props.statusFirstPage && !this.props.blobSecondDocFirstPage && !this.props.base64CroppedSecondDocFirstPage ? (
                            <div className={disableFirstImage}>
                                <div className="center" onClick={() => this.goSecondDocCapture("first")} style={{ width: "100%", height: "100%" }}>
                                    <img
                                        src={cameraCap}
                                        alt="title-page1"
                                        className="camera-capture mt-5"
                                        style={{ width: "20%" }}
                                    />
                                    <div className="mb-5 mt-2">
                                        <b>{this.props.t("PageDocumentCaptureType2Select.first_page")}</b>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="card mb-3">
                                <div className="card size-box">
                                    <div className="center" style={{ backgroundColor: "#D3CAC3", width: "100%", height: "100%" }}>
                                        <img
                                            src={this.props.blobSecondDocFirstPage}
                                            onClick={() => this.zoomImage(this.props.blobSecondDocFirstPage)}
                                            id="imageModal"
                                            alt="page1"
                                            className="camera-capture"
                                            style={{ width: "50%" }} />
                                    </div>
                                </div>
                                <div className="card-footer center">
                                    <button
                                        type="button"
                                        style={{ backgroundColor: '#1F78AD', marginTop: '0px', fontSize: '0.9em' }}
                                        onClick={() => this.retryTakePhoto('first')}
                                        disabled={this.state.disableBtn1Retry}
                                        className="btn"
                                    >{this.props.t("PageDocumentCaptureType2Select.xretry_take_page")}</button>
                                    {/* {this.props.t("PageDocumentCaptureConfirm.btn_take_new_picture")} */}
                                    {/* {this.props.t("PageDocumentCaptureType2Select.retry_take_first_page", { count: this.state.setCount - this.props.firstPageRetries })} */}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="container-fulid">
                        <div className="row row-cols-1 row-justify-center">
                            {!this.props.statusSecondPage && !this.props.blobSecondDocSecondPage && !this.props.base64CroppedSecondDocSecondPage ? (
                                <div className={disableSecondImage}>
                                    <div className="center" onClick={() => this.goSecondDocCapture("second")} style={{ width: "100%", height: "100%" }}>
                                        <img
                                            src={cameraCap}
                                            alt="title-page2"
                                            className="camera-capture mt-5"
                                            style={{ width: "20%" }} />
                                        <div className="mb-5 mt-2">
                                            <b>{this.props.t("PageDocumentCaptureType2Select.second_page")}</b>
                                        </div>
                                    </div>
                                </div>) : (
                                <div className="card mb-3 size-box">
                                    <div className="center" style={{ backgroundColor: "#D3CAC3", width: "100%", height: "100%" }}>
                                        <img
                                            src={this.props.blobSecondDocSecondPage}
                                            onClick={() => this.zoomImage(this.props.blobSecondDocSecondPage)}
                                            id="imageModal"
                                            alt="page2"
                                            className="camera-capture"
                                            style={{ width: "50%" }} />
                                    </div>
                                    <div className="card-footer center">
                                        <button
                                            type="button"
                                            style={{ backgroundColor: '#1F78AD', marginTop: '0px', fontSize: '0.9em' }}
                                            disabled={this.state.disableBtn2Retry}
                                            onClick={() => this.retryTakePhoto('second')}
                                            className="btn"
                                        >{this.props.t("PageDocumentCaptureType2Select.xretry_take_page")}</button>
                                        {/* {this.props.t("PageDocumentCaptureConfirm.btn_take_new_picture")} */}
                                        {/* {this.props.t("PageDocumentCaptureType2Select.retry_take_first_page", { count: this.state.setCount - this.props.secondPageRetries })} */}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <footer className="footer">
                    <button
                        type="button"
                        disabled={this.props.base64CroppedSecondDocFirstPage ? false : true}
                        onClick={this.submit}
                        style={{ fontSize: '1em', backgroundColor: '#D31145', marginTop: '0px' }}
                        className="btn">{this.props.t("PageDocumentCaptureType2Select.confirm_document")}</button>
                </footer>
                <div id="madalImageSecond" className="modal-image-second">
                    <span className="close" onClick={() => this.modal.style.display = "none"}>&times;</span>
                    <img className="modal-content-second" id="img01" alt="zoom-pages" />
                </div>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageDocumentCaptureType2Select)
export default connect(
    (state) => {
        const {
            blobSecondDocFirstPage,
            blobSecondDocSecondPage,
            base64CroppedSecondDocFirstPage,
            base64CroppedSecondDocSecondPage,
            documentQualityRetriesFront,
            documentQualityRetriesBack,
            firstPageRetries,
            secondPageRetries,
            statusFirstPage,
            statusSecondPage,
            secondDocumentType,
            idDocument
        } = state.documentCaptureSecondType;
        return {
            ...state.changeMultiLanguage,
            documentCapture: state.documentCapture,
            blobSecondDocFirstPage,
            blobSecondDocSecondPage,
            base64CroppedSecondDocFirstPage,
            base64CroppedSecondDocSecondPage,
            documentQualityRetriesFront,
            documentQualityRetriesBack,
            firstPageRetries,
            secondPageRetries,
            statusFirstPage,
            statusSecondPage,
            secondDocumentType,
            idDocument
        }
    },
    {
        secondDocumentsSave,
        go,
        setStatusImageSecond,
        retryDocumentCapture,
        nextStep,
        setTypeSelectSecondDocument,
        resetSecondDocument
    }
)(componentWithTranslation)
