// eslint-disable-next-line
import React, { Component } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line
import { func, string, bool, instanceOf } from "prop-types";
import { Modal } from "react-bootstrap";
import PageContent, { PAGE_CONTENT_TYPE_CAPTURE } from "../../../PageContent";
import OldFaceLiveness3DUI from "../OldFaceLiveness3DUI";
import CaptureButtonPanel from "../../../CaptureButtonPanel";
import "./style.css";
import {
  challengeTemplateUpload,
  challengeCreate,
} from "logic/actions/api/liveness";
import { arrayBufferToBase64 } from "logic/arrayBufferToBase64";
import faceLivenessResolution from "logic/faceLivenessResolution";
import {
  instantiateFaceLiveness3D,
  onUpdate,
  clear3dflState,
  clearReadyState,
} from "logic/actions/faceLiveness3D";
import { go } from "logic/actions/navigation";
import {
  THANK_YOU,
  OLD_INSTRUCTIONS_FACE_LIVENESS_3D,
} from "logic/enums/pages";
import {
  faceLivenessMovementDuration,
  faceLivenessMovementDelay,
} from "logic/faceLivenessTimingConfiguration";
import { withTranslation } from "react-i18next";
import { failedAttempt } from "logic/actions/livenessTest";
// eslint-disable-next-line
import { store } from "logic/store";
import { isIOS, isMobile, isAndroid, isMac } from "logic/deviceType";
import imgwarning from "../../../../assets/warning-icon.png";
import {
  flipCamera
} from "logic/actions/documentCaptureConfiguration";

//TODO@gva: mark for refactor to hooks (componentWillMount is deprecated)
export class OldPageFaceLiveness3D extends Component {

  livenessVideoWidth = faceLivenessResolution.width;
  livenessVideoHeight = faceLivenessResolution.height;

  static propTypes = {
    instantiateFaceLiveness3D: func,
    onUpdate: func,
    go: func,
    instance: instanceOf(Object),
    message: string,
    error: string,
    isReady: bool
  };

  constructor(props) {
    super(props);
    document.addEventListener("visibilitychange", this.listenVisibilityChange);

    this.state = {
      completedLiveness: false,
      restartLivenessPrompt: false,
      isRunning: false,
      canSwitchCamera: store.getState().camera.cameras.length > 1,
      isMetadataLoaded: false,
      cameraPermission: true,
      toggleLoading: true,
      video: undefined,
      camera: store.getState().camera,
      showDebug: false,
      rotateLandscape: false,
      mirror: false,
      debugMsg: "N/A",
      timerMaxWaitSeconds: 240
    };
    this.onCameraStarted.bind(this);
    // this.loadVideoMetadata.bind(this);
    this.start.bind(this);
    this.onTemplateCreated.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (!this.props.instance || !this.props.instance.__3dflProcessor) {
      this.props.instantiateFaceLiveness3D(false);
    }
  }

  componentDidMount() {
    window.addEventListener("orientationchange", this.checkScreenOrientation);
    this.checkScreenOrientation();
  }

  checkScreenOrientation = () => {
    let vidWidth = this.livenessVideoWidth;
    let vidHeight = this.livenessVideoHeight;

    let screenOrientation = undefined;
    try {
      if (isAndroid(navigator.userAgent) || isIOS(navigator.userAgent) || isMobile(navigator.userAgent) || isMac(navigator.userAgent)) {
        if (window.screen.orientation) {
          screenOrientation = window.screen.orientation.type.includes('landscape') ? 'landscape' : 'portrait';
        } else {
          // iOS/safari
          screenOrientation = Math.abs(+window.orientation) === 90 ? 'landscape' : 'portrait';
        }
      }
    }
    catch (err) {
      this.setState({ debugMsg: err.toString() })
    }

    if (screenOrientation === 'landscape') {
      if (vidWidth > window.innerWidth) {
        vidWidth = window.innerWidth;
        vidHeight = Math.ceil(vidHeight / vidWidth) * window.innerWidth;
      }
    } else {
      if (vidHeight > window.innerHeight) {
        vidHeight = window.innerHeight;
        vidWidth = Math.ceil((vidWidth / vidHeight) * window.innerHeight);
      }
    }
    console.log('vid width/height = ' + vidWidth + " / " + vidHeight);
    this.livenessVideoWidth = vidWidth;
    this.livenessVideoHeight = vidHeight;

    this.setState({
      stsOrientation: screenOrientation,
      rotateLandscape: screenOrientation === "landscape" ? true : false,
    })
  }

  onCameraStarted = (video, videoRef = undefined) => {
    if (!this.props.instance || !this.props.instance.__3dflProcessor) {
      this.props.instantiateFaceLiveness3D(false);
    }
    const videoR = video;
    // eslint-disable-next-line
    this.video = videoR;
    const ref = videoRef;
    // eslint-disable-next-line
    this.videoRef = ref;

    this.video.onloadedmetadata = () => {
      this.loadVideoMetadata();
    }
  };

  loadVideoMetadata = () => {
    if (!this.props.instance || !this.props.instance.__3dflProcessor) {
      this.props.instantiateFaceLiveness3D(false);
    }
    this.props.instance.setOptionalConfig({ allowMP4: false });
    try {
      if (this.video !== undefined) {
        const videoState = this.video;
        this.props.instance.videoEl = videoState;
        this.props.instance.initialize({
          mWidth: parseInt(process.env.REACT_APP_USE_LIVENESS_RESOLUTION_WIDTH),
          mHeight: parseInt(process.env.REACT_APP_USE_LIVENESS_RESOLUTION_HEIGHT),
          video: videoState,
          onUpdate: this.props.onUpdate,
          onTemplateCreated: (value) => this.onTemplateCreated(value),
          movementDelay: faceLivenessMovementDelay,
          movementDuration: faceLivenessMovementDuration,
        });
      }
    } catch (err) {
      this.setState({ debugMsg: "loaded " + err.toString() })
    }

    this.props.instance.startProcessing();
  };

  start = () => {
    this.setState({ isRunning: true });
    try {
      this.props.instance.startSession();
    } catch (err) {
      alert("Start error")
      this.setState({ debugMsg: err.toString() })
    }
  };

  onTemplateCreated = (tpl) => {
    this.setState({ pending: true });
    const base64template = arrayBufferToBase64(tpl);
    this.props
      .challengeTemplateUpload(base64template)
      .then((response) => {
        if (response.data.processingStatus === "PROCESSED") {
          this.setState({ completedLiveness: true, isRunning: false });

          this.props.instance.stopCamera();
          this.props.instance.terminate();
          this.props.go(THANK_YOU);
        } else {
          this.setState({ pending: false, completedLiveness: true });
          this.props.failedAttempt();
        }
      })
      .catch((err) => {
        if (this.videoRef && this.videoRef.current) {
          this.videoRef.current.srcObject = undefined;
        }
        this.setState({ debugMsg: err.toString() })
        this.onError();
      });
  };

  cameraPermissionDenied = () => {
    this.setState({
      cameraPermission: false,
      message: this.props.t("Common.error_camera"),
      isError: true,
      retryCameraInit: false,
      canSwitchCamera: store.getState().camera.cameras.length > 1,
    });
  };

  cameraSourceUnavailable = () => {
    this.setState({
      cameraPermission: false,
      message: this.props.t("Common.error_camera_source"),
      isError: true,
      retryCameraInit: false,
      wasmIsLoading: false,
      canSwitchCamera: store.getState().camera.cameras.length > 1,
    })
  }

  retryCameraInit = () => {
    if (!this.state.cameraPermission)
      this.setState({
        cameraPermission: true,
        retryCameraInit: true,
        message: "",
        isError: false,
      });
  };

  onError = () => {
    this.setState({
      networkError: true,
      message: this.props.t("Common.error_connection_issues")
    });

    setTimeout(() => {
      this.setState({
        pending: false,
      });
      this.props.failedAttempt();
    }, 3000);
  };

  componentWillUnmount() {
    this.props.clear3dflState();
    document.removeEventListener("visibilitychange", this.listenVisibilityChange);
    this.setState = (state, callback) => {
      return;
    };
  }

  listenVisibilityChange = () => {
    if (!this.state.isRunning) {
      try {
        this.props.instance.stopCamera();
      } catch (err) { }
      this.props.go(OLD_INSTRUCTIONS_FACE_LIVENESS_3D);
    }
  };

  redOverlayLoaded = () => {
    this.setState({ isRedOverlayLoaded: true });
  };

  greenOverlayLoaded = () => {
    this.setState({ isGreenOverlayLoaded: true });
  };

  onDone = () => {
    this.setState({ pending: true });
  };

  render() {
    const messageClass = `message-box box-shadow ${this.state.networkError ? "message-box-error" : ""}`;
    const btnEnabled =
      !this.state.isRunning &&
      this.props.isReady &&
      this.state.isGreenOverlayLoaded &&
      this.state.isRedOverlayLoaded;
    return (
      <PageContent
        toggleLoading={
          !this.props.isReady ||
          this.state.pending ||
          !this.state.isRedOverlayLoaded
        }
        isSpinner={true}
        type={PAGE_CONTENT_TYPE_CAPTURE}
      >
        {/* <VisiblityChangeHandler
          onVisibilityChange={this.listenVisibilityChange}
        /> */}
        <p className="instructions-on-liveness-header">
          {this.props.t("OldPageFaceLiveness3D.description")}
        </p>
        <div
          className="curtain"
          style={{
            display: this.state.isRunning ? "block" : "none",
            backgroundColor: this.state.colorCode,
          }}
        />
        {this.props.instance && (
          <OldFaceLiveness3DUI
            onCameraStarted={this.onCameraStarted}
            width={this.livenessVideoWidth}
            height={this.livenessVideoHeight}
            isRunning={this.state.isRunning}
            isReady={this.props.isReady}
            result={this.state.result}
            score={this.state.score}
            retryCameraInit={this.state.retryCameraInit}
            cameraPermissionDenied={this.cameraPermissionDenied}
            redOverlayLoaded={this.redOverlayLoaded}
            greenOverlayLoaded={this.greenOverlayLoaded}
            onError={this.onError}
            onDone={this.onDone}
          />
        )}
        <footer>
          <div className={this.state.message ? messageClass : null}>
            {this.state.message}
          </div>
          {!this.state.cameraPermission && (
            <div className="center">
              <button className={"btn"} style={{ backgroundColor: '#D31145' }} onClick={this.retryCameraInit}>
                {this.props.t("Common.confirm_camera")}
              </button>
            </div>
          )}
          {this.state.cameraPermission && !this.state.isRunning && (
            <CaptureButtonPanel
              // showMirror={true}
              // onChangeMirror={this.changeMirror}
              onCaptureClick={this.start}
              showSwitchCameraButton={false}
              disabled={!btnEnabled}
              backDisabled={true}
              text={this.props.t("Common.start_liveness")}
              facingMode={"user"}
            />
          )}
        </footer>
        <Modal
          show={this.state.rotateLandscape}
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Body>
            <div className="row row-justify-center">
              <img
                style={{ width: '20%', height: 'inherit' }}
                src={imgwarning}
                alt="warning"
              />
            </div>
            <div className="center">
              <h3 className="mt-3">{this.props.t('PageDocumentCapturePortrait.display_is_landscape_header')}</h3>
              <p className="mb-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('PageDocumentCapturePortrait.display_is_landscape_title')}</p>
            </div>
            <div className="row row-justify-center">
              <button type="button" className="btn btn-lg" style={{ backgroundColor: "#D31145", fontSize: '1em' }} onClick={() => this.setState({ rotateLandscape: false })}>{this.props.t("PageDocumentConfirmOCRData.btn_confirm_data")}</button>
            </div>
          </Modal.Body>
        </Modal>
        {/*<OldPageFaceLivenessTimer timerMax={this.state.timerMaxWaitSeconds} onFinish={() => this.setState({ restartLivenessPrompt: true })} />*/}
        {/*<Modal*/}
        {/*  show={this.state.restartLivenessPrompt}*/}
        {/*  centered*/}
        {/*  backdrop="static"*/}
        {/*  keyboard={false}*/}
        {/*>*/}
        {/*  <Modal.Body>*/}
        {/*    <div className="row row-justify-center">*/}
        {/*      <img*/}
        {/*        style={{ width: '20%', height: 'inherit' }}*/}
        {/*        src={imgwarning}*/}
        {/*        alt="warning"*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*    <div className="center">*/}
        {/*      <h3 className="mt-3">{this.props.t('OldPageFaceLiveness3D.restart_header')}</h3>*/}
        {/*      <p className="mb-1" style={{ color: 'black', fontFamily: 'AIA Regular' }}>{this.props.t('OldPageFaceLiveness3D.restart')}</p>*/}
        {/*    </div>*/}
        {/*    <div className="row row-justify-center">*/}
        {/*      <button type="button" className="btn btn-lg"*/}
        {/*              style={{ backgroundColor: "#D31145", fontSize: '1em' }}*/}
        {/*              onClick={() => {this.setState({ restartLivenessPrompt: false })}}*/}
        {/*          >{this.props.t("OldPageFaceLiveness3D.btn_restart_no")}</button>*/}
        {/*      <button type="button" className="btn btn-lg"*/}
        {/*              style={{ backgroundColor: "#5bd311", fontSize: '1em' }}*/}
        {/*              onClick={() => this.props.go(OLD_INSTRUCTIONS_FACE_LIVENESS_3D)}*/}
        {/*          >{this.props.t("OldPageFaceLiveness3D.btn_restart_yes")}</button>*/}
        {/*    </div>*/}
        {/*  </Modal.Body>*/}
        {/*</Modal>*/}
      </PageContent>
    );
  }
}

const componentWithTranslation = withTranslation()(OldPageFaceLiveness3D);
export default connect(
  (state) => {
    return {
      ...state.faceLiveness3D,
      retryCount: state.livenessTest.retryCount,
      documentCaptureConfiguration: state.documentCaptureConfiguration
    };
  },
  {
    instantiateFaceLiveness3D,
    onUpdate,
    challengeTemplateUpload,
    challengeCreate,
    go,
    failedAttempt,
    clear3dflState,
    flipCamera,
    clearReadyState
  }
)(componentWithTranslation);