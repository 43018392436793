/*global Daon */
/*eslint no-undef: "error"*/
import React, { Component } from "react";
import { string, func, instanceOf } from "prop-types";
import { connect } from "react-redux";
import { isMobile, isIOS } from "logic/deviceType";
import { store } from "logic/store";
import { cameraResolution } from "logic/actions/documentCaptureConfiguration";
import { withTranslation } from "react-i18next";
import "./style.css";
import { canvasDrawingClass } from "./utils/canvasDrawingClass";
import id_card_frame from "assets/id_card_guiding.png";
import passport_guiding from "assets/passport_guiding.png";
import monk_id_guiding from "assets/monk_id_guiding.png";
import other_doc_guiding from "assets/other_doc_guiding.png"

export class DocumentCaptureUI extends Component {

  static propTypes = {
    documentType: string,
    blobPreview: instanceOf(Blob),
    onInitialized: func,
    onCameraStarted: func,
    documentCapture: instanceOf(Daon.DocumentCapture),
  };
  constructor(props) {
    super(props);
    this.state = {
      isMobile: true,
      facingMode: 'environment',
      mirror: false,
      finished: false
    };
    //this.canvasRef = new React.createRef();
    this.videoRef = new React.createRef();
    this.videoContainer = {
      video: this.videoRef.current,
      ready: false
    };
    if (typeof this.props.onInitialized === "function")
      this.props.onInitialized(this.props.docCaptureInstance);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.retryCameraInit !== this.props.retryCameraInit)
      !this.props.retryCameraInit && this.initializeCamera();
  }

  componentDidMount() {
    const cameraRef = store.getState().camera;
    if (cameraRef.rearCamera !== undefined) {
      this.initializeCamera(cameraRef.rearCamera.cameraId);
    } else {
      this.initializeCamera();
    }
    this.setState({
      isMobile: isMobile(navigator.userAgent),
      mirror: this.props.documentCaptureConfiguration.mirror
    });
    this.setCanvasDimensions();
    if (isIOS(navigator.userAgent)) this.setState({ isIOS: true });

    // this.canvas = this.refs.canvas;
    // this.ctx = this.canvas.getContext("2d");
    window.addEventListener("resize", this.drawIdCard, false);
    window.addEventListener("orientationchange", this.drawIdCard);
    this.drawIdCard();
  }

  drawIdCard = () => {
    try {
      const { clientWidth, clientHeight } = document.querySelector(".ui-canvas-container");
      this.canvas = this.refs.canvas;
      this.canvas.width = clientWidth;
      this.canvas.height = clientHeight;
      this.ctx = this.canvas.getContext("2d");
      this.imageGuiding = new Image();
      let adjScale;
      if (this.props.documentType === "ID_CARD") {
        this.imageGuiding.src = id_card_frame;
        adjScale = 0.97
      } else if (this.props.documentType === "PASSPORT") {
        this.imageGuiding.src = passport_guiding;
        adjScale = 0.97
      } else if (this.props.documentType === "MONK_ID_DOCUMENT") {
        this.imageGuiding.src = monk_id_guiding;
        adjScale = 0.97
      } else if (this.props.documentType === "COPY_ID_CARD") {
        this.imageGuiding.src = id_card_frame;
        adjScale = 0.97
      } else {
        this.imageGuiding.src = other_doc_guiding
        adjScale = 0.9
      }

      this.canvasDrawingClass = new canvasDrawingClass(
        this.canvas,
        this.ctx,
        this.imageGuiding
      );

      this.imageGuiding.onload = () => {
        this.canvasDrawingClass.drawOutline(this.imageGuiding, adjScale, clientWidth, clientHeight)
      };
    } catch (err) {
      console.log(err)
    }
  }

  componentDidUpdate(prevProps, _) {
    if (prevProps.facingMode !== this.props.documentCaptureConfiguration.facingMode) {
      this.setState({ facingMode: prevProps.facingMode })
      this.reinitializeCamera();
    }
    else if (prevProps.cameraId !== this.props.cameraId) {
      this.reinitializeCamera(this.props.cameraId);
    }
    if (this.state.mirror !== this.props.documentCaptureConfiguration.mirror) {
      this.setState({ mirror: this.props.documentCaptureConfiguration.mirror })
    }
    this.setCanvasDimensions();
  }

  setCanvasDimensions = () => {
    if (document.querySelector(".ui-canvas-container")) {
      const { clientWidth, clientHeight } = document.querySelector(".ui-canvas-container");
      if (this.videoRef !== undefined && this.videoRef.current !== undefined) {
        this.videoRef.current.width = clientHeight;
        this.videoRef.current.height = clientWidth;
      }
      if (this.videoContainer !== undefined && this.videoContainer.ready) {
        this.videoContainer.video.videoHeight = clientWidth;
        this.videoContainer.video.videoWidth = clientHeight;
      }
    }
  };

  initializeCamera(cameraId) {
    let camId = cameraId ? cameraId : this.props.cameraId;
    this.props.docCaptureInstance
      .startCamera(
        this.videoRef.current,
        camId
      )
      .then(({ videoEl }) => {
        videoEl.setAttribute("playsInline", "")
        videoEl.style.display = "inherit"
        videoEl.style.transform = ""
        if (this.props.docCaptureInstance.camera.videoTracks.length) {
          const trackSettings = this.props.docCaptureInstance.camera.videoTracks[0].getSettings();
          this.setState({
            width: trackSettings.width,
            height: trackSettings.height,
          });
          this.props.cameraResolution({
            width: trackSettings.width,
            height: trackSettings.height,
          });
        } else {
          this.setState({
            width: videoEl.videoWidth,
            height: videoEl.videoHeight,
          });
          this.props.cameraResolution({
            width: videoEl.videoWidth,
            height: videoEl.videoHeight,
          });
        }
        this.props.toggleLoading();
        if (typeof this.props.onCameraStarted === "function") {
          this.props.onCameraStarted(this.props.docCaptureInstance, videoEl);
        }
      })
      .catch((err) => {
        if (this.props.selectedCamera !== undefined && this.props.selectedCamera.deviceId !== undefined && this.props.selectedCamera.deviceId !== cameraId) {
          if (err.toString().indexOf("PermissionDeniedError") >= 0) {
            if (this.props.selectedCamera !== undefined && this.props.selectedCamera.deviceId !== undefined && this.props.selectedCamera.deviceId !== cameraId) {
              this.props.cameraPermissionDenied();
            }
          } else if (err.toString().indexOf("SourceUnavailableError") >= 0) {
            this.props.cameraSourceUnavailable();
          } else if (err.toString().indexOf("NotReadableError") >= 0) {
            this.props.cameraSourceUnavailable();
          } else {
            this.props.cameraPermissionDenied();
          }
        }
      });
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  reinitializeCamera = (camId) => {
    this.props.docCaptureInstance.stopAutoCapture();
    try {
      this.props.docCaptureInstance.camera.videoTracks.forEach(e => e.stop());
      this.props.docCaptureInstance.stopCamera();
    } catch (err) { }
    this.props.docCaptureInstance.config.facingMode = this.props.documentCaptureConfiguration.facingMode;
    let cameraId = camId ? camId : this.props.cameraId
    this.initializeCamera(cameraId);
  };

  componentWillUnmount = () => {
    if (this.props.docCaptureInstance) {
      this.props.docCaptureInstance.stopAutoCapture();

      try {
        this.props.docCaptureInstance.stopCamera();
      } catch (err) { }
    }
    window.removeEventListener("resize", this.drawIdCard);
    window.removeEventListener("orientationchange", this.drawIdCard);
  };

  render() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    const shouldScaleEdge = isEdge ? "ui-canvas scale-edge" : "ui-canvas";
    const containerCanvas = `ui-canvas-container ui-canvas`;
    let containerClassName;
    if (this.props.captureType === "LANDSCAPE") {
      containerClassName = this.state.isIOS
        ? "landscape-safari"
        : "others-landscape";
    } else if (isEdge) {
      containerClassName = "ui-canvas-container hiddenEdge";
    } else {
      containerClassName = "ui-canvas-container";
    }

    // let docGuideClassName;
    // let docRatio;
    // if (this.props.documentType === "ID_CARD") {
    //   docGuideClassName = "document-idcard";
    //   docRatio = "3.4x2.1";
    // } else if (this.props.documentType === "PASSPORT") {
    //   docGuideClassName = "document-passport";
    //   docRatio = "4.9x3.5";
    // } else if (this.props.documentType === "MONK_ID_DOCUMENT") {
    //   docGuideClassName = "document-idcard";
    //   docRatio = "3.4x2.1";
    // } else if (this.props.documentType === "COPY_ID_CARD") {
    //   docGuideClassName = "document-idcard";
    //   docRatio = "3.4x2.1";
    // } else {
    //   docGuideClassName = "document-other";
    //   docRatio = "1x1.4142";
    // }

    const shouldBeFlipped = !this.state.mirror ? "flip-x" : "";
    const videoClassName = `calculating-canvas ${shouldScaleEdge} ${shouldBeFlipped}`;
    const containerStyle = "justify-content: center; align-items: center";
    return (
      <div className={containerClassName} style={{ containerStyle }}>
        <canvas
          style={{ zIndex: 10 }}
          className={containerCanvas}
          ref="canvas"
          id="guidingFrame"
          width={480}
          height={640}
        />
        <video className={videoClassName} ref={this.videoRef}></video>
        {/* <div className={docGuideClassName} ratio={docRatio}></div> */}
      </div>
    );
  }
}

const componentWithTranslation = withTranslation()(DocumentCaptureUI);
export default connect(
  (state) => {
    return {
      ...state.documentCapture,
      documentCaptureConfiguration: state.documentCaptureConfiguration
    }
  },
  { cameraResolution }
)(componentWithTranslation);
