import { PREVIEW, RETRY, RESET } from "../actions/faceCapture";

export function faceCapture(
  state = {
    retries: 0,
  },
  action
) {
  switch (action.type) {
    case PREVIEW:
      return {
        ...state,
        ...action.payload,
      };
    case RETRY:
      return {
        ...state,
        blob: null,
        retries: state.retries + 1,
      };
    case RESET:
      return {
        retries: 0,
        blob: null
      }
    default:
      return state;
  }
}
