export const SET_START_EXPIRE_TIME = "SET_START_EXPIRE_TIME";
export const SET_STAMP_TIME = "SET_STAMP_TIME"
export const GET_START_EXPIRE_TIME = "GET_START_EXPIRE_TIME";

export function setExpireTimeOut (payload){
    return {type: SET_START_EXPIRE_TIME, payload}
}

export function setStampTimeOut (payload){
    return {type: SET_STAMP_TIME, payload}
}

export function getExpireTimeOut (payload){
    return {type: GET_START_EXPIRE_TIME, payload}
}