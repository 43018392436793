export class canvasDrawingClass {
    constructor(canvas, ctx, imageGuiding) {
        this.ctx = ctx;
        this.canvas = canvas;
        this.imageGuiding = imageGuiding;
    }

    drawOutline(img, adjScale, clientWidth, clientHeight) {
        this.ctx.clearRect(0, 0, this.canvas.width * 1.5, this.canvas.height);
        let scale = (clientWidth / img.width) * adjScale;
        let dx = (this.canvas.width - img.width * scale) / 2;
        let dy = (this.canvas.height - img.height * scale) / 2;

        this.ctx.globalAlpha = 0.6;
        this.ctx.fillStyle = "#5c6e77";
        this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height)

        this.ctx.clearRect(dx, dy, img.width * scale, img.height * scale)
        this.ctx.globalAlpha = 1.0;
        this.ctx.drawImage(
            img,
            dx,
            dy,
            img.width * scale,
            img.height * scale
        );
        // this.ctx.globalCompositeOperation = "source-in";

        // // draw color
        // this.ctx.fillStyle = "#09f";
        // this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    }
}
