const defaultState = {
  frontCamera: undefined,
  rearCamera: undefined,
  selectedCamera: undefined,
  cameras: [],
  flVideoRef: undefined,
};

const CAMERA_SET_CAMERA_LIST = "CAMERA_SET_CAMERA_LIST";
const CAMERA_SET_SELECTED_CAMERA = "CAMERA_SET_CASELECTED_CAMERA";
const CAMERA_SET_SELECTED_REAR = "CAMERA_SET_REAR_CAMERA";
const CAMERA_SET_SELECTED_FRONT = "CAMERA_SET_FRONT_CAMERA";
const VIDEO_3DFL = "3DFL_VIDEO_REFERENCE";

export function setCameraList(cameraList) {
  return {
    type: CAMERA_SET_CAMERA_LIST,
    payload: cameraList,
  };
}

export function setSelectedCamera(device){
  return {
    type: CAMERA_SET_SELECTED_CAMERA,
    payload: device,
  };
}

export function setFrontCamera(device){
  return {
    type: CAMERA_SET_SELECTED_FRONT,
    payload: device,
  };
}

export function setRearCamera(device){
  return {
    type: CAMERA_SET_SELECTED_REAR,
    payload: device,
  };
}

export function set3DFLVideoRef(videoRef){
  return {
    type: VIDEO_3DFL,
    payload: videoRef,
  }
}

export function camera(state = defaultState, action) {
  switch(action.type){
    case CAMERA_SET_CAMERA_LIST:
      return { ...state, cameras: action.payload };
    case CAMERA_SET_SELECTED_CAMERA:
      return { ...state, selectedCamera: action.payload };
    case CAMERA_SET_SELECTED_FRONT:
      return { ...state, frontCamera: action.payload };
    case CAMERA_SET_SELECTED_REAR:
      return { ...state, rearCamera: action.payload };
    case VIDEO_3DFL:
      return { ...state, flVideoRef: action.payload};
    default:
      return state;
  }
}
