const actionPrefix = "SECOND_DOCUMENT_";

export const SET_STATUS_IMAGE_SECOND_TYPE = actionPrefix + "SET_STATUS_IMAGE_SECOND_TYPE";
export const IMAGE_SET_SECOND_PAGE = actionPrefix + "IMAGE_SET_SECOND_PAGE"
export const SET_IMAGE = actionPrefix + "SET_IMAGE_SECOND_DOCUMENT"
export const RETRY_DOCUMENT_SECOND_TYPE_CAPTURE = actionPrefix + "RETRY_DOCUMENT_SECOND_TYPE_CAPTURE"
export const SET_TYPE_SELECT_SECOND_DOCUMENT = actionPrefix + "SET_TYPE_SELECT_SECOND_DOCUMENT"
export const RESET_SECOND_DOCUMENT = actionPrefix + "RESET_SECOND_DOCUMENT"
export const ROLL_BACK_IMAGE_STATUS = actionPrefix + "ROLL_BACK_IMAGE_STATUS"

export function setStatusImageSecond(payload) {
    return { type: SET_STATUS_IMAGE_SECOND_TYPE, payload }
}

export function retryDocumentCapture(payload) {
    return { type: RETRY_DOCUMENT_SECOND_TYPE_CAPTURE, payload }
}

export function setImageSecondDocument(payload) {
    return { type: SET_IMAGE, payload }
}

export function setTypeSelectSecondDocument(payload) {
    return { type: SET_TYPE_SELECT_SECOND_DOCUMENT, payload }
}

export function resetSecondDocument(payload) {
    return { type: RESET_SECOND_DOCUMENT, payload }
}

export function rollBackImageStatus(payload) {
    return {
        type: ROLL_BACK_IMAGE_STATUS,
        payload
    }
}