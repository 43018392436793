// import i18n from "../../i18n"

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE"

const defaultState = {
    setupLanguage: "th"
}
export function changeMultiLanguage(state = defaultState, action){
    switch(action.type){
        case CHANGE_LANGUAGE :
            sessionStorage.setItem('LANGUAGE', action.payload.setupLanguage)
            return state = action.payload
        default:
            try{
                if(!sessionStorage.getItem('LANGUAGE')){
                    sessionStorage.setItem('LANGUAGE', state.setupLanguage)
                }else{
                    sessionStorage.removeItem('LANGUAGE')
                    sessionStorage.setItem('LANGUAGE', state.setupLanguage)
                }
            }
            catch(err){
                sessionStorage.setItem('LANGUAGE', state.setupLanguage)
            }
            
            return state
    }

}

export const checkLanguage = (state) => {
    return state.changeMultiLanguage.setupLanguage
}