// export const width = 3840;
// export const height = 2160;

// export const width =  2160;
// export const height = 1620;

export const width =  window.innerWidth;
export const height = window.innerHeight;

export const secWidth = 2160;
export const secHeight = 1620;
