import Axios from "axios";
import { toggleNetworkWarnings } from "logic/actions/apiFetch";
import { store } from "../store";
import i18n from "i18n";
import { go } from "logic/actions/navigation";
import { PAGE_SESSION_EXPIRED } from "logic/enums/pages";
// import { clearCache } from "logic/localCacheAdapter";

const axiosInstance = Axios.create({
  withCredentials: true,
});

axiosInstance.defaults.headers["Content-Type"] = "application/json";
// Override timeout default for the library
// Now all requests using this instance will wait 15 seconds before timing out
axiosInstance.defaults.timeout = 30000;

axiosInstance.interceptors.request.use((request) => {
  // console.log(request.url)
  dispatchToggleNetworkWarnings(true);
  if (
    request.url === "/actuator/info" ||
    request.url === "/url_configuration.json" ||
    request.url === "/userConsents"
  )
    return request;
  try {
    const { configuration } = store.getState();
    if (request.url === `${configuration.gdprServiceHostname}/userConsents`)
      return request;

    const { tenant } = configuration;
    const subUrl = tenant
      ? `/${tenant.replace(/\//g, "")}${request.url}`
      : request.url;

    //for split request.url = `${configuration.sampleAppHostname}${subUrl}`;
    request.url = `${subUrl}`;
    // console.log(request.url)
  } catch (err) {
    //axios intercepts errors and ommits it from console
    console.log("Axios interceptor error: Request add tenant to URL", err);
  }
  return request;
});

axiosInstance.interceptors.response.use(
  //2xx
  function (response) {
    if (response.headers) {
      const csrfHeaderKey = response.headers["x-csrf-header"];
      if (csrfHeaderKey) {
        axiosInstance.defaults.headers.common[csrfHeaderKey] =
          response.headers[csrfHeaderKey.toLowerCase()];
      }
    }
    dispatchToggleNetworkWarnings(false);
    return response;
  },
  //> 2xx
  function (error) {
    dispatchToggleNetworkWarnings(false);
    if (error.code === "ECONNABORTED") {
      return Promise.reject(new Error("Connection time out"));
    } else if (
      (error.response.status === 401 &&
        error.response.config) ||
      error.response.status === 403
    ) {
      //usually happens when JWT token expires on the middle tier
      store.dispatch(go(PAGE_SESSION_EXPIRED));
      // clearCache();
      return Promise.reject(new Error(i18n.t("ApiMessaging.session_expired")));
    }
    return Promise.reject(error);
  }
);
// console.log("2")
function dispatchToggleNetworkWarnings(toggle) {
  try {
    store.dispatch(toggleNetworkWarnings(toggle));
  } catch (err) {
    //axios intercepts errors and ommits it from console
    console.log(
      "Axios interceptor error: Request dispatch network warning",
      err
    );
  }
}

export { axiosInstance };
