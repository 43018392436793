/*global Daon */
/*eslint no-undef: "error"*/
import React, { Component } from "react";
import { connect } from "react-redux";
import { string, func, number, bool } from "prop-types";
import { changeCamera, flipCamera } from "logic/actions/documentCaptureConfiguration";
import { store } from "logic/store";

class FaceCaptureUI extends Component {
  static propTypes = {
    url: string,
    width: number,
    height: number,
    onInitialized: func,
    onCameraStarted: func,
    cameraPermissionDenied: func,
    cameraSourceUnavailable: func,
    cameraRetryInit: bool
  };

  constructor(props) {
    super(props);
    this.videoRef = new React.createRef();
    this.state = {
      facingMode: "user",
      mirror: false
    }
  }

  componentDidMount() {
    this.props.flipCamera({
      mirror: this.state.mirror
    })

    const { url, width, height } = this.props;
    // const xWidth = 1280;
    // const xHeight = 720;
    this.fc = new Daon.FaceCapture({ url, width, height });
    this.setState({ fc: this.fc })
    if (typeof this.props.onInitialized === "function") {
      this.props.onInitialized(this.fc);
    }

    const frontCamera = store.getState().camera.frontCamera;
    if (frontCamera !== undefined) {
      this.initializeCamera(frontCamera.cameraId);
    } else {
      this.initializeCamera()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.retryCameraInit !== this.props.retryCameraInit)
      this.initializeCamera();
  }

  componentWillUnmount() {
    if (this.fc) {
      if (this.videoRef && this.videoRef.current) {
        this.videoRef.current.srcObject = undefined;
      }
      this.fc.stopAutoCapture();
      try {
        this.fc.stopCamera();
      } catch (err) {}
    }
  }

  componentDidUpdate(prevProps, _) {
    if (prevProps.facingMode !== this.props.facingMode) {
      this.setState({ facingMode: this.props.facingMode })
      this.reinitializeCamera(this.props.cameraId);
    }
    else if (prevProps.cameraId !== this.props.cameraId) {
      this.setState({ facingMode: this.props.facingMode })
      this.reinitializeCamera(this.props.cameraId);
    }
    if (this.state.mirror !== this.props.mirror) {
      this.setState({ mirror: this.props.mirror })
    }
  }

  initializeCamera(cameraId) {
    this.fc
      .startCamera(this.videoRef.current, cameraId)
      .then(() => {
        this.fc.videoEl.style = "";
        if (typeof this.props.onCameraStarted === "function")
          this.props.onCameraStarted(this.fc, this.videoRef.current);
      })
      .catch((err) => {
        if (err.toString().indexOf("NotAllowedError") >= 0 || err.toString().indexOf("SecurityError") >= 0) {
          this.props.cameraPermissionDenied();
        } else if (err.toString().indexOf("NotFoundError") >= 0 || err.toString().indexOf("AbortError") > 0 || err.toString().indexOf("NotReadableError") >= 0) {
          this.props.cameraSourceUnavailable();
        } else {
          this.props.cameraPermissionDenied();
        }
      });
  }

  reinitializeCamera(cameraId) {
    this.fc.stopAutoCapture();
    try {
      this.fc.stopCamera();
    } catch (err) {}
    const { url, width, height } = this.props;
    this.fc = new Daon.FaceCapture({ url, width, height });
    this.fc.config.facingMode = this.props.facingMode;
    if (typeof this.props.onInitialized === "function") {
      this.props.onInitialized(this.fc);
    }
    setTimeout(() => {
      this.fc
        .startCamera(this.videoRef.current, cameraId)
        .then(({ videoEl }) => {
          // this.fc.videoEl.style = ""
          if (typeof this.props.onCameraStarted === "function")
            this.props.onCameraStarted(this.fc, this.videoRef.current);
        })
        .catch((err) => {
          if (err.toString().indexOf("NotAllowedError") >= 0 || err.toString().indexOf("SecurityError") >= 0) {
            this.props.cameraPermissionDenied();
          } else if (err.toString().indexOf("NotFoundError") >= 0 || err.toString().indexOf("AbortError") > 0 || err.toString().indexOf("NotReadableError") >= 0) {
            this.props.cameraSourceUnavailable();
          } else {
            this.props.cameraPermissionDenied();
          }
        });
    }, 100)
  }

  render() {
    const isEdge = window.navigator.userAgent.indexOf("Edge") > -1;
    const shouldBeFlipped = !this.state.mirror ? "flip-x" : "";
    const shouldScaleEdge = isEdge ? "ui-canvas scale-edge" : "ui-canvas";
    const videoClassName = `calculating-canvas ${shouldScaleEdge} ${shouldBeFlipped}`;
    const containerClassName = isEdge
      ? "ui-canvas-container hiddenEdge"
      : "ui-canvas-container";

    return (
      <div className={containerClassName}>
        <video className={videoClassName} ref={this.videoRef}></video>
        {isEdge ? null : <div className={this.props.className || ""}></div>}
      </div>
    );
  }
}

export default connect(
  (state) => {
    const { cameraId, facingMode, mirror } = state.documentCaptureConfiguration;

    return {
      cameraId,
      facingMode,
      mirror
    }
  },
  {
    changeCamera,
    flipCamera
  }
)(FaceCaptureUI);
