import React, { Component } from "react";
import { connect } from "react-redux"
import "./style.css"
import { withTranslation } from "react-i18next";
import PageContent from "Components/PageContent";
import { go } from "logic/actions/navigation";
import { func, string, instanceOf } from "prop-types";
import {
    // eslint-disable-next-line
    NEW_DOCUMENT_CAPTURE_INSTRUCTIONS,
    DOCUMENT_CAPTURE_PORTRAIT
} from "../../../../logic/enums/pages"
import RoundIcon from "../../../RoundIcon";
import Icon from "../../../Icon";
import {
    ID_CARD,
    // eslint-disable-next-line
    PASSPORT,
    COPY_ID_CARD,
    COPY_PASSPORT,
    MONK_ID_DOCUMENT,
    OFFICIAL_ID_DOCUMENT
} from "logic/enums/documentType";
import {
    documentSidesSelector,
    verticalDocumentsPresent,
    changeCamera,
    flipCamera,
    isSinglePaged,
} from "logic/actions/documentCaptureConfiguration";
import {
    selectDocumentType,
    setDocumentQualityRetries,
    newDocumentSelected,
} from "logic/actions/documentCapture";
import { setExpireTimeOut } from "logic/actions/expireTimeOut";
import iconMonk from "../../../../assets/icons/16-24/book-monk.svg";
import iconDocGrov from "../../../../assets/icons/16-24/document-groverment.svg";
import {
    width,
    height
} from "logic/documentResolution";
import {
    createInstance,
    checkActionFromPortrait
} from "logic/actions/documentCapture";

const START = 0;
class PageDocumentCaptureOtherSelect extends Component {
    static propTypes = {
        selectDocumentType: func,
        go: func,
        documentType: string,
        base64CroppedFirstPage: instanceOf(Blob),
        configuration: instanceOf(Object),
        documentCapture: instanceOf(Object),
    };

    constructor(props) {
        super(props)
        this.state = {
            dc: undefined,
            pending: false,
            message: "",
            formState: START,
            shouldStartNativeCamera: false,
            // width: 2560,
            // height: 1440,
            facingMode: "environment",
        }
        this.go.bind(this);
        if (this.props.documentCapture) {
            this.props.changeCamera({
                cameraId: this.props.cameraId,
                facingMode: this.state.facingMode
            })
            this.props.flipCamera({ mirror: true })
        }
    }

    componentDidMount() {
        if (!this.props.documentCapture.base64CroppedFirstPage &&
            this.props.documentCapture.documentType !== "PASSPORT") {
            this.documentPagesNum(this.props.documentCapture.documentType);
        }
        if (!this.props.verticalDocumentsPresent) {
            this.changeDocumentOrientation("default");
        }
    }
    initDocumentCaptureSDK = (url, width, height, documentType, facingMode) => {
        const config = {
            url,
            width,
            height,
            facingMode,
            captureType: "PORTRAIT",
            documentType: documentType === "PASSPORT" ? documentType : "ID_CARD",
        };
        this.props.createInstance(config);
    };
    documentPagesNum = (type) => {
        switch (type) {
            case ID_CARD:
                this.props.isSinglePaged(true);
                break;
            case COPY_ID_CARD:
                this.props.isSinglePaged(true);
                break;
            case COPY_PASSPORT:
                this.props.isSinglePaged(true);
                break;
            case MONK_ID_DOCUMENT:
                this.props.isSinglePaged(true);
                break;
            case OFFICIAL_ID_DOCUMENT:
                this.props.isSinglePaged(true);
                break;
            default:
                this.props.isSinglePaged(false);
        }
    };
    changeDocumentOrientation(orientation) {
        if (orientation === "vertical") {
            switch (this.props.documentCapture.documentType) {
                case "ID_CARD": {
                    this.props.selectDocumentType("ID_CARD_PORT");
                    break;
                }
                case "DRIVERS_LICENCE": {
                    this.props.selectDocumentType("DRIVERS_LICENCE_PORT");
                    break;
                }
                default: {
                    this.props.selectDocumentType("OTHER_DOCUMENT_PORT");
                }
            }
        } else if (orientation === "default") return;
        else {
            switch (this.props.documentCapture.documentType) {
                case "ID_CARD_PORT": {
                    this.props.selectDocumentType("ID_CARD");
                    break;
                }
                case "DRIVERS_LICENCE_PORT": {
                    this.props.selectDocumentType("DRIVERS_LICENCE");
                    break;
                }
                default: {
                    this.props.selectDocumentType("OTHER_DOCUMENT");
                }
            }
        }
    }
    go = (type) => {
        const { documentQualityHostname } = this.props.configuration;
        const url = documentQualityHostname + "/rest/v1/quality/assessments";
        const xWidth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH) <= 0 ? width : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_WIDTH);
        const xHeigth = parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT) <= 0 ? height : parseInt(process.env.REACT_APP_USE_DOCUMENT_RESOLUTION_HEIGHT);
        this.initDocumentCaptureSDK(
            url,
            xWidth,
            xHeigth,
            type,
            this.state.facingMode
        );
        this.props.selectDocumentType(type);
        this.props.newDocumentSelected();
        this.documentPagesNum(type);
        this.props.checkActionFromPortrait(false);
        this.changeDocumentOrientation("default");
        this.props.go(DOCUMENT_CAPTURE_PORTRAIT);
    };
    goToCopyIdCard = () => this.go(COPY_ID_CARD);
    goToCopyPassport = () => this.go(COPY_PASSPORT);
    goToMonkIdDocument = () => this.go(MONK_ID_DOCUMENT);
    goToOfficialIdDocument = () => this.go(OFFICIAL_ID_DOCUMENT);

    componentWillUnmount() {
        if (this.props.documentCapture.docCaptureInstance) {
            this.props.documentCapture.docCaptureInstance.clearCapturedFrames();
        }
    }
    render() {
        return (
            <PageContent toggleLoading={false} progressBar={false}>
                <div style={{ marginBottom: "20%" }}>
                    <h1>{this.props.t("PageDocumentCaptureOtherSelect.header")}</h1>
                    <div
                        className="panel-doc-type mx-2"
                        data-type={COPY_ID_CARD}
                        onClick={this.goToCopyIdCard}
                    >
                        <RoundIcon color="red">
                            <Icon name="ID-card" color="white" />
                        </RoundIcon>
                        <div className="w-full">
                            <div>
                                <h3 style={{ fontFamily: 'AIA Regular' }} className="mt-3">{this.props.t("PageDocumentCaptureOtherSelect.copy_of_idcard")}</h3>
                            </div>
                        </div>
                    </div>
                    <div
                        className="panel-doc-type mx-2"
                        data-type={MONK_ID_DOCUMENT}
                        onClick={this.goToMonkIdDocument}
                    >
                        <RoundIcon color="red">
                            <img src={iconMonk} alt="" className="icon" />
                        </RoundIcon>
                        <div className="w-full">
                            <div>
                                <h3 style={{ fontFamily: 'AIA Regular' }} className="mt-3">{this.props.t("PageDocumentCaptureOtherSelect.book_of_monk")}</h3>
                            </div>
                        </div>
                    </div>
                    <div
                        className="panel-doc-type mx-2"
                        data-type={OFFICIAL_ID_DOCUMENT}
                        onClick={this.goToOfficialIdDocument}
                    >
                        <RoundIcon color="red">
                            <img src={iconDocGrov} alt="" className="icon" />
                        </RoundIcon>
                        <div className="w-full">
                            <div>
                                <h3 style={{ fontFamily: 'AIA Regular' }} className="mt-3">{this.props.t("PageDocumentCaptureOtherSelect.official_id_document")}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </PageContent>
        )
    }
}

const componentWithTranslation = withTranslation()(PageDocumentCaptureOtherSelect)
export default connect(
    (state) => {
        return {
            ...state.setApplicationParams,
            ...state.configuration.extraConfig.featureFlags,
            documentCapture: state.documentCapture,
            configuration: state.configuration,
            ...state.documentCaptureConfiguration,
        }
    },
    {
        go,
        selectDocumentType,
        setDocumentQualityRetries,
        newDocumentSelected,
        setExpireTimeOut,
        documentSidesSelector,
        verticalDocumentsPresent,
        changeCamera,
        flipCamera,
        isSinglePaged,
        createInstance,
        checkActionFromPortrait
    }
)(componentWithTranslation)

